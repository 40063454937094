import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from 'redux-persist';
import createIndexedDBStorage from 'redux-persist-indexeddb-storage';
import rootReducer from "./rootReducer";

// Use IndexedDB storage instead of localStorage
const storage = createIndexedDBStorage({
  name: 'myAppDB',         // Name of the IndexedDB database
  storeName: 'persistStore', // Name of the object store within the IndexedDB database
});

// Persist config using IndexedDB storage
const persistConfig = {
  key: 'root',
  storage,
  // You can use a whitelist or blacklist to control which reducers are persisted
  // whitelist: ['reducer1', 'reducer2'],
  // blacklist: ['largeReducer'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

 const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export const persistor = persistStore(store);

// Optional: Handle any errors, including QuotaExceededError
persistor.subscribe(() => {
  try {
    // Handle storage or other logic if needed
  } catch (error) {
    if (error.name === 'QuotaExceededError') {
      console.error('Storage quota exceeded in IndexedDB');
      persistor.purge(); // Optionally, clear the storage
    }
  }
});

export default store;
