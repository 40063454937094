import {
  FETCH_COUNTRYSETTINGS_REQUEST,
  FETCH_COUNTRYSETTINGS_SUCCESS,
  FETCH_COUNTRYSETTINGS_ERROR
} from "./countryTypes";

const initialState = {
  loading: false,
  countryData: {},
  error: ""
};

const countryReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COUNTRYSETTINGS_REQUEST:
      return { ...state, loading: true };
    case FETCH_COUNTRYSETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        countryData: action.payload,
        error: ""
      };
    case FETCH_COUNTRYSETTINGS_ERROR:
      return {
        loading: false,
        countryData: {},
        error: action.payload
      };
    default:
      return state;
  }
};
export default countryReducer;
