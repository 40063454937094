import {
    FETCH_LOCALE_REQUEST,
    FETCH_LOCALE_SUCCESS,
    FETCH_LOCALE_ERROR,
    UPDATE_RENDER_LOCALE_LIST
  } from "./localeTypes";
  
  const initialState = {
    loading: false,
    countryLocale: {},
    error: ""
  };
  
  const localeReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_LOCALE_REQUEST:
        return { ...state, loading: true };
      case FETCH_LOCALE_SUCCESS:
        return {
          ...state,
          loading: false,
          countryLocale: action.payload,
          error: ""
        };
      case  FETCH_LOCALE_ERROR:
        return {
          loading: false,
          countryLocale: {},
          error: action.payload
        };
      default:
        return state;
        case   UPDATE_RENDER_LOCALE_LIST:
      return {
        ...state,
        countryLocale: action.payload,
      };
    }
  };
  export default localeReducer;