import {
  FETCH_ASSETS_REQUEST,
  FETCH_ASSETS_SUCCESS,
  FETCH_ASSETS_ERROR,
  FETCH_FAVASSETS_SUCCESS,
  FETCH_FAVASSETS_ERROR,
  FETCH_CARTASSETS_SUCCESS,
  FETCH_CARTASSETS_ERROR,
  UPDATE_CARTASSETS_LIST,
  UPDATE_ASSETS,
  UPDATE_RENDER_ASSET_LIST,
  UPDATE_FAVASSETS_LIST
} from "./assetTypes";

export const fetchAssetsRequest = () => {
  return {
    type: FETCH_ASSETS_REQUEST
  };
};

export const fetchAssetsSuccess = assets => ({
  type: FETCH_ASSETS_SUCCESS,
  payload: assets
});

export const fetchAssetsError = error => {
  return {
    type: FETCH_ASSETS_ERROR,
    payload: error
  };
};

export const setRenderAssetList = assets => ({
  type: UPDATE_RENDER_ASSET_LIST,
  payload: assets
});

export const setAssets = assets => ({
  type: UPDATE_ASSETS,
  payload: assets
});

export const fetchFavAssetsSuccess = assets => ({
  type: FETCH_FAVASSETS_SUCCESS,
  payload: assets
});

export const fetchFavAssetsError = error => ({
  type: FETCH_FAVASSETS_ERROR,
  payload: error
});

export const setFavAssetsList = assets => ({
  type: UPDATE_FAVASSETS_LIST,
  payload: assets
});
export const fetchCartAssetsSuccess = assets => ({
  type: FETCH_CARTASSETS_SUCCESS,
  payload: assets
});

export const fetchCartAssetsError = error => ({
  type: FETCH_CARTASSETS_ERROR,
  payload: error
});

export const setCartAssetsList = assets => ({
  type: UPDATE_CARTASSETS_LIST,
  payload: assets
});
export const updateAssets = ownProps => {
  return dispatch => {
    dispatch(setAssets(ownProps));
  };
};

export const updateRenderAssetList = ownProps => {
  return dispatch => dispatch(setRenderAssetList(ownProps));
};

export const updateFavAssetsList = ownProps => {
  return dispatch => dispatch(setFavAssetsList(ownProps));
};

export const updateSetRenderAssetList = ownProps => {
  return dispatch => dispatch(fetchAssetsSuccess(ownProps));
};
export const updateCartAssetsList = ownProps => {
  return dispatch => dispatch(setCartAssetsList(ownProps));
};

export const updateSetRenderCartAssetList = ownProps => {
  return dispatch => dispatch(fetchAssetsSuccess(ownProps));
};