import React from "react";
import "./checkBox.scss";
import { useTranslation } from "react-i18next";

export const CheckBox = props => {
  const { t } = useTranslation("common");
  let setValue, displayValue;
  if (Object.keys(props).includes("brandName")) {
    setValue = props.brandName;
  } else if (Object.keys(props).includes("sub_brand")) {
    setValue = props.sub_brand;
  } else if (Object.keys(props).includes("asset_type")) {
    setValue = props.asset_type.dcAssetType;
    displayValue = t(`Asset Type.${setValue}`);
  } else if (Object.keys(props).includes("file_type")) {
    setValue = props.file_type;
  } else if (Object.keys(props).includes("release_date")) {
    setValue = props.release_date;
  } else {
    setValue = props.name;
  }
  if (setValue === undefined || setValue.length === 0) {
    return null;
  }

  return (
    <div className="checkbox">
      <input
        type="checkbox"
        key={setValue}
        id={setValue}
        name={setValue}
        value={setValue}
        filtertype={props.filterType}
        onChange={event => props.handleFilterRemOnChange(event)}
        checked={props.itemChecked}
      ></input>
      <span>
        <i className="bi bi-check"></i>
      </span>
      <label htmlFor={setValue}>
        {Object.keys(props).includes("asset_type") ? displayValue : t(setValue)}
      </label>
      <br></br>
    </div>
  );
};

export default CheckBox;
