export const FETCH_ASSETS_REQUEST = "FETCH_ASSETS_REQUEST";
export const FETCH_ASSETS_SUCCESS = "FETCH_ASSETS_SUCCESS";
export const FETCH_ASSETS_ERROR = "FETCH_ASSETS_ERROR";
export const UPDATE_ASSETS = "UPDATE_ASSETS";
export const UPDATE_RENDER_ASSET_LIST = "UPDATE_RENDER_ASSET_LIST";
export const FETCH_FAVASSETS_SUCCESS = "FETCH_FAVASSETS_SUCCESS";
export const FETCH_FAVASSETS_ERROR = "FETCH_FAVASSETS_ERROR";
export const UPDATE_FAVASSETS_LIST = "UPDATE_FAVASSETS_LIST";
export const FETCH_CARTASSETS_SUCCESS = "FETCH_CARTASSETS_SUCCESS";
export const FETCH_CARTASSETS_ERROR = "FETCH_CARTASSETS_ERROR";
export const UPDATE_CARTASSETS_LIST = "UPDATE_CARTASSETS_LIST";
