import { useEffect, useState} from "react";
import {
  Container, 
  FormControl, 
  Button,
  InputLabel, 
  Select,
  MenuItem,
  Checkbox, 
  TextField,
  } from "@material-ui/core";
import Autocomplete from '@mui/material/Autocomplete';
import { Modal } from 'react-bootstrap'
import axios from "axios";
//import AuthContext from "../../components/Auth/Auth-context";
import { createFilterOptions } from '@mui/material/Autocomplete';
import { useHistory } from "react-router-dom";
import DotLoader from "react-spinners/DotLoader";
import config from "../../config";
import { getCountryCode } from "../../util/Helper.js";
import { css } from "@emotion/react";
import './LinkProducts.css';

const filterOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: (option) => {
    return option.ptitle + option.ecompackage + option.pznorean
  },
});

export function LinkProducts() {
    const [retailersList, setRetailersList] = useState([]);
    const [selectedRetailer,setSelectedRetailer] = useState([]);
    const [productList, setProductList] = useState([]);
    const [autoCompleteValues, setAutoCompleteValues]  = useState([]);
    const [responseModal, setResponseModal] = useState(false);
    const [linkProductResponse, setLinkProductResponse] = useState("");
    const [isResponse, setIsResponse] = useState(false);
    const [countryFilter, setcountryFilter] = useState("");
    const [countryOptions, setCountryOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');
    //const AuthCtx = useContext(AuthContext);
    //const userId = AuthCtx.giveToken();
    const userId= localStorage.email
    let history = useHistory();
      // eslint-disable-next-line
    const [gigyaRole, setGigyaRole] = useState();
let userProfile
  const postGigyaID = async () => {
    const response = await axios({
      method: "get",
      url: `${config.baseApi}dc/exports/getrolepermission/${userId}`,
      data: {
        "useAdministration": {
          "Gigya_ID": userId,
        }
      },
      headers: {
        "X-Country-Code": `${getCountryCode()}`
      }
    })
    setGigyaRole(response?.data?.data?.roleName);
  }

    const override = css`
  display: block;
  margin: 0 auto;
`;

    useEffect(() => {
      postGigyaID ();
        getData(countryFilter);
        getCountries();
    //eslint-disable-next-line react-hooks/exhaustive-deps
    },[countryFilter])
    
    const getData = async (e) => {
      userProfile= (gigyaRole === "Global Admin")? e.toLowerCase() : `${getCountryCode()}`
        const response = await axios
          .get(
            `${config.baseApi}dc/exports/getDataForLinkItems`,
            // `https://3kvzvhjhql.execute-api.eu-central-1.amazonaws.com/Stage/dc/exports/getDataForLinkItems`,

            {
              headers: {
                "X-Country-Code": userProfile
              }
            }
          )
          setRetailersList(response.data.data.retailers);
          setProductList(response.data.data.entities);
      };

    const getCountries = async () => {
        const response = await axios
            .get(
                `${config.baseApi}dc/config/getCountries`,
            
            )
         
            const obj = {countryCode: "All", region: "All"};
            setCountryOptions([...response.data.data, obj])
        return response.data;
    };
    

      const handleChangeRetailer = (e) => {
        setSelectedRetailer(e.target.value)
      }

      const handleInputChange = (e) => {
        setInputValue(e.target.value)
      };
    
      const handleItemsChange = (event, value) => {
        event.preventDefault();
        setAutoCompleteValues(value);
      }

     

      const handleCancel = () => {
        setSelectedRetailer([])
        setAutoCompleteValues([])
        history.push('/home/my-exports')
      }
     

      const linkProducts = async () => {
        setIsResponse(true);
        let linkingProducts = [];
        userProfile= (gigyaRole === "Global Admin")? countryFilter.toLowerCase() : `${getCountryCode()}`

      autoCompleteValues.forEach(productname => {
        const findItem = productList.find(item => {
          return item.ptitle === productname.ptitle
    })
      
      linkingProducts.push({
        "ecompackage": findItem && findItem.ecompackage,
        "gigyaId": userId,
        "retailer":retailersList && retailersList.length > 0 ? selectedRetailer.retailer:inputValue,
        "countryCode": userProfile.toUpperCase(),
        "upc":findItem && findItem.upc
      })
      
    })
    
    await axios({
          method: "post",
          url: `${config.baseApi}dc/exports/linkitems`,
          data : linkingProducts,
          headers: {
            "X-Country-Code": userProfile,
            "X-User-Role": "Role"
          }
    })
    .then(response => {
      if(response && response.data && response.data.data){
        setIsResponse(false);
        setLinkProductResponse(response.data.data);
        setResponseModal(true);
      }
    })
   
      }

      const handleResponseModal = () => {
        setResponseModal(false)
        history.push('/home/my-exports')
      };

      const handlecountryChange = (evt) => {
        setcountryFilter(evt.target.value);
        getData(evt.target.value)
       // console.log("option1",evt.target.value)
    };

    return(
        <div id='link-products'>
          <Container className="border" style={{ marginTop: "5%", marginBottom: "5%", width: "80%" }}>
          <Container
          style={{
            textAlign: "left",
            paddingLeft: "2%",
            paddingBottom: "1%",
            fontWeight: "600",
            paddingTop: "2%"
          }}>
             {isResponse && 
             <div className="spinner d-flex align-items-center" >
             <DotLoader color={"#0091df"} css={override} size={65} marginTop={5} />
           </div>
              } 
            <h5 style={{ paddingBottom: "3%" }}>
            Link Products
          </h5>
          {(gigyaRole === "Global Admin")?
          <div>
            <FormControl variant="filled" className= 'selectUnderline' style={{ marginTop: "2%", width: "100%" }}>
          <InputLabel id="demo-simple-select-filled-label" style={{ marginTop: "2%" }}>Select Country</InputLabel>
          <Select
             labelId="demo-simple-select-label"
             id="demo-simple-select"
             value={countryFilter}
             label="Select Country"
             style={{ marginTop: "2%", width: "inherit" }}
             onChange={handlecountryChange}
             defaultValue={countryFilter}
             displayEmpty
             >
                {countryOptions.sort((a, b) => a.region > b.region ? 1 : -1).map((item, index) => {
               return (
                <MenuItem  className="countryInputRegion"  key={index} value={item?.countryCode}>
                {item?.region}
                </MenuItem>

               );
             })}

          </Select>
        </FormControl>
        <FormControl variant="filled" className= 'selectUnderline' style={{ marginTop: "2%", width: "100%" }}>
        {(countryFilter && retailersList && retailersList.length > 0  )? 
          <>
          <InputLabel id="demo-simple-select-filled-label"  style={{ marginTop: "1%" }}>
            Select Retailer
        </InputLabel>
        <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              style={{ marginTop: "2%", width: "inherit" }}
              value={selectedRetailer} onChange={handleChangeRetailer}
            >
              {retailersList.map((item, index) => {
                return (
                  <MenuItem key={index} value={item}> 
                  {item?.retailername}
                  </MenuItem>
                )
              })}

            </Select></> :
            (countryFilter === "" )? 
            ""
            :
            <TextField
            id="filled-basic"
            label="Retailer"
            variant="filled"
            value={inputValue}
            onChange={handleInputChange}
          ></TextField>
           //   <TextField
          //   fullWidth
          //   disabled = {true}
          //   id="filled-basic"
          //   label="Retailer"
          //   variant="filled"
          //   value="No Retailer Group Available. Go to retailer tab in the Administration to add Retailer"
          // ></TextField>
          }
          </FormControl>
          </div>
          :
          <FormControl variant="filled" className= 'selectUnderline' style={{ marginTop: "2%", width: "inherit" }}>
               {(retailersList && retailersList.length > 0  )? 
               <>
          <InputLabel id="demo-simple-select-filled-label"  style={{ marginTop: "1%" }}>
            Select Retailer
        </InputLabel>
        <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              style={{ marginTop: "2%", width: "inherit" }}
              value={selectedRetailer} onChange={handleChangeRetailer}
            >
              {retailersList.map((item, index) => {
                return (
                  <MenuItem key={index} value={item}> 
                  {item.retailername}
                  </MenuItem>
                )
              })}

            </Select></>
             :
             <TextField
             id="filled-basic"
             label="Retailer"
             variant="filled"
             value={inputValue}
           onChange={handleInputChange}
           ></TextField>
            //   <TextField
          //   fullWidth
          //   disabled = {true}
          //   id="filled-basic"
          //   label="Retailer"
          //   variant="filled"
          //   value="No Retailer Group Available. Go to retailer tab in the Administration to add Retailer"
          // ></TextField>
         }
          </FormControl>
          }
          <FormControl variant="filled"  className= 'selectUnderline' style={{ marginTop: "2%", width: "inherit" }}>

        <Autocomplete
      multiple
      id="tags-standard"
      options={productList}
      value={autoCompleteValues}
      disableCloseOnSelect
      filterOptions={filterOptions}
      onChange={handleItemsChange}
      getOptionLabel={(option) => {
        return option.ptitle
      }}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
             checked={selected}
            style={{ marginRight: 8 }}
          />
         {option.displayName}
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params}   variant="filled" label="Select Product(s)" placeholder="Search" />
      )}
    />

            <div style={{ display: "inline-block", width: "inherit", marginTop: "2%" ,marginLeft:"72%"}}>
              <Button variant="contained"  onClick={handleCancel} style={{ width: "13%",  marginRight: "2%", marginTop: "3%", border: "#CCC", marginBottom: "3%",color: "#FF3366", backgroundColor: "#FFFFFF", fontWeight:"700" }}><i>CANCEL</i></Button>
              <Button variant="contained"  onClick={linkProducts} style={{ width: "13%", marginTop: "3%", marginBottom: "3%", backgroundColor: "#FF3366", color:"#FFFFFF",fontWeight:"700" }}><i>SAVE</i></Button>
            </div>
        </FormControl>

            </Container>
          </Container>  

         
        <Modal show={responseModal} onHide={handleResponseModal} aria-labelledby="contained-modal-title-vcenter"centered >
          <Modal.Header >
              <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
          </Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>{linkProductResponse}</Modal.Body>
        <Modal.Footer>
            <Button variant="secondary"  onClick={handleResponseModal} style={{marginRight:"1%", backgroundColor: "#89d329"}}>
                Close
            </Button>
        </Modal.Footer>
        </Modal>

        </div>
    )
}