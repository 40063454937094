import React from "react";
import DotLoader from "react-spinners/DotLoader";
import { css } from "@emotion/react";
import { usePromiseTracker } from "react-promise-tracker";
export const ProgressLoader = props => {
  const override = css`
    display: block;
    margin: 0 auto;
  `;
  const divStyle = {
    height: "calc(100vh - 200px)"
  };
  const { promiseInProgress } = usePromiseTracker({ area: props.area });

  return promiseInProgress
    ? props.skeleton || (
        <div className="spinner d-flex align-items-center" style={divStyle}>
          <DotLoader color={"#0091df"} css={override} size={65} margin={2} />
        </div>
      )
    : props.render || "";
};
