import {
    FETCH_LOCALE_REQUEST,
    FETCH_LOCALE_SUCCESS,
    FETCH_LOCALE_ERROR,UPDATE_RENDER_LOCALE_LIST
  } from "./localeTypes";
  
  export const fetchLocaleRequest = () => ({
    type: FETCH_LOCALE_REQUEST
  });

  
  export const fetchLocaleSuccess = countryLocale => {
    return {
      type: FETCH_LOCALE_SUCCESS,
      payload: countryLocale
    };
  };
  
  export const fetchLocaleError = error => ({
    type: FETCH_LOCALE_ERROR,
    payload: error
  });


  export const setRenderLocaletList = countryLocale => ({
    type: UPDATE_RENDER_LOCALE_LIST,
    payload: countryLocale
  });

  export const updateRenderLocaleList = ownProps => {
    return dispatch => dispatch(setRenderLocaletList(ownProps));
  };