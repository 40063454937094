import React, { useEffect, useState, useRef, useContext } from "react";
import AuthContext from "../../components/Auth/Auth-context";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter, Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { updateRenderProductList, updateRenderAssetList } from "../../redux";
import CardComponent from "../../components/Card/card.jsx";
import "./home.scss";
import Slider from "react-slick";
import MediaQuery from "react-responsive";
import InternalSearch from "../../components/Search/search";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import config from "../../config";
import { getCountryCode } from "../../util/Helper.js";
import axios from "axios";
import MaterialTable from "material-table";
import { forwardRef } from 'react';
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { makeStyles} from '@mui/styles';
import { FormControl, InputLabel } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import "../../components/TermsOfUseModal/TermsOfUseModal";
import { assetCategories } from "../../util/Helper.js";
import AppMenuItemComponent from "../../components/AppMenu/AppMenuItemComponent";
import ListItemText from "@material-ui/core/ListItemText";
import LaunchIcon from '@mui/icons-material/Launch';
import Select from "@mui/material/Select";
import { MenuItem } from "@material-ui/core";
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import {
  fetchCountryCodeSuccess,
  fetchRoleSettingsSuccess,
} from "../../redux";
import store from "../../redux/store";
// import _ from "lodash";
// import i18next from "i18next";
var qs = require("qs");
var dayjs = require("dayjs");
var isBetween = require("dayjs/plugin/isBetween");
var duration = require("dayjs/plugin/duration");
dayjs.extend(duration);
dayjs.extend(isBetween);
const env = process.env.REACT_APP_ENVIRONMENT;
let ga_env = env === "PROD" ? "Production" : "Testing";
var now = dayjs();
var productExpDate = dayjs().subtract(90, "day");
var assetExpDate = dayjs().subtract(21, "day");
const setSearchParam = { showNewDigitalContent: true };

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="prev-arrow" onClick={onClick}>
      <i className="bi bi-chevron-left"></i>
    </div>
  );
}

function NextArrow(props) {
  const { onClick } = props;
  return (
    <div className="next-arrow" onClick={onClick}>
      <i className="bi bi-chevron-right"></i>
    </div>
  );
}

var settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        dots: false,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
      }
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
      }
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
      }
    }
  ]
};
let selectkey
let newsUpdates = []
let productUpdates =[]
const HomePage = props => {
  const { t } = useTranslation("common");
  const { productsList, assetsList, countrySettings, assetType, countryCode } = { ...props };
  const [newProducts, setNewProducts] = useState([]);
  const [assetCategoriesList, setAssetCategoriesList] = useState(null);
  const [newAssets, setNewAssets] = useState([]);
  const [dummykey, setDummykey] = useState(selectkey);
    // eslint-disable-next-line
  const [dummyCategory, setDummyCategory] = useState(selectkey);

  const [selected, setSelected] = useState(selectkey)
  const [translationDataList, setTranslationDataList] = useState([]);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [iconChange, setIconChange] = useState(true)
  const [filter, setFilter] = useState(false)
  const productHeading = useRef("");
  const assetHeading = useRef("");
  const { title } = { ...props };
  const [gigyaRole, setGigyaRole] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const filterHandler = () => {
    setFilter(!filter)
    setIconChange(!iconChange)
  }
  const getCreatedDate = obj => {
    const date = Object.keys(obj).includes("createdDate")
      ? obj.createdDate
      : "";
    return date;
  };
  const AuthCtx = useContext(AuthContext);
  let history = useHistory();
  const gigya = window.gigya;
 const userGigyaId = AuthCtx.giveToken();
 const userId= localStorage.email

  useEffect(() => {
    
    let newProducts = productsList.filter(product => {
      const createdDate = getCreatedDate(product);
      if (createdDate.length !== 0) {
        let isBetween = dayjs(createdDate).isBetween(
          now,
          productExpDate,
          "day"
        );
        return isBetween;
      } else {
        return false;
      }
    });

    let newAssets = assetsList.filter(asset => {
      const createdDate = getCreatedDate(asset);
      if (createdDate.length !== 0) {
        let isBetween = dayjs(createdDate).isBetween(now, assetExpDate, "day");
        return isBetween;
      } else {
        return false;
      }
    });

    setNewProducts(newProducts);
    setNewAssets(newAssets);
    props.updateRenderProductList(newProducts);
    props.updateRenderAssetList(newAssets);
    productHeading.current.innerText =
      newProducts.length > 0 ? t("HomePage.new-products") : "";
    assetHeading.current.innerText =
      newAssets.length > 0 ? t("HomePage.new-assets") : "";
     //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productsList, assetsList, props, t, dummykey, localStorage.getItem('previousLanguage')]);
    const getIndividualRole = async () => {
      let userMail = "";
      let userFirstName = "";
      let userLastName = "";
      let userLoginMethod = "";
      let profileData = localStorage.getItem("userProfile");
      let user_token = localStorage.getItem("email");
      let userProfile = profileData ? JSON.parse(profileData) : "";
      
      userMail = userProfile.email;
      userFirstName = userProfile.firstName;
      userLastName = userProfile.lastName;
      userLoginMethod = userProfile.loginProvider;
    
      const individualRolesUrl = `${config.baseApi}dc/userdata/${user_token}/checkUser`;
    
      try {
        const response = await axios({
          method: "post",
          url: individualRolesUrl,
          data: {
            "EMAILID": userMail,
            "COUNTRYCODE": getCountryCode(), 
            "USERNAME": `${userFirstName} ${userLastName}`,
            "LOGINMETHOD": userLoginMethod
          },
          headers: {
            "X-Country-Code": getCountryCode(),
            "urlDetermination" : window.location.origin.toLowerCase(),
          }
        });
    
        if (response?.data?.data?.countryCode) {
         response?.data?.data?.countryCode  &&  localStorage.setItem('checkUser', response.data.data.countryCode.toLowerCase()) 
         localStorage.setItem('previousLanguage', response.data.data.countryCode.toLowerCase());
    
        }
        store.dispatch(fetchCountryCodeSuccess(response?.data?.data?.countryCode));
        props.updateRenderCountryCode(response?.data?.data?.countryCode)
        console.log("countryCodecountryCode new", response?.data?.data?.countryCode )
        return response;
      } catch (error) {
        console.error("Failed to fetch individual role:", error);
      }
    };
  useEffect(() => {
    postGigyaID()
    getIndividualRole()
    document.title = title;
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, userId,  AuthCtx.giveToken(), AuthCtx.giveEmail(), localStorage.getItem('previousLanguage'), localStorage.getItem('email')]);
  useEffect(()=>{
    callingFunction(dummykey)
    showAlternatives(dummykey);

    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[dummykey, selectkey])
  useEffect(() => {
    let assetCategoriesListdummy
    if (Object.keys(countrySettings).includes("assetTypeMap")) {
      const translatedAssetList = countrySettings.assetTypeMap.map(i => {
        let translate_key = `asset_type.${i.dcAssetType}`;
        return {
          ...i,
          translated: t(translate_key)
        };
      });
      assetCategoriesListdummy = assetCategories(translatedAssetList, assetType)
      setAssetCategoriesList(assetCategories(translatedAssetList, assetType));
    }
    selectkey= assetCategoriesListdummy &&  Object.values(assetCategoriesListdummy)?.sort((a, b) => a.id > b.id ? 1 : -1)[0]?.category
    handleColor(selectkey)
   //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countrySettings, assetType,selectkey, t]);
  const getModalData = async () => {

    const response = await axios
      .get(
        `${config.favApi}dc/userdata/${userId}/use-acceptance`,
        {
          headers: {
            "X-Country-Code": `${getCountryCode()}`
          }
        }
      )
    return response.data;
  };

  const getSideContent = async () => {
    const response = await axios({
      method: "get",
      url: `${config.baseApi}dc/config/getNews`,
      headers: {
        "X-Country-Code": `${countryCode}`
      }
    });
    setTranslationDataList(response.data.data.News);  
  }
  const postGigyaID = async () => {
    const response = await axios({
      method: "get",
      url: `${config.baseApi}dc/exports/getrolepermission/${AuthCtx?.userProfile?.email?AuthCtx?.userProfile?.email : userId }`,
      data: {
        "useAdministration": {
          "Gigya_ID": AuthCtx?.userProfile?.email?AuthCtx?.userProfile?.email : userId ,
        }
      },
      headers: {
        "X-Country-Code": `${getCountryCode()}`
      }
    })
    setGigyaRole(response.data.data?.roleName);
    localStorage.setItem('user_role',JSON.stringify(response?.data?.data?.roleName))
    store.dispatch(fetchRoleSettingsSuccess(response?.data?.data?.viewPermission));
  }
  newsUpdates = translationDataList.filter(item => item?.newsType?.toLowerCase()=== "neuigkeiten" || item?.newsType?.toLowerCase()=== "nieuws" || item?.newsType?.toLowerCase()=== "news")
  productUpdates = translationDataList.filter(item => item?.newsType?.toLowerCase() === "produktaktualisierungen" || item?.newsType?.toLowerCase() === "productupdates" ||item?.newsType?.toLowerCase() === "product updates")

  const options = {
    filterType: "dropdown",
    responsive: "scroll",
    print: false,
    download: false,
    checkbox: false,
    selectableRows: false,
    showTitle: false,
    filtering:filter,
    addRowPosition:"first",
    doubleHorizontalScroll: true
  };

  const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  }

  const useStyles = makeStyles({
    tabs: {
      "& .MuiTabs-indicator": {
      display: "none",
      },
      "& .MuiTab-root.Mui-selected": {
        color: 'white !important',
        background:"#00617f !important"
      },
      "& hover ":{
        background:"#89d329 !important"
      }
    },
    table:{
      "& .MuiTableSortLabel-root":{
        fontSize: 16,
        color: "#00617f",
        fontWeight:600,
        fontFamily: 'Neue Helvetica',
      },
      "& .MuiTypography-root": {
      margin: "0",
      fontSize: "1rem",
      textAlign: "center"
      }
    },
    tableLable:{
      "& .MuiTableSortLabel-root":{
        fontSize: 16,
        color: "#00617f",
        fontWeight:600,
        fontFamily: 'Neue Helvetica',
      
      },
      "& .MuiTypography-root": {
        margin: "0",
        fontSize: "1rem",
        textAlign: "center"
      },
      "& .MTableToolbar-actions-9":{
        pointerEvents:"all !important",
        opacity:"0.65",
      },
      "& .MTableToolbar-actions-10":{
        pointerEvents:"all !important",
        opacity:"0.65",
      },
      "& .jss10":{
        pointerEvents:"all !important",
        opacity:"0.65",
      },
      "& .jss9":{
        pointerEvents:"all !important",
        opacity:"0.65",
      }

    },
  })
  let today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1;
  let dd = today.getDate();

  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;

  today = yyyy + '-' + mm + '-' + dd;
  const translationDefault= ()=>{
   let  translationDataListNewsTypeDefault =[]
   translationDataListNewsTypeDefault= [ t("Header.news"),  t("Header.productUpdates")] 
    return(
      translationDataListNewsTypeDefault.map(s => 
        <MenuItem         
          key={s}
          value={s}>{s}
        </MenuItem>)
    )
  }
  
  const analyticsColumns = [
    { title: "Entity", field: "newsType", 
    editComponent: ({value, onChange, rowData}) => (  
      <FormControl style={{ width: "100%" }}>
        <InputLabel id="demo-simple-select-label">Select One</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label="Select One"
          onChange={(event) => onChange(event.target.value)}
          displayEmpty
          renderValue={value === "" ? undefined : () => value}
        >
              {  translationDefault()}
          </Select> 
      </FormControl> 
    )},
    { title: "Heading",field: "newsTitle"},
    { title: "Creation Date", field: "newsCreatedOn",cellStyle: { width: "15%" },width: "15%",headerStyle: { width: "15%" }, initialEditValue: today, editable:"never"},
    { title: "Content", field: "newsContent",cellStyle: { width: "50%" },width: "50%",headerStyle: { width: "50%" }},
    { title: "PK",field: "PK",  hidden: true },
    { title: "SK",field: "SK", hidden: true },
  ]

  const labelData =translationDataList.map((item,index) =>{
    return {
      key: index, 
      newsType:  item?.newsType?.toLowerCase()=== "neuigkeiten" || item?.newsType?.toLowerCase()=== "nieuws" || item?.newsType?.toLowerCase()=== "news" ? t("Header.news") : t("Header.productUpdates"),
      newsTitle: item.newsTitle, 
      newsCreatedOn: item.newsCreatedOn?.slice(0,10),
      newsContent: item.newsContent,
      PK:item.PK,
      SK:item.SK

    }
  })

  const handleRowUpdate = async (newData, oldData, resolve) => { 
    const dataUpdate = [...labelData];
    const index = oldData.tableData.id;
    dataUpdate[index] = newData; 
    if (newData.newsType.toLowerCase() === "news" || newData.newsType.toLowerCase() === "nieuws" || newData.newsType.toLowerCase() === "neuigkeiten") {
      newData.newsType = "Neuigkeiten";
  } else if (newData.newsType.toLowerCase() === "produktaktualisierungen" || newData.newsType.toLowerCase() === "product updates" || newData.newsType.toLowerCase() === "productupdates") {
    newData.newsType = "Produktaktualisierungen";
  }
    setTranslationDataList([...dataUpdate])
    const response = await axios({
      method: "post",
      url: `${config.baseApi}dc/config/updateNews`,
      data: newData,
      headers: {
        "X-Country-Code": `${getCountryCode()}`,
        "X-Gigya-Id" :`${userGigyaId}`,
        "X-USER-EMAILID" :`${userId}`

      }
    });
    resolve();
    getSideContent()
    return response;
  };

  const handleRowAdd = async(newData, resolve) => { 
      let dataToAdd = [...translationDataList];        
      dataToAdd.push(newData);
      if (newData.newsType.toLowerCase() === "news" || newData.newsType.toLowerCase() === "nieuws" || newData.newsType.toLowerCase() === "neuigkeiten") {
        newData.newsType = "Neuigkeiten";
    } else if (newData.newsType.toLowerCase() === "produktaktualisierungen" || newData.newsType.toLowerCase() === "product updates" || newData.newsType.toLowerCase() === "productupdates") {
      newData.newsType = "Produktaktualisierungen";
    }
      setTranslationDataList(dataToAdd);      
      const response = await axios({
        method: "post",
        url: `${config.baseApi}dc/config/addNews`,
        data: newData,
        headers: {
          "X-Country-Code": `${getCountryCode()}`,
          "X-Gigya-Id" :`${userGigyaId}`,
          "X-USER-EMAILID" :`${userId}`
        }
      
      });
    resolve();
    getSideContent()
    return response;
  }

  const handleRowDelete =async (oldData, resolve) => {
    const dataDelete = [...translationDataList];
    setTranslationDataList(dataDelete)
    const response = await axios({
      method: "post",
      url: `${config.baseApi}dc/config/deleteNews`,
      data: oldData,
    
    });
  resolve();
  getSideContent()
  return response

  } 

  const tableRef = React.createRef();
  const classes = useStyles();

  const showModal = async () => {
    if (!userId) {
      return;
    }

    const currVersion = config.currVersionOfTerms;
    const modalData = await getModalData(); 
    var initial = modalData.data?.data?.data_of_last_confirmation.split(/\//);
    const formattedDate = [initial?.[1], initial?.[0], initial?.[2]].join('/'); 
    const date1 = new Date(formattedDate);

    let date2 = new Date();

    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

    if ((diffDays >= 365) || (modalData.data?.data?.version_of_conditions !== currVersion)) {
      handleShow();
    }
  }
  useEffect(() => {
    showModal();
    getSideContent()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem('previousLanguage'), localStorage.getItem('email'), countryCode,  AuthCtx.giveToken(), AuthCtx.giveEmail()])

  const postModalData = async () => {
    let today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; 
    let dd = today.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    today = dd + '/' + mm + '/' + yyyy;
    let country_code = getCountryCode();
    const response = await axios({
      method: "post",
      url: `${config.favApi}dc/userdata/${userId}/use-acceptance`,
      data: {
        "useAcceptance": {
          "Gigya_ID": userId,
          "data_of_last_confirmation": today,
          "version_of_conditions": config.currVersionOfTerms,
          "country_code": country_code,
        }
      },
      headers: {
        "X-Country-Code": `${getCountryCode()}`
      }
    });

    console.log("post response", response)
    handleClose()
  }
  

  const feedbackPrompt = () => {
    history.push("/login");
  };
  const logOutOnClick = () => {

    gigya.accounts.logout({
      callback: function (response) {
        if (response.errorCode === 0) {
          AuthCtx.logout();
          feedbackPrompt();
        }
      }
    });

    handleClose();
  };
 

  const setAssetCategoryIcon = category => {
    let image = "";
   
    switch (category) {
      case "E-Commerce":
        image = (
          <i
          className="bi bi-shop-window iconWidth"
          style={{ fontSize: 40, color: "#00617f" }}
        ></i>
        );
        break;
      case "Online Advertising":
        image = (
          <i
          className="bi bi-megaphone iconWidth"
          style={{ fontSize: 40, color: "#00617f" }}
        ></i>
        );
        break;
      case "Pharmacy":
        image =   <i
        className="bi bi-prescription iconWidth"
        style={{ fontSize: 40, color: "#00617f", fill:"white !important", width:"16", height:"16", viewBox:"0 0 16 16"  }}
      ></i>;
        break;
      case "HCP":
        image =  <i
        className="bi bi-person-workspace iconWidth"
        style={{ fontSize: 40, color: "#00617f" }}
      ></i>;
        break;
      case "Patients":
        image =   <i
        className="bi bi-megaphone iconWidth"
        style={{ fontSize: 40, color: "#00617f" }}
      ></i>;
        break;
      case "Brand Materials":
        image = (
          <i
          className="bi bi-capsule iconWidth"
          style={{ fontSize: 40, color: "#00617f" }}
        ></i>
        );
        break;
      case "Press":
        image =   <i
        className="bi bi-newspaper iconWidth"
        style={{ fontSize: 40, color: "#00617f" }}
      ></i>;
        break;
      default:
        break;
    }
    return image;
  };


  const callingFunction = async (funKeyFlag) => {
    setDummykey(funKeyFlag)
    setDummyCategory(funKeyFlag)
    showAlternatives(dummykey)
  };



  const handleClick = event => {
    let entity_type = "",
      entity_val = "";

    if (event.currentTarget.hasAttribute("type")) {
      entity_val = event.currentTarget.getAttribute("value");
      if (event.currentTarget.getAttribute("type") === "brand") {
        entity_type = "Brand";
     
      } else {
        entity_type = "Asset Type";
      
      }

      window.dataLayer.push({
        event: "gaGenericEvent",
        eventCategory: "Filter",
        eventAction: entity_val,
        eventLabel: entity_type,
        environment: ga_env
      });
    }
  };
  const showAlternatives = (dummykey) => {

    
        return (
          <div className="assetHome">
          { assetCategoriesList &&  Object.values(assetCategoriesList).map(entry => (
          <div className="assetListHome">
         
              {entry.asset_list
                .filter(asset   => {
                  if (asset.dcAssetCategory === dummykey  && asset.dcAssetType.trim() !== "") {
                    return true;
                  }
                  return false;
                })
                .map(asset => (
                  <button className="homeButton" style={{margin:"2%",  background: "#0091df",border: "#0091df", color: "white", width:"30%"}}>
                  <AppMenuItemComponent
                
                    value={asset.dcAssetType}
                    key={asset.dcAssetType}
                  >
                    {" "}
                    <ListItemText
                      onClick={event => handleClick(event)}
                      primary={t(`Asset Type.${asset.dcAssetType}`)}
                      value={asset.dcAssetType}
                      type="asset"
                      className="assetMenuHome"
                    />
                  </AppMenuItemComponent>
                  </button>
                ))}
         
          </div>
        
          ))}
         </div>
        );
  };

  const handleColor = (row) => {
    if(!row?.category){
      setDummykey(selectkey)
      setSelected(selectkey);
    }
    else{
      setSelected(row.category);
      
    }
   
  };

  const handleCloseModal = () => {
    setShowModalEdit(false);
    getSideContent()
  };
  const OpenModal=()=>{
    setShowModalEdit(true)  
  }
console.log("dummy",assetCategoriesList)
  return (
    <Container fluid className="home ">
      <Row>
        <Col xl={9} md={12} lg={9} sm={12} xs={12} >
          <Row className="home-banner ">
            <div className="blueColor">
              <div className="banner-text justify-content-center">
                <span className="text">{t("Header.home_search")}</span>
              </div>
              <div className="searchHome">
                <InternalSearch />
              </div>

              <div className="buttonFlex">
              {assetCategoriesList && Object.keys(assetCategoriesList).length > 0 ? (
                    Object.values(assetCategoriesList).map((entry, index) => (
                      <div className="buttonhomepagediv" key={index}>
                        <div>
                          <button
                            key={entry.category}
                            className="buttonhomepage"
                            id={entry.category}
                            onClick={() => { handleColor(entry); callingFunction(entry.category); }}
                            style={{
                              backgroundColor:
                                entry.category === selected
                                  ? "white"
                                  : "rgb(160, 154, 154)",
                            }}
                          >
                            {setAssetCategoryIcon(entry.category)}
                          </button>
                        </div>
                        <span className="iconWidthName">{t(`Asset Category.${entry.category}`)}</span>
                      </div>
                    ))
                  ) : assetCategoriesList && Object.keys(assetCategoriesList).length === 0 ? (
                   " "
                  ) : (
                    [...Array(5)].map((_, index) => (
                      <div className="buttonhomepagediv" key={index}>
                        <div>
                          <button
                            className="buttonhomepage"
                            style={{
                              backgroundColor: "rgb(160, 154, 154)",
                              filter: "blur(5px)", 
                              width: "50px", 
                              height: "50px", 
                            }}
                          >
                          </button>
                        </div>
                        <span
                          className="iconWidthName"
                          style={{
                            filter: "blur(5px)",
                            display: "block",
                            height: "20px",
                            backgroundColor: "rgb(160, 154, 154)",
                            width: "60px",
                            marginTop: "10px",
                          }}
                        >
                        </span>
                      </div>
                    ))
)}
</div>

            </div>
            <div>{showAlternatives(dummykey)}</div>
          </Row>
      <MediaQuery maxWidth={1024}>
        <div className="searchBar">
          <Row className="searchContainer">
            <InternalSearch />
          </Row>
        </div>
      </MediaQuery>
      <Container className="widthNew">
        <Row className="digital-content-products justify-content-center">
          <span className="heading" ref={productHeading}>
            {productHeading.current.innerText}
          </span>
          <Slider {...settings}>
            {newProducts.map((product, index) => {
              return (
                <div key={index} className="col-4">
                  <CardComponent item={product} index={index} />
                </div>
              );
            })}
          </Slider>
        </Row>
        <Row className="digital-content-assets justify-content-center">
          <span className="heading" ref={assetHeading}>
            {assetHeading.current.innerText}
          </span>
          <Slider {...settings}>
            {newAssets.map((asset, index) => {
              return (
                <div key={index}>
                  <CardComponent item={asset} index={index} />
                </div>
              );
            })}
          </Slider>
        </Row>
        {(newProducts.length > 0 || newAssets.length) > 0 ? (
          <Row className="button-placeholder justify-content-center">
            <button className="showAllbtn">
              <Link
                to={{
                  pathname: "/search-page",
                  search: `?${qs.stringify(setSearchParam)}`
                }}
              >
                <span>{t("HomePage.show-all")}</span>
              </Link>
            </button>
          </Row>
        ) : null}
      </Container>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered

      >
        <Modal.Header >
          <Modal.Title>{t("Product Detail Page.termsofUses")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {t("Product Detail Page.statement")}
        </Modal.Body>
        <Modal.Footer>
          <Button className="primaryButton" onClick={logOutOnClick}>
            Disagree
          </Button>
          <div></div>
          <Button className="primaryButton" onClick={postModalData}>Agree</Button>
          <p>you cannot access Download center data without agreeing terms of use and acceptance,
            clicking Disagree will automatically logout from Download center</p>

        </Modal.Footer>
      </Modal>

      <Modal show={showModalEdit}  onHide={handleCloseModal} aria-labelledby="contained-modal-title-vcenter" className="edit-modal-global">
 
  <FormControl className={classes.tableLable} >
  <div className="col-md-9 col-lg-9 col-xl-12" style={{position:"relative"}}>
           
            <div className="col-md-9 col-lg-9 col-xl-12">
            
            
          <div className="homepageeditfilter">
          <div className="filterIcon " style={{fontStyle:"italic"}}> 
                {iconChange ?
       (<span style={{cursor:"pointer"}} onClick={filterHandler} >Show Filter<FilterListIcon ></FilterListIcon></span>) :
       (<span style={{cursor:"pointer"}}onClick={filterHandler}>Hide Filter<FilterListOffIcon></FilterListOffIcon></span>)}
                       </div>
         </div> 
                       
                      
          </div>
         

          <MaterialTable  
    tableRef={tableRef}
    options={options}
    icons={tableIcons}
    columns={analyticsColumns}
    data={labelData}
    editable={{
    
      onRowUpdate: (newData, oldData) =>
        new Promise((resolve) => {
          handleRowUpdate(newData, oldData, resolve);
          
        }),
        onRowAdd: (newData) =>
          new Promise((resolve) => {
          handleRowAdd(newData, resolve)
      }),
        onRowDelete: (oldData) =>
          new Promise((resolve) => {
            handleRowDelete(oldData, resolve)
      }),
    }} 
  />
          
          
          </div> 

  </FormControl>
  <Modal.Footer className="edit-footer">
   
    <Button variant="secondary" className="bg-primary" onClick={handleCloseModal}>
      Close Modal
    </Button>
  </Modal.Footer>
</Modal>
        </Col>
        <Col xl={3} lg={3} md={12} sm={12} xs={12} style={{ borderLeft: "1px solid #00617f ", textAlign:"left",color: "#00617f", fontFamily: "Neue Helvetica",}}>
          <div style={{display:"flex", flexDirection: "row", justifyContent:"space-between", alignItems: "baseline"}}>
          <div style={{ fontSize: "20px", fontWeight: "600", marginBottom:"4%"}}> {t("Header.news")}</div>
          {(gigyaRole === "Global Admin" || gigyaRole=== "Country Admin")?

          <IconButton
                        color="inherit"
                        onClick={() => OpenModal()}
                        style={{ fontSize: "20px", fontWeight: "600", marginBottom:"4%"}}
                      >Edit
                       <LaunchIcon />
                    </IconButton>
                    :""
} 
          </div>    
       {newsUpdates.map((data) => {
         return (
           <>
           <div>
             <span style={{fontWeight:"600"}}>{data.newsTitle}</span>
           </div>
           <div>
             <span>{data.newsCreatedOn?.slice(0,10)}</span>
           </div>
           <div>
           <span>{data.newsContent}</span>
           </div>                               
           <div style={{ marginBottom:"6%"}}></div>
           </>
         );
       })}
         <div style={{ fontSize: "20px", fontWeight: "600", marginBottom:"4%"}}> {t("Header.productUpdates")}</div>
       {productUpdates.map((data) => {
         return (
           <>
           <div>
             <span style={{fontWeight:"600"}}>{data.newsTitle}</span>
           </div>
           <div>
             <span>{data.newsCreatedOn?.slice(0,10)}</span>
           </div>
           <div>
           <span>{data?.newsContent}</span>
           </div>                               
           <div style={{ marginBottom:"6%"}}></div>
           </>
         );
       })}
   
        </Col>
      </Row>
    </Container>
  );
};
const mapStateToProps = (state) => {
  return {
    productsList: state.product.productsList,
    assetsList: state.asset.assetsList,
    countrySettings: state.country.countryData,
    assetType: state.filter.assets,
    countryCode: state.countryCode.countryCode,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateRenderProductList: ownProps =>
      dispatch(updateRenderProductList(ownProps)),
    updateRenderAssetList: ownProps => dispatch(updateRenderAssetList(ownProps))
  };
};
export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(HomePage);