import {
  FETCH_ROLESETTINGS_REQUEST,
  FETCH_ROLESETTINGS_SUCCESS,
  FETCH_ROLESETTINGS_ERROR,
  UPDATE_ROLESETTINGS
} from "./roleTypes";

const initialState = {
  loading: false,
  countryData: {},
  error: ""
};

const roleReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ROLESETTINGS_REQUEST:
      return { ...state, loading: true };
    case FETCH_ROLESETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        countryData: action.payload,
        error: ""
      };
    case FETCH_ROLESETTINGS_ERROR:
      return {
        loading: false,
        countryData: {},
        error: action.payload
      };
      case UPDATE_ROLESETTINGS:
        return {
          ...state,
          countryData: action.payload,
        };
      default:
          return state;
  }
};
export default roleReducer;
