import _ from "lodash";
import axios from "axios";
import config from "../config.js";
import { trackPromise } from "react-promise-tracker";

export function downloadOnClick(event, entity, location) {
  //const userId = localStorage.token;
  const userId= localStorage.email
  let timeStamp = new Date();
  let track_country = getCountryCode();
  let track_brand = "";
  let track_assetId = "";
  let track_assetName=""
  event?.preventDefault();
  const env = process.env.REACT_APP_ENVIRONMENT;
  let ga_env = env === "PROD" ? "Production" : "Testing";
  window.dataLayer = window.dataLayer || [];
  var FileSaver = require("file-saver");
  let assetBrand;
  if (entity.type === "asset") {
    assetBrand = event?.currentTarget.getAttribute("data-brand");
  }
  event?.currentTarget.classList.add("done");
  event?.currentTarget.lastElementChild.classList.remove(
    "bi-file-earmark-arrow-down"
  );
  event?.currentTarget.lastElementChild.classList.add("bi-check-lg");
  let fileSrc;
  if (location === "asset") {
    if (Object.keys(entity).includes("asset_location")) {
      if (Array.isArray(entity.asset_location)) {
        fileSrc = entity.asset_location.filter(file => {
          const extension = file.slice(((file.lastIndexOf(".") - 1) >>> 0) + 2);
          if (extension === "zip") {
            return true;
          } else {
            return false;
          }
        });

        if (fileSrc.length === 0) {
          fileSrc = entity.asset_location;
        }
      } else {
        fileSrc = entity.asset_location;
      }
      switch (entity.type) {
        case "product":
          track_brand = entity.asset_info.brand;
          track_assetId = entity.asset_info.assetId;
          break;
        case "asset":
          track_brand = entity.brand;
          track_assetId = entity.assetId;
          track_assetName=entity.title;
          break;
        default:
          break;
      }
    }
  } else if (location === "print_media") {
    if (Object.keys(entity).includes("print_media_location")) {
      if (Array.isArray(entity.print_media_location)) {
        fileSrc = entity.print_media_location.filter(file => {
          const extension = file.slice(((file.lastIndexOf(".") - 1) >>> 0) + 2);
          if (extension === "zip") {
            return true;
          } else {
            return false;
          }
        });

        if (fileSrc.length === 0) {
          fileSrc = entity.print_media_location;
        }
      } else {
        fileSrc = entity.print_media_location;
      }

      switch (entity.type) {
        case "product":
          track_brand = entity.asset_info_print.brand;
          track_assetId = entity.asset_info_print.assetId;
          break;
        case "asset":
          track_brand = entity.brand;
          track_assetId = entity.assetId;
          track_assetName=entity.title;
          break;
        default:
          break;
      }
    }
  }
  let zipFiles = [];
  if (Array.isArray(fileSrc) && fileSrc.length > 1) {
    const domain = window.location.origin;
    fileSrc.forEach(file => {
      const downloadUrl = `${domain}/${file}`;
      zipFiles.push(downloadUrl);
    });
  }
  else if (fileSrc.indexOf(".zip") === -1 && entity.attachments?.length) { // if it is not zip try to zip
    const domain = window.location.origin;
    zipFiles.push(`${domain}/${fileSrc}`);
    entity.attachments.forEach(attachment => {
      zipFiles.push(`${domain}/${attachment.asset_location}`);
    })
  }
  if (zipFiles.length > 0) {
    const zip = require("jszip")();
    let files = zipFiles;
    files.forEach(function (fileItem) {
      let fileName = fileItem.substring(
        fileItem.lastIndexOf("/") + 1,
        fileItem.length
      );
      if (fileName.indexOf(".zip") === -1) {
        zip.file(fileName, fileItem);
      }
    });
    // for (let file = 0; file < files.length; file++) {
    //   let fileName = files[file].substring(
    //     files[file].lastIndexOf("/") + 1,
    //     files[file].length
    //   );
    //   zip.file(fileName, files[file]);
    // }
    if (entity.type === "product") {
      window.dataLayer.push({
        event: "gaGenericEvent",
        eventCategory: "Download (local)",
        eventAction: entity.brand,
        eventLabel: "product",
        eventValue: "E-CommercePackage.zip",
        environment: ga_env
      });
      zip.generateAsync({ type: "blob" }).then(content => {
        FileSaver.saveAs(content, "E-CommercePackage.zip");
      });
    } else {
      window.dataLayer.push({
        event: "gaGenericEvent",
        eventCategory: "Download (local)",
        eventAction: `${assetBrand}`,
        eventLabel: "asset",
        eventValue: "E_CommerceAssets.zip",
        environment: ga_env
      });
      zip.generateAsync({ type: "blob" }).then(content => {
        FileSaver.saveAs(content, "E_CommerceAssets.zip");
      });
    }
    axios({
      method: "post",
      url: `${config.favApi}dc/userdata/${userId}/downloads`,
      data: {
        downloads: {
          Country: track_country,
          Asset_ID: track_assetId,
          Asset_Name: track_assetName,
          Brand: track_brand,
          User_ID: userId,
          Time_Stamp: timeStamp
        }
      },
      headers: {
        "X-Country-Code": `${getCountryCode()}`
      }
    });
  } else {
    const filePath = Array.isArray(fileSrc) ? fileSrc[0] : fileSrc;
    let fileName = filePath.substring(filePath.lastIndexOf("/") + 1);
    const domain = window.location.origin;
    let downloadUrl = `${domain}/${filePath}`;
    if (entity.type === "product") {
      window.dataLayer.push({
        event: "gaGenericEvent",
        eventCategory: "Download (local)",
        eventAction: entity.brand,
        eventLabel: "product",
        eventValue: "E-CommercePackage.zip",
        environment: ga_env
      });
    } else {
      window.dataLayer.push({
        event: "gaGenericEvent",
        eventCategory: "Download (local)",
        eventAction: `${assetBrand}`,
        eventLabel: "asset",
        eventValue: `${fileName}`,
        environment: ga_env
      });
    }

    if (downloadUrl !== null && downloadUrl.length > 0) {
      if (entity.type === "product" || entity.type === "asset" ) {
        FileSaver.saveAs(downloadUrl, fileName);
      } else if(entity.type === "asset"){
        downloadUrl = `${config.baseApi}dc/assets/download/files/${assetBrand}/${entity.assetId}`;
        if (entity.asset_location.indexOf(".zip") === -1) {
          fileName = (entity.title || "Asset_Attachments") + ".zip";
        }
        trackPromise(axios.get(downloadUrl, {
          headers: {
            "X-Country-Code": `${getCountryCode()}`
          }
        }).then(res => res.data).then(res => {
          // console.log(res);
          FileSaver.saveAs(res.data.url, fileName);
        }), "assets-downloading");

      }
    }
    axios({
      method: "post",
      url: `${config.favApi}dc/userdata/${userId}/downloads`,
      data: {
        downloads: {
          Country: track_country,
          Asset_ID: track_assetId,
          Asset_Name: track_assetName,
          Brand: track_brand,
          User_ID: userId,
          Time_Stamp: timeStamp
        }
      },
      headers: {
        "X-Country-Code": `${getCountryCode()}`
      }
    });
  }
  let currentDate = new Date().toJSON().slice(0, 10);
  const assetPostResponse =  axios({
    method: "POST",
    url: `${config.baseApi}dc/userdata/${userId}/updateAssetNotification`,
    data: {
      "notificationFlag": true,
      "country": `${getCountryCode()}`,
      "assetUpdatedOn": currentDate,
      "downloadedOn": currentDate,
      "assetId": entity.assetId,
      "assetName": entity.title,
      "brand": entity.brand,
      "notificationCheckedOn": currentDate
    },

  })
  return assetPostResponse
}

export function routeConfig(t) {
  const routes = [
    { path: "/", name: "Home", Component: "" },
    { path: "/profile-page", name:  t("BreadCrumbs.profile"), Component: "" },
    { path: "/favorites-page", name:  t("BreadCrumbs.favpage"), Component: "" },
    { path: "/search-page/?paramString", name:  t("BreadCrumbs.searchPage"), Component: "" },
    { path: "/product-detail-page/?paramString", name: "", Component: "" },
    { path: "/asset-detail-page/?paramString", name: "", Component: "" }
  ];
  return routes;
}

export function pimAttributes() {
  const countryCode = getCountryCode();
  let pimAttributeList = []
  if (countryCode === "de") {
    pimAttributeList = [
      "pharmacentralnum",
      "ptitle",
      "germquantity",
      "manuname",
      "uspecomstory",
      "claishrtverone",
      "treatment",
      "symptoms",
      "activeingredients",
      "containindications",
      "tolerance",
      "ingestion",
      "mandatoryinfo",
      "ecomproddesc",
      "ecomone",
      "ecomtwo",
      "ecomthree",
      "ecomfour",
      "ecomfive",
      "odor",
      "color",
      "youtubelink",
      "brand",
      "subbrand",
      "pcat",
      "hiddkywrds",
      "claishrtverfour",
      "skintypenl",
      "sideeffects",
      "lighsens",
      "ageappro",
      "usedbreastfeed",
      "usedpregnancy",
      "containalcohol",
      "containbiocides",
      "containcoloring",
      "containfragrances",
      "containgelatin",
      "containlactose",
      "containnuts",
      "containpreservatives",
      "containsoy",
      "containsugar",
      "durabilityopening",
      "homeopathic",
      "vegan",
      "vegetable"
    ];
  }
  else {
    pimAttributeList = [
      "pharmacentralnum",
      "ptitle",
      "germquantity",
      "manuname",
      "uspecomstory",
      "claishrtverone",
      "treatment",
      "symptoms",
      "activeingredients",
      "containindications",
      "tolerance",
      "ingestion",
      "mandatoryinfo",
      "ecomproddesc",
      "ecomone",
      "ecomtwo",
      "ecomthree",
      "ecomfour",
      "ecomfive",
      "odor",
      "color",
      "youtubelink",
      "brand",
      "subbrand",
      "pcat",
      "hiddkywrds",
      "claishrtverfour",
      "skintypenl",
      "sideeffects",
      "placestorage",
      "lighsens",
      "ageappro",
      "usedbreastfeed",
      "usedpregnancy",
      "containalcohol",
      "containbiocides",
      "containcoloring",
      "containfragrances",
      "containgelatin",
      "containlactose",
      "containnuts",
      "containpreservatives",
      "containsoy",
      "containsugar",
      "durabilityopening",
      "homeopathic",
      "vegan",
      "vegetable"
    ];
  }
  return pimAttributeList;
}

export function productCharacteristicsTab() {
  const countryCode = getCountryCode();
  let productCharacteristics = []
  if(countryCode?.toLowerCase() === "nl" || countryCode?.toLowerCase() === "be"){
     productCharacteristics = [
      // "allergensinfo",
      // "containalcohol",
      // "containbiocides",
      // "containcoloring",
      // "containfragrances",
      // "containgelatin",
      // "containlactose",
      // "containnuts",
      // "containpreservatives",
      // "containsoy",
      // "containsugar",
      // "formlov",
      // "claishrtverfour", 
      // "homeopathic",
      // "vegetable",
      // "vegan",
      "usedbreastfeed",
      "usedpregnancy",
      "gender",
      "phneutral",
      "dermatested",
      "hypoallergenic",
      "isthisnoncomedogenic",
      "allergeinfo",
      "containalcohol",
      "containbiocides",
      "containcoloring",
      "containfragrances",
      "containgelatin",
      "containlactose",
      "containnuts",
      "containpreservatives",
      "containsoy",
      "containsugar",
      "claishrtverfour",
      "isthisparabensfree",
      "isthismicroplasticfree",
      "isthissiliconefree",
      "isthissulfatefree",
      "isthisoctocrylenefree",
      "isthisaluminiumfree",
      "isthissoapfree",
      "isthistitaniumdfree",
      "natuprod",
      "isthisvegetarian",
      "organicthree",
      "kosher",
      "isthishalal",
      "vegan",
    ];
  }
  else{
     productCharacteristics = [
      "homeopathic",
      "vegan",
      "vegetable",
      "claishrtverfour",
      "containalcohol",
      "containbiocides",
      "containcoloring",
      "containfragrances",
      "containgelatin",
      "containlactose",
      "containnuts",
      "containpreservatives",
      "containsoy",
      "containsugar"
    ];
  }
 
  return productCharacteristics;
}
export function dimensionalDataTab() {
  const dimensionalData = [
    "unitweight",
    "unitweightuom",
    "unitwidth",
    "unitwidthuom",
    "unitheight",
    "unitheightuom",
    "unitlength",
    "unitlengthuom"
  ];
  return dimensionalData;
}
export function technicalDataTab() {
  const countryCode = getCountryCode();
  let technicalData = []
  if(countryCode?.toLowerCase() === "nl"){
    technicalData = [
      // "ean",
      // "cenum",
      // // "manufactureraddress",
      // "manuname",
      // "unitgtin",
      // // "heightce",
      // // "widthce",
      // // "lengthce",
      // "unitweight",
      // "dispunetweight",
      // "prdctionsite",
      // "split up",
      // "layerperpallet",
    "finigoodpacksize",
"finigoodpacksizeuom",
"numberofpcs",
"markcountries",
"rvgnum"
    ];
  }
  else if(countryCode?.toLowerCase() === "be"){
    technicalData = [
      // "ean",
      // "cnkcode",
      // "cenum",
      // "nutnumber(vms)",
      // // "manufactureraddress",
      // "manuname",
      // "unitgtin",
      // // "heightce",
      // // "widthce",
      // // "lengthce",
      // "unitweight",
      // "dispunetweight",
      // "prdctionsite",
      // "split up",
      // "layerperpallet",
      "finigoodpacksize",
      "finigoodpacksizeuom",
      "numberofpcs",
      "markcountries",
      "rvgnum"
    ];
  }
  else{
    technicalData = [
      "ean",
      "heightce",
      "widthce",
      "lengthce",
      "unitweight",
      "dispunetweight",
      "prdctionsite",
      "split up",
      "layerperpallet",
    ];
  }
 
  return technicalData;
}
export function retailerInformationTab() {
  const countryCode = getCountryCode();
  let retailerInformationData = []
  if(countryCode?.toLowerCase() === "nl" || countryCode?.toLowerCase() === "be"){
    retailerInformationData = [
      "dareportpdpurl",
      "dareportunitupc",
      "dareportvendorcode",
      "dareportvendorname"
    ];
  }
  else{
    retailerInformationData = [     
    ];
  }
 
  return retailerInformationData;
}
export function assetCategories(asset_map, asset_type_list) {
  let asset_categorie = {};
  if (asset_map !== undefined) {
    asset_map.forEach(asset => {
      if (asset.dcAssetCategory !== "Others") {
        asset_categorie.hasOwnProperty(asset.dcAssetCategory)
          ? _.findIndex(
            asset_categorie[asset.dcAssetCategory].asset_list,
            a => {
              return a.dcAssetType === asset.dcAssetType;
            }
          ) === -1 &&
          _.findIndex(asset_type_list, function (o) {
            return o.asset_type.dcAssetType === asset.dcAssetType;
          }) > -1 &&
          (asset_categorie[asset.dcAssetCategory] = {
            ...asset_categorie[asset.dcAssetCategory],
            asset_list: [
              ...asset_categorie[asset.dcAssetCategory].asset_list,
              asset
            ]
          })
          : _.findIndex(asset_type_list, function (o) {
            return o.asset_type.dcAssetType === asset.dcAssetType;
          }) > -1 &&
          (asset_categorie[asset.dcAssetCategory] = {
            id: Object.keys(asset_categorie).length + 1,
            category: asset.dcAssetCategory,
            asset_list: [asset]
          });
      }
    });
  }

  return asset_categorie;
}

export const getCountryCode = () => {
  // eslint-disable-next-line
  var userLocale = navigator.language
   // const  locale  = new Intl.Locale(userLocale)
   // const name = locale?.region ? countries[locale.region]?.name : countries[userLocale.toUpperCase()]?.name 
   // localStorage.setItem("userCountryName", name);
    //localStorage.setItem("userCountryCode", navigator.language.length === 5 ? navigator.language.substring(3, 5).toLowerCase():navigator.language.substring(0, 2).toLowerCase() );
   let dummycountryCode =  navigator.language.length === 5 ? navigator.language.substring(3, 5).toLowerCase():navigator.language.substring(0, 2).toLowerCase() 



  // var currentLanguage = navigator.language || navigator.userLanguage || navigator.languages[0];
  // console.log('Current language: ' + userLocale);

   // Check if the previous language is stored in localStorage
   var previousLanguage = localStorage.getItem('previousLanguage');
  //localStorage.setItem('previousLanguage', dummycountryCode);
  // var LoggedIn = localStorage.getItem('LoggedIn');

 
   if ( previousLanguage && previousLanguage !== dummycountryCode ) {
    //console.log("countrycode prev", previousLanguage, "dummy", dummycountryCode)
    //countryCode=localStorage.getTime('previousLanguage', previousLanguage)
       // eslint-disable-next-line no-unused-expressions
       if(localStorage.getItem("LoggedIn")  === "false"){
        localStorage.setItem('previousLanguage', dummycountryCode)
       }
   // localStorage.getItem("LoggedIn") === true ? "": localStorage.setItem('previousLanguage', dummycountryCode)
    
    //console.log('Language changed from countrycode' + previousLanguage + ' to ' + countryCode);
     // Perform any actions or updates you need when the language changes
   }
  
   let countryCode=localStorage.getItem('previousLanguage')=== null ?dummycountryCode : localStorage.getItem('previousLanguage')
   // Save the current language in localStorage for future comparisons

  return countryCode;
};
export const legalTest = async()=>{
  let dummycountryCode =  navigator.language.length === 5 ? navigator.language.substring(3, 5).toLowerCase():navigator.language.substring(0, 2).toLowerCase() 
 
  const countryCode =  localStorage.getItem('previousLanguage')=== null ?dummycountryCode : localStorage.getItem('previousLanguage');
  const countrySettingsUrl = `${config.baseApi}dc/config/country`;
  let country_settings = await axios({
    method: "get",
    url: `/${countryCode}`,
    baseURL: countrySettingsUrl,
    headers: { Authorization: "Bearer" }
  });
 let country_data = country_settings.data.data;
console.log("datacheck",country_data)
   if(localStorage.getItem("LoggedIn") === 'false') { 
    localStorage.setItem("LegalTextCountryFilter", country_data?.region)
    localStorage.setItem("LegalTextLanguageFilter",  country_data && country_data?.languages?.filter(language => language?.langCode )[0]?.lang)
  
  }
  else{
       localStorage.setItem("LegalTextCountryFilter", country_data?.region)
       localStorage.setItem("LegalTextLanguageFilter",  country_data && country_data?.languages?.filter(language => language?.langCode )[0]?.lang)
     
    // eslint-disable-next-line no-unused-expressions
    localStorage.getItem("LoggedIn") === 'true' ?  localStorage.setItem("LegalTextCountryFilter", country_data?.region):""
    // eslint-disable-next-line no-unused-expressions
    localStorage.getItem("LoggedIn") === 'true' ? localStorage.setItem("LegalTextLanguageFilter",  country_data && country_data?.languages?.filter(language => language?.langCode)[0]?.lang)
    :""
     // }

  }

}
window.addEventListener('languagechange', legalTest);
window.addEventListener('languagechange', getCountryCode);
export const loadCookie = country => {
  let { allow_button = "", dismiss_button = "", deny_button = "", message = "" } = {
    ...country.cookie_consent
  };

  const optIn = new CustomEvent("cookieOpted", {
    consent: true
  });

  const optOut = new CustomEvent("cookieDeclined", {
    consent: false
  });

  const getDomainName = () => {
    const windowOrigin = window.location.origin;
    let domain = new URL(windowOrigin);
    domain = domain.hostname;
    //domain = domain.hostname.replace("www.", "");
    return domain;
  };
  const cookieConsent = status => {
    /*  let consentLogUrl = new URL(
      "https://services.bayerbbs-hosting.com/consentlogging/"
    ); */

    async function getIp() {
      let response = await fetch("https://api.ipify.org/?format=json");
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      let publicAddress = await response.json().then(
        setTimeout(response => {
          return response;
        }, 5000)
      );
      return publicAddress;
    }
    async function logConsent(ip, domain) {
      if (document.cookie.includes("cookieconsent_status=allow")) {
        //if (stateStatus.state === "success") {
        (function (w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
          var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l !== "dataLayer" ? "&l=" + l : "";
          j.async = true;
          j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, "script", "dataLayer", "GTM-NHKHMPH");
        return true;
        //}
      }
      else if (document.cookie.includes("cookieconsent_status=deny")) {
        //if (stateStatus.state === "success") {
        (function (w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
          var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l !== "dataLayer" ? "&l=" + l : "";
          j.async = true;
          j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, "script", "dataLayer", "GTM-NHKHMPH");
        return false;
        //}
      } else {
        window.location.href =
          window.location.origin + "/privacy/#cookieSettings";
      }



      /* let response = await fetch(
        `https://services.bayerbbs-hosting.com/consentlogging/?domain=${domain}&ip=${ip}`
      ); */
      // let stateStatus = await response.json();
    }
    getIp().then(publicAddress => {
      const domain = getDomainName();
      logConsent(publicAddress.ip, domain);
    });
    setTimeout(function () {
      const overLay = document.getElementsByClassName("cookieBackdrop");
      overLay[0].style.display = "none";
    }, 500);

    if (status === "allow") {
      document.dispatchEvent(optIn);
    } else {
      document.dispatchEvent(optOut);
    }
  };

  window.cookieconsent.initialise({
    revokeBtn: "<div class='cc-revoke'></div>",
    type: "opt-in",
    palette: {
      popup: {
        background: "#0090C5",
        text: "#fff"
      },
      button: {
        background: "#6BC200",
        text: "#fff"
      }
    },
    theme: "custom",
    position: "top",
    //position: "bottom-left",
    cookie: {
      domain: getDomainName(),
      expiryDays: 180
    },
    content: {
      header: "Usage Of Cookies",
      message: message,
      link: "Datenschutzerklärung.",
      href: window.location.origin + "/privacy",
      allow: allow_button,
      dismiss: dismiss_button,
      deny: deny_button,
      close: "❌",
      target: "_blank"
    },
    elements: {
      header: '<span class="cc-header">{{header}}</span>',
      message:
        '<span id="cookieconsent:desc" class="cc-message">{{message}}</span>',
      messagelink:
        '<span id="cookieconsent:desc" class="cc-message">{{message}} <a aria-label="{{link}}" tabindex="0" class="cc-link" href="{{href}}">{{link}}</a></span>',
      allow:
        '<span aria-label="{{allow}}" tabindex="0" class="cc-btn cc-allow">{{allow}}</span>',
      dismiss:
        '<span aria-label="{{dismiss}}" tabindex="0" class="cc-btn cc-dismiss">{{dismiss}}</span>',
      deny: '<span aria-label="{{deny}}" tabindex="0" class="cc-btn cc-deny">{{deny}}</span>',
      link:
        '<a aria-label="{{link}}" tabindex="0" class="cc-link" href="{{href}}" target="_blank">{{link}}</a>',
      close:
        '<span aria-label="{{close}}" tabindex="0" class="cc-close">{{close}}</span>'
    },
    window:
      '<div role="dialog" aria-live="polite" aria-label="cookieconsent" aria-describedby="cookieconsent:desc" class="cc-window {{classes}}"><!--googleoff: all-->{{children}}<!--googleon: all--></div>',
    compliance: {
      "opt-in":
        '<div class="cc-compliance cc-highlight">{{dismiss}}{{allow}}{{deny}}</div><div class="cc-clear"></div>'
    },
    onInitialise: function (status) {
      if (status === window.cookieconsent.status.allow) { cookieConsent(status); }
      else { cookieConsent() }
    },
    onStatusChange: function (status) {
      if (this.hasConsented()) { cookieConsent(status); }
      else { cookieConsent() }
    }
  });
};

export const getProductTitle = (upc, productList, countryLocale) => {
  const targetProduct = productList.filter(product => product.unitupc === upc);
  return ( typeof  targetProduct[0]?.ptitle === "string"
  ?  targetProduct[0]?.ptitle : countryLocale &&   targetProduct[0]?.ptitle?.[countryLocale] ?
  targetProduct[0]?.ptitle?.[countryLocale]  :  targetProduct[0]?.ptitle?.['en-ZZ'])
};

export const dynamicAssetTitle = (item, t, productsList, countryLocale) => {
  let asset_title = [];
  if (Object.keys(item).includes("upc")) {
    let ptitle = getProductTitle(item.upc, productsList, countryLocale);
    ptitle !== undefined
      ? asset_title.push(ptitle)
      : asset_title.push(item.title);

    if (_.has(item, "asset_type")) {
      asset_title.push(t(`Asset Type.${item.asset_type[0].dcAssetType}`));
    }
  } else {
    if (_.has(item, "brand")) {
      let assetBrand = item.brand;
      if (_.has(item, "sub_brand")) {
        asset_title.push(item.sub_brand[0]);
      } else {
        asset_title.push(assetBrand);
      }
    }
    if (_.has(item, "asset_type")) {
      asset_title.push(t(`Asset Type.${item.asset_type[0].dcAssetType}`));
    }
    if (_.has(item, "customer_explanation")) {
      asset_title.push(item.customer_explanation);
    }
  }
  asset_title = asset_title.join(" ");
  return asset_title;
};
