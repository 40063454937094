import React, { Component } from "react";
import { withRouter, Link, Redirect } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { updateFavProductsList, updateSetRenderProductList, updateSetRenderCartAssetList,updateCartAssetsList  } from "../../redux";
import { Tab, Tabs, Accordion, Card, Button } from "react-bootstrap";
import {
  downloadOnClick,
  pimAttributes,
  productCharacteristicsTab,
  dimensionalDataTab,
  technicalDataTab,
  retailerInformationTab,
  getCountryCode
} from "../../util/Helper";
// import { ExportToExcel } from "../../components/ExportToExcel/ExportToExcel Header.jsx";
import "./ProductDetailPage.scss";
//import CarouselComponent from "../components/Carousel/carousel";
import ReactPlayer from "react-player";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { withTranslation } from "react-i18next";
import qs from "qs";
import _ from "lodash";
import config from "../../config.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import MediaQuery from "react-responsive";
import { css } from "@emotion/react";
import DotLoader from "react-spinners/DotLoader";
import CardComponent from "../../components/Card/card.jsx";
import CardComponentNew from "../../components/CardDetail/cardDetail.jsx";
 import { IconButton } from "@material-ui/core";
import { MoreVert } from "@mui/icons-material";
 import { Menu, MenuItem } from "@mui/material";
import AuthContext from "../../components/Auth/Auth-context";
import { getCartList } from "../../Services/service.js";

const axios = require("axios");
const productlistUrl = `${config.baseApi}dc/entities/product`;
const env = process.env.REACT_APP_ENVIRONMENT;
const override = css`
  display: block;
  margin: 0 auto;
`;
const divStyle = {
  height: "calc(100vh - 200px)"
};
let ga_env = env === "PROD" ? "Production" : "Testing";
window.dataLayer = window.dataLayer || [];
let downloadArrayLength
let productInfoButton
let previousUpc = ""
var dateTogglerProductUsage
//var file=[];
var getProductExcelFile
class File {
  constructor(id, title, filePath, brand,fileTitle, country_code, type){
    this.title = fileTitle
    this.filePath = filePath
    this.id = id
    this.brand= brand
    this.fileTitle = title
    this.country_code = country_code
    this.type= type
  }
}
class ProductDetail extends Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = {
      copyText: "Product Detail Page.copy-link",
      copyLinkText: "Product Detail Page.copy-link",
      emailLinkText: "Product Detail Page.sendEmail",
      downloadNow: "Product Detail Page.download-now",
      printDownload: "Product Detail Page.print-download",
      downloadUnavailable: "Product Detail Page.download-unavailable",
      selectedImage : null,
      productDetailData: [],
      excelData: [],
      productTitle: "",
      productBrand: "",
      productPzn: "",
      productDescription: "",
      fileName: "",
      productUspEcomStory: "",
      productHeader1: "",
      branddesc: "",
      ptitel: "",
      subtitel: "",
      shortproductdescription: "",
      productdescriptionlang: "",
      ecomone: "",
      ecomtwo: "",
      ecomthree: "",
      ecomfour: "",
      ecomfive: "",
      prodesbom: "",
      disclaimer: "",
      website:"",
      productActiveIngredients: "",
      productType:"",
      showMore: false,
      showShareOptions: false,
      productCharacteristics: productCharacteristicsTab(),
      dimensionalData: dimensionalDataTab(),
      technicalData: technicalDataTab(),
      retailerInformationData : retailerInformationTab(),
      pimAttributes: pimAttributes(),
      hasResponse: false,
      wrongId: false,
      related_assets_count: null,
      showDisclaimerEcom: false,
      showDisclaimerPrintPackage: false,
      enableDownloadEcom: true,
      enableDownloadPrintPackage: true,
      license_expiry_date_ecom: null,
      license_expiry_date_print: null,
      hover_ecom_button: false,
      hover_printpack_button: false,
      hover_productexcel_button: false,
      downloadAllCheckBox: false,
      downloadArrayLengthCheck: true,
      downloadMainCheckbox: false,
      triggerFlag: false,
      lastModifiedDates: {},
      anchorEl : null,
      grayColorOut : false,
      gigyaRole : "",
      cartModal: false,
      downloadcom: false,
      isOpen_productDescription : JSON.parse(localStorage.getItem('is-open-productDescription')) || false,
      isOpen_productUsage : JSON.parse(localStorage.getItem('is-open-productUsage')) || false,
      productDescriptionTabText: "productDescriptionTabText",
      productUsageTabText: "productUsageTabText",

    };
   
    this.countrySettings = this.props.countrySettings;
    this.RoleSettings= this.props.RoleSettings;
    this.countryLocale= this.props.countryLocale;
    this.dropdownRef = React.createRef();
    this.uspStoryRef = React.createRef();
    this.ecomdescRef = React.createRef();
    this.disclaimerCheckboxRef = React.createRef();
    this.downloadAllRef = React.createRef()
  }


 normalValueShow =(item, particularAttribute, countryLocale)=>{
    return(
      typeof item?.[particularAttribute] === "string"
      ? item?.[particularAttribute]  : countryLocale &&  item?.[particularAttribute]?.[countryLocale] ?
      item?.[particularAttribute]?.[countryLocale]  : item?.[particularAttribute]?.['en-ZZ']
    )
  }

   handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({anchorEl : event.currentTarget})
  };
   handleClose = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({anchorEl : !event.currentTarget})
  };
   postGigyaID = async () => {
    //const userId = this.context.giveToken();
    const userId= localStorage.email
    const response = await axios({
      method: "get",
      url: `${config.baseApi}dc/exports/getrolepermission/${userId}`,
      data: {
        "useAdministration": {
          "Gigya_ID": userId,
        }
      },
      headers: {
        "X-Country-Code": `${getCountryCode()}`
      }
    })
    this.setState({gigyaRole: response.data.data?.roleName })

  }
   hideShowFunction = async (item, status) => {
    const userGigyaId = this.context.giveToken();
    const userId = localStorage.email
    const response = await axios({
      method: "POST",
      url: `${config.favApi}dc/exports/hsproductsassetsbyadmin`,
      data: {
        "gigyaId": userGigyaId,
        "emailId": userId,
        "PK": item?.PK,
        "SK": item?.SK,
        "status": status, // show
      },
      headers: {
        "X-Country-Code": `${getCountryCode()}`
      }
    })
    const responseUpdated = await axios({
      method: "get",
      url: `${config.baseApi}dc/entities`,
     
      headers: {
        "X-Country-Code": `${getCountryCode()}`
      }
    })
     this.props.updateSetRenderProductList(responseUpdated.data.data.products);

    return response

  }


  getQueryParam = id => {
    let product = this.props.productsList.filter(product => product.id === id && (typeof product?.plcstatus === "string" ?  product?.plcstatus?.toLowerCase().includes("ready for publication") : product?.plcstatus?.['en-ZZ']?.toLowerCase().includes("ready for publication")));
    if(product.length > 0){
      return {
        title:this.normalValueShow(product[0],"ptitle",this.props.countryLocale),
        brandName: product[0]?.PK,
        id: product[0]?.SK
      }
    }     
  };

  async componentDidMount() {
    const appContainer = document.querySelector(".App");
    appContainer.scrollTo(0, 0);
    document.title = this.props.title;
    let params = this.getQueryParam(this.props.match.params.id);
    // if(!params){
    //   //<Redirect to="/home" />
    //   const wrongId = true;
    //   this.setState({ wrongId });    
    // }
    this.homePageRedirectFunc()
    this.postGigyaID()
    //console.log("countrylocalepdp",this.props.countryLocale)
    //const params = this.props.location.search;
    //const params = this.props.location.state.queryParams;
    //const parsedParams = qs.parse(params, { ignoreQueryPrefix: true });
    const response = await axios.get(productlistUrl, {
      params: params,
      headers: {
        "X-Country-Code": `${getCountryCode()}`
      }
    });
    const productData = response.data.data.product;
    const productDetailData = productData;

    if (productDetailData !== undefined) {
      const hasResponse = true;
      const fileName = `Produktdaten_${this.normalValueShow(productDetailData,"ptitle",this.props.countryLocale)}_${this.normalValueShow(productDetailData,"pharmacentralnum",this.props.countryLocale)}`;
      this.setState({ fileName });
      this.excelProductData(productDetailData);
      this.setState({ productDetailData });
      this.setState({ hasResponse });
      this.productDetailPageInfo(productDetailData);
      let product_related_assets = this.props.assetsList.filter(
        asset => asset.upc === this.normalValueShow(productDetailData,"unitupc",this.props.countryLocale)
      );
      this.setState({
        related_assets_count: product_related_assets.length
      });
    }
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  homePageRedirectFunc=()=>{
    setTimeout(() => {
      if(!this.getQueryParam(this.props.match.params.id)){
        // <Redirect to="/home" />
       // const wrongId = true;
          this.setState({ wrongId :true }); 
      }
    }, 15000);
    
  }

  async componentDidUpdate(prevProps) {
    //
    // let params = this.props.location.state.queryParams;
    //let params = this.props.location.search;
    //let prevParams = prevProps.location.search;
    //let prevParams = prevProps.location.state.queryParams;
    //const parsedParams = qs.parse(params, { ignoreQueryPrefix: true });
    //const prevparsedParams = qs.parse(prevParams, { ignoreQueryPrefix: true });
    let productDetailData = "";
    /* if (parsedParams.id !== prevparsedParams.id) */
    if (this.props !== prevProps) {
      // if(!this.getQueryParam(this.props.match.params.id)){
      //   // <Redirect to="/home" />
      //   const wrongId = true;
      //    this.setState({ wrongId }); 
      // }
      this.homePageRedirectFunc()
      const response = await axios.get(productlistUrl, {
        //params: parsedParams,
        params: this.getQueryParam(this.props.match.params.id),
        headers: {
          "X-Country-Code": `${getCountryCode()}`
        }        
      });      
      const productData = response.data.data.product;
      productDetailData = productData;
      if (productDetailData !== undefined) {
        const hasResponse = true;
        const fileName = `Produktdaten_${this.normalValueShow(productDetailData,"ptitle",this.props.countryLocale)}_${this.normalValueShow(productDetailData,"pharmacentralnum",this.props.countryLocale)}`;
        this.setState({ fileName });
        this.excelProductData(productDetailData);
        this.setState({ productDetailData });
        this.setState({ hasResponse });
        this.productDetailPageInfo(productDetailData);
      }
    }
    if (prevProps.assetsList !== this.props.assetsList) {
      let product_related_assets = this.props.assetsList.filter(
        asset => asset.upc ===this.normalValueShow(productDetailData,"unitupc",this.props.countryLocale)
      );
      this.setState({
        related_assets_count: product_related_assets.length
      });
    }
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  extractContent = val => {
    let span = document.createElement("span");
    span.innerHTML = val;
    return span.textContent || span.innerText;
  };

  productLastUpdatedDate = (productDetailData, attribute) => {
    const updatedDates = this.normalValueShow(productDetailData,"lastUpdatedDate",this.props.countryLocale);
    if(updatedDates === undefined || updatedDates.length < 1){
       return "";
    }
    const date = this.normalValueShow(productDetailData,"lastUpdatedDate",this.props.countryLocale)[0];
    const dateLocations = date.updatedAttributes;
    const attributeLists = dateLocations.filter (modifiedLocation => modifiedLocation.attributeList[attribute] !== undefined);
    if(attributeLists !== undefined && attributeLists.length > 0){
      const modifiedDate = attributeLists[0].modifiedDate.slice(0,10);
      return modifiedDate;
    }else{
      return ""
    }
}

  excelProductData = productDetailData => {
    const { t } = this.props;
    let productProperties = Object.keys(productDetailData);
    let { pimAttributes } = { ...this.state };
    let excelData = [];
    let excelPair = {};
    pimAttributes.forEach(attribute => {
      if (productProperties.includes(attribute)) {
        let temp = {};
        temp[t(`Excel Header.attribute`)]  = t(`Product Detail Page${attribute}`);
        temp[t(`Excel Header.content`)]= productDetailData[attribute];
        temp[t(`Excel Header.last_update`)] = this.productLastUpdatedDate(productDetailData, attribute);
        excelPair = Object.assign({}, temp);
        excelData.push(excelPair);
      }
    });

    this.setState({ excelData: excelData });
     productInfoButton = {
      apiData:excelData,
      fileName: this.state.fileName,
      brand:this.state.productBrand,
      id:"excel",
      type: "excel",
      release_date: "",
      file_size:"",
      upc:"",
      asset_location:"excel"
    }

  };

  cartExcelProductData = productDetailData => {
    const { t } = this.props;
    let productProperties = Object.keys(productDetailData);
    let { pimAttributes } = { ...this.state };
    let excelData = [];
    let excelPair = {};
    pimAttributes.forEach(attribute => {
      if (productProperties.includes(attribute)) {
        let temp = {};
        temp[t(`Excel Header.attribute`)]  = t(`Product Detail Page${attribute}`);
        temp[t(`Excel Header.content`)]= productDetailData[attribute];
        temp[t(`Excel Header.last_update`)] = this.productLastUpdatedDate(productDetailData, attribute);
        excelPair = Object.assign({}, temp);
        excelData.push(excelPair);
      }
    });


  };

  productDetailPageInfo = productDetailData => {
    const { t } = this.props;
    let productTitle,
      productBrand,
      productPzn,
      productDescription,
      productUspEcomStory,
      productHeader1,
      branddesc,
      ptitel,
      subtitel,
      shortproductdescription,
      productdescriptionlang,
      ecomone,
      ecomtwo,
      ecomthree,
      ecomfour,
      ecomfive,
      prodesbom,
      disclaimer,
      website,
      productActiveIngredients,
      productType,
      showDisclaimerEcom = "",
      showDisclaimerPrintPackage = "",
      license_expiry_date_ecom = null,
      license_expiry_date_print = null;
    if (
      productDetailData !== undefined &&
      Object.keys(productDetailData).length > 0
    ) {
      productTitle = Object.keys(productDetailData).includes("ptitle")
        ? this.normalValueShow(productDetailData,"ptitle",this.props.countryLocale)
        :t("Miscellaneous.notAvailable");
      productBrand = Object.keys(productDetailData).includes("brand")
        ? this.normalValueShow(productDetailData,"brand",this.props.countryLocale)
        :t("Miscellaneous.notAvailable");
      productPzn = Object.keys(productDetailData).includes("pharmacentralnum")
        ? this.normalValueShow(productDetailData,"pharmacentralnum",this.props.countryLocale)
        :t("Miscellaneous.notAvailable");
        (getCountryCode() === "nl" || getCountryCode() === "be") ? 
        productDescription = Object.keys(productDetailData).includes(
          "longproddescription"
        )
          ? this.normalValueShow(productDetailData,"longproddescription",this.props.countryLocale)
          :t("Miscellaneous.notAvailable")
          :
      productDescription = Object.keys(productDetailData).includes(
        "ecomproddesc"
      )
        ? this.normalValueShow(productDetailData,"ecomproddesc",this.props.countryLocale)
        :t("Miscellaneous.notAvailable");

      if (getCountryCode() === "nl" || getCountryCode() === "be") {
        productUspEcomStory = _.has(productDetailData, "shortproddescription")
          ? this.normalValueShow(productDetailData,"shortproddescription",this.props.countryLocale)
          :t("Miscellaneous.notAvailable");

      } else {
        productUspEcomStory = _.has(productDetailData, "uspecomstory")
          ? this.normalValueShow(productDetailData,"uspecomstory",this.props.countryLocale)
          :t("Miscellaneous.notAvailable");
      }

      
      productHeader1 = _.has(productDetailData, "header1")
      ? this.normalValueShow(productDetailData,"header1",this.props.countryLocale)
      :t("Miscellaneous.notAvailable");
      branddesc = _.has(productDetailData, "branddesc")
      ? this.normalValueShow(productDetailData,"branddesc",this.props.countryLocale)
      :t("Miscellaneous.notAvailable");
      ptitel = _.has(productDetailData, "ptitel")
      ? this.normalValueShow(productDetailData,"ptitel",this.props.countryLocale)
      :t("Miscellaneous.notAvailable");    
      subtitel = _.has(productDetailData, "subtitel")
      ? this.normalValueShow(productDetailData,"subtitel",this.props.countryLocale)
      :t("Miscellaneous.notAvailable");
      productdescriptionlang = _.has(productDetailData, "brproductdescriptionlanganddesc")
      ? this.normalValueShow(productDetailData,"productdescriptionlang",this.props.countryLocale)
      :t("Miscellaneous.notAvailable");
      ecomone = _.has(productDetailData, "ecomone")
      ? this.normalValueShow(productDetailData,"ecomone",this.props.countryLocale)
      :t("Miscellaneous.notAvailable");
      ecomtwo = _.has(productDetailData, "ecomtwo")
      ? this.normalValueShow(productDetailData,"ecomtwo",this.props.countryLocale)
      :t("Miscellaneous.notAvailable");
      ecomthree = _.has(productDetailData, "ecomthree")
      ? this.normalValueShow(productDetailData,"ecomthree",this.props.countryLocale)
      :t("Miscellaneous.notAvailable");
      ecomfour = _.has(productDetailData, "ecomfour")
      ? this.normalValueShow(productDetailData,"ecomfour",this.props.countryLocale)
      :t("Miscellaneous.notAvailable");
      ecomfive = _.has(productDetailData, "ecomfive")
      ? this.normalValueShow(productDetailData,"ecomfive",this.props.countryLocale)
      :t("Miscellaneous.notAvailable");
      prodesbom = _.has(productDetailData, "prodesbom")
      ? this.normalValueShow(productDetailData,"prodesbom",this.props.countryLocale)
      :t("Miscellaneous.notAvailable");
      disclaimer = _.has(productDetailData, "disclaimer")
      ? this.normalValueShow(productDetailData,"disclaimer",this.props.countryLocale)
      :t("Miscellaneous.notAvailable");
      website =  _.has(productDetailData, "website")
      ? this.normalValueShow(productDetailData,"website",this.props.countryLocale)
      :t("Miscellaneous.notAvailable");
      productActiveIngredients = _.has(productDetailData, "activeingredients")
        ? this.normalValueShow(productDetailData,"activeingredients",this.props.countryLocale)
        :t("Miscellaneous.notAvailable");

      productType = _.has(productDetailData, "ptype")
        ? this.normalValueShow(productDetailData,"ptype",this.props.countryLocale)
        :t("Miscellaneous.notAvailable");  

      if (_.has(productDetailData, "asset_info")) {
        if (Object.keys(productDetailData.asset_info).includes("license_expiry_date") && (this.normalValueShow(productDetailData.asset_info,"license_expiry_date",this.props.countryLocale).length>0) ) {
          showDisclaimerEcom = true;
          license_expiry_date_ecom = this.normalValueShow(productDetailData.asset_info,"license_expiry_date",this.props.countryLocale);
        }
      }

      if (_.has(productDetailData, "asset_info_print")) {
        if (
          Object.keys(productDetailData.asset_info_print).includes(
            "license_expiry_date"
          )  && (this.normalValueShow(productDetailData.asset_info_print,"license_expiry_date",this.props.countryLocale).length>0) 
        ) {
          showDisclaimerPrintPackage = true;
          license_expiry_date_print =this.normalValueShow(productDetailData.asset_info_print,"license_expiry_date",this.props.countryLocale);
        }
      }

      let lastModifiedDates = this.getLastModifiedDates(productDetailData);
      let isOpen_productDescription = this.getSwitcherStatus(this.state.productDescriptionTabText)
      let isOpen_productUsage = this.getSwitcherStatus(this.state.productUsageTabText)
      this.setState({
        productTitle,
        productBrand,
        productPzn,
        productDescription,
        productUspEcomStory,
        productHeader1,
        branddesc,
        ptitel,
        subtitel,
        shortproductdescription,
        productdescriptionlang,
        ecomone,
        ecomtwo,
        ecomthree,
        ecomfour,
        ecomfive,
        prodesbom,
        disclaimer,
        website,
        productActiveIngredients,
        productType,
        showDisclaimerEcom,
        showDisclaimerPrintPackage,
        enableDownloadEcom: !showDisclaimerEcom,
        enableDownloadPrintPackage: !showDisclaimerPrintPackage,
        license_expiry_date_ecom,
        license_expiry_date_print,
        lastModifiedDates,
        isOpen_productDescription,
        isOpen_productUsage
      });
    }
  };

  getLastModifiedDates = productDetailData => {
    let lastModifiedDates = {};
    const updatedDates = this.normalValueShow(productDetailData,"lastUpdatedDate",this.props.countryLocale);
    if(updatedDates === undefined || updatedDates.length < 1){
       return lastModifiedDates;
    }
    const date =  this.normalValueShow(productDetailData,"lastUpdatedDate",this.props.countryLocale)[0];
    const dateLocations = date.updatedAttributes;
    dateLocations.forEach (modifiedLocation => {
      const attribes = Object.keys(modifiedLocation.attributeList)
      attribes.forEach(attr => {
        lastModifiedDates[attr] = modifiedLocation.modifiedDate.slice(0,10);
      })
    });
    return lastModifiedDates;
  }
  updateSwitcherStatusLocally(switcherTabName, value) {
    // 'is-open-productDescription'
    let unitupc =  this.normalValueShow(this.state.productDetailData,"unitupc",this.props.countryLocale)|| ''
    localStorage.setItem(switcherTabName + unitupc, JSON.stringify(value))
    
  }

  getSwitcherStatus(switcherTabName) {
    let unitupc = this.normalValueShow(this.state.productDetailData,"unitupc",this.props.countryLocale)|| ''
    return JSON.parse(localStorage.getItem(switcherTabName + unitupc)) || false
  }
  updateSwitcherProdDescription = (t) => {
    const uspecomstory = this.lastUpdateComp("uspecomstory");
    const ecomproddesc = this.lastUpdateComp("ecomproddesc");
    const ingredients = this.lastUpdateComp("activeingredients");
    if((uspecomstory || ecomproddesc || ingredients) && (uspecomstory !== "" || ecomproddesc !== "" || ingredients !== "" )) {
      return <div className="row mt-2 mb-0"> 
      <div className="col-lg-11 col-md-10 col-sm-6">
        <div className="show-heading mt-2"> 
          <p>{t("Product Detail Page.toggleLastUpdateHeading")}</p>
        </div>
      </div>
      <div className="col-lg-1 col-md-2 col-sm-6">
      <label class="switch" >
        <input type="checkbox" onClick={()=>this.handleToggle("productDescription")} checked={this.state.isOpen_productDescription } />
        <span class="slider round"></span>
      </label>
      </div>
  </div>
    } else {
      return ""
    }
  }

  // updateSwitcherProdUsage = (t) => {
  //   const lastUpdateDate = this.lastUpdateComp(usage.headline.split('.').pop())
  //   if(lastUpdateDate && lastUpdateDate !== "" ) {
  //     return <div className="row mt-2 mb-0"> 
  //     <div className="col-lg-11 col-md-10 col-sm-6">
  //       <div className="show-heading mt-2"> 
  //         <p>{t("product.detail.toggleLastUpdateHeading")}</p>
  //       </div>
  //     </div>
  //     <div className="col-lg-1 col-md-2 col-sm-6">
  //     <label class="switch" >
  //       <input type="checkbox" onClick={()=>this.handleToggle("productUsage")} checked={this.state.isOpen_productUsage} />
  //       <span class="slider round"></span>
  //     </label>
  //     </div>
  // </div>
  //   } else {
  //     return ""
  //   }
  // }

  lastUpdateComp = key => {
    const { t } = this.props;
    const countryCode = getCountryCode();
    const lastUpdatedDate = this.state.lastModifiedDates[key];
    if(countryCode === "de") {
      if(lastUpdatedDate){
      const [year, month, day] = lastUpdatedDate?.split('-')
       let formatedDate= `${day}.${month}.${year}`
       if(lastUpdatedDate === undefined || lastUpdatedDate === ""){
        return ""
        }else{
          return <span className="lastupdated">({t("Product Detail Page.lastupdate")}: &nbsp;
            {formatedDate})
          </span>
        }
      }
    }else{
      if(lastUpdatedDate === undefined || lastUpdatedDate === ""){
        return ""
        }else{
          return <span className="lastupdated">({t("Product Detail Page.lastupdate")}: &nbsp;
            {lastUpdatedDate})
          </span>
        }
    }
  }

  copyLinkOption = e => {
    this.setState({ copyLinkText: "Product Detail Page.link-copied" });
    e.target.classList.add("done");
    navigator.clipboard.writeText(window.location.href);
    window.dataLayer.push({
      event: "gaGenericEvent",
      eventCategory: "Share Link(local)",
      eventAction: this.state.productTitle,
      eventLabel: "product",
      eventValue: window.location.href,
      environment: ga_env
    });
  };

  copyLinkEmail = e => {
    e.target.parentElement.classList.add("done");
    window.dataLayer.push({
      event: "gaGenericEvent",
      eventCategory: "Share LinkviaEmail(local)",
      eventAction: this.state.productTitle,
      eventLabel: "product",
      eventValue: window.location.href,
      environment: ga_env
    });
  };

  copyLink = e => {
    let shareOptions = this.state.showShareOptions;
    this.setState({
      showShareOptions: !shareOptions
    });
    e.currentTarget.classList.add("done");
  };

  handleDelete = () => {
    this.setState({ showChip: false });
  };

  ecomBulletPoint = productDetailData => {
    let ecomBullet = [];
    let ecomBulletKeys = [
      "ecomone",
      "ecomtwo",
      "ecomthree",
      "ecomfour",
      "ecomfive"
    ];
    ecomBulletKeys.forEach(ecomBulletKey => {
      Object.keys(productDetailData).forEach(key => {
        if (ecomBulletKey === key) {
          const ecomBulletPoint = this.normalValueShow(productDetailData,key,this.props.countryLocale);
          if (ecomBulletPoint !== "N/A" && ecomBulletPoint.length > 0) {
            ecomBullet.push(<li>{ecomBulletPoint}</li>);
          }
        }
      });
    });

    if (ecomBullet.length > 0) {
      return ecomBullet;
    }
  };

  copyOnClick = e => {
    let textToCopy;
    e.target.classList.remove("bi-clipboard");
    e.target.classList.add("bi-clipboard-check");
    textToCopy = e.target.parentElement.classList.contains("usp-story")
      ? this.state.productUspEcomStory
      : e.target.parentElement.classList.contains("usp-header")
      ? this.state.productHeader1
      : e.target.parentElement.classList.contains("usp-website")
      ? this.state.website
      : this.state.productDescription;
    navigator.clipboard.writeText(textToCopy);
    window.dataLayer.push({
      event: "gaGenericEvent",
      eventCategory: "Copy Text(local)",
      eventAction: this.state.productTitle,
      eventLabel: "product",
      eventValue: "product description",
      environment: ga_env
    });
  };
   endsWith= (str, suffix) =>{
    return str.indexOf(suffix, str.length - suffix.length) !== -1;
  }
   productImageRender = (attachments) => {
    const { t } = this.props;
    let imageUrldefault,
    // eslint-disable-next-line 
     preview_available = false;
     //const domain = env === "PROD" ?  "https://s3.eu-central-1.amazonaws.com/downloadcenter.bayer.de" : "https://s3.eu-central-1.amazonaws.com/qa-downloadcenter.bayer.com"
     const domain = window.location.origin;
     let upc = this.normalValueShow(this.state.productDetailData,"unitupc",this.props.countryLocale);
    let assets = this.props.assetsList.filter(asset => asset?.upc?.split(";").map(item => item.trim()).includes(upc));

    function filterAssets(assets) {
      // Filter by asset_location ending with .zip but not with print.zip
      const filteredAssets = assets.filter(asset => {
          const location = asset.asset_location.toLowerCase();
          return location.endsWith('.zip') && !location.endsWith('print.zip');
      });
  
      // Create a new array of objects with assetId and preview_location
      const result = filteredAssets.flatMap(asset => {
          if (asset?.attachments?.length > 0) {
              return asset.attachments.map(attachment => ({
                  assetId: attachment.assetId,
                  preview_location: attachment.preview_location
              }));
          } 
          else if(asset.hasOwnProperty("attachments") === false){
            return []
          }
          else {
              return [{
                  assetId: asset.assetId,
                  preview_location: asset.preview_location
              }];
          }
      });
  
      return result;
  }

    if (
      Object.keys(attachments).includes("asset_info_print") &&
      Object.keys(attachments.asset_info_print).includes("preview_location")
    ) {
      imageUrldefault = `${domain}/${attachments.asset_info_print.preview_location}`;
      preview_available = true;
    } else if (Object.keys(attachments).includes("thumbnail")) {
      imageUrldefault = attachments.thumbnail;
      if (imageUrldefault !== undefined && imageUrldefault.length > 0) {
        preview_available = true;}
    } else if (
      Object.keys(attachments).includes("asset_info") &&
      Object.keys(attachments.asset_info).includes("preview_location")
    ) {
      imageUrldefault = `${domain}/${attachments.asset_info.preview_location}`;
      preview_available = true;
    } else if (Object.keys(attachments).includes("asset_location")) {
      let primaryImage = "";
      if (Array.isArray(attachments.asset_location)) {
        primaryImage = attachments.asset_location.filter(
          file =>
            file.includes("Primary_Image") && file.indexOf("_MACOSX") === -1
        );
      } else if (
        attachments.asset_location.includes("Primary_Image") &&
        attachments.asset_location.indexOf("_MACOSX") === -1
      ) {
        primaryImage = attachments.asset_location;
      }
      if (primaryImage.length > 0) {
        imageUrldefault = `${domain}/${primaryImage}`;
        // eslint-disable-next-line 
        preview_available = true;
        this.setState({selectedImage :imageUrldefault})
      }
    }
  
    // Function to determine the image URL
    const getImageUrl = item => {
      let imageUrl = '';
      let preview_available = false;
  
      if (
        item.asset_info_print &&
        item.asset_info_print.preview_location
      ) {
        imageUrl = `${domain}/${item.asset_info_print.preview_location}`;
        preview_available = true;
      } else if (item.thumbnail) {
        imageUrl = item.thumbnail;
        if (imageUrl && imageUrl.length > 0) {
          preview_available = true;
        }
      } else if (
        item.asset_info &&
        item.asset_info.preview_location
      ) {
        imageUrl = `${domain}/${item.asset_info.preview_location}`;
        preview_available = true;
      } else if (item.asset_location) {
        let primaryImage = "";
        if (Array.isArray(item.asset_location)) {
          primaryImage = item.asset_location.filter(
            file =>
              file.includes("Primary_Image") && file.indexOf("_MACOSX") === -1
          );
        } else if (
          item.asset_location.includes("Primary_Image") &&
          item.asset_location.indexOf("_MACOSX") === -1
        ) {
          primaryImage = item.asset_location;
        }
        if (primaryImage.length > 0) {
          imageUrl = `${domain}/${primaryImage}`;
          preview_available = true;
        }
      }
      else{ 
        imageUrl = `${domain}/${item.preview_location}`;
        preview_available = true;
      }
  
      if (preview_available) {
        return imageUrl;
      } else {
        return imageUrl;
      }
    };
  
    // Extract the image URLs from the attachments
    const images = attachments &&  (attachments?.asset_info?.length >0 &&  attachments?.asset_info?.attachments>0) ? attachments?.asset_info?.attachments?.map(attachment => ({
      id: attachment?.assetId,
      preview_location: attachment?.preview_location,
    })) : filterAssets(assets);
  
    return (
      <div className="product-image-display">
        <div className="thumbnails">
          {images && images?.reverse().map((image, index) => {
            const imageUrl = getImageUrl(image);
        
            return (
              <div
                key={index}
                className={`thumbnailInside ${this.state.selectedImage === image.preview_location ? 'selected' : ''}`}
                onClick={() =>  this.setState({selectedImage :image.preview_location })}
              >
                {imageUrldefault ? (
                  <img alt="Product thumbnail" src={imageUrl} height="100%" width="100%" />
                ) : (
                  <React.Fragment>
                    <div className="no-preview">
                      <div className="bayer-logo-background"></div>
                      {( this.state.gigyaRole === "Internal") ?
                        <span className="text">{t("Miscellaneous.noPreviewForInternal")}</span>
                        :
                        <span className="text">{t("Miscellaneous.noPreview")}</span>}
                    </div>
                  </React.Fragment>
                )}
              </div>
            );
          })}
        </div>
        <div className="main-image">
          {this.state.selectedImage  || imageUrldefault? (
            <img src={ this.state.selectedImage === null ?imageUrldefault    : `${domain}/${this.state.selectedImage}`} alt={ `Product thumbnail`} />
          ) : (
            <React.Fragment>
                    <div className="no-preview">
                      <div className="bayer-logo-background"></div>
                      {( this.state.gigyaRole === "Internal") ?
                        <span className="text">{t("Miscellaneous.noPreviewForInternal")}</span>
                        :
                        <span className="text">{t("Miscellaneous.noPreview")}</span>}
                    </div>
                  </React.Fragment>
          )}
        </div>
      </div>
    );
  };
  
  downloadButton = productDetailData => {
    const { t } = this.props;
    let class_name = "";
    if (this.state.enableDownloadEcom) {
      class_name = "download-button active";
    } else {
      class_name = "download-button";
    }
    return (
      <div
        className={class_name}
        onMouseEnter={() => {
          this.setState({ hover_ecom_button: true });
        }}
        onMouseLeave={() => {
          this.setState({ hover_ecom_button: false });
        }}
      >
        <button
          onClick={e => {
            downloadOnClick(e, productDetailData, "asset");
          }}
        >
          <span>{t(this.state.downloadNow)}</span>
          <FontAwesomeIcon icon={faChevronRight} />
          {/* <i className="bi bi-file-earmark-arrow-down"></i> */}
        </button>
      </div>
    );
  };

  printDownloadButton = productDetailData => {
    const { t } = this.props;
    let class_name = "";
    if (this.state.enableDownloadPrintPackage) {
      class_name = "print-download-button active";
    } else {
      class_name = "print-download-button";
    }
    return (
      <div
        className={class_name}
        onMouseEnter={() => {
          this.setState({ hover_printpack_button: true });
        }}
        onMouseLeave={() => {
          this.setState({ hover_printpack_button: false });
        }}
      >
        <button
          onClick={e => {
            downloadOnClick(e, productDetailData, "print_media");
          }}
        >
          <span>{t(this.state.printDownload)}</span>
          <FontAwesomeIcon icon={faChevronRight} />
          {/* <i className="bi bi-file-earmark-arrow-down"></i> */}
        </button>
      </div>
    );
  };

  getPrintPackageInfo = () => {
    if (
      Object.keys(this.state.productDetailData).includes(
        "print_media_location"
      ) &&
      this.normalValueShow(this.state.productDetailData,"print_media_location",this.props.countryLocale) != null &&
      this.normalValueShow(this.state.productDetailData,"print_media_location",this.props.countryLocale).length > 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  getEComPackageInfo = () => {
    if (
      Object.keys(this.state.productDetailData).includes("asset_location") &&
      this.normalValueShow(this.state.productDetailData,"asset_location",this.props.countryLocale) != null &&
      this.normalValueShow(this.state.productDetailData,"asset_location",this.props.countryLocale).length > 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  getCopyLinkButton = () => {
    return (
      <button
        className="copyLinkButton"
        onClick={event => {
          this.copyLink(event);
        }}
      >
        <i className="bi bi-share"></i>
      </button>
    );
  };
  addToCart = (event, item) => {

    event.preventDefault();
    let cartSet = event.currentTarget.getAttribute("data-cartSet");
    const type = event.currentTarget.getAttribute("data-Carttype");
    const id = event.currentTarget.getAttribute("data-Cartid");

    const title = event.currentTarget.getAttribute("data-Carttitle");
    //const userId = localStorage.token;
    const userId= localStorage.email
    let productidList = this.props.favProductsList;
    let assetidList = this.props.CartAssetsList;
    if (cartSet === "false") {

      event.currentTarget.setAttribute("data-cartSet", "true");
      event.currentTarget.firstElementChild.classList.remove("bi-cart-plus");
      event.currentTarget.firstElementChild.classList.add("bi-cart-check-fill");
      switch (type) {
        case "product":
          if (assetidList !== undefined) {
            assetidList.push(id);
            this.props.updateCartAssetsList(assetidList);
          }
          break;
        case "asset":
          if (assetidList !== undefined) {        
            assetidList.push(id);
            this.props.updateCartAssetsList(assetidList);
  
          }
          break;
        default:
          break;
      }
    } else {
      event.currentTarget.setAttribute("data-cartSet", "false");
      event.currentTarget.firstElementChild.classList.remove("bi-cart-check-fill");
      event.currentTarget.firstElementChild.classList.add("bi-cart-plus");
      switch (type) {
        case "product":
          if (productidList !== undefined) {
              assetidList = assetidList.filter(assetId => assetId !== id);
              this.props.updateCartAssetsList(assetidList);
          }
          break;
        case "asset":
          if (assetidList !== undefined) {
          assetidList = assetidList.filter(assetId => assetId !== id);
          this.props.updateCartAssetsList(assetidList);
          }
          break;
        default:
          break;
      }
    }
    let flag= false
    if (type === "product"  && cartSet=== "false") {
      axios({
        method: "post",
        url: `${config.favApi}dc/assets/addAssetsToCart/${userId}`,
        data: {         
          country: item.country_code,
          assetId: this.normalValueShow(item,"id",this.props.countryLocale) ,
          assetName: this.normalValueShow(item,"title",this.props.countryLocale),
          brand: this.normalValueShow(item,"brand",this.props.countryLocale),
          type: this.normalValueShow(item,"type",this.props.countryLocale) 
          },
        headers: {
          "X-Country-Code": `${getCountryCode()}`
        }
      })
      .then(async() => {
        
        flag = true;
        await getCartList(userId);
      })
      .catch((error) => {
        console.error("Error in Axios request", error);
      });
    }
    if (type === "product" && cartSet=== "true") {
      axios({
        method: "post",
        url:  `${config.favApi}dc/assets/deleteAssetsFromCart/${userId}`,
        data: {         
          country: item.country_code,
          assetId: this.normalValueShow(item,"id",this.props.countryLocale) ,
          assetName: this.normalValueShow(item,"title",this.props.countryLocale),
          brand: this.normalValueShow(item,"brand",this.props.countryLocale),
          type: this.normalValueShow(item,"type",this.props.countryLocale) 
        },
        headers: {
          "X-Country-Code": `${getCountryCode()}`
        }
      })
      .then(async() => {
        
        flag = true;
        await getCartList(userId);
      })
      .catch((error) => {
        console.error("Error in Axios request", error);
      });
    }
    if (type === "asset" && cartSet=== "false") {
      axios({
        method: "post",
        url:  `${config.favApi}dc/assets/addAssetsToCart/${userId}`,
        data: {         
          country: item.country_code,
          assetId: this.normalValueShow(item,"id",this.props.countryLocale) ,
          assetName: this.normalValueShow(item,"title",this.props.countryLocale),
          brand: this.normalValueShow(item,"brand",this.props.countryLocale),
          type: this.normalValueShow(item,"type",this.props.countryLocale) 
        },
        headers: {
          "X-Country-Code": `${getCountryCode()}`
        }
      })
      .then(async() => {
        
        flag = true;
        await getCartList(userId);
      })
      .catch((error) => {
        console.error("Error in Axios request", error);
      });
    }
    if (type === "asset" && cartSet=== "true") {
      axios({
        method: "post",
        url:  `${config.favApi}dc/assets/deleteAssetsFromCart/${userId}`,
        data: {         
          country: item.country_code,
          assetId: this.normalValueShow(item,"id",this.props.countryLocale) ,
          assetName: this.normalValueShow(item,"title",this.props.countryLocale),
          brand: this.normalValueShow(item,"brand",this.props.countryLocale),
          type: this.normalValueShow(item,"type",this.props.countryLocale) 
        },
        headers: {
          "X-Country-Code": `${getCountryCode()}`
        }
      })
      .then(async() => {
        
        flag = true;
        await getCartList(userId);
      })
      .catch((error) => {
        console.error("Error in Axios request", error);
      });
    }
    window.dataLayer.push({
      event: "gaGenericEvent",
      eventCategory: "Mark Favorites(local)",
      eventAction: title,
      eventLabel: type,
      eventValue: window.location.href,
      environment: ga_env
    });
    flag && getCartList(userId)
  };
   productCartModal = entity => {
    let productsList = this.props.productsList
    let assetList=  this.props.assetsList
    let favAssets = this.props.CartAssetsList.map(asset => this.normalValueShow(asset,"assetId",this.props.countryLocale));
    let filteringassets =assetList.filter((asset)=> favAssets.includes(asset?.assetId))
    let upcValues = filteringassets.map(asset => this.normalValueShow(asset,"upc",this.props.countryLocale));
    const combinedList = Array.from(new Set([...favAssets, ...upcValues]));

    let cartSet=false;
    let filteredproduct = productsList.filter((products)=> combinedList.includes(products?.unitupc)).map(pro =>this.normalValueShow(pro,"unitupc",this.props.countryLocale));
    cartSet= filteredproduct.includes(this.normalValueShow(entity,"unitupc",this.props.countryLocale)) ? true : false;
    const iconchange = cartSet?"bi bi-cart-check-fill":"bi bi-cart-plus"
    return (
      <div
        className="cart-icon"
        style={{fontSize: "22px",marginRight: "10px",color:"#ff3162"}}
        onClick={e => this.handleCartClick(e, entity, "asset")}
        data-brand={(this.normalValueShow(entity,"brand",this.props.countryLocale))}
      >
        <i className={iconchange}></i>
      </div>
    );
    // }
  };
   assetCartButton = item => {
    let cartSet=false;
    let favAssets = this.props.CartAssetsList.map(asset => asset?.assetId);
    let itemTitle;
    switch (item.type) {
      case "product":
        if (favAssets !== undefined) {
          cartSet = favAssets.includes(item.id) ? true : false;
        }
        itemTitle = this.normalValueShow(item,"ptitle",this.props.countryLocale);
        break;
      case "asset":
        if (favAssets !== undefined) {
          cartSet = favAssets.includes(item.id) ? true : false;
        }
        itemTitle = this.normalValueShow(item,"title",this.props.countryLocale);
        break;
      default:
        break;
    }
    let icon = cartSet === true ? "bi-cart-check-fill" : "bi-cart-plus";
    return (
      <div style={{display: "flex",alignItems: "center"}} onClick={evt => {evt.preventDefault()}}>
      
      <div
        className="cart-icon"
        data-Carttype={this.normalValueShow(item,"type",this.props.countryLocale)}
        data-Cartid={this.normalValueShow(item,"id",this.props.countryLocale)}
        data-cartSet={cartSet}
        data-Carttitle={itemTitle}
        onClick={e => this.addToCart(e, item)}
        style={{fontSize: "22px",marginRight: "6px"}}
      >
      {/* {RoleSettings &&  RoleSettings['Mark favourites'] === true?   */}
      <i className={`bi ${icon}`}></i>
      {/* : ""} */}
      </div>
      {/* <Link className="card-block"  to={handleClickOnCard(item)}>
   
      </Link> */}
         <div style={{fontSize:"12px", color:"#00617f",textDecoration:"none"}}>{item.fileTitle}</div>
      </div>
    );
  };
   endsWith=(str, suffix)=> {
    return str.indexOf(suffix, str.length - suffix.length) !== -1;
  }
  isValidFileLocation=(fileName)=> {
    return fileName !== "" && fileName !== null && fileName !== undefined

  }
  getExcelData=(item)=>{
    const { t } = this.props;
    const fileName = `Produktdaten_${this.normalValueShow(item,"ptitle",this.props.countryLocale)}_${this.normalValueShow(item,"unitupc",this.props.countryLocale)}`
    const excelDatas = this.cartExcelProductData(item);
    const productBrand = Object.keys(item).includes("brand")
      ? this.normalValueShow(item,"brand",this.props.countryLocale)
      :t("Miscellaneous.notAvailable");
    getProductExcelFile = [fileName, excelDatas, productBrand];
    return getProductExcelFile;
  }
   quickCartOptions =(item)=>{
    let upc = this.normalValueShow(item,"unitupc",this.props.countryLocale);
    let assets = this.props.assetsList.filter(asset => asset?.upc?.split(";").map(item => item.trim()).includes(upc));
    assets.sort((a,b)=>  a.asset_location.toLowerCase().split("_").reverse()[0] < b.asset_location.toLowerCase().split("_").reverse()[0] ? -1 : 
    a.asset_location.toLowerCase().split("_").reverse()[0] < b.asset_location.toLowerCase().split("_").reverse()[0]? 1 :0);
 
    const { t } = this.props;
    //const domain = env === "PROD" ?  "https://s3.eu-central-1.amazonaws.com/downloadcenter.bayer.de" : "https://s3.eu-central-1.amazonaws.com/qa-downloadcenter.bayer.com"
     const domain = window.location.origin;
    var files = []
  

    assets.forEach((ele)=>{
      var eCommerceProductImages = new File(ele.assetId,
        !this.endsWith(ele?.asset_location, "Print.zip") ? t(`Asset Type Card.${ele.asset_type[0].dcAssetType}`) 
        : t("Asset Detail Page.PrintProduct"),
        ele?.asset_location ? `${domain}/${ele?.asset_location}` : "", ele.brand,  ele.title, ele.country_code, ele.type)
      files.push(eCommerceProductImages)
    })
    var productData = new File(item.unitupc,t("Asset Detail Page.Excel"),this.getExcelData(item),  this.normalValueShow(item,"brand",this.props.countryLocale), this.normalValueShow(item,"ptitle",this.props.countryLocale), item.country_code,this.normalValueShow(item,"type",this.props.countryLocale));
    files.push(productData)

    files = files.filter(file => {
      return this.isValidFileLocation(file.filePath)
    } )
   // file=files

    var listItem = files.map((file) => (
      <div style={{display: "flex",alignItems: "center"}} onClick={evt => {evt.preventDefault()}}>
     {this.assetCartButton(file)}
      </div>
    ));
    return listItem;

  }

   handleCartClick = (e) => {
    e.preventDefault();
    //setCartModal(true)
    this.setState({cartModal: !this.state.cartModal})

  };

  //  cartHandler=(e)=>{
  //   e.preventDefault();
  //  // setCartModal(false)
  //   this.setState({cartModal: false})

  // }

 

  addFavorites = event => {
    let favSet = event.currentTarget.getAttribute("data-favset");
    const id = event.currentTarget.getAttribute("data-id");
    //const userId = localStorage.token;
    const userId= localStorage.email
    let productidList = this.props.favProductsList;
    if (favSet === "false") {
      let currentDate = new Date().toJSON().slice(0, 10);
      event.currentTarget.setAttribute("data-favset", "true");
      event.currentTarget.firstElementChild.classList.remove("bi-bookmark");
      event.currentTarget.firstElementChild.classList.add("bi-bookmark-fill");
      if (productidList !== undefined) {
        let dataList={
          "notificationFlag": "",
          "productId": id,
          "updatedAttributes": [],
          "addedToFavoritesOn": currentDate,
          "notificationCheckedOn": ""
      }
        productidList.push(dataList);
        this.props.updateFavProductsList(productidList);
      }
    } else {
      let currentDate = new Date().toJSON().slice(0, 10);
      event.currentTarget.setAttribute("data-favset", "false");
      event.currentTarget.firstElementChild.classList.remove("bi-bookmark-fill");
      event.currentTarget.firstElementChild.classList.add("bi-bookmark");
      if (productidList !== undefined) {
        let dataList={
          "notificationFlag": "",
          "productId": id,
          "updatedAttributes": [],
          "addedToFavoritesOn": currentDate,
          "notificationCheckedOn": ""
      }
      productidList = productidList.filter(productId => productId.productId !== dataList.productId  );        
      this.props.updateFavProductsList(productidList);
      }
    }

    axios({
      method: "post",
      url: `${config.favApi}dc/userdata/${userId}/favorites`,
      data: {
        favorites: {
          products: productidList
        }
      },
      headers: {
        "X-Country-Code": `${getCountryCode()}`
      }
    });
    window.dataLayer.push({
      event: "gaGenericEvent",
      eventCategory: "Mark Favorites(local)",
      eventAction: this.state.productTitle,
      eventLabel: "product",
      eventValue: window.location.href,
      environment: ga_env
    });
  };

  favouriteButton = product =>{
    let favSet;
    let favProducts = this.props.favProductsList;
    let filteredProductList;
    if (favProducts !== undefined) {
      filteredProductList= favProducts.map((prodId)=>prodId.productId)
      favSet = filteredProductList.includes(product.name) ? true : false;
      //favSet = favProducts.includes(product.name) ? true : false;
    }
    const icon = favSet === true ? "bi-bookmark-fill" : "bi-bookmark";
    return (
      <div
        className="fav-icon"
        data-type={product.type}
        data-id={product.name}
        data-favset={favSet}
        onClick={e => this.addFavorites(e)}
      >
       {this.props.RoleSettings.countryData['Mark favourites'] === true?  <i className={`bi ${icon}`}></i> : ""}
      </div>
    );
  };

  productCharacteristicsCount = () => {
    let count = 0;
    const { productDetailData, productCharacteristics } = { ...this.state };
    let productProperties = Object.keys(productDetailData);
    productCharacteristics.forEach(key => {
      if (productProperties.includes(key)) {
        count = count + 1;
      }
    });
    return count;
  };

  technicalDataCount = () => {
    let count = 0;
    const { productDetailData, technicalData } = { ...this.state };
    let productProperties = Object.keys(productDetailData);
    technicalData.forEach(key => {
      if (productProperties.includes(key)) {
        count = count + 1;
      }
    });
    return count;
  };
  retailerInformationDataCount = () => {
    let count = 0;
    const { productDetailData, retailerInformationData } = { ...this.state };
    let productProperties = productDetailData && productDetailData?.dareportdetails &&  Object.keys(productDetailData?.dareportdetails);
    retailerInformationData.forEach(key => {
      if (productProperties && productProperties.includes(key)) {
        count = count + 1;
      }
    });
    return count;
  };
  dimensionalDataCount = () => {
    let count = 0;
    const { productDetailData, dimensionalData } = { ...this.state };
    let productProperties = this.normalValueShow(productDetailData,"unitdetails",this.props.countryLocale) && Object.keys(productDetailData?.unitdetails);
    dimensionalData.forEach(key => {
      if (productProperties && productProperties.includes(key)) {
        count = count + 1;
      }
    });
    return count;
  };
  productCharacteristicsTab = () => {
    const { productDetailData, productCharacteristics } = { ...this.state };
    const { t } = this.props;
    let productCharacteristicsTable = {},
      propStatus,
      productProperties = [],
      elem;
    productProperties = Object.keys(productDetailData);
    productCharacteristics.forEach(key => {
      if (productProperties.includes(key)) {
        propStatus = this.setProductCharactersitics(this.normalValueShow(productDetailData,key,this.props.countryLocale));
        productCharacteristicsTable[key] = propStatus;
      }
    });

    return (
      <table className="table table-bordered align-middle">
        <thead>
          <tr>
            <th>{t("Product Detail Page.characteristics")}</th>
            <th>{t("Product Detail Page.status")}</th>
          </tr>
        </thead>
        <tbody>
        
          {productCharacteristics.map(property => {
                    switch (productCharacteristicsTable[property]) {
                      case true:
                        elem = (
                          <tr>
                            <td>{t(`Product Detail Page.${property}`)}</td>
                            <td>
                              <i className="bi bi-check"></i>
                            </td>
                          </tr>
                        );
                        break;
                      case false:
                        elem = (
                          <tr>
                            <td>{t(`Product Detail Page.${property}`)}</td>
                            <td>
                              <i className="bi bi-x"></i>
                            </td>
                          </tr>
                        );
                        break;
                        case "Not Available":
                          elem = (
                            <tr>
                              <td >{t(`Product Detail Page.${property}`)}</td>
                              <td >{t("Product Detail Page.noInfo")}</td>
                            </tr>
                          );
                        break;
                        case " ":
                          elem = (
                            <tr>
                              <td >{t(`Product Detail Page.${property}`)}</td>
                              <td >{t("Product Detail Page.noInfo")}</td>
                            </tr>
                          );
                        break;
                      case productCharacteristicsTable[property]:
                        elem = (
                          <tr>
                            <td>{t(`Product Detail Page.${property}`)}</td>
                            <td>
                            {Object.keys(productCharacteristicsTable).includes(property) && productCharacteristicsTable[property] ? productCharacteristicsTable[property] : t("Product Detail Page.noInfo")}
                            </td>
                          </tr>
                        );
                      break;                
                      default:
                        elem = (
                          <tr>
                            <td >{t(`Product Detail Page.${property}`)}</td>
                            <td >{t("Product Detail Page.noInfo")}</td>
                          </tr>
                        );
                        break;
                    }

            return elem;
          })}
        </tbody>
      </table>
    );
  };
  dimensionalDataTab = () => {
    const { productDetailData, dimensionalData } = { ...this.state };
    const { t } = this.props;
    let dimensionalDataTable = {},
      propStatus,
      productProperties = [],
      elem;
      productProperties = Object.keys(this.normalValueShow(productDetailData,"unitdetails",this.props.countryLocale));

    dimensionalData.forEach(key => {
      if (productProperties.includes(key)) {
        propStatus = this.setDimensionalData(this.normalValueShow(productDetailData,"unitdetails",this.props.countryLocale)[key]);
        dimensionalDataTable[key] = propStatus;
      }
    });
    return (
      <table className="table table-bordered align-middle">
        <thead>
          <tr>
            <th>{t("Product Detail Page.dimension")}</th>
            <th>{t("Product Detail Page.value")}</th>
          </tr>
        </thead>
        <tbody>
          {dimensionalData.map(property => {
                    switch (dimensionalDataTable[property]) {
                      // case true:
                      //   elem = (
                      //     <tr>
                      //       <td>{t(`Product Detail Page.${property}`)}</td>
                      //       <td>
                      //         <i className="bi bi-check"></i>
                      //       </td>
                      //     </tr>
                      //   );
                      //   break;
                      // case false:
                      //   elem = (
                      //     <tr>
                      //       <td>{t(`Product Detail Page.${property}`)}</td>
                      //       <td>
                      //         <i className="bi bi-x"></i>
                      //       </td>
                      //     </tr>
                      //   );
                      //   break;
                        case "Not Available":
                          elem = (
                            <tr>
                              <td >{t(`Product Detail Page.${property}`)}</td>
                              <td >{t("Product Detail Page.noInfo")}</td>
                            </tr>
                          );
                        break;
                        case " ":
                          elem = (
                            <tr>
                              <td >{t(`Product Detail Page.${property}`)}</td>
                              <td >{t("Product Detail Page.noInfo")}</td>
                            </tr>
                          );
                        break;
                      case dimensionalDataTable[property]:
                        elem = (
                          <tr>
                            <td>{t(`Product Detail Page.${property}`)}</td>
                            <td>
                            {Object.keys(dimensionalDataTable).includes(property) && dimensionalDataTable[property] ? dimensionalDataTable[property] : t("Product Detail Page.noInfo")}
                            </td>
                          </tr>
                        );
                      break;                
                      default:
                        elem = (
                          <tr>
                            <td >{t(`Product Detail Page.${property}`)}</td>
                            <td >{t("Product Detail Page.noInfo")}</td>
                          </tr>
                        );
                        break;
                    }

            return elem;
          })}
        </tbody>
      </table>
    );
  };
  technicalDataTab = () => {
    const { productDetailData, technicalData } = { ...this.state };
    const { t } = this.props;
    let technicalDataTable = {},
      propStatus,
      productProperties = [],
      elem;
    productProperties = Object.keys(productDetailData);
    technicalData.forEach(key => {
      if (productProperties.includes(key)) {
        propStatus = this.setTechnicalData(this.normalValueShow(productDetailData,key,this.props.countryLocale));
        technicalDataTable[key] = propStatus;
      }
    });

    return (
      <table className="table table-bordered align-middle">
        <thead>
          <tr>
            <th style={{ width: "50%" }}>{t("Product Detail Page.attribute")}</th>
            <th style={{ width: "50%" }}>{t("Product Detail Page.value")}</th>
          </tr>
        </thead>
        <tbody>
        {technicalData.map(property => {
                    switch (technicalDataTable[property]) {
                      
                        case "Not Available":
                          elem = (
                            <tr>
                              <td >{t(`Product Detail Page.${property}`)}</td>
                              <td >{t("Product Detail Page.noInfo")}</td>
                            </tr>
                          );
                        break;
                        case " ":
                          elem = (
                            <tr>
                              <td >{t(`Product Detail Page.${property}`)}</td>
                              <td >{t("Product Detail Page.noInfo")}</td>
                            </tr>
                          );
                        break;
                      case technicalDataTable[property]:
                        elem = (
                          <tr>
                            <td>{property==="unitweight"? "PCE Gross weight" : t(`Product Detail Page.${property}`)}</td>
                            <td>
                            {Object.keys(technicalDataTable).includes(property) && technicalDataTable[property] ? technicalDataTable[property] : t("Product Detail Page.noInfo")}
                            </td>
                          </tr>
                        );
                      break;                
                      default:
                        elem = (
                          <tr>
                            <td >{t(`Product Detail Page.${property}`)}</td>
                            <td >{t("Product Detail Page.noInfo")}</td>
                          </tr>
                        );
                        break;
                    }

            return elem;
          })}
        </tbody>
      </table>
    );
  };
  retailerInformationTab = () => {
    const { productDetailData, retailerInformationData } = { ...this.state };
    const { t } = this.props;
    let retailerInformationDataTable = {},
      propStatus,
      productProperties = [],
      elem;
    productProperties = Object.keys(productDetailData?.dareportdetails);

    retailerInformationData.forEach(key => {
      if (productProperties.includes(key)) {
        propStatus = this.setRetailerInformationData(productDetailData?.dareportdetails[key]);
        retailerInformationDataTable[key] = propStatus;
      }
    });
    return (
      <table className="table table-bordered align-middle">
        <thead>
          <tr>
            <th style={{ width: "50%" }}>{t("Product Detail Page.attribute")}</th>
            <th style={{ width: "50%" }}>{t("Product Detail Page.value")}</th>
          </tr>
        </thead>
        <tbody>
  
        {retailerInformationData.map(property => {
         const isUrl = (string) => {
          try {
            new URL(string);
            return true;
          } catch (_) {
            return false;
          }
        };
                    switch (retailerInformationDataTable[property]) {
                        case "Not Available":
                          elem = (
                            <tr>
                              <td >{t(`Product Detail Page.${property}`)}</td>
                              <td >{t("Product Detail Page.noInfo")}</td>
                            </tr>
                          );
                        break;
                        case " ":
                          elem = (
                            <tr>
                              <td >{t(`Product Detail Page.${property}`)}</td>
                              <td >{t("Product Detail Page.noInfo")}</td>
                            </tr>
                          );
                        break;
                      case retailerInformationDataTable[property]:
                        elem = (
                          <tr>
                            <td>{t(`Product Detail Page.${property}`)}</td>
                            <td>
                            {Object.keys(retailerInformationDataTable).includes(property) && retailerInformationDataTable[property] ? (isUrl(retailerInformationDataTable[property])
                              ? <a href={retailerInformationDataTable[property]} target="_blank" rel="noopener noreferrer">{retailerInformationDataTable[property]}</a>
                                   : 
                                  retailerInformationDataTable[property]
                                  ): 
                                  t("Product Detail Page.noInfo")
                              }  
                            </td>
                          </tr>
                        );
                      break;                
                      default:
                        elem = (
                          <tr>
                            <td >{t(`Product Detail Page.${property}`)}</td>
                            <td >{t("Product Detail Page.noInfo")}</td>
                          </tr>
                        );
                        break;
                    }

            return elem;
          })}
        </tbody>
      </table>
    );
  };
  setProductCharactersitics = input => {
    let value;
    switch (input) {
      case "Ja":
        value = true;
        break;
       case "Yes":
      value = true;
      break;
      case "Oui":
        value = true;
        break;
      case "Y":
      value = true;
      break;
      case "Nein":
        value = false;
        break;
        case "Non":
        value = false;
        break;
      case "Nee":
      value = false;
      break;
      case "nee, in overleg met uw arts":
      value = false;
      break;
      case "N":
      value = false;
      break;
      case "No":
      value = false;
      break;
      case "N/A":
      value = false;
      break;
      case "N/a":
        value = false;
        break;
      case "n/a":
        value = false;
        break;
      case input: 
        value = input;
      break;
      case "": 
        value = " ";
      break;
      default:
        value = "Not Available";
      break;
    }
    return value;
  };
  setDimensionalData = input => {
    let value;
    switch (input) {
      // case "Ja":
      //   value = true;
      //   break;
      // case "Nein":
      //   value = false;
      //   break;
      case input: 
        value = input;
      break;
      case "": 
        value = " ";
      break;
      default:
        value = "Not Available";
      break;
    }
    return value;
  };
  setTechnicalData = input => {
    let value;
    switch (input) {
      // case "Ja":
      //   value = true;
      //   break;
      // case "Nein":
      //   value = false;
      //   break;
      case input: 
        value = input;
      break;
      case "": 
        value = " ";
      break;
      default:
        value = "Not Available";
      break;
    }
    return value;
  };
  setRetailerInformationData = item => {
    let value;
    // input && Object.entries(input).map((item)=>{
      switch (item) {
        // case "Ja":
        //   value = true;
        //   break;
        // case "Nein":
        //   value = false;
        //   break;
        case item: 
          value = item;
        break;
        case "": 
          value = " ";
        break;
        default:
          value = "Not Available";
        break;
      }
      return value;
    // })
   
  };
  getUsage = () => {
    const { t } = this.props;
    const countryCode = getCountryCode();
    let propertyArray=[]
    if(countryCode==="de"){
    propertyArray = [
        {
          headline: "Product Detail Page.treatment",
          content: this.normalValueShow(this.state.productDetailData,"treatment",this.props.countryLocale)
        },
        // {
        //   headline: "Product Detail Page.dosage",
        //   content: this.state.productDetailData.dosage
        // },
        {
          headline: "Product Detail Page.ingestion",
          content: this.normalValueShow(this.state.productDetailData,"ingestion",this.props.countryLocale)
        },
        {
          headline: "Product Detail Page.activeingredients",
          content: this.normalValueShow(this.state.productDetailData,"activeingredients",this.props.countryLocale)
        },
        {
          headline: "Product Detail Page.ageappro",
          content: this.normalValueShow(this.state.productDetailData,"ageappro",this.props.countryLocale)
        },
        {
          headline: "Product Detail Page.durabilityopening",
          content: this.normalValueShow(this.state.productDetailData,"durabilityopening",this.props.countryLocale)
        },
        {
          headline: "Product Detail Page.lightsens",
          content: this.normalValueShow(this.state.productDetailData,"lightsens",this.props.countryLocale)
        },
        {
          headline: "Product Detail Page.usedbreastfeed",
          content: this.normalValueShow(this.state.productDetailData,"usedbreastfeed",this.props.countryLocale)
        },
        {
          headline: "Product Detail Page.usedpregnancy",
          content: this.normalValueShow(this.state.productDetailData,"usedpregnancy",this.props.countryLocale)
        },
        {
          headline: "Product Detail Page.fordiabetics",
          content: this.normalValueShow(this.state.productDetailData,"fordiabetics",this.props.countryLocale)
        },
        {
          headline: "Product Detail Page.tolerance",
          content:this.normalValueShow(this.state.productDetailData,"tolerance",this.props.countryLocale)
        },
        {
          headline: "Product Detail Page.containindications",
          content: this.normalValueShow(this.state.productDetailData,"containindications",this.props.countryLocale)
        },
        {
          headline: "Product Detail Page.mandatoryinfo",
          content: this.normalValueShow(this.state.productDetailData,"mandatoryinfo",this.props.countryLocale)
        }
      ]; 
    }
    else{
      propertyArray = [
        {
          headline: "Product Detail Page.packageinsertlink",
          content:  this.normalValueShow(this.state.productDetailData,"packageinsertlink",this.props.countryLocale)
        },
        {
          headline: "Product Detail Page.ageappro",
          content: this.normalValueShow(this.state.productDetailData,"ageappro",this.props.countryLocale)
        },
        {
          headline: "Product Detail Page.dosage",
          content:  this.normalValueShow(this.state.productDetailData,"dosage",this.props.countryLocale)
        },
        {
          headline: "Product Detail Page.maxdosage",
          content:  this.normalValueShow(this.state.productDetailData,"maxdosage",this.props.countryLocale)
        },
        {
          headline: "Product Detail Page.indication",
          content:  this.normalValueShow(this.state.productDetailData,"indication",this.props.countryLocale)
        },
        {
          headline: "Product Detail Page.containindications",
          content:  this.normalValueShow(this.state.productDetailData,"containindications",this.props.countryLocale)
        },
        {
          headline: "Product Detail Page.Ingredients",
          content:  this.normalValueShow(this.state.productDetailData,"Ingredients",this.props.countryLocale)
        },
        {
          headline: "Product Detail Page.activeingredients",
          content: this.normalValueShow(this.state.productDetailData,"activeingredients",this.props.countryLocale)
        },
        {
          headline: "Product Detail Page.placestorage",
          content:  this.normalValueShow(this.state.productDetailData,"placestorage",this.props.countryLocale)
        },
        {
          headline: "Product Detail Page.storagetemprange",
          content:  this.normalValueShow(this.state.productDetailData,"storagetemprange",this.props.countryLocale)
        },
        {
          headline: "Product Detail Page.instructuse",
          content:  this.normalValueShow(this.state.productDetailData,"instructuse",this.props.countryLocale)
        },
      ];
    }
    
    
    return propertyArray.map(usage => {
      const isUrl = (string) => {
        return string.startsWith("https://");
      }
      dateTogglerProductUsage = this.lastUpdateComp(usage.headline.split('.').pop())
      const contentWrapping = usage?.content?.split('\n').map((line, index) => {
        const trimmedLine = line.trim();
        const isLink = isUrl(trimmedLine);
        return (
          <div key={index} className="line">
            {isLink ? (
              <a href={trimmedLine} target="_blank" rel="noopener noreferrer">
                {trimmedLine}
              </a>
            ) : (
              trimmedLine.replace(/\t/g, '\u00A0\u00A0\u00A0\u00A0')
            )}
          </div>
        );
      });

      return (
        <div className="product-usage-lastupdateddate">
          {usage.content && usage.content !== "N/A" && getCountryCode() === "nl" && (
          
            <div className="usage-wrapper">
              <span className="usage-headline">{t(usage.headline)}</span>
              <div className="usage-content">{contentWrapping}</div>
            </div>
          )}
          {usage.content && usage.content !== "N/A" && getCountryCode() !== "nl" && (
          
          <div className="usage-wrapper">
            <span className="usage-headline">{t(usage.headline)}</span>
            <div className="usage-content">{contentWrapping}</div>
          </div>
        )}
          {((!usage.content || usage.content === "N/A") && getCountryCode() !== "nl") && (
            <div className="usage-wrapper">
              <span className="usage-headline">{t(usage.headline)}</span>
              <div className="usage-content">
                {t("Product Detail Page.noInfo")}
              </div>
            </div>
          )}

          {((!usage.content || usage.content === "N/A") && getCountryCode() === "nl") && (
            <div className="usage-wrapper">
              <span className="usage-headline" style={{ display: "none" }}>{t(usage.headline)}</span>
              <div className="usage-content" style={{ display: "none" }}>
                {t("Product Detail Page.noInfo")}

              </div>
            </div>
          )}       
    
      {this.state.isOpen_productUsage  && this.lastUpdateComp(usage.headline.split('.').pop())}
        </div>
      );
    });
  };

  detectURLs = message => {
    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return message.match(urlRegex);
  };

  getCarousel = videos => {
    const videoArray = this.detectURLs(videos);
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <React.Fragment>
        {/* <span class="video-headline">Videos</span> */}
        <Slider {...settings}>
          {videoArray?.map(videoLink => (
            <ReactPlayer url={videoLink} controls key={videoLink} />
          ))}
        </Slider>
      </React.Fragment>
    );
  };

  setShowMore = () => {
    let showMore = this.state.showMore;
    showMore = showMore === false ? true : false;
    this.setState({ showMore });
  };

  getShareDropDowns = () => {
    const { t } = this.props;
    return (
      <div className="sugg-wrapper" ref={this.dropdownRef}>
        <ul className="suggestions">
          <li onClick={e => this.copyLinkOption(e)}>
            {t(this.state.copyLinkText)}
          </li>
          <li onClick={e => this.copyLinkEmail(e)}>
            <a
              href={
                "mailto:?subject=" +
                this.state.productTitle +
                " | Bayer Download Center&body=" +
                encodeURIComponent(window.location.href)
              }
            >
              {t(this.state.emailLinkText)}
            </a>
          </li>
        </ul>
      </div>
    );
  };

  handleClickOutside = event => {
    if (event.target.getAttribute("class") !== "bi bi-share") {
      if (
        this.dropdownRef.current &&
        !this.dropdownRef.current.contains(event.target)
      ) {
        let showDropdown = this.state.showShareOptions;
        this.setState({ showShareOptions: !showDropdown });
      }
    }
  };

  clickOnTab = activeKey => {
    window.dataLayer.push({
      event: "gaGenericEvent",
      eventCategory: "Tab",
      eventAction: `Click - ${activeKey}`,
      eventLabel: "product tab",
      environment: ga_env
    });
  };

  provideLoader = () => {
    return (
      <div className="spinner d-flex align-items-center" style={divStyle}>
        <DotLoader color={"#0091df"} css={override} size={65} margin={2} />
      </div>
    );
  };
  wrongLoader =()=>{
      return (
       <Redirect to="/home" />
      )
  }
  productRelatedAssets = num => {
    let upc = this.normalValueShow(this.state.productDetailData,"unitupc",this.props.countryLocale);
    let assets = this.props.assetsList.filter(asset => asset.upc === upc);
    return assets.map((asset, index) => (
      <div className={`col-${num}`}>
        <CardComponent item={asset} index={index} />
      </div>
    ));
  };

  productRelatedAssetsNew = num => {
    let upc = this.normalValueShow(this.state.productDetailData,"unitupc",this.props.countryLocale);
    let assets = this.props.assetsList.filter(asset => asset?.upc?.split(";").map(item => item.trim()).includes(upc));
    
  //assets.sort((a)=>  a.asset_location.endsWith("er.zip") ? -1 : a.asset_location.endsWith("print.zip")? -1 : 0  )
  assets.sort((a,b)=>  a.asset_location.toLowerCase().split("_").reverse()[0] < b.asset_location.toLowerCase().split("_").reverse()[0] ? -1 : 
   a.asset_location.toLowerCase().split("_").reverse()[0] < b.asset_location.toLowerCase().split("_").reverse()[0]? 1 :0);
   if (this.normalValueShow(this.state.productDetailData,"packageinsertlink",this.props.countryLocale)) {
    var packageInsert = {
      id: "packageInsertLink",
      type: "packageInsertLink",
      asset_location:"packageInsertLink",
      filePath: this.normalValueShow(this.state.productDetailData,"packageinsertlink",this.props.countryLocale) ,
     brand: this.normalValueShow(this.state.productDetailData,"brand",this.props.countryLocale),
     fileType : this.normalValueShow(this.state.productDetailData,"packageinsertlink",this.props.countryLocale)?.substring(this.normalValueShow(this.state.productDetailData,"packageinsertlink",this.props.countryLocale).lastIndexOf(".") + 1),
     fileName : this.normalValueShow(this.state.productDetailData,"packageinsertlink",this.props.countryLocale)?.substring(this.normalValueShow(this.state.productDetailData,"packageinsertlink",this.props.countryLocale).lastIndexOf("/") + 1),
     upc:"",
     
    }
    assets.push(packageInsert);
   }
  assets.push(productInfoButton)

    return assets.map((asset, index) => (
      
      <div >
        <CardComponentNew item={asset} index={index} downloadAllRef={this.downloadAllRef} sendingProps={this.sendingProps} downloadAllCheckBox={this.state.downloadAllCheckBox } triggerFlag={this.state.triggerFlag} />
      </div>
    ));
  };


  disclaimerCheckbox = () => {
    const { t } = this.props;
    if (
      this.state.showDisclaimerEcom ||
      this.state.showDisclaimerPrintPackage
    ) {
      return (
        <div className="disclaimer-text-container">
          <div className="disclaimer-checkbox">
            <input
              type="checkbox"
              ref={this.disclaimerCheckboxRef}
              onClick={() => {
                this.state.showDisclaimerEcom &&
                  this.setState({
                    enableDownloadEcom: !this.state.enableDownloadEcom
                  });
                this.state.showDisclaimerPrintPackage &&
                  this.setState({
                    enableDownloadPrintPackage: !this.state
                      .enableDownloadPrintPackage
                  });
              }}
            ></input>
          </div>
          <div className="disclaimer-text" onClick={() => {
            this.disclaimerCheckboxRef.current.click()
          }}>
            {t("Product Detail Page.license-expiry-text")}{" "}
            {this.state.license_expiry_date_ecom != null &&
              `${t("Product Detail Page.license-expiry-text-ecom")} ${
                this.state.license_expiry_date_ecom
              }`}
            {this.state.license_expiry_date_print != null &&
              `${t("Product Detail Page.license-expiry-text-print")} ${
                this.state.license_expiry_date_print
              }`}
          </div>
        </div>
      );
    }
  };
  sendingProps = async(sendingProps) => {
    const includePackageInsert = this.normalValueShow(this.state.productDetailData,"packageinsertlink",this.props.countryLocale)
    
    downloadArrayLength= sendingProps 
    let checkingLength= includePackageInsert? this.props.assetsList.filter(asset => asset.upc === this.normalValueShow(this.state.productDetailData,"unitupc",this.props.countryLocale)).length + 2 : this.props.assetsList.filter(asset => asset.upc === this.normalValueShow(this.state.productDetailData,"unitupc",this.props.countryLocale)).length + 1
    let checkLengthVariable= (checkingLength > 0 && sendingProps > 0) && checkingLength === sendingProps
    await this.setState({downloadMainCheckbox:(checkLengthVariable)? (previousUpc === this.normalValueShow(this.state.productDetailData,"unitupc",this.props.countryLocale) )?true : false : false })  

    await this.setState({downloadAllCheckBox: this.state.downloadMainCheckbox? this.state.downloadMainCheckbox: this.state.downloadAllCheckBox})
     previousUpc = this.normalValueShow(this.state.productDetailData,"unitupc",this.props.countryLocale)
  }

  onCheckboxChange= async(e)=>{
    await this.setState({downloadAllCheckBox:e.target.checked})
    await  this.setState({downloadMainCheckbox: this.state.downloadMainCheckbox})
    this.setState({triggerFlag: !this.state.triggerFlag})
  }

   grayOut =(evt, item, hide)=>{
    evt?.preventDefault();
    evt?.stopPropagation();
    this.setState({grayColorOut : true})
    this.hideShowFunction(item, hide); 

  }

   closeGrayOut =(evt, item, show)=>{
    evt?.preventDefault();
    evt?.stopPropagation();
    this.setState({grayColorOut : false})
    this.hideShowFunction(item, show); 
  }

  handleToggle = (chekingVariable) => {
    
    if(chekingVariable === "productDescription"){
      const updatedResult = !this.state.isOpen_productDescription
      this.setState({isOpen_productDescription : updatedResult})
      this.updateSwitcherStatusLocally(this.state.productDescriptionTabText, updatedResult)
    }
    if(chekingVariable === "productUsage"){
      const updatedResult = !this.state.isOpen_productUsage
      this.setState({isOpen_productUsage : updatedResult})
      this.updateSwitcherStatusLocally(this.state.productUsageTabText, updatedResult)
    }
 
  };
 showdown =()=>{
  return(
    <div className="quickCartProduct">
                <div className='downloadmodalheader'>Add to Cart</div>
                <div className='download-options'>
                  {this.quickCartOptions(this.state.productDetailData)}
                </div>
              </div>
  )
 }

  render() {
    const { t } = this.props;
    const open = Boolean(this.state?.anchorEl);
    return (
      <React.Fragment>
        {this.state.wrongId && this.wrongLoader()}
        {!this.state.hasResponse && this.provideLoader()}      
        {this.state.hasResponse && (
          <div className="productDetailPage">
            <div className="container">
              <div className="product-detail-content">
                <div className="top-section">
                  <div className="product-image-container">
                    <MediaQuery maxWidth={480}>
                      <div className="wrapper-container">
                        <span className="product-title">
                          {this.state.productTitle}
                        </span>
                        <div className="icon-wrapper">
                        {this.productCartModal(this.state.productDetailData)}
                          {this.favouriteButton(this.state.productDetailData)}
                          <div className="copy-link-button">
                            {this.getCopyLinkButton()}
                          </div>
                          {(this.state.gigyaRole === "Global Admin" || this.state.gigyaRole === "Country Admin") ? (
                          <div>
                              <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-haspopup="true"
                                onClose={this.handleClose}
                                onClick={this.handleClick}
                                className="three-dots"
                              >
                                <MoreVert />
                              </IconButton>
                              <Menu
                                id="long-menu"
                                MenuListProps={{
                                'aria-labelledby': 'long-button',
                                }}
                                anchorEl={this.state.anchorEl}
                                open={open}
                                onClose={this.handleClose}
                                PaperProps={{
                                  style: {
                                    maxHeight: 150,
                                    width: '96px',
                                  },
                                }}
                              >
                                <MenuItem onClick={(evt)=>this.grayOut(evt, this.state.productDetailData, "hide")}   onClose={this.handleClose} style = {{padding: "0px 14px"}}> 
                                  {t("Miscellaneous.hide")}
                                </MenuItem>

                                <MenuItem onClick={(evt)=>this.closeGrayOut(evt, this.state.productDetailData, "show")}  onClose={this.handleClose} style = {{padding: "6px 14px 0px"}}>   
                                   {t("Miscellaneous.show")}  
                                </MenuItem>
                              </Menu>
                          </div>)
                          :""}
                            {this.state.cartModal && this.showdown()}
                        </div>                       
                            {this.state.showShareOptions && this.getShareDropDowns()}
                      </div>
                    </MediaQuery>
                    <div className="carousel-container">
                      {/* <CarouselComponent item={this.state.productDetailData} /> */}
                      {this.productImageRender(this.state.productDetailData)}
                    </div>
                    {this.disclaimerCheckbox()}
                  </div>
                  <div className="product-detail-container">
                    <MediaQuery minWidth={481}>
                      <div className="wrapper-container">
                        <div className="icon-wrapper"> 
                        {this.productCartModal(this.state.productDetailData)}                      
                          {this.favouriteButton(this.state.productDetailData)}
                          <div className="copy-link-button">
                            {this.getCopyLinkButton()}
                          </div>
                           {(this.state.gigyaRole === "Global Admin" || this.state.gigyaRole === "Country Admin") ? (
                          <div>
                              <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-haspopup="true"
                                onClose={this.handleClose}
                                onClick={this.handleClick}
                                className="three-dots"
                              >
                                <MoreVert />
                              </IconButton>
                              <Menu
                                id="long-menu"
                                MenuListProps={{
                                'aria-labelledby': 'long-button',
                                }}
                                anchorEl={this.state.anchorEl}
                                open={open}
                                onClose={this.handleClose}
                                PaperProps={{
                                  style: {
                                    maxHeight: 150,
                                    width: '96px',
                                  },
                                }}
                              >
                                <MenuItem onClick={(evt)=>this.grayOut(evt, this.state.productDetailData, "hide")}   onClose={this.handleClose} style = {{padding: "0px 14px"}}> 
                                {t("Miscellaneous.hide")}
                                </MenuItem>

                                <MenuItem onClick={(evt)=>this.closeGrayOut(evt, this.state.productDetailData, "show")}  onClose={this.handleClose} style = {{padding: "6px 14px 0px"}}>   
                               {t("Miscellaneous.show")}  
                                </MenuItem>
                              </Menu>
                          </div>)
                          :""}
                        </div>
                        {this.state.cartModal && this.showdown()}
                        {this.state.showShareOptions &&
                          this.getShareDropDowns()}
                         
                      </div>
                      <span className="product-title">
                        {this.state.productTitle}
                      </span>
                    </MediaQuery>
                    <div className="brandName">
                      <span className="brand-label">{t("Product Detail Page.brand")}:</span>
                      <span className="brand-name">
                        <Link
                          to={`/search-page/?${qs.stringify({
                            brand: [this.state.productBrand]
                          })}`}
                        >
                          <span>{this.state.productBrand}</span>
                        </Link>
                      </span>
                    </div>
                    {/* {this.countrySettings.countryCode === "NL" ? ( */}
                      <div>
                        {this.normalValueShow(this.state.productDetailData,"ean",this.props.countryLocale) && this.normalValueShow(this.state.productDetailData,"ean",this.props.countryLocale) !=="Not Available"?
                      <div className="ean-code" style={{    margin: "10px 0px 10px 0px"}}>
                        <span className="ean-label">{t("Product Detail Page.ean-number")}:</span>
                        <span className="ean-number">
                          {this.normalValueShow(this.state.productDetailData,"ean",this.props.countryLocale)}
                        </span>
                      </div> : ""}
                      {this.normalValueShow(this.state.productDetailData,"koagnum",this.props.countryLocale) && this.normalValueShow(this.state.productDetailData,"koagnum",this.props.countryLocale) !=="Not Available"  ? 
                        <div className="koagnum-code" style={{    margin: "10px 0px 10px 0px"}}>
                        <span className="koagnum-label">{t("Product Detail Page.koag")}:</span>
                        <span className="koagnum-number">
                          {this.normalValueShow(this.state.productDetailData,"koagnum",this.props.countryLocale)}
                        </span>
                        </div> : ""
                      }
                       {this.normalValueShow(this.state.productDetailData,"nutnumber",this.props.countryLocale) && this.normalValueShow(this.state.productDetailData,"nutnumber",this.props.countryLocale) !=="Not Available"?
                      <div className="nut-code" style={{    margin: "10px 0px 10px 0px"}}>
                        <span className="nut-label">{t("Product Detail Page.CNK")}:</span>
                        <span className="nut-number">
                          {this.normalValueShow(this.state.productDetailData,"nutnumber",this.props.countryLocale)}
                        </span>
                      </div> : ""}
                      {this.normalValueShow(this.state.productDetailData,"cnkcode",this.props.countryLocale) && this.normalValueShow(this.state.productDetailData,"cnkcode",this.props.countryLocale) !=="Not Available"?
                      <div className="cnk-code" style={{    margin: "10px 0px 10px 0px"}}>
                        <span className="cnk-label">{t("Product Detail Page.CNK")}:</span>
                        <span className="cnk-number">
                          {this.normalValueShow(this.state.productDetailData,"cnkcode",this.props.countryLocale)}
                        </span>
                      </div> : ""}
                      </div>
                      
                    {/* ) : ( */}

                    {/* )} */}
                    { this.normalValueShow(this.state.productDetailData,"pharmacentralnum",this.props.countryLocale) &&  this.normalValueShow(this.state.productDetailData,"pharmacentralnum",this.props.countryLocale) !=="Not Available"  ?
                      <div className="pzn-code" style={{    margin: "10px 0px 10px 0px"}}>
                        <span className="pzn-label">{t("Product Detail Page.pzn")}:</span>
                        <span className="pzn-number">
                          { this.normalValueShow(this.state.productDetailData,"pharmacentralnum",this.props.countryLocale)}
                        </span>
                      </div>
                      : ""}
                    {(getCountryCode() === "nl"  || getCountryCode() === "be"  )? 
                    (
                      <div>
                          { this.normalValueShow(this.state.productDetailData,"pcat",this.props.countryLocale) &&  this.normalValueShow(this.state.productDetailData,"pcat",this.props.countryLocale) !=="Not Available"  ?
                      <div className="pzn-code" style={{    margin: "10px 0px 10px 0px"}}>
                        <span className="pzn-label">{t("Product Detail Page.pcat")}:</span>
                        <span className="pzn-number">
                          { this.normalValueShow(this.state.productDetailData,"pcat",this.props.countryLocale)}
                        </span>
                      </div>
                      : ""}
                      {this.normalValueShow(this.state.productDetailData,"pclass",this.props.countryLocale) && this.normalValueShow(this.state.productDetailData,"pclass",this.props.countryLocale) !=="Not Available"?
                      <div className="pzn-code">
                        <span className="pzn-label">{t("Product Detail Page.pclass")}:</span>
                        <span className="pzn-numbe">
                          {this.normalValueShow(this.state.productDetailData,"pclass",this.props.countryLocale).charAt(0).toUpperCase() + this.normalValueShow(this.state.productDetailData,"pclass",this.props.countryLocale).slice(1)}
                        </span>
                      </div> : ""}
                      </div>
                    ):(
                    <div>
                       {this.normalValueShow(this.state.productDetailData,"pclass",this.props.countryLocale) && this.normalValueShow(this.state.productDetailData,"pclass",this.props.countryLocale) !=="Not Available"?
                      <div className="pClassName">
                        <span className="pClass-label">{t("Product Detail Page.pclass")}:</span>
                        <span className="pClass-name">
                          {this.normalValueShow(this.state.productDetailData,"pclass",this.props.countryLocale).charAt(0).toUpperCase() + this.normalValueShow(this.state.productDetailData,"pclass",this.props.countryLocale).slice(1)}
                        </span>
                      </div> : ""}
                    <div className="ecom-bullet-desc">
                      <ul>
                        {this.ecomBulletPoint(this.state.productDetailData)}
                      </ul>
                    </div>
                    <div className="button-hover-message">
                      {this.state.hover_ecom_button && (
                        <div className="message">
                          <i class="bi bi-info-circle"></i>
                          <span>
                            {t("Product Detail Page.hover_ecom_button_message")}
                          </span>
                        </div>
                      )}
                      {this.state.hover_printpack_button && (
                        <div className="message">
                          <i class="bi bi-info-circle"></i>
                          <span>
                            {t("Product Detail Page.hover_printpack_button_message")}
                          </span>
                        </div>
                      )}
                      {this.state.hover_productexcel_button && (
                        <div className="message">
                          <i class="bi bi-info-circle"></i>
                          <span>
                            {t(
                              "Product Detail Page.hover_productexcel_button_message"
                            )}
                          </span>
                        </div>
                      )}
                    </div>
                    </div>)
                      }
                    {/* <div className="button-wrapper">
                      {this.getEComPackageInfo() &&
                        this.downloadButton(this.state.productDetailData)}
                      {this.getPrintPackageInfo() &&
                        this.printDownloadButton(this.state.productDetailData)}
                      <div
                        className="product-info-button"
                        onMouseEnter={() => {
                          this.setState({ hover_productexcel_button: true });
                        }}
                        onMouseLeave={() => {
                          this.setState({ hover_productexcel_button: false });
                        }}
                      >
                        {
                          <ExportToExcel
                            apiData={this.state.excelData}
                            fileName={this.state.fileName}
                            brand={this.state.productBrand}
                          />
                        }
                      </div>
                    </div> */}
                  </div>
                </div>
                <MediaQuery minWidth={768}>
                  {(getCountryCode() === "nl"  || getCountryCode() === "be"  )? 
                  <div className="product-tab">
                  <Tabs
                    defaultActiveKey={getCountryCode() === "nl" ? "product-description" : this.activeKey}
                    className="mb-4"
                    onSelect={activeKey => this.clickOnTab(activeKey)}
                  >
                     <Tab
                      eventKey="product-description"
                      title={t("Product Detail Page.description")}
                    >
                      <div className="product-description">
                        {this.updateSwitcherProdDescription(t)}
                        {this.state.branddesc !== "Nicht verfügbar" && this.state.branddesc !== "Not Available" && (
                        <div className="product-ecom-description branddesc">
                          <div className="product-desc-heading">
                            <span className="prod-desc-label"
                            style={{display :(this.state.branddesc==="Nicht verfügbar"||this.state.branddesc==="Not Available")?"none":""}}>
                              {t("Product Detail Page.branddesc")}:
                            </span>
                            <button
                              className="copy-button ecom-desc"
                              onClick={e => this.copyOnClick(e)}
                              style={{display : (this.state.branddesc==="Nicht verfügbar"||this.state.branddesc==="Not Available")?"none":""}}
                            >
                              <i className="bi bi-clipboard" style={{display :(this.state.branddesc==="Nicht verfügbar"||this.state.branddesc==="Not Available")?"none":""}}></i>
                            </button>
                          </div>
                          <span className="prod-desc-text" style={{display : (this.state.branddesc==="Nicht verfügbar"||this.state.branddesc==="Not Available")?"none":""}}>
                            {this.state.showMore
                              ? this.state.branddesc
                              : this.state.branddesc.substring(
                                0,
                                250
                              )}
                            {this.state.branddesc.length > 250 ?  
                              <button
                                className=" show-more-btn"
                                onClick={this.setShowMore}
                              >
                                {this.state.showMore
                                  ? `${t("Product Detail Page.showLess")}`
                                  : `...${t("Product Detail Page.showMore")}`}
                              </button>  : ""}
                          </span>

                          {this.state.branddesc  && this.lastUpdateComp("branddesc")}
                        </div>
                        )}
                        {this.state.ptitel !== "Nicht verfügbar" && this.state.ptitel !== "Not Available" && (
                        <div className="product-usp-header ptitel">
                            <div className="product-usp-header-heading">
                                <span className="heading" style={{display : (this.state.ptitel==="Nicht verfügbar"||this.state.ptitel==="Not Available")?"none":""}}>
                                {t("Product Detail Page.ptitel")}
                                {":"}
                                </span>                         
                            <button
                                className="copy-button ptitel"
                                onClick={e => this.copyOnClick(e)}
                                style={{display : (this.state.ptitel==="Nicht verfügbar"||this.state.ptitel==="Not Available")?"none":""}}
                            >
                                <i className="bi bi-clipboard"
                                style={{display : (this.state.ptitel==="Nicht verfügbar"||this.state.ptitel==="Not Available")?"none":""}}></i>
                            </button>
                            </div>
                            <span className="uspecomheader"  style={{display :(this.state.ptitel==="Nicht verfügbar"||this.state.ptitel==="Not Available")?"none":"inline"}}>
                            {this.state.ptitel}
                            </span>
                            
                            {this.state.isOpen_productDescription && this.lastUpdateComp("ptitel")}
                        </div>
                        )}
                        {this.state.subtitel !== "Nicht verfügbar" && this.state.subtitel !== "Not Available" && (
                        <div className="product-usp-header subtitel">
                            <div className="product-usp-header-heading">
                                <span className="heading" style={{display : (this.state.subtitel==="Nicht verfügbar"||this.state.subtitel==="Not Available")?"none":""}}>
                                {t("Product Detail Page.subtitel")}
                                {":"}
                                </span>                         
                            <button
                                className="copy-button subtitel"
                                onClick={e => this.copyOnClick(e)}
                                style={{display : (this.state.subtitel==="Nicht verfügbar"||this.state.subtitel==="Not Available")?"none":""}}
                            >
                                <i className="bi bi-clipboard"
                                style={{display : (this.state.subtitel==="Nicht verfügbar"||this.state.subtitel==="Not Available")?"none":""}}></i>
                            </button>
                            </div>
                            <span className="uspecomheader"  style={{display :(this.state.subtitel==="Nicht verfügbar"||this.state.subtitel==="Not Available")?"none":"inline"}}>
                            {this.state.subtitel}
                            </span>
                            
                            {this.state.isOpen_productDescription && this.lastUpdateComp("subtitel")}
                        </div>
                        )}
                         {this.state.productUspEcomStory !== "Nicht verfügbar" && this.state.productUspEcomStory !== "Not Available" && (
                        <div className="product-usp-story">
                          <div className="product-usp-heading">
                            {getCountryCode() === "nl" ? (
                              <span className="heading" style={{display : (this.state.productUspEcomStory==="Nicht verfügbar"||this.state.productUspEcomStory==="Not Available")?"none":""}}>
                                {t("Product Detail Page.shortproddesc")}
                                {":"}
                              </span>
                            ) : (
                              <span className="heading">
                                {t("Product Detail Page.uspecomstory")}
                                {":"}
                              </span>
                            )}
                            <button
                              className="copy-button usp-story"
                              onClick={e => this.copyOnClick(e)}
                              style={{display : (getCountryCode() === "nl")&&(this.state.productUspEcomStory==="Nicht verfügbar"||this.state.productUspEcomStory==="Not Available")?"none":""}}
                            >
                              <i className="bi bi-clipboard"
                               style={{display : (getCountryCode() === "nl")&&(this.state.productUspEcomStory==="Nicht verfügbar"||this.state.productUspEcomStory==="Not Available")?"none":""}}></i>
                            </button>
                          </div>
                          <span className="uspecomstory"  style={{display : (getCountryCode() === "nl")&&(this.state.productUspEcomStory==="Nicht verfügbar"||this.state.productUspEcomStory==="Not Available")?"none":"inline"}}>
                            {this.state.productUspEcomStory}
                          </span>
                         
                          {this.state.isOpen_productDescription && this.lastUpdateComp("uspecomstory")}
                        </div>
                         )}
                        {this.state.productdescriptionlang !== "Nicht verfügbar" && this.state.productdescriptionlang !== "Not Available" && (
                          <div className="product-usp-header productdescriptionlang">
                            <div className="product-usp-header-heading">
                                <span className="heading" style={{display : (this.state.productdescriptionlang==="Nicht verfügbar"||this.state.productdescriptionlang==="Not Available")?"none":""}}>
                                {t("Product Detail Page.productdescriptionlang")}
                                {":"}
                                </span>                         
                            <button
                                className="copy-button productdescriptionlang"
                                onClick={e => this.copyOnClick(e)}
                                style={{display : (this.state.productdescriptionlang==="Nicht verfügbar"||this.state.subproductdescriptionlangtitel==="Not Available")?"none":""}}
                            >
                                <i className="bi bi-clipboard"
                                style={{display : (this.state.productdescriptionlang==="Nicht verfügbar"||this.state.productdescriptionlang==="Not Available")?"none":""}}></i>
                            </button>
                            </div>
                            <span className="uspecomheader"  style={{display :(this.state.productdescriptionlang==="Nicht verfügbar"||this.state.productdescriptionlang==="Not Available")?"none":"inline"}}>
                            {this.state.productdescriptionlang}
                            </span>
                            
                            {this.state.isOpen_productDescription && this.lastUpdateComp("productdescriptionlang")}
                        </div>
                        )}
                        {this.state.ecomone !== "Nicht verfügbar" && this.state.ecomone !== "Not Available" && (
                        <div className="product-usp-header ecomone">
                        <div className="product-usp-header-heading">
                            <span className="heading" style={{display : (this.state.ecomone==="Nicht verfügbar"||this.state.ecomone==="Not Available")?"none":""}}>
                            {t("Product Detail Page.ecomone")}
                            {":"}
                            </span>                         
                        <button
                            className="copy-button productdescriptionlang"
                            onClick={e => this.copyOnClick(e)}
                            style={{display : (this.state.ecomone==="Nicht verfügbar"||this.state.ecomone==="Not Available")?"none":""}}
                        >
                            <i className="bi bi-clipboard"
                            style={{display : (this.state.ecomone==="Nicht verfügbar"||this.state.ecomone==="Not Available")?"none":""}}></i>
                        </button>
                        </div>
                        <span className="uspecomheader"  style={{display :(this.state.ecomone==="Nicht verfügbar"||this.state.ecomone==="Not Available")?"none":"inline"}}>
                        {this.state.ecomone}
                        </span>
                        
                        {this.state.ecomone && this.lastUpdateComp("ecomone")}
                        </div>
                        )}
                        {this.state.ecomtwo !== "Nicht verfügbar" && this.state.ecomtwo !== "Not Available" && (
                        <div className="product-usp-header ecomtwo">
                            <div className="product-usp-header-heading">
                                <span className="heading" style={{display : (this.state.ecomtwo==="Nicht verfügbar"||this.state.ecomtwo==="Not Available")?"none":""}}>
                                {t("Product Detail Page.ecomtwo")}
                                {":"}
                                </span>                         
                            <button
                                className="copy-button productdescriptionlang"
                                onClick={e => this.copyOnClick(e)}
                                style={{display : (this.state.ecomtwo==="Nicht verfügbar"||this.state.ecomtwo==="Not Available")?"none":""}}
                            >
                                <i className="bi bi-clipboard"
                                style={{display : (this.state.ecomtwo==="Nicht verfügbar"||this.state.ecomtwo==="Not Available")?"none":""}}></i>
                            </button>
                            </div>
                            <span className="uspecomheader"  style={{display :(this.state.ecomtwo==="Nicht verfügbar"||this.state.ecomtwo==="Not Available")?"none":"inline"}}>
                            {this.state.ecomtwo}
                            </span>
                            
                            {this.state.ecomtwo && this.lastUpdateComp("ecomtwo")}
                        </div>
                        )}
                        {this.state.ecomthree !== "Nicht verfügbar" && this.state.ecomthree !== "Not Available" && (
                        <div className="product-usp-header ecomthree">
                            <div className="product-usp-header-heading">
                                <span className="heading" style={{display : (this.state.ecomthree==="Nicht verfügbar"||this.state.ecomthree==="Not Available")?"none":""}}>
                                {t("Product Detail Page.ecomthree")}
                                {":"}
                                </span>                         
                            <button
                                className="copy-button ecomthree"
                                onClick={e => this.copyOnClick(e)}
                                style={{display : (this.state.ecomthree==="Nicht verfügbar"||this.state.ecomthree==="Not Available")?"none":""}}
                            >
                                <i className="bi bi-clipboard"
                                style={{display : (this.state.ecomthree==="Nicht verfügbar"||this.state.ecomthree==="Not Available")?"none":""}}></i>
                            </button>
                            </div>
                            <span className="uspecomheader"  style={{display :(this.state.ecomthree==="Nicht verfügbar"||this.state.ecomthree==="Not Available")?"none":"inline"}}>
                            {this.state.ecomthree}
                            </span>
                            
                            {this.state.ecomthree && this.lastUpdateComp("ecomthree")}
                        </div>
                        )}
                        {this.state.ecomfour !== "Nicht verfügbar" && this.state.ecomfour !== "Not Available" && (
                        <div className="product-usp-header ecomfour">
                            <div className="product-usp-header-heading">
                                <span className="heading" style={{display : (this.state.ecomfour==="Nicht verfügbar"||this.state.ecomfour==="Not Available")?"none":""}}>
                                {t("Product Detail Page.ecomfour")}
                                {":"}
                                </span>                         
                            <button
                                className="copy-button ecomfour"
                                onClick={e => this.copyOnClick(e)}
                                style={{display : (this.state.ecomfour==="Nicht verfügbar"||this.state.ecomfour==="Not Available")?"none":""}}
                            >
                                <i className="bi bi-clipboard"
                                style={{display : (this.state.ecomfour==="Nicht verfügbar"||this.state.ecomfour==="Not Available")?"none":""}}></i>
                            </button>
                            </div>
                            <span className="uspecomheader"  style={{display :(this.state.ecomfour==="Nicht verfügbar"||this.state.ecomfour==="Not Available")?"none":"inline"}}>
                            {this.state.ecomfour}
                            </span>
                            
                            {this.state.ecomfour && this.lastUpdateComp("ecomfour")}
                        </div>
                        )}
                        {this.state.ecomfive !== "Nicht verfügbar" && this.state.ecomfive !== "Not Available" && (
                        <div className="product-usp-header ecomfive">
                            <div className="product-usp-header-heading">
                                <span className="heading" style={{display : (this.state.ecomfive==="Nicht verfügbar"||this.state.ecomfive==="Not Available")?"none":""}}>
                                {t("Product Detail Page.ecomfive")}
                                {":"}
                                </span>                         
                            <button
                                className="copy-button ecomfive"
                                onClick={e => this.copyOnClick(e)}
                                style={{display : (this.state.ecomfive==="Nicht verfügbar"||this.state.ecomfive==="Not Available")?"none":""}}
                            >
                                <i className="bi bi-clipboard"
                                style={{display : (this.state.ecomfive==="Nicht verfügbar"||this.state.ecomfive==="Not Available")?"none":""}}></i>
                            </button>
                            </div>
                            <span className="uspecomheader"  style={{display :(this.state.ecomfive==="Nicht verfügbar"||this.state.ecomfive==="Not Available")?"none":"inline"}}>
                            {this.state.ecomfive}
                            </span>
                            
                            {this.state.ecomfive && this.lastUpdateComp("ecomfive")}
                        </div>
                        )}
                        {this.state.prodesbom !== "Nicht verfügbar" && this.state.prodesbom !== "Not Available" && (
                        <div className="product-usp-header prodesbom">
                            <div className="product-usp-header-heading">
                                <span className="heading" style={{display : (this.state.prodesbom==="Nicht verfügbar"||this.state.prodesbom==="Not Available")?"none":""}}>
                                {t("Product Detail Page.prodesbom")}
                                {":"}
                                </span>                         
                            <button
                                className="copy-button prodesbom"
                                onClick={e => this.copyOnClick(e)}
                                style={{display : (this.state.prodesbom==="Nicht verfügbar"||this.state.prodesbom==="Not Available")?"none":""}}
                            >
                                <i className="bi bi-clipboard"
                                style={{display : (this.state.prodesbom==="Nicht verfügbar"||this.state.prodesbom==="Not Available")?"none":""}}></i>
                            </button>
                            </div>
                            <span className="uspecomheader"  style={{display :(this.state.prodesbom==="Nicht verfügbar"||this.state.prodesbom==="Not Available")?"none":"inline"}}>
                            {this.state.prodesbom}
                            </span>
                            
                            {this.state.prodesbom && this.lastUpdateComp("prodesbom")}
                        </div>
                        )}
                        {this.state.disclaimer !== "Nicht verfügbar" && this.state.disclaimer !== "Not Available" && (
                        <div className="product-usp-header disclaimer">
                            <div className="product-usp-header-heading">
                                <span className="heading" style={{display : (this.state.disclaimer==="Nicht verfügbar"||this.state.disclaimer==="Not Available")?"none":""}}>
                                {t("Product Detail Page.disclaimer")}
                                {":"}
                                </span>                         
                            <button
                                className="copy-button disclaimer"
                                onClick={e => this.copyOnClick(e)}
                                style={{display : (this.state.disclaimer==="Nicht verfügbar"||this.state.disclaimer==="Not Available")?"none":""}}
                            >
                                <i className="bi bi-clipboard"
                                style={{display : (this.state.disclaimer==="Nicht verfügbar"||this.state.disclaimer==="Not Available")?"none":""}}></i>
                            </button>
                            </div>
                            <span className="uspecomheader"  style={{display :(this.state.disclaimer==="Nicht verfügbar"||this.state.disclaimer==="Not Available")?"none":"inline"}}>
                            {this.state.disclaimer}
                            </span>
                            
                            {this.state.disclaimer && this.lastUpdateComp("disclaimer")}
                        </div>
                        )}
                        {this.state.website !== "Nicht verfügbar" && this.state.website !== "Not Available" && (
                        <div className="product-usp-website">
                          <div className="product-usp-website-heading">
                              <span className="heading" style={{display : (this.state.website==="Nicht verfügbar"||this.state.website==="Not Available")?"none":""}}>
                                {t("Product Detail Page.website")}
                                {":"}
                              </span>                           
                            <button
                              className="copy-button usp-website"
                              onClick={e => this.copyOnClick(e)}
                              style={{display : (this.state.website==="Nicht verfügbar"||this.state.website==="Not Available")?"none":""}}
                            >
                              <i className="bi bi-clipboard"
                               style={{display : (this.state.website==="Nicht verfügbar"||this.state.website==="Not Available")?"none":""}}></i>
                            </button>
                          </div>
                          <span className="uspecomwebsite"  style={{display : (this.state.website==="Nicht verfügbar"||this.state.website==="Not Available")?"none":"inline"}}>
                            {this.state.website}
                          </span>
                         
                          {this.state.isOpen_productDescription && this.lastUpdateComp("uspecomstory")}
                        </div>
                        )}
                      </div>
                    </Tab>

                    {this.productCharacteristicsCount() > 0 && (
                      <Tab
                        eventKey="product-properties"
                        title={t("Product Detail Page.properties")}
                      >
                        {this.productCharacteristicsTab()}
                      </Tab>
                    )}
                     

                    <Tab
                      eventKey="product-usage"
                      title={t("Product Detail Page.usage")}
                    >
                   
                    {(dateTogglerProductUsage && dateTogglerProductUsage  !== "") &&
                    <div className="row mt-2 mb-0"> 
                        <div className="col-lg-11 col-md-10 col-sm-6">
                          <div className="show-heading mt-2"> 
                            <p>{t("Product Detail Page.toggleLastUpdateHeading")}</p>
                          </div>
                        </div>
                        <div className="col-lg-1 col-md-2 col-sm-6">
                        <label class="switch" >
                          <input type="checkbox" onClick={()=>this.handleToggle("productUsage")} checked={this.state.isOpen_productUsage} />
                          <span class="slider round"></span>
                        </label>
                        </div>
                    </div> }
                    {this.getUsage()}
                    </Tab>
                    {/* {this.state.related_assets_count > 0 && ( */}
                    {this.technicalDataCount() > 0 && (
                        <Tab
                          eventKey="technical-data"
                          title={t("Product Detail Page.technicalData")}
                        >
                          {this.technicalDataTab()}
                        </Tab>
                      )}
                      {this.retailerInformationDataCount() > 0 && (
                        <Tab
                          eventKey="retailerInformation-data"
                          title={t("Product Detail Page.retailerInformationData")}
                        >
                          {this.retailerInformationTab()}
                        </Tab>
                      )}
                      
                      <Tab
                        eventKey="product-related-assets"
                        title={t("Product Detail Page.related-assets")}
                      >
                        <div className="row">
                          <MediaQuery minWidth={991}>
                            {/* <div className="col-3"> */}
                            <div className="downloadAllButton">
                              {/* <tr className="downloadAllButton"> */}
                                <div><input type="checkbox" onChange={(e) => this.onCheckboxChange(e)} checked={this.state.downloadMainCheckbox}></input></div>
                                <div>{"\u00A0\u00A0"}</div>
                                <div>{t("Asset Detail Page.type")}</div>
                                <div>{t("Asset Detail Page.date")}</div> 
                                <div>{t("Asset Detail Page.file-size")}</div> 
                                <div>{t("Asset Detail Page.file-type")}</div>           
                              {/* </tr>  */}
                            </div>
                            {this.productRelatedAssetsNew(3)}
                            <button className="multipleDownloads" onClick={() =>{ this.downloadAllRef.current.downloadAll()}}>Download({downloadArrayLength})</button>
                            {/* </div> */}
                          </MediaQuery>
                          <MediaQuery maxWidth={990}>
                            {/* <div className="col-3"> */}
                            <div className="downloadAllButton">
                              {/* <tr className="downloadAllButton"> */}
                              <div><input type="checkbox" onChange={(e) => this.onCheckboxChange(e)} checked={this.state.downloadMainCheckbox}></input></div>
                                <div>{"\u00A0\u00A0"}</div>
                                <div>{t("Asset Detail Page.type")}</div>
                                <div>{t("Asset Detail Page.date")}</div> 
                                <div>{t("Asset Detail Page.file-size")}</div> 
                                <div>{t("Asset Detail Page.file-type")}</div>
                              {/* </tr>  */}
                            </div>
                            {this.productRelatedAssetsNew(3)}
                            <button className="multipleDownloads" onClick={() =>{ this.downloadAllRef.current.downloadAll()}}>Download({downloadArrayLength})</button>
                            {/* </div> */}
                          </MediaQuery>
                        </div>
                      </Tab>
                    {/* )} */}
                    {this.dimensionalDataCount() > 0 && (
                      <Tab
                        eventKey="dimensional-properties"
                        title={t("Product Detail Page.dimensionalData")}
                      >
                        {this.dimensionalDataTab()}
                      </Tab>
                    )}
                    {this.normalValueShow(this.state.productDetailData,"youtubelink",this.props.countryLocale) && (
                      <Tab
                        eventKey="product-data"
                        title={t("Product Detail Page.data")}
                      >
                        {this.getCarousel(
                         this.normalValueShow(this.state.productDetailData,"youtubelink",this.props.countryLocale)
                        )}
                      </Tab>
                    )}
                    
                  </Tabs>
                </div>
                : 
                <div className="product-tab">
                <Tabs
                  defaultActiveKey={getCountryCode() === "nl" ? "product-description" : this.activeKey}
                  className="mb-4"
                  onSelect={activeKey => this.clickOnTab(activeKey)}
                >
                  {/* {this.state.related_assets_count > 0 && ( */}
                    <Tab
                      eventKey="product-related-assets"
                      title={t("Product Detail Page.related-assets")}
                    >
                      <div className="row">
                        <MediaQuery minWidth={991}>
                          {/* <div className="col-3"> */}
                          <div className="downloadAllButton">
                            {/* <tr className="downloadAllButton"> */}
                              <div><input type="checkbox" onChange={(e) => this.onCheckboxChange(e)} checked={this.state.downloadMainCheckbox}></input></div>
                              <div>{"\u00A0\u00A0"}</div>
                              <div>{t("Asset Detail Page.type")}</div>
                              <div>{t("Asset Detail Page.date")}</div> 
                              <div>{t("Asset Detail Page.file-size")}</div> 
                              <div>{t("Asset Detail Page.file-type")}</div>           
                            {/* </tr>  */}
                          </div>
                          {this.productRelatedAssetsNew(3)}
                          <button className="multipleDownloads" onClick={() =>{ this.downloadAllRef.current.downloadAll()}}>Download({downloadArrayLength})</button>
                          {/* </div> */}
                        </MediaQuery>
                        <MediaQuery maxWidth={990}>
                          {/* <div className="col-3"> */}
                          <div className="downloadAllButton">
                            {/* <tr className="downloadAllButton"> */}
                            <div><input type="checkbox" onChange={(e) => this.onCheckboxChange(e)} checked={this.state.downloadMainCheckbox}></input></div>
                              <div>{"\u00A0\u00A0"}</div>
                              <div>{t("Asset Detail Page.type")}</div>
                              <div>{t("Asset Detail Page.date")}</div> 
                              <div>{t("Asset Detail Page.file-size")}</div> 
                              <div>{t("Asset Detail Page.file-type")}</div>
                            {/* </tr>  */}
                          </div>
                          {this.productRelatedAssetsNew(3)}
                          <button className="multipleDownloads" onClick={() =>{ this.downloadAllRef.current.downloadAll()}}>Download({downloadArrayLength})</button>
                          {/* </div> */}
                        </MediaQuery>
                      </div>
                    </Tab>
                  {/* )} */}
                  <Tab
                    eventKey="product-description"
                    title={t("Product Detail Page.description")}
                  >
                    <div className="product-description">
                      {this.updateSwitcherProdDescription(t)}
                     
                      <div className="product-usp-story">
                        <div className="product-usp-heading">
                          {getCountryCode() === "nl" ? (
                            <span className="heading" style={{display : (this.state.productUspEcomStory==="Nicht verfügbar"||this.state.productUspEcomStory==="Not Available")?"none":""}}>
                              {t("Product Detail Page.shortproddesc")}
                              {":"}
                            </span>
                          ) : (
                            <span className="heading">
                              {t("Product Detail Page.uspecomstory")}
                              {":"}
                            </span>
                          )}
                          <button
                            className="copy-button usp-story"
                            onClick={e => this.copyOnClick(e)}
                            style={{display : (getCountryCode() === "nl")&&(this.state.productUspEcomStory==="Nicht verfügbar"||this.state.productUspEcomStory==="Not Available")?"none":""}}
                          >
                            <i className="bi bi-clipboard"
                             style={{display : (getCountryCode() === "nl")&&(this.state.productUspEcomStory==="Nicht verfügbar"||this.state.productUspEcomStory==="Not Available")?"none":""}}></i>
                          </button>
                        </div>
                        <span className="uspecomstory"  style={{display : (getCountryCode() === "nl")&&(this.state.productUspEcomStory==="Nicht verfügbar"||this.state.productUspEcomStory==="Not Available")?"none":"inline"}}>
                          {this.state.productUspEcomStory}
                        </span>
                       
                        {this.state.isOpen_productDescription && this.lastUpdateComp("uspecomstory")}
                      </div>
                      <div className="product-ecom-description">
                        <div className="product-desc-heading">
                          <span className="prod-desc-label"
                          style={{display : (getCountryCode() === "nl")&&(this.state.productDescription==="Nicht verfügbar"||this.state.productDescription==="Not Available")?"none":""}}>
                            {t("Product Detail Page.longdescription")}:
                          </span>
                          <button
                            className="copy-button ecom-desc"
                            onClick={e => this.copyOnClick(e)}
                            style={{display : (getCountryCode() === "nl")&&(this.state.productDescription==="Nicht verfügbar"||this.state.productDescription==="Not Available")?"none":""}}
                          >
                            <i className="bi bi-clipboard" style={{display : (getCountryCode() === "nl")&&(this.state.productDescription==="Nicht verfügbar"||this.state.productDescription==="Not Available")?"none":""}}></i>
                          </button>
                        </div>
                        <span className="prod-desc-text" style={{display : (getCountryCode() === "nl")&&(this.state.productDescription==="Nicht verfügbar"||this.state.productDescription==="Not Available")?"none":""}}>
                        {this.state.showMore
                            ? this.state.productDescription
                            : this.state.productDescription.substring(
                              0,
                              250
                            )}
                              {this.state.productDescription.length > 250 ?  
                          <button
                            className=" show-more-btn"
                            onClick={this.setShowMore}
                          >
                            {this.state.showMore
                              ? `${t("Product Detail Page.showLess")}`
                              : `...${t("Product Detail Page.showMore")}`}
                          </button>  : ""}
                        </span>
                       
                        {this.state.isOpen_productDescription  && this.lastUpdateComp("ecomproddesc")}
                      </div>
                      {/* <div className="product-active-ingredients">
                        <span className="prod-desc-label"style={{display : (getCountryCode() === "nl")&&(this.state.productActiveIngredients==="Nicht verfügbar"||this.state.productActiveIngredients==="Not Available")?"none":""}}>
                          {t("Product Detail Page.activeingredients")}:
                        </span>
                        <span className="prod-desc-text" style={{display : (getCountryCode() === "nl")&&(this.state.productActiveIngredients==="Nicht verfügbar"||this.state.productActiveIngredients==="Not Available")?"none":""}}>
                          {this.state.productActiveIngredients}
                        </span>
                       
                        {this.state.isOpen_productDescription && this.lastUpdateComp("activeingredients")}
                      </div> */}
                      {/* <div className="product-type">
                        <span className="prod-desc-label"style={{display : (getCountryCode() === "nl")&&(this.state.productType==="Nicht verfügbar"||this.state.productType==="Not Available")?"none":""}}>
                          Product Type :
                        </span>
                        <span className="prod-desc-text" style={{display : (getCountryCode() === "nl")&&(this.state.productType==="Nicht verfügbar"||this.state.productType==="Not Available")?"none":""}}>
                          {this.state.productType}
                        </span> */}
                       
                        {/* {this.state.isOpen_productDescription && this.lastUpdateComp("activeingredients")} */}
                      {/* </div> */}
                    </div>
                  </Tab>
                  {this.productCharacteristicsCount() > 0 && (
                    <Tab
                      eventKey="product-properties"
                      title={t("Product Detail Page.properties")}
                    >
                      {this.productCharacteristicsTab()}
                    </Tab>
                  )}
                 

                  <Tab
                    eventKey="product-usage"
                    title={t("Product Detail Page.usage")}
                  >
                 
                  {(dateTogglerProductUsage && dateTogglerProductUsage  !== "") &&
                  <div className="row mt-2 mb-0"> 
                      <div className="col-lg-11 col-md-10 col-sm-6">
                        <div className="show-heading mt-2"> 
                          <p>{t("Product Detail Page.toggleLastUpdateHeading")}</p>
                        </div>
                      </div>
                      <div className="col-lg-1 col-md-2 col-sm-6">
                      <label class="switch" >
                        <input type="checkbox" onClick={()=>this.handleToggle("productUsage")} checked={this.state.isOpen_productUsage} />
                        <span class="slider round"></span>
                      </label>
                      </div>
                  </div> }
                  {this.getUsage()}
                  </Tab>
                  {this.dimensionalDataCount() > 0 && (
                    <Tab
                      eventKey="dimensional-properties"
                      title={t("Product Detail Page.dimensionalData")}
                    >
                      {this.dimensionalDataTab()}
                    </Tab>
                  )}
                  { this.normalValueShow(this.state.productDetailData,"youtubelink",this.props.countryLocale) && (
                    <Tab
                      eventKey="product-data"
                      title={t("Product Detail Page.data")}
                    >
                      {this.getCarousel(
                        this.normalValueShow(this.state.productDetailData,"youtubelink",this.props.countryLocale)
                      )}
                    </Tab>
                  )}
                  
                </Tabs>
              </div>
                }
                </MediaQuery>
                <MediaQuery maxWidth={767}>
                  <div className="product-accordion">
                    <Accordion>
                      <Card>
                        <Card.Header>
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="0"
                          >
                            {t("Product Detail Page.description")}
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body>
                            <div className="product-description">
                              <div className="product-usp-story">
                                <div className="product-usp-heading">
                                  <span className="heading">
                                    {t("Product Detail Page.uspecomstory")}
                                    {":"}
                                  </span>
                                  <button
                                    className="copy-button usp-story"
                                    onClick={e => this.copyOnClick(e)}
                                  >
                                    <i className="bi bi-clipboard"></i>
                                  </button>
                                </div>
                                <span className="uspecomstory">
                                  {this.state.productUspEcomStory}
                                </span>
                              </div>
                              <div className="product-ecom-description">
                                <div className="product-desc-heading">
                                  <span className="prod-desc-label">
                                    {t("Product Detail Page.longdescription")}:
                                  </span>
                                  <button
                                    className="copy-button ecom-desc"
                                    onClick={e => this.copyOnClick(e)}
                                  >
                                    <i className="bi bi-clipboard"></i>
                                  </button>
                                </div>
                                <span className="prod-desc-text">
                                  {this.state.showMore
                                    ? this.state.productDescription
                                    : this.state.productDescription.substring(
                                        0,
                                        250
                                      )}
                                     {this.state.productDescription.length > 250 ?  
                              <button
                                className=" show-more-btn"
                                onClick={this.setShowMore}
                              >
                                {this.state.showMore
                                  ? `${t("Product Detail Page.showLess")}`
                                  : `...${t("Product Detail Page.showMore")}`}
                              </button>  : ""}
                              `   </span>
                              </div>
                              {/* <div className="product-active-ingredients">
                                <span className="prod-desc-label">
                                  {t("Product Detail Page.activeingredients")}:
                                </span>
                                <span className="prod-desc-text">
                                  {this.state.productActiveIngredients}
                                </span>
                              </div> */}
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                      {this.productCharacteristicsCount() > 0 && (
                        <Card>
                          <Card.Header>
                            <Accordion.Toggle
                              as={Button}
                              variant="link"
                              eventKey="1"
                            >
                              {t("Product Detail Page.properties")}
                            </Accordion.Toggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey="1">
                            <Card.Body>
                              {" "}
                              {this.productCharacteristicsTab()}
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      )}
                      <Card>
                        <Card.Header>
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="2"
                          >
                            {t("Product Detail Page.usage")}
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="2">
                          <Card.Body>{this.getUsage()}</Card.Body>
                        </Accordion.Collapse>
                      </Card>
                      {this.dimensionalDataCount() > 0 && (
                        <Card>
                          <Card.Header>
                            <Accordion.Toggle
                              as={Button}
                              variant="link"
                              eventKey="5"
                            >
                              {t("Product Detail Page.dimensionalData")}
                            </Accordion.Toggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey="5">
                            <Card.Body>
                              {" "}
                              {this.dimensionalDataTab()}
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      )}
                      {this.normalValueShow(this.state.productDetailData,"youtubelink",this.props.countryLocale) && (
                        <Card>
                          <Card.Header>
                            <Accordion.Toggle
                              as={Button}
                              variant="link"
                              eventKey="3"
                            >
                              {t("Product Detail Page.data")}
                            </Accordion.Toggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey="3">
                            <Card.Body>
                              {" "}
                              {this.getCarousel(
                               this.normalValueShow(this.state.productDetailData,"youtubelink",this.props.countryLocale)
                              )}
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      )}
                      {/* {this.state.related_assets_count > 0 && ( */}
                        <Card>
                          <Card.Header>
                            <Accordion.Toggle
                              as={Button}
                              variant="link"
                              eventKey="4"
                            >
                              {t("Product Detail Page.related-assets")}
                            </Accordion.Toggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey="4">
                            <Card.Body>
                              <MediaQuery minWidth={481} maxWidth={991}>
                              <div className="downloadAllButton">
                                {/* <tr className="downloadAllButton"> */}
                                <div><input type="checkbox" onChange={(e) => this.onCheckboxChange(e)} checked={this.state.downloadMainCheckbox}></input></div>
                                  <div>{"\u00A0\u00A0"}</div>
                                  <div>{t("Asset Detail Page.type")}</div>
                                  <div>{t("Asset Detail Page.date")}</div> 
                                  <div>{t("Asset Detail Page.file-size")}</div> 
                                  <div>{t("Asset Detail Page.file-type")}</div>
          
                                {/* </tr>  */}
                              </div>
                                <div className="tablesize481">{this.productRelatedAssetsNew(10)}</div>
                                <button className="multipleDownloads" onClick={() =>{ this.downloadAllRef.current.downloadAll()}}>Download({downloadArrayLength})</button>
                              </MediaQuery>
                              <MediaQuery minWidth={376} maxWidth={480}>
                              <div className="downloadAllButton">
                                {/* <tr className="downloadAllButton"> */}
                                <div><input type="checkbox" onChange={(e) => this.onCheckboxChange(e)} checked={this.state.downloadMainCheckbox}></input></div>
                                  <div>{"\u00A0\u00A0"}</div>
                                  <div>{t("Asset Detail Page.type")}</div>
                                  <div>{t("Asset Detail Page.date")}</div> 
                                  <div>{t("Asset Detail Page.file-size")}</div> 
                                  <div>{t("Asset Detail Page.file-type")}</div>
                                {/* </tr>  */}
                              </div>
                                {this.productRelatedAssetsNew(12)}
                                <button className="multipleDownloads" onClick={() =>{ this.downloadAllRef.current.downloadAll()}}>Download({downloadArrayLength})</button>
                              </MediaQuery>
                              <MediaQuery maxWidth={375}>
                              <div className="downloadAllButton">
                                {/* <tr className="downloadAllButton"> */}
                                <div><input type="checkbox" onChange={(e) => this.onCheckboxChange(e)} checked={this.state.downloadMainCheckbox}></input></div>
                                  <div>{"\u00A0\u00A0"}</div>
                                  <div>{t("Asset Detail Page.type")}</div>
                                  <div>{t("Asset Detail Page.date")}</div> 
                                  <div>{t("Asset Detail Page.file-size")}</div> 
                                  <div>{t("Asset Detail Page.file-type")}</div>
                                {/* </tr>  */}
                              </div>
                                {this.productRelatedAssetsNew(12)}
                                <button className="multipleDownloads" onClick={() =>{ this.downloadAllRef.current.downloadAll()}}>Download({downloadArrayLength})</button>
                              </MediaQuery>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      {/* )} */}
                    </Accordion>
                  </div>
                </MediaQuery>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
const ProductDetailPage = withTranslation("common")(ProductDetail);
const mapStateToProps = state => {
  return {
    productsList: state.product.productsList,
    assetsList: state.asset.assetsList,
    favProductsList: state.product.favProductsList,
    countrySettings: state.country.countryData,
    RoleSettings: state.role,
    CartAssetsList: state.asset.CartAssetsList,
    countryLocale: state.locale.countryLocale
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateFavProductsList: ownProps => dispatch(updateFavProductsList(ownProps)),
    updateSetRenderProductList:ownProps => dispatch(updateSetRenderProductList(ownProps)),
    updateCartAssetsList: ownProps => dispatch(updateCartAssetsList(ownProps)),
    updateSetRenderCartAssetList: ownProps => dispatch(updateSetRenderCartAssetList(ownProps))
  };
};
export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ProductDetailPage);
