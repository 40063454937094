import config from "../config.js";
import store from "../redux/store";
import { fetchFavAssetsSuccess, fetchFavProductsSuccess, fetchCartAssetsSuccess } from "../redux";
import axios from "axios";

export const getFavoritesList = async userId => {
  var favProducts = [];
  var favAssets = [];
  const favoritesListUrl = `${config.favApi}dc/userdata/${userId}/favorites`;
  const favoritesData = await axios.get(favoritesListUrl).then(res => res.data);
  const favorites = favoritesData.data;
  if (favorites.length > 0) {
    if (Object.keys(favorites[0]).includes("products")) {
      favProducts = favorites[0].products;
    }
    if (Object.keys(favorites[0]).includes("assets")) {
      favAssets = favorites[0].assets;
    }
  }
  store.dispatch(fetchFavProductsSuccess(favProducts));
  store.dispatch(fetchFavAssetsSuccess(favAssets));
  return;
};
export const getCartList = async userId => {
 // var favProducts = [];
  var favAssets = [];
  const favoritesListUrl = `${config.favApi}dc/assets/getAllAssetsFromCart/${userId}`;
  const favoritesData = await axios.get(favoritesListUrl).then(res => res.data);
  const favorites = favoritesData.data;
  if (favorites.length > 0) {
    // if (Object.keys(favorites[0]).includes("products")) {
    //   favProducts = favorites[0].products;
    // }
    // if (Object.keys(favorites[0]).includes("assets")) {
      favAssets = favorites;
    // }
  }
  //store.dispatch(fetchFavProductsSuccess(favProducts));
  store.dispatch(fetchCartAssetsSuccess(favAssets));
  return;
};
export const getUserSettings = async userId => {
  const userSettingsApi = `${config.favApi}dc/userdata/${userId}/settings`;
  const userSettingsData = await axios.get(userSettingsApi).then(res => res.data);
  const settings = userSettingsData.data;
  return settings;
};
