import React from "react";
import { withRouter } from "react-router-dom";
import ProductDetailPage from "../../pages/ProductDetailPageView/ProductDetailPage";
import AssetDetailPage from "../../pages/AssetDetailPageView/AssetDetailPage";

const RedirectRoute = props => {
  return /^\d+$/.test(props.match.params.id) ? (
    <React.Fragment>
      <ProductDetailPage title="Product Detail Page" />
    </React.Fragment>
  ) : (
    <React.Fragment>
      <AssetDetailPage title="Asset Detail Page" />
    </React.Fragment>
  );
};

export default withRouter(RedirectRoute);
