import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { withRouter, Link } from "react-router-dom";
import AuthContext from "../../components/Auth/Auth-context";
import {
  downloadOnClick,
  getCountryCode,
  getProductTitle,
  pimAttributes
} from "../../util/Helper.js";
import { updateFavProductsList, updateFavAssetsList,updateSetRenderProductList, updateSetRenderAssetList,
  updateSetRenderCartAssetList,updateCartAssetsList } from "../../redux";
//import qs from "qs";
import _ from "lodash";
import axios from "axios";
import LinesEllipsis from "react-lines-ellipsis";
import config from "../../config.js";
import "./card.scss";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";
// import { Button } from "react-bootstrap";
import Checkbox from "@material-ui/core/Checkbox";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { IconButton } from "@material-ui/core";
import { MoreVert } from "@mui/icons-material";
import { Menu, MenuItem } from "@mui/material";
import { getCartList } from "../../Services/service.js";
import qs from "qs";
var dayjs = require("dayjs");

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}));

const env = process.env.REACT_APP_ENVIRONMENT;
let ga_env = env === "PROD" ? "Production" : "Testing";
window.dataLayer = window.dataLayer || [];
var file=[];
var  getProductExcelFile
const countryCode = getCountryCode();
const Card = props => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(!event.currentTarget);
  };
  const classes = useStyles();
  let { item, index, match, countrySettings, productsList, RoleSettings, countryLocale } = { ...props };
  const { t } = props;
  let upc = item.unitupc;
    let assets = props.assetsList.filter(asset => asset?.upc?.split(";").map(item => item.trim()).includes(upc));
  assets.sort((a,b)=>  a.asset_location.toLowerCase().split("_").reverse()[0] < b.asset_location.toLowerCase().split("_").reverse()[0] ? -1 :
  a.asset_location.toLowerCase().split("_").reverse()[0] < b.asset_location.toLowerCase().split("_").reverse()[0]? 1 :0);
  let [enableDownload, setEnableDownload] = useState(true);
  let [backDropOpen, setBackDrop] = useState(false);
  // eslint-disable-next-line
  let [license_expiry_date, setExpiryDate] = useState(null);
  let [remaining_days, setRemainingDays] = useState(null);
  let [quickDownloadModal, setquickDownloadModal]=useState(false);
  let [cartModal, setCartModal]=useState(false);
  const [checked, setChecked] = useState([]);
  const[downloadcom,setDownloadCom]=useState(false)
   const [grayColorOut, setGrayColorOut] = useState(false)
   const [viewPermissionData, setviewPermissionData] = useState([])
     // eslint-disable-next-line
  const [gigyaRole, setGigyaRole] = useState();
  const AuthCtx = useContext(AuthContext);
  const userGigyaId = AuthCtx.giveToken();
  const userId= localStorage.email
  let [modified_date, setModifieddate] = useState(null);

  const postGigyaID = async () => {
   // console.log("userId",userId,localStorage.email,localStorage.getItem("email"),AuthCtx.userProfile.email)
    const response = await axios({
      method: "get",
      url: `${config.baseApi}dc/exports/getrolepermission/${AuthCtx?.userProfile?.email?AuthCtx?.userProfile?.email : userId }`,
      data: {
        "useAdministration": {
          "Gigya_ID": AuthCtx?.userProfile?.email?AuthCtx?.userProfile?.email : userId ,
        }
      },
      headers: {
        "X-Country-Code": `${getCountryCode()}`
      }
    })
    setGigyaRole(response.data.data?.roleName);
    setviewPermissionData(response.data.data?.viewPermission)
    console.log("permission call",response,RoleSettings)
  }
  const hideShowFunction = async (item, status) => {
    const response = await axios({
      method: "POST",
      url: `${config.favApi}dc/exports/hsproductsassetsbyadmin`,
      data: {
        "gigyaId": userGigyaId,
        "emailId": userId,
        "PK": item?.PK,
        "SK": item?.SK,
        "status": status, // show
      },
      headers: {
        "X-Country-Code": `${getCountryCode()}`
      }
    })
    const responseUpdated = await axios({
      method: "get",
      url: `${config.baseApi}dc/entities`,
     
      headers: {
        "X-Country-Code": `${getCountryCode()}`
      }
    })
    props.updateSetRenderProductList(responseUpdated.data.data.products);
    props.updateSetRenderAssetList(responseUpdated.data.data.assets)
    return response
  }
  useEffect(() => {
    postGigyaID();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item?.hideShowStatus?.[0]?.currentStatus]);  
  useEffect(() => {
    if (item.type === "asset") {
      let set,
        exp_date = null;
      set = Object.keys(item).includes("license_expiry_date") && item.license_expiry_date.length > 0 ? false : true;
      setEnableDownload(set);
      !set && setExpiryDate(item.license_expiry_date);
      let today = dayjs();
      if (!set) {
        exp_date = dayjs(item.license_expiry_date);
      }

      if (exp_date != null && exp_date.diff(today, "day") < 31) {
        setRemainingDays(exp_date.diff(today, "day"));
      }
    }
    if (item.type === "product") {
      let set;
      if (Object.keys(item).includes("asset_info")) {
        set = Object.keys(item.asset_info).includes("license_expiry_date")
          ? false
          : true;
        !set && setExpiryDate(normalValueShow(item.asset_info,"license_expiry_date",countryLocale) );
      } else if (Object.keys(item).includes("license_expiry_date")) {
        set = true;
        setExpiryDate(normalValueShow(item,"license_expiry_date",countryLocale) );
      } else {
        set = false;
      }
      setEnableDownload(set);
    }
    document.addEventListener("click", e => {
      if (e.target.classList.contains("MuiBackdrop-root")) {
        setBackDrop(false);
      }
    });
   //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, item?.hideShowStatus?.[0]?.currentStatus]);
  useEffect(() => {
    if (item.type === "asset") {
      let set,
        exp_date = null;
      set = Object.keys(item).includes("release_date") && normalValueShow(item,"release_date",countryLocale).length > 0 ? false : true;
      setEnableDownload(set);
      !set && setModifieddate(normalValueShow(item,"release_date",countryLocale));

      let today = dayjs();
      if (!set) {
        exp_date = dayjs(normalValueShow(item,"release_date",countryLocale));
      }

      if (exp_date != null && exp_date.diff(today, "day") < 31) {
        setModifieddate(exp_date.diff(today, "day"));
      }
      let formatedmodifieddate= normalValueShow(item,"release_date",countryLocale)?.slice(0,10)
      if(countryCode === "de") {
        if(formatedmodifieddate){
          const [year, month, day] = formatedmodifieddate.split('-')

          // dd/mm/yyyy
          let formatedDate= `${day}-${month}-${year}`
          setModifieddate(formatedDate);
        }

      }
      else{
        setModifieddate(formatedmodifieddate);
      }

    }
    if (item.type === "product") {
      let set;
      if (Object.keys(item).includes("asset_info")) {
        set = Object.keys(item.asset_info).includes("modifiedDate")
          ? false
          : true;
        !set && setModifieddate(normalValueShow(item.asset_info,"modifiedDate",countryLocale));
      } else if (Object.keys(item).includes("modifiedDate")) {
        set = true;
        setModifieddate(normalValueShow(item,"modifiedDate",countryLocale));
      } else {
        set = false;
      }
      setEnableDownload(set);
    }
    let formatedmodifieddate= normalValueShow(item,"modifiedDate",countryLocale)?.slice(0,10)
    if(countryCode === "de") {
      if(formatedmodifieddate){
        const [year, month, day] = formatedmodifieddate.split('-')

        // dd/mm/yyyy
        let formatedDate= `${day}-${month}-${year}`
        setModifieddate(formatedDate);
      }

    }
    else{
      setModifieddate(formatedmodifieddate);
    }

    document.addEventListener("click", e => {
      if (e.target.classList.contains("MuiBackdrop-root")) {
        setBackDrop(false);
      }
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, item?.hideShowStatus?.[0]?.currentStatus]);
  const placeholder = item => {
    if (item.type === "product") {
      let imageUrl,
        preview_available = false;
      const domain = window.location.origin;
      if (
        Object.keys(item).includes("asset_info_print") &&
        Object.keys(item.asset_info_print).includes("preview_location")
      ) {
        imageUrl = `${domain}/${item.asset_info_print.preview_location}`;
        preview_available = true;
      }else if (Object.keys(item).includes("thumbnail")) {
        imageUrl = item.thumbnail;
        if (imageUrl !== undefined && imageUrl.length > 0) {
          preview_available = true;
        }
      } else if (
        Object.keys(item).includes("asset_info") &&
        Object.keys(item.asset_info).includes("preview_location")
      ) {
        imageUrl = `${domain}/${item.asset_info.preview_location}`;
        preview_available = true;
      } else if (Object.keys(item).includes("asset_location")) {
        let primaryImage = "";
        if (Array.isArray(item.asset_location)) {
          primaryImage = item.asset_location.filter(
            file =>
              file.includes("Primary_Image") && file.indexOf("_MACOSX") === -1
          );
        } else if (
          item.asset_location.includes("Primary_Image") &&
          item.asset_location.indexOf("_MACOSX") === -1
        ) {
          primaryImage = item.asset_location;
        }
        if (primaryImage.length > 0) {
          imageUrl = `${domain}/${primaryImage}`;
          preview_available = true;
        }
      }
      if (preview_available) {
        return <img alt="" src={imageUrl} height="100%" width="100%"></img>;
      } else {
        return (
          <React.Fragment>
            <div className="no-preview">
              <div className="bayer-logo-background"></div>
              {( gigyaRole === "Internal") ?
              <span className="text">{t("Miscellaneous.noPreviewForInternal")}</span>
              :
              <span className="text">{t("Miscellaneous.noPreview")}</span>}
            </div>
          </React.Fragment>
        );
      }
    } else {
      let isPreviewAvailable = false;
      let previewFileSrc;
      let imageUrl;
      const domain = window.location.origin;
      if (_.has(item, "preview_location")) {
        previewFileSrc = item.preview_location;
        imageUrl = `${domain}/${previewFileSrc}`;
        isPreviewAvailable = true;
      } else if (_.has(item, "asset_location")) {
        if (Array.isArray(item.asset_location)) {
          previewFileSrc = item.asset_location.filter(
            file =>
              file.includes("Primary_Image") && file.indexOf("_MACOSX") === -1
          );
          if (previewFileSrc.length > 0) {
            isPreviewAvailable = true;
            imageUrl = `${domain}/${previewFileSrc}`;
          } else {
            const imageTypes = ["png", "jpg", "jpeg"];
            previewFileSrc = item.asset_location.filter(file => {
              const check = imageTypes.some(imgtype => file.includes(imgtype));
              return check;
            });
            if (previewFileSrc.length === 1) {
              isPreviewAvailable = true;
            }
          }
        } else {
          previewFileSrc = item.asset_location;
          const extension = previewFileSrc.slice(
            ((previewFileSrc.lastIndexOf(".") - 1) >>> 0) + 2
          );
          const imageTypes = ["png", "jpg", "jpeg"];
          if (imageTypes.includes(extension)) {
            isPreviewAvailable = true;
            imageUrl = `${domain}/${previewFileSrc}`;
          }
        }
      }
      if (isPreviewAvailable) {
        imageUrl = `${domain}/${previewFileSrc}`;
        return <img alt="" src={imageUrl} height="100%" width="100%"></img>;
      } else {
        return (
          <React.Fragment>
            <div className="no-preview">
              <div className="bayer-logo-background"></div>
              {( gigyaRole === "Internal") ?
              <span className="text">{t("Miscellaneous.noPreviewForInternal")}</span>
              :
              <span className="text">{t("Miscellaneous.noPreview")}</span>}
            </div>
          </React.Fragment>
        );
      }
    }
  };

  const handleAssetDownloadClick = (e, entity, type) => {
    e.preventDefault();
    // if (enableDownload) {
    downloadOnClick(e, entity, type);
    // } 
    // else {
    //   setBackDrop(true);
    // }
  };

  const assetDownloadButton = entity => {
    if (Object.keys(item).includes("asset_location")) {
      return (
    (RoleSettings &&  RoleSettings['Download Content']) || (viewPermissionData && viewPermissionData['Download Content'])=== true?
        <button
          className="downloadButton"
          onClick={e => handleAssetDownloadClick (e, entity, "asset")}
          data-brand={normalValueShow(item,"brand",countryLocale)}
        >
          <i className="bi bi-download"></i>
        </button>
        :""
      );
    }
  };

  const domain = window.location.origin;
  // function getLeafLetFile(item) {
  //   const attachments = item.asset_info?.attachments
  //   if(!attachments) {
  //     return ""
  //   }
  //   const pdfLocations = attachments.filter (attachment => endsWith(attachment.asset_location, "Beipackzettel.pdf"))
  //     if (pdfLocations.length > 0) {
  //       const pdfLocation = pdfLocations[pdfLocations.length - 1];
  //       const pdfLocationDomain = `${domain}/${pdfLocation.asset_location}`
  //       return pdfLocationDomain
  //     } else {
  //       return ""
  //     }
  //     function endsWith(str, suffix) {
  //       return str.indexOf(suffix, str.length - suffix.length) !== -1;
  //     }

  // }
  function isValidFileLocation(fileName) {
    return fileName !== "" && fileName !== null && fileName !== undefined

  }

  class File {
    constructor(id, title, filePath, brand,fileTitle, country_code, type){
      this.title = fileTitle
      this.filePath = filePath
      this.id = id
      this.brand= brand
      this.fileTitle = title
      this.country_code = country_code
      this.type= type
    }
  }
  const productLastUpdatedDate = (item, attribute) => {
    const updatedDates = item.lastUpdatedDate;
    if(updatedDates === undefined || updatedDates.length < 1){
      return "";
    }
    const date = item.lastUpdatedDate[0];
    const dateLocations = date.updatedAttributes;
    const attributeLists = dateLocations.filter (modifiedLocation => modifiedLocation.attributeList[attribute] !== undefined);
    if(attributeLists !== undefined && attributeLists.length > 0){
      const modifiedDate = attributeLists[0].modifiedDate.slice(0,10);
      return modifiedDate;
    }else{
      return ""
    }
  }
  const excelProductData = (item) =>  {
    let productProperties = Object.keys(item);
    let excelData = [];
    let excelPair = {};
    const attribs = pimAttributes()
    attribs.forEach(attribute => {
      if (productProperties.includes(attribute)) {
        let temp = {};
        temp[t(`Excel Header.attribute`)] = t(`Product Detail Page.${attribute}`);
        temp[t(`Excel Header.content`)] = item[attribute];
        temp[t(`Excel Header.last_update`)] = productLastUpdatedDate(item, attribute);
        excelPair = Object.assign({}, temp);
        excelData.push(excelPair);
      }
    });
    return excelData;
  };

  const getExcelData=(item)=>{
    const fileName = `Produktdaten_${normalValueShow(item,"ptitle",countryLocale)}_${normalValueShow(item,"pharmacentralnum",countryLocale)}`
    const excelDatas = excelProductData(item);
    const productBrand = Object.keys(item).includes("brand")
      ? normalValueShow(item,"brand",countryLocale)
      :t("Miscellaneous.notAvailable");
    getProductExcelFile = [fileName, excelDatas, productBrand];
    return getProductExcelFile;
  }

  const handleChange = (e,file) => {
    e.preventDefault()
    if (checked.includes(file.id)){
      let filter= checked.filter(val=>val!==file.id)
      setChecked(filter)
    }
    else{
      setChecked([...checked,file.id])
    }
  };

  const callingFile =(file)=>{ 
    if (checked.includes(file.id) && typeof file.filePath==="string"){
      let fileName= file.filePath.split("/")
      //const userId = localStorage.token;
      const userId= localStorage.email
      let timeStamp = new Date();
      let track_brand = file.brand;
      let track_assetId = file.id;
      let track_country = getCountryCode();
      axios({
        method: "post",
        url: `${config.favApi}dc/userdata/${userId}/downloads`,
        data: {
          downloads: {
            Country: track_country,
            Asset_ID: track_assetId,
            Brand: track_brand,
            User_ID: userId,
            Time_Stamp: timeStamp
          }
        },
        headers: {
          "X-Country-Code": `${getCountryCode()}`
        }
      });
      FileSaver.saveAs( file.filePath,fileName[fileName.length-1]);
      let currentDate = new Date().toJSON().slice(0, 10);
      const assetPostResponse =  axios({
        method: "POST",
        url: `${config.baseApi}dc/userdata/${userId}/updateAssetNotification`,
        data: {
          "notificationFlag": true,
          "country": `${getCountryCode()}`,
          "assetUpdatedOn": currentDate,
          "downloadedOn": currentDate,
          "assetId": file.id,
          "assetName": file.fileTitle,
          "brand": file.brand,
          "notificationCheckedOn": currentDate
        },
        
      })

      return assetPostResponse

    }
    else if(checked.includes(file.id) && file.id === "F4"){
      exportToCSV(file.filePath[1],file.filePath[0],file.filePath[2])
     
    }
    else if(checked.includes(file.id) && file.id === "F3"){
      downloadPackageInsert(file.filePath[0], 'downloaded_file.pdf')
    }
    
  }
  const downloadHandler =(e)=>{
    e.preventDefault()
    if (checked.length>0){
      file.map((file)=>(
        callingFile(file)
      ))
      setquickDownloadModal(false)
      setDownloadCom(true)
    }
  }


  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExt = ".xlsx";

  const exportToCSV = (apiData, fileName, brand) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExt);
    window.dataLayer.push({
      event: "gaGenericEvent",
      eventCategory: "Download (local)",
      eventAction: brand,
      eventLabel: "product",
      eventValue: "ProductDataPackage.xlsx",
      environment: ga_env
    });
  };

  const downloadPackageInsert = async (url, filename) => {
    try {
      // Fetch the PDF file as a blob
      const response = await axios.get(url, { responseType: 'blob' });
      const blob = new Blob([response.data], { type: 'application/pdf' });
  
      // Use FileSaver.js to save the blob as a file
      FileSaver.saveAs(blob, filename);
    } catch (error) {
      console.error( error);
    }
    
  };

  function endsWith(str, suffix) {
    return str.indexOf(suffix, str.length - suffix.length) !== -1;
  }

  const quickDownloadOptions =(assets,item)=>{
    var files = []
    assets.forEach((ele)=>{
      var eCommerceProductImages = new File(ele.assetId,
        !endsWith(ele?.asset_location, "Print.zip") ? t(`Asset Type Card.${ele.asset_type[0].dcAssetType}`) : t("Asset Detail Page.PrintProduct"),
        ele?.asset_location ? `${domain}/${ele?.asset_location}` : "", ele.brand,  ele.title, ele.country_code, ele.type)
      files.push(eCommerceProductImages)
    })

    //var eCommerceProductImages = new File("F1", t("Asset Detail Page.E-Com"),  item[0]?.asset_location ? `${domain}/${item[0]?.asset_location}` : "")
    // var printProductImages = new File("F2", t("Asset Detail Page.PrintProduct"), item?.print_media_location ? `${domain}/${item?.print_media_location}` : "")
    if (item.packageinsertlink) {
      var packageInsert = new File(
        "F3",
        "Package Insert",
        item.packageinsertlink,
        normalValueShow(item,"brand",countryLocale),
      );
      files.push(packageInsert);
    }
  
    var productData = new File("F4",t("Asset Detail Page.Excel"), getExcelData(item));
    files.push(productData)

    files = files.filter(file => {
      return isValidFileLocation(file.filePath)
    } )
    file=files
    var listItem = files.map((file) => (
      <div onClick={evt => {evt.preventDefault()}}>
        <Checkbox
          onClick={(e)=>{handleChange(e,file)} }
          checked={checked.includes(file.id)}
          style={{
            color: "#00617f",
            width: 30,
            height: 30

          }}
        />
        {file.fileTitle}
      </div>
    ));
    return listItem;

  }

  const quickCartOptions =(assets,item)=>{
    var files = []

    assets.forEach((ele)=>{
      var eCommerceProductImages = new File(ele.assetId,
        !endsWith(ele?.asset_location, "Print.zip") ? t(`Asset Type Card.${ele.asset_type[0].dcAssetType}`) 
        : t("Asset Detail Page.PrintProduct"),
        ele?.asset_location ? `${domain}/${ele?.asset_location}` : "", ele.brand,  ele.title, ele.country_code, ele.type)
      files.push(eCommerceProductImages)
    })

    //var eCommerceProductImages = new File("F1", t("Asset Detail Page.E-Com"),  item[0]?.asset_location ? `${domain}/${item[0]?.asset_location}` : "")
    // var printProductImages = new File("F2", t("Asset Detail Page.PrintProduct"), item?.print_media_location ? `${domain}/${item?.print_media_location}` : "")
    // var packageLeafLet = new File("F3", t("Asset Detail Page.Package"), getLeafLetFile(item))
    var productData = new File(normalValueShow(item,"id",countryLocale),t("Asset Detail Page.Excel"),getExcelData(item),normalValueShow(item,"brand",countryLocale),normalValueShow(item,"ptitle",countryLocale), item.country_code, normalValueShow(item,"type",countryLocale));
    files.push(productData)

    files = files.filter(file => {
      return isValidFileLocation(file.filePath)
    } )
    file=files

    var listItem = files.map((file) => (
      <div style={{display: "flex",alignItems: "center"}} onClick={evt => {evt.preventDefault()}}>
     {assetCartButton(file)}
      </div>
    ));
    return listItem;

  }


  
  const handleDownloadClick = (e) => {
    e.preventDefault();
    setquickDownloadModal(true)

  };

  const quickdownloadCancelHandler=(e)=>{
    e.preventDefault();
    setquickDownloadModal(false)
  }

  const handleCartClick = (e) => {
    e.preventDefault();
    setCartModal(true)

  };

  const cartHandler=(e)=>{
    e.preventDefault();
    setCartModal(false)
  }

  const productCartModal = entity => {
 
    let productsList = props.productsList
    let assetList=  props.assetsList
    let favAssets = props.CartAssetsList.map(asset => asset?.assetId);
    let filteringassets =assetList.filter((asset)=> favAssets.includes(asset?.assetId))
    let upcValues = filteringassets.map(asset => normalValueShow(asset,"upc",countryLocale));
    const combinedList = Array.from(new Set([...favAssets, ...upcValues]));

    let cartSet=false;
    let filteredproduct = productsList.filter((products)=> combinedList.includes(products?.unitupc)).map(pro => normalValueShow(pro,"unitupc",countryLocale));
    cartSet= filteredproduct.includes(normalValueShow(entity,"unitupc",countryLocale)) ? true : false;
    const iconchange = cartSet?"bi bi-cart-check-fill":"bi bi-cart-plus"
    return (
      <div
        className="cart-icon"
        style={{fontSize: "22px",marginRight: "6px"}}
        onClick={e => handleCartClick(e, entity, "asset")}
        data-brand={ normalValueShow(item,"brand",countryLocale)}
      >
        <i className={iconchange}></i>
      </div>
    );
  };


  const downloadButton = entity => {
    // if (Object.keys(item).includes("asset_location")) {
    const ifdownloaded= downloadcom?"downloadButton done":"downloadButton"
    const iconchange = downloadcom?"bi bi-download bi-check-lg":"bi bi-download"
    return (
      <button
        className={ifdownloaded}
        onClick={e => handleDownloadClick(e, entity, "asset")}
        data-brand={ normalValueShow(item,"brand",countryLocale)}
      >
        <i className={iconchange}></i>
      </button>
    );
    // }
  };

  const addFavorites = event => {
    event.preventDefault();
    let favSet = event.currentTarget.getAttribute("data-favset");
    const type = event.currentTarget.getAttribute("data-type");
    const id = event.currentTarget.getAttribute("data-id");
    const title = event.currentTarget.getAttribute("data-title");
    //const userId = localStorage.token;
    const userId= localStorage.email
    let productidList = props.favProductsList;
    let assetidList = props.favAssetsList;
    if (favSet === "false") {
      let currentDate = new Date().toJSON().slice(0, 10);
      event.currentTarget.setAttribute("data-favset", "true");
      event.currentTarget.firstElementChild.classList.remove("bi-bookmark");
      event.currentTarget.firstElementChild.classList.add("bi-bookmark-fill");
      switch (type) {
        case "product":
          if (productidList !== undefined) {
            let dataList={
              "notificationFlag": false,
              "productId": id,
              "updatedAttributes": [],
              "addedToFavoritesOn": currentDate,
              "notificationCheckedOn": currentDate
          }
            productidList.push(dataList);
            props.updateFavProductsList(productidList);
          }
          break;
        case "asset":
          if (assetidList !== undefined) {
          //   let dataList={
          //     "notificationFlag": "",
          //     "assetId": id,
          //     "updatedAttributes": [],
          //     "addedToFavoritesOn": currentDate,
          //     "notificationCheckedOn": ""
          // }
          //   assetidList.push(dataList);
            assetidList.push(id);
            props.updateFavAssetsList(assetidList);
          }
          break;
        default:
          break;
      }
    } else {
      let currentDate = new Date().toJSON().slice(0, 10);
      event.currentTarget.setAttribute("data-favset", "false");
      event.currentTarget.firstElementChild.classList.remove("bi-bookmark-fill");
      event.currentTarget.firstElementChild.classList.add("bi-bookmark");
      switch (type) {
        case "product":
          if (productidList !== undefined) {
            let dataList={
              "notificationFlag": false,
              "productId": id,
              "updatedAttributes": [],
              "addedToFavoritesOn": currentDate,
              "notificationCheckedOn": currentDate
          }
          //productidList = productidList.filter(productId => productId !== id);

            productidList = productidList.filter(productId => productId.productId !== dataList.productId  );
             // console.log("productidList",productidList, dataList.productId )

              // if(productidList?.[0].productId  !== dataList?.productId ){
              //   console.log("productidList",productidList?.[0].productId, dataList.productId )
              //   productidList.push(dataList);
              // }
              //productidList.push(dataList);
              //console.log("productidList123",productidList?.[0].productId, dataList.productId )

          
            props.updateFavProductsList(productidList);
          }
          break;
        case "asset":
          if (assetidList !== undefined) {
          //   let dataList={
          //     "notificationFlag": "",
          //     "assetId": id,
          //     "updatedAttributes": [],
          //     "addedToFavoritesOn":currentDate,
          //     "notificationCheckedOn": ""
          // }
          // assetidList = assetidList.filter(assetId => assetId.assetId !== dataList.assetId);
          assetidList = assetidList.filter(assetId => assetId !== id);
          props.updateFavAssetsList(assetidList);
          }
          break;
        default:
          break;
      }
    }

    if (type === "product") {
      axios({
        method: "post",
        url: `${config.favApi}dc/userdata/${userId}/favorites`,
        data: {
          favorites: {
            products: productidList
          }
        },
        headers: {
          "X-Country-Code": `${getCountryCode()}`
        }
      });
    }
    if (type === "asset") {
      axios({
        method: "post",
        url: `${config.favApi}dc/userdata/${userId}/favorites`,
        data: {
          favorites: {
            assets: assetidList
          }
        },
        headers: {
          "X-Country-Code": `${getCountryCode()}`
        }
      });
    }
    window.dataLayer.push({
      event: "gaGenericEvent",
      eventCategory: "Mark Favorites(local)",
      eventAction: title,
      eventLabel: type,
      eventValue: window.location.href,
      environment: ga_env
    });
  };

  const favouriteButton = item => {
    let favSet=false;
    let favProducts = props.favProductsList;
    let favAssets = props.favAssetsList;
    let filteredProductList;
    //let filteredAssettList;
    let itemTitle;
    switch (item.type) {
      case "product":
        if (favProducts !== undefined) {
          filteredProductList= favProducts.map((prodId)=>prodId.productId)
          favSet = filteredProductList.includes(normalValueShow(item,"id",countryLocale)) ? true : false;
        }
        itemTitle =  normalValueShow(item,"ptitle",countryLocale);
        break;
      case "asset":
        if (favAssets !== undefined) {
          // filteredAssettList= favAssets.map((AssetId)=>AssetId.assetId)
          // favSet = filteredAssettList.includes(item.id) ? true : false;
          favSet = favAssets.includes(normalValueShow(item,"id",countryLocale)) ? true : false;
        }
        itemTitle = normalValueShow(item,"title",countryLocale);
        break;
      default:
        break;
    }
    let icon = favSet === true ? "bi-bookmark-fill" : "bi-bookmark";
    return (
      <div
        className="fav-icon"
        data-type={normalValueShow(item,"type",countryLocale)}
        data-id={ normalValueShow(item,"id",countryLocale)}
        data-favset={favSet}
        data-title={itemTitle}
        onClick={e => addFavorites(e)}
      >
        {console.log("permission",RoleSettings, "view", viewPermissionData)}
      {(RoleSettings &&  RoleSettings['Mark favourites']) || (viewPermissionData && viewPermissionData['Mark favourites'])=== true?  <i className={`bi ${icon}`}></i>: ""}
      </div>
    );
  };
  const addToCart = (event, item) => {
    event.preventDefault();

    let cartSet = event.currentTarget.getAttribute("data-cartSet");
    const type = event.currentTarget.getAttribute("data-Carttype");
    const id = event.currentTarget.getAttribute("data-Cartid");
    const title = event.currentTarget.getAttribute("data-Carttitle");
    //const userId = localStorage.token;
    const userId= localStorage.email
    let productidList = props.favProductsList;
    let assetidList = props.CartAssetsList;
    if (cartSet === "false") {
      event.currentTarget.setAttribute("data-cartSet", "true");
      event.currentTarget.firstElementChild.classList.remove("bi-cart-plus");
      event.currentTarget.firstElementChild.classList.add("bi-cart-check-fill");
      switch (type) {
        case "product":
          if (assetidList !== undefined) {
          //   let dataList={
          //     "notificationFlag": false,
          //     "productId": id,
          //     "updatedAttributes": [],
          //     "addedToFavoritesOn": currentDate,
          //     "notificationCheckedOn": currentDate
          // }
         // console.log("productidList00",productidList, dataList.productId )
         assetidList.push(id);
            props.updateCartAssetsList(assetidList);
          }
          break;
        case "asset":
          if (assetidList !== undefined) {
          //   let dataList={
          //     "notificationFlag": "",
          //     "assetId": id,
          //     "updatedAttributes": [],
          //     "addedToFavoritesOn": currentDate,
          //     "notificationCheckedOn": ""
          // }
          //   assetidList.push(dataList);
            assetidList.push(id);
            props.updateCartAssetsList(assetidList);
  
          }
          break;
        default:
          break;
      }
    } else {
     // let currentDate = new Date().toJSON().slice(0, 10);
      event.currentTarget.setAttribute("data-cartSet", "false");
      event.currentTarget.firstElementChild.classList.remove("bi-cart-check-fill");
      event.currentTarget.firstElementChild.classList.add("bi-cart-plus");
      switch (type) {
        case "product":
          if (productidList !== undefined) {
            //console.log("cartSet2", cartSet)
          //   let dataList={
          //     "notificationFlag": false,
          //     "productId": id,
          //     "updatedAttributes": [],
          //     "addedToFavoritesOn": currentDate,
          //     "notificationCheckedOn": currentDate
          // }
          // //productidList = productidList.filter(productId => productId !== id);

          //   productidList = productidList.filter(productId => productId.productId !== dataList.productId  );
             // console.log("productidList",productidList, dataList.productId )

              // if(productidList?.[0].productId  !== dataList?.productId ){
              //   console.log("productidList",productidList?.[0].productId, dataList.productId )
              //   productidList.push(dataList);
              // }
              //productidList.push(dataList);
              //console.log("productidList123",productidList?.[0].productId, dataList.productId )

          
              assetidList = assetidList.filter(assetId => assetId !== id);
              props.updateCartAssetsList(assetidList);
          }
          break;
        case "asset":
          if (assetidList !== undefined) {
          //   let dataList={
          //     "notificationFlag": "",
          //     "assetId": id,
          //     "updatedAttributes": [],
          //     "addedToFavoritesOn":currentDate,
          //     "notificationCheckedOn": ""
          // }
          // assetidList = assetidList.filter(assetId => assetId.assetId !== dataList.assetId);
          assetidList = assetidList.filter(assetId => assetId !== id);
          props.updateCartAssetsList(assetidList);
          }
          break;
        default:
          break;
      }
    }
    let flag= false
    if (type === "product"  && cartSet=== "false") {
      axios({
        method: "post",
        url: `${config.favApi}dc/assets/addAssetsToCart/${userId}`,
        data: {         
          country: item.country_code,
          assetId: normalValueShow(item,"id",countryLocale),
          assetName: normalValueShow(item,"title",countryLocale),
          brand:  normalValueShow(item,"brand",countryLocale),
          type:normalValueShow(item,"type",countryLocale),
          },
        headers: {
          "X-Country-Code": `${getCountryCode()}`
        }
      })
      .then(async() => {
        
        flag = true;
        await getCartList(userId);
      })
      .catch((error) => {
        // Handle error if needed
        console.error("Error in Axios request", error);
      });
    }
    if (type === "product" && cartSet=== "true") {
      axios({
        method: "post",
        url:  `${config.favApi}dc/assets/deleteAssetsFromCart/${userId}`,
        data: {         
          country: item.country_code,
          assetId: normalValueShow(item,"id",countryLocale),
          assetName: normalValueShow(item,"title",countryLocale),
          brand:  normalValueShow(item,"brand",countryLocale),
          type:normalValueShow(item,"type",countryLocale),
        },
        headers: {
          "X-Country-Code": `${getCountryCode()}`
        }
      })
      .then(async() => {
        
        flag = true;
        await getCartList(userId);
      })
      .catch((error) => {
        // Handle error if needed
        console.error("Error in Axios request", error);
      });
    }
    if (type === "asset" && cartSet=== "false") {
      axios({
        method: "post",
        url:  `${config.favApi}dc/assets/addAssetsToCart/${userId}`,
        data: {         
          country: item.country_code,
          assetId: normalValueShow(item,"id",countryLocale),
          assetName: normalValueShow(item,"title",countryLocale),
          brand:  normalValueShow(item,"brand",countryLocale),
          type:normalValueShow(item,"type",countryLocale),
        },
        headers: {
          "X-Country-Code": `${getCountryCode()}`
        }
      })
      .then(async() => {
        
        flag = true;
        await getCartList(userId);
      })
      .catch((error) => {
        // Handle error if needed
        console.error("Error in Axios request", error);
      });
    }
    if (type === "asset" && cartSet=== "true") {
      axios({
        method: "post",
        url:  `${config.favApi}dc/assets/deleteAssetsFromCart/${userId}`,
        data: {         
        country: item.country_code,
        assetId: normalValueShow(item,"id",countryLocale),
        assetName: normalValueShow(item,"title",countryLocale),
        brand:  normalValueShow(item,"brand",countryLocale),
        type:normalValueShow(item,"type",countryLocale),
        },
        headers: {
          "X-Country-Code": `${getCountryCode()}`
        }
      })
      .then(async() => {
        
        flag = true;
        await getCartList(userId);
      })
      .catch((error) => {
        // Handle error if needed
        console.error("Error in Axios request", error);
      });
    }
    window.dataLayer.push({
      event: "gaGenericEvent",
      eventCategory: "Mark Favorites(local)",
      eventAction: title,
      eventLabel: type,
      eventValue: window.location.href,
      environment: ga_env
    });
    flag && getCartList(userId)
  };
  
  

  const assetCartButton = item => {
    let cartSet=false;

   // let favProducts = props.favProductsList;
    let favAssets = props.CartAssetsList.map(asset => asset?.assetId);
   // let filteredProductList;
    //let filteredAssettList;
    let itemTitle;
    switch (item.type) {
      case "product":
        if (favAssets !== undefined) {
          //filteredProductList= favProducts.map((prodId)=>prodId.productId)
          cartSet = favAssets.includes(normalValueShow(item,"id",countryLocale)) ? true : false;
        }
        itemTitle = normalValueShow(item,"ptitle",countryLocale);
        break;
      case "asset":
        if (favAssets !== undefined) {
          // filteredAssettList= favAssets.map((AssetId)=>AssetId.assetId)
          // cartSet = filteredAssettList.includes(item.id) ? true : false;
          cartSet = favAssets.includes(normalValueShow(item,"id",countryLocale)) ? true : false;
        }
        itemTitle = normalValueShow(item,"title",countryLocale);
        break;
      default:
        break;
    }
    let icon = cartSet === true ? "bi-cart-check-fill" : "bi-cart-plus";
    return (
      <div style={{display: "flex",alignItems: "center"}} onClick={evt => {evt.preventDefault()}}>
      
      <div
        className="cart-icon"
        data-Carttype={normalValueShow(item,"type",countryLocale)}
        data-Cartid={normalValueShow(item,"id",countryLocale)}
        data-cartSet={cartSet}
        data-Carttitle={itemTitle}
        onClick={e => addToCart(e, item)}
        style={{fontSize: "22px",marginRight: "6px"}}
      >
      {/* {RoleSettings &&  RoleSettings['Mark favourites'] === true?   */}
      <i className={`bi ${icon}`}></i>
      {/* : ""} */}
      </div>
      {/* <Link className="card-block"  to={handleClickOnCard(item)}> */}
      <div style={{fontSize:"12px", color:"#00617f",textDecoration:"none"}}>{normalValueShow(item,"fileTitle",countryLocale)}</div>
      {/* </Link> */}
      </div>
    );
  };
  const dispCardAssetBrand = brand => {
    let brandString = "N/A";
    if (brand !== undefined && brand.length > 0) {
      brandString = brand.map(item => `${item}`).join("/");
      return brandString;
    }
    return brandString;
  };

  const handleClickOnCard = item => {
    let { url } = { ...match };
    url =
      url.charAt(url.length - 1) === "/"
        ? url.substring(0, url.length - 1) + "" + url.substring(url.length)
        : url;
    let paramObj;
    switch (item.type) {
      case "product":
        paramObj = {
          pathname: `${url}/product-detail-page/${normalValueShow(item,"id",countryLocale)}`,
          state: {
            queryParams: { brand: [normalValueShow(item,"brand",countryLocale).trim()] },
            id: normalValueShow(item,"id",countryLocale),
            title: normalValueShow(item,"ptitle",countryLocale)
          }
        };
        break;
      case "asset":
        let flag;
        flag = url.includes("/asset-detail-page")
          ? 0
          : url.includes("/product-detail-page")
            ? 1
            : null;
        switch (flag) {
          case 0:
            paramObj = {
              pathname: `${url.substring(0, url.lastIndexOf("/"))}/${normalValueShow(item,"id",countryLocale)}`,
              state: {
                queryParams: { brand: [normalValueShow(item,"brand",countryLocale).trim()] },
                id: normalValueShow(item,"id",countryLocale),
                title: dynamicAssetTitle(item)
              }
            };
            break;
          case 1:
            paramObj = {
              pathname: `${url
                .substring(0, url.lastIndexOf("/"))
                .replace("product-detail-page", "asset-detail-page")}/${normalValueShow(item,"id",countryLocale)
                }`,
              state: {
                queryParams: { brand: [normalValueShow(item,"brand",countryLocale).trim()] },
                id: normalValueShow(item,"id",countryLocale),
                title: dynamicAssetTitle(item)
              }
            };
            break;
          default:
            paramObj = {
              pathname: `${url}/asset-detail-page/${normalValueShow(item,"id",countryLocale)}`,
              state: {
                queryParams: { brand: [normalValueShow(item,"brand",countryLocale).trim()] },
                id: normalValueShow(item,"id",countryLocale),
                title: dynamicAssetTitle(item)
              }
            };
            break;
        }
        break;
      default:
        break;
    }
    return paramObj;
  };

  const fileExtension = asset => {
    const file_type =
      Object.keys(asset).includes("file_type") && asset.file_type !== ""
        ? asset.file_type
        : "N/A";
    return file_type;
  };

  const dynamicAssetTitle = item => {
    let asset_title = [];
    if (Object.keys(item).includes("upc")) {
      let ptitle = getProductTitle(item.upc, productsList, countryLocale);
      ptitle !== undefined
        ? asset_title.push(ptitle)
        : asset_title.push(item.title);

      if (_.has(item, "asset_type")) {
        item.asset_type[0].dcAssetType? asset_title.push(t(`Asset Type Card.${item.asset_type[0].dcAssetType}`)):asset_title.push( t(`Asset Type Card.Others`)) ;
      }
    } else {
      if (_.has(item, "brand")) {
        let assetBrand = item.brand;
        if (_.has(item, "sub_brand")) {
          asset_title.push(item.sub_brand[0]);
        } else {
          asset_title.push(assetBrand);
        }
      }
      if (_.has(item, "asset_type")) {
        item.asset_type[0].dcAssetType? asset_title.push(t(`Asset Type Card.${item.asset_type[0].dcAssetType}`)):asset_title.push( t(`Asset Type Card.Others`)) ;
      }
      if (_.has(item, "customer_explanation")) {
        asset_title.push(item.customer_explanation);
      }
    }
    asset_title = asset_title.join(" ");
    return asset_title;
  };

  const disclaimerCheckbox = () => {
    return (
      <div className="disclaimer-text-container">
        <div className="disclaimer-checkbox">
          <input
            type="checkbox"
            onClick={() => {
              setEnableDownload(!enableDownload);
              setBackDrop(!backDropOpen);
            }}
          ></input>
        </div>
        <div className="disclaimer-text">
          {t("Miscellaneous.license-expiry-text")} {normalValueShow(item,"license_expiry_date",countryLocale)}

        </div>
      </div>
    );
  };

  const displayExpiryMessage = days => {
    if (days != null) {
      return (
        <div className="asset-expiry">
          {/* <span style={{color:"#ff3162"}}>{`${t("Miscellaneous.card_expiry_message")}:`}</span> */}
          <span >{`${t('Miscellaneous.card_expiry_message',{exDate:remaining_days})}`}</span>
          {/* <span>{remaining_days}</span> */}
        </div>
      );
    }
  };

  const grayOut =(evt, item, hide)=>{
    evt?.preventDefault();
    evt?.stopPropagation();
    setGrayColorOut(true)
   //grayColorOut= true
   hideShowFunction(item, hide); 
  }
  const closeGrayOut =(evt, item, show)=>{

    evt?.preventDefault();
    evt?.stopPropagation();
    setGrayColorOut(false)
    // grayColorOut= false
    hideShowFunction(item, show);  
  }
  
  const normalValueShow =(item, particularAttribute, countryLocale)=>{
 
    return(
      typeof item?.[particularAttribute] === "string"
      ? item?.[particularAttribute]  : countryLocale &&  item?.[particularAttribute]?.[countryLocale] ?
      item?.[particularAttribute]?.[countryLocale]  : item?.[particularAttribute]?.['en-ZZ']
    )
  }


  let presentationComp;
  if (item.type === "product") {

      const ifdownloaded =grayColorOut  || item?.hideShowStatus?.[0]?.currentStatus ==="hide"  ? "card-comp-hide" : "card-comp"
    presentationComp = (
      <React.Fragment>
        <Backdrop className={classes.backdrop} open={backDropOpen}>
          {disclaimerCheckbox()}
        </Backdrop>
        <Link className="card-block" to={handleClickOnCard(item)}>
          <div key={index} className={ifdownloaded}>
            <div className="top-section">
              <div className="productmarker">{t("Product Detail Page.cardmarker")}</div>
              <div className="top-section-inside">
              <div className="productCartButton">{productCartModal(item)}</div>
                <div className="favouriteButton">{favouriteButton(item)}</div>
                {( RoleSettings &&  RoleSettings['Download Content'] )|| (viewPermissionData && viewPermissionData['Download Content'])=== true?
                <div className="downloadButton">{downloadButton(item)}</div>: ""}
                {(gigyaRole=== "Global Admin" || gigyaRole === "Country Admin") ? (            
                <div>
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-haspopup="true"
                      onClose={handleClose}
                      onClick={handleClick}
                      className="three-dots"
                    >
                      <MoreVert />
                    </IconButton>
                    <Menu
                      id="long-menu"
                      MenuListProps={{
                      'aria-labelledby': 'long-button',
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      PaperProps={{
                        style: {
                          maxHeight: 150,
                          width: '96px',
                        },
                      }}
                    >
                      <MenuItem onClick={(evt)=>grayOut(evt, item, "hide")}   onClose={handleClose} style = {{padding: "0px 14px"}}> 
                      {t("Miscellaneous.hide")}
                      </MenuItem>

                      <MenuItem onClick={(evt)=>closeGrayOut(evt, item, "show")}  onClose={handleClose} style = {{padding: "6px 14px 0px"}}>   
                      {t("Miscellaneous.show")}
                      </MenuItem>
                    </Menu>
                </div>)
                :""}
              </div></div>
            <div
              className="card-details"
              onClick={() => handleClickOnCard(item)}
            >
              {quickDownloadModal ?
                <div className="quickdownloadmodal">
                  <div className='downloadmodalheader'>Download Options</div>
                  <div className='download-options'>
                    {quickDownloadOptions (assets, item)}
                  </div>
                  <div classname="downloadmodalfooter">
                    <span className='downloadcancel' onClick={quickdownloadCancelHandler}> Cancel </span>
                    <span className='quickdownloaddone' onClick={(e)=>downloadHandler(e)}> Download </span>
                  </div>
                </div>
                : cartModal ? 
                <div className="quickdownloadmodal">
                <div className='downloadmodalheader'>Add to Cart</div>
                <div className='download-options'>
                  {quickCartOptions(assets, item)}
                </div>
                <div classname="downloadmodalfooter">
                  <span className='downloadcancel' onClick={cartHandler}> Cancel </span>
                 
                </div>
              </div>
                :<div className="thumbnail">{placeholder(item)}</div>}
              <div className="product-details">
                <span className="category">
                  <LinesEllipsis
                    text={normalValueShow(item,"ptitle",countryLocale)}
                    maxLine ="3"
                    ellipsis="..."
                    trimRight
                    basedOn="letters"
                  />
                </span>
                <div className="productbrand">
                  <span className="labelproductbrand">
                    {t("Miscellaneous.Brand")}
                    {": "}
                  </span>
                  <span className="brand">{normalValueShow(item,"brand",countryLocale)}</span>
                </div>
                {countrySettings.countryCode === "NL" ? (
                  <div className="product-ean-number">
                    <span className="labeleannumber">
                      {t("Product Detail Page.ean-number")}
                      {": "}
                    </span>
                    <span className="ean-number">{normalValueShow(item,"ean",countryLocale)}</span>
                  </div>
                ) : (
                  <div className="product-pzn-number">
                    <span className="labelpznnumber">
                      {t("Product Detail Page.pzn")}
                      {": "}
                    </span>
                    <span className="pzn-number">{normalValueShow(item,"pharmacentralnum",countryLocale) ? normalValueShow(item,"pharmacentralnum",countryLocale) : t("Miscellaneous.notAvailable")}</span>
                  </div>
                )}
              </div>
            </div>
            {/* <div className="downloadButton">{downloadButton(item)}</div> */}
            <div className="bottom-div">
              <div className="bottom-section" style={{flexDirection: "row-reverse"}}>

              <span className="more-info" style={{color:"#39789c",marginTop: "3%",
    marginBottom: "3%"}} > {t("Miscellaneous.moreInformation")}</span>

              </div>
            </div>
          </div>
        </Link>
      </React.Fragment>
    );
  }
  if (item.type === "asset") {
    const ifdownloaded =grayColorOut  || item?.hideShowStatus?.[0]?.currentStatus ==="hide"  ? "card-comp-hide" : "card-comp"
    const queryParams = props.location.search;
    let queryObject = queryParams && qs.parse(queryParams, { ignoreQueryPrefix: true });
   
    let matchingAssetType = item?.asset_type.find(typeObj => 
      queryObject?.assettype?.includes(typeObj.dcAssetType)
  );
    presentationComp = (
      <React.Fragment>
        <Backdrop className={classes.backdrop} open={backDropOpen}>
          {disclaimerCheckbox()}
        </Backdrop>
        <Link className="card-block" to={handleClickOnCard(item)}>
          <div key={index} className={ifdownloaded}>
            <div className="top-section">
              <div className="assetmarker">
                {matchingAssetType ? matchingAssetType && matchingAssetType ? t(`Asset Type Card.${matchingAssetType?.dcAssetType}`) : t('Asset Type Card.Others') :
                item.asset_type[0].dcAssetType ? t(`Asset Type Card.${item.asset_type[0].dcAssetType}`): t(`Asset Type Card.Others`)}
              </div>
              <div className="top-section-inside">
                <div className="assetCartButton">{assetCartButton(item)}</div>
                <div className="favouriteButton">{favouriteButton(item)}</div>
                {( RoleSettings &&  RoleSettings['Download Content'] )|| (viewPermissionData && viewPermissionData['Download Content'])=== true?
                <div className="downloadButton">{assetDownloadButton(item)}</div>:""}
                 {(gigyaRole=== "Global Admin" || gigyaRole === "Country Admin") ? (                
                <div>
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-haspopup="true"
                      onClose={handleClose}
                      onClick={handleClick}
                      className="three-dots"
                    >
                      <MoreVert />
                    </IconButton>
                    <Menu
                      id="long-menu"
                      MenuListProps={{
                      'aria-labelledby': 'long-button',
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      PaperProps={{
                        style: {
                          maxHeight: 150,
                          width: '96px',
                        },
                      }}
                    >
                      <MenuItem onClick={(evt)=>grayOut(evt, item, "hide")}   onClose={handleClose} style = {{padding: "0px 14px"}}> 
                      {t("Miscellaneous.hide")}
                      </MenuItem>

                      <MenuItem onClick={(evt)=>closeGrayOut(evt, item, "show")}  onClose={handleClose} style = {{padding: "6px 14px 0px"}}>   
                      {t("Miscellaneous.show")}
                      </MenuItem>
                    </Menu>
                </div>)
                :""}
              </div>
            </div>
            <div
              className="card-details"
              onClick={() => handleClickOnCard(item)}
            >
              <div className="thumbnail">{placeholder(item)}</div>
              <div className="asset-details">
                <div className="assetname">
                  <span className="name">
                    <LinesEllipsis
                      text={dynamicAssetTitle(item)}
                      maxLine ="3"
                      ellipsis="..."
                      trimRight
                      basedOn="letters"
                    />
                  </span>
                </div>
                <div className="assetbrand">
                  <span className="label">
                    {t("Miscellaneous.Brand")}
                    {": "}
                  </span>
                  <span className="brand">
                    {dispCardAssetBrand(item.sub_brand)}
                  </span>
                </div>
                <div className="assetsize">
                  <span className="label">
                    {t("Asset Detail Page.size")}
                    {": "}
                  </span>
                  <span className="file-size">{item.file_size}</span>
                </div>

                <div className="assetlastupdated">
                  <span className="label">
                    {t("Asset Detail Page.lastupdate")}
                    {": "}
                  </span>
                  <span className="lastupdated">{modified_date}</span>
                </div>

                {displayExpiryMessage(remaining_days)}
              </div>
            </div>
            <div className="bottom-div">
              <div className="bottom-section">
                <div className="file-type">
                  <span>{fileExtension(item)}</span>
                </div>
                <span className="more-info" style={{color:"#39789c"}} >{t("Miscellaneous.moreInformation")}</span>
                {/* <div className="downloadButton">{downloadButton(item)}</div> */}
              </div>
            </div>
          </div>
        </Link>
      </React.Fragment>
    );
  }
  return presentationComp;
};
const CardComponent = withTranslation("common")(Card);
const mapStateToProps = state => {
  return {
    productsList: state.product.productsList,
    renderProductList: state.product.renderProductList,
    assetsList: state.asset.assetsList,
    renderAssetList: state.asset.renderAssetList,
    filterRem: state.filter.filterRem,
    toggleData: state.filter.toggleData,
    favAssetsList: state.asset.favAssetsList,
    favProductsList: state.product.favProductsList,
    countrySettings: state.country.countryData,
    RoleSettings: state.role.countryData,
    CartAssetsList: state.asset.CartAssetsList,
    countryLocale: state.locale.countryLocale
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateFavProductsList: ownProps =>
      dispatch(updateFavProductsList(ownProps)),
    updateFavAssetsList: ownProps => dispatch(updateFavAssetsList(ownProps)),
    updateCartAssetsList: ownProps => dispatch(updateCartAssetsList(ownProps)),
    updateSetRenderProductList: ownProps =>
    dispatch(updateSetRenderProductList(ownProps)),
    updateSetRenderAssetList: ownProps =>
    dispatch(updateSetRenderAssetList(ownProps)),
    updateSetRenderCartAssetList: ownProps =>
    dispatch(updateSetRenderCartAssetList(ownProps)),
  };
};
export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CardComponent);