import React, { useEffect, useCallback } from "react";
import { connect } from "react-redux";
import "./passwordResetPage.scss";

const PasswordReset = props => {
  const { title, countrySettings } = { ...props };
  const gigya = window.gigya;
  const gigyaPasswordReset = useCallback(() => {
    gigya.accounts.showScreenSet({
      screenSet: `${countrySettings?.gigya?.screenSet}`,
      startScreen: "bayer-reset-password-screen",
      containerID: "passwordResetScreen",
      lang: "de",
      onHide: handleResult
    });
  }, [countrySettings, gigya.accounts]);
  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    gigyaPasswordReset();
  }, [countrySettings, gigyaPasswordReset]);

  function handleResult(eventObj) {
    if (eventObj.reason === "missing" || eventObj.reason === "finished") {
      window.location.href = "/";
    }
  }
  return (
    <div className="passwordResetContainer">
      <div id="passwordResetScreen" className="passwordResetScreen"></div>
    </div>
  );
};

const mapStateToProps = state => ({
  countrySettings: state.country.countryData
});
export default connect(mapStateToProps)(PasswordReset);
