import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
//import downloadCenterLogo from "../../sass/assets/downloadCenterLogo.png";
import "./footer.scss";

const Footer = (props) => {
  const { t } = useTranslation("common");
  const { countrySettings } = { ...props };
  const [lang, setLang] = useState("");

  const langTranslation=(userCountry)=>{
    setLang(userCountry?.locale)
  //   if(userCountry==="de"){
  //     setLang("de-DE")
  // }
  // else if(userCountry==="fr"){
  //   setLang("fr-BE")
  // }
  // else if(userCountry==="nl"){
  //   setLang("nl-NL")
  // }
  // else if(userCountry==="us"){
  //   setLang("en-US")
  // }
  // else if(userCountry==="be"){
  //   setLang("nl-NL")
  // }
  // else{
  //   setLang("en-US")
  // }
}

function getTranslation(lang, label) {
const translation = countrySettings?.accountRequestPageTranslations?.find(item =>
    item?.location === "Account Request Page" &&
    item?.labels === label &&
    item[lang]
);

if (translation) {
    return translation[lang];
} else {
    // If translation for specific country code not found, fall back to default value
    const defaultTranslation = countrySettings?.accountRequestPageTranslations?.find(item =>
        item?.location === "Account Request Page" &&
        item?.labels === label &&
        item?.defaultValue
    );

    if (defaultTranslation) {
        return defaultTranslation.defaultValue;
    } else {
        // If no default value is provided, return an empty string or handle it as needed
        return "";
    }
}
}
useEffect(() => {
 
 
  langTranslation(countrySettings)
  
  //eslint-disable-next-line react-hooks/exhaustive-deps
}, [countrySettings]);

console.log("lang",getTranslation(lang, "conditionsOfUse"),t("Account Request Page.conditionsOfUse"))
  return (
    <div className="footer-container">
      {/* <div className="projectLogo">
        <img
          className="downloadCenterLogo"
          src={downloadCenterLogo}
          alt="logo"
        />
      </div> */}
      <div className="overlay">Copyright 2022 Bayer AG</div>
      {/* <div className="footer-links-main"> */}
        <div className="footer-links">
          {/* <span className="link-border"></span> */}
          <div className="link-container">
            <span className="link">
              <Link to="/conditions">{localStorage.getItem("LoggedIn") === "false" ? getTranslation(lang, "conditionsOfUse"): t("Account Request Page.conditionsOfUse")}</Link>
            </span>
            <span className="link">
              <Link to="/privacy">{localStorage.getItem("LoggedIn") === "false" ? getTranslation(lang, "privacy"): t("Account Request Page.privacy")}</Link>
            </span>
            <span className="link">
              <Link to="/imprint">{localStorage.getItem("LoggedIn") === "false" ? getTranslation(lang, "imprint"): t("Account Request Page.imprint")}</Link>
            </span>
          </div>
        </div>
      {/* </div> */}
    </div>
  );
};


const mapStateToProps = state => ({
  countrySettings: state.country.countryData
});
export default connect(mapStateToProps)(Footer);


