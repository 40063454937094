import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link } from "react-router-dom";
const Breadcrumbs = ({ crumbs }) => {
  // Don't render a single breadcrumb.
  if (crumbs.length <= 1) {
    return null;
  }
  return (
    <Breadcrumb>
      {/* Link back to any previous steps of the breadcrumb. */}
      {crumbs.map(({ name, path }, key) =>
        key + 1 === crumbs.length ? (
          <Breadcrumb.Item key={key} active>
            {name}
          </Breadcrumb.Item>
        ) : (
          <Breadcrumb.Item key={key} >
            <Link to={path}>{name}</Link>         
          </Breadcrumb.Item>
        )
      )}
    </Breadcrumb>
  );
};
export default Breadcrumbs;
