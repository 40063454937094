import {
  FETCH_PRODUCTS_REQUEST,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_ERROR,
  UPDATE_RENDER_PRODUCT_LIST,
  FETCH_FAVPRODUCTS_SUCCESS,
  FETCH_FAVPRODUCTS_ERROR,
  UPDATE_FAVPRODUCTS_LIST
} from "./productTypes";

const initialState = {
  loading: true,
  productsList: [],
  renderProductList: [],
  favProductsList: [],
  error: ""
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCTS_REQUEST:
      return { ...state, loading: true };
    case FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        productsList: action.payload,
        error: ""
      };
    case FETCH_PRODUCTS_ERROR:
      return {
        loading: false,
        productsList: [],
        renderProductList: [],
        error: action.payload
      };
    case UPDATE_RENDER_PRODUCT_LIST:
      return {
        ...state,
        renderProductList: action.payload
      };
    case FETCH_FAVPRODUCTS_SUCCESS:
      return {
        ...state,
        favProductsList: action.payload
      };
    case FETCH_FAVPRODUCTS_ERROR:
      return {
        ...state,
        favProductsList: action.payload
      };
    case UPDATE_FAVPRODUCTS_LIST:
      return {
        ...state,
        favProductsList: action.payload
      };
    default:
      return state;
  }
};
export default productReducer;
