import {
  FETCH_COUNTRY_CODE_REQUEST,
  FETCH_COUNTRY_CODE_SUCCESS,
  FETCH_COUNTRY_CODE_ERROR,
  UPDATE_COUNTRY_CODE
} from "./countryCodeTypes";

const initialState = {
  loading: false,
  countryCode: {},
  error: ""
};

const countryCodeReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COUNTRY_CODE_REQUEST:
      return { ...state, loading: true };
    case FETCH_COUNTRY_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        countryCode: action.payload,
        error: ""
      };
    case FETCH_COUNTRY_CODE_ERROR:
      return {
        ...state,
        loading: false,
        countryCode: {},
        error: action.payload
      };
    case UPDATE_COUNTRY_CODE:
      return {
        ...state,
        countryCode: action.payload,
      };
    default:
        return state;
  }
};

export default countryCodeReducer;
