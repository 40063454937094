import React, { useEffect } from "react";
import SearchResult from "../../components/SearchResult/searchResult";

const SearchPage = props => {
  const { title } = { ...props };
  useEffect(() => {
    document.title = title;
  }, [title]);
  return <SearchResult />;
};

export default SearchPage;
