import { combineReducers } from "redux";
import productReducer from "./product/productReducer";
import assetReducer from "./asset/assetReducer";
import filterReducer from "./filter/filterReducer";
import countryReducer from "./country/countryReducer";
import roleReducer from './role/roleReducer'
import localeReducer from './locale/localeReducer'
import countryCodeReducer from './countryCode/countryCodeReducer'

const rootReducer = combineReducers({
  product: productReducer,
  asset: assetReducer,
  filter: filterReducer,
  country: countryReducer,
  role:roleReducer,
  locale:localeReducer,
  countryCode: countryCodeReducer,
});
export default rootReducer;
