import React,{ useEffect, useState, useContext }  from "react";
import config from "../../config.js";
import './ViewArchive.scss'
import {getCountryCode} from "../../util/Helper.js";
import axios from "axios";
import { Link} from 'react-router-dom';
import AuthContext from "../../components/Auth/Auth-context";
import FileSaver from "file-saver";
import { useTranslation } from "react-i18next";
import { forwardRef } from 'react';
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { makeStyles} from '@mui/styles';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import {  useNavigate} from "../../hooks";
// eslint-disable-next-line
let downloadArray=[]
let downloadedAssets=[]
let downloadArrayData =[]
let implementationArray =[]
const  ViewArchive=()=> {
  const { navigate } = useNavigate();
    const { t } = useTranslation("common");
    const [archiveData, setArchiveData] = useState([]);
    const [needToUpdate, setNeedToUpdate] = useState(true);
    const [downloaded, setDownloaded] = useState([]);
    const countryCode = getCountryCode();
    const [filter, setFilter] = useState(false)
    const [iconChange, setIconChange] = useState(true)

    const filterHandler = () => {
        setFilter(!filter)
        setIconChange(!iconChange)
      } 
    const AuthCtx = useContext(AuthContext);
    const userId = AuthCtx.giveToken();
    const emailId = localStorage.email
    const fetchingArchivedData = async () => {
  
        const response = await axios({
          method: "get",
          url: `${config.baseApi}dc/exports/getexports?archived=true`,
       
          headers: {
            "X-Country-Code": `${getCountryCode()}`,
          }
        });
       setArchiveData(response)

        setDownloaded(userId)
        return response;
    }

    // const setArchiveData = (data) => {
    //     archiveData = data
    // }
    const  ImplementationCheckBox =(tData, e) =>{
        // tData.currentImplementationStatus = e.target.checked
        const data = Object.assign({}, archiveData)
        data.data.message.forEach((item, index) => {
            if(item.SK === tData.SK) {
                data.data.message[index].currentImplementationStatus = e.target.checked
            }
    })
        setArchiveData(data)

        if(!e.target.checked) {
            let filteringdata ={
                "exportId": tData.SK,
                "gigyaId": userId,
                "implemented": false}
                implementationArray.push(filteringdata)
        } else {
            implementationArray = implementationArray.filter(item => {
                return item.exportId !== tData.SK
            })
        }
        
        
    }
    const saveButton = async()=>{
        if (implementationArray.length !== 0) {
            const response = await axios({
                method: "post",
                url: `${config.baseApi}dc/exports/implementstatustrack`,
                data: implementationArray,
              });
              implementationArray = [];
              //window.location.reload(true)
              setNeedToUpdate(!needToUpdate)
              console.log(response) 
        } 
      setTimeout(() => {
        navigate("/my-exports")
      }, 1000);
     
       // window.location.reload(true)
    }
    useEffect(() => {
        fetchingArchivedData();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const [downloadedAssetsNew, updateDownloadedAssetsNew] = useState([]);
    const labelColumns = [
        { title: "Asset Name", field: "asssetName" },
        { title: t("My Exports.file_type"),field: "fileType"},
        { title: t("My Exports.upload_date"), field: "uploadDate"},
        { title: t("My Exports.file_size"), field: "fileSize"},
        { title: t("My Exports.expiration_date"), field: "expirationDate"},
        { title: "Download Status",
        field: "downloadStatus",
        customSort: (a, b) => a.downloadStatus.props.children.props.children.length-b.downloadStatus.props.children.props.children.length,
        customFilterAndSearch: (term, rowData) =>rowData.downloadStatus.props.children.props.children?.[1].toLowerCase().includes(term.toLowerCase()) ||
         rowData.downloadStatus.props.children.props.children.includes(term.charAt(0).toUpperCase())  },
        { title: t("My Exports.implemented"), field: "implemented", filtering: false },
      ]
      
      const labelData = archiveData.data?.message.map((tData, index) => {
        const uploadDateFormat = tData.createdDate;
        const uploadDate = new Date(uploadDateFormat);
        const currentUploadDate = uploadDate.getDate() + "-" + (uploadDate.getMonth()+1) + "-" + uploadDate.getFullYear();
        const expirationDate = tData.updatedDate;
        const expireDate = new Date(expirationDate);
        const expirationDateInKB = expireDate.getDate() + "-" + (expireDate.getMonth()+1) + "-" + expireDate.getFullYear();
        const FileSizeInMB = ( tData.fileSize / (1024*1024)).toFixed(3);
        const currentFileSize = FileSizeInMB + " MB"
            return {
                key: index, 
                asssetName:  tData.fileName,
                fileType: tData.fileType.split("application/"), 
                uploadDate: countryCode === "de" ? tData.updatedDate?.slice(0,10): currentUploadDate,
                fileSize: currentFileSize,
                expirationDate: countryCode === "de" ? tData.updatedDate?.slice(0,10): expirationDateInKB,
                downloadStatus:  tData?.downloadedInfo?.map((ele)=>ele.gigyaId).includes(downloaded) && tData?.downloadedInfo?.map((ele)=>ele.downloaded=== true) 
                ?
                    (<i class="bi bi-check-circle-fill" style={{ color: "green", fontSize: "15px" }}><span style={{ color: "black", fontStyle: "normal" }} onClick={() => handleDownload(tData) }> Completed</span></i>) :
                    downloadedAssetsNew.includes(tData.SK)
                    ?
                    (<i class="bi bi-check-circle-fill" style={{ color: "green", fontSize: "15px" }}><span style={{ color: "black", fontStyle: "normal" }} onClick={() => handleDownload(tData) }> Completed</span></i>) :
                    (<i class="bi bi-download" style={{ color: "red", fontSize: "15px" }} onClick={() => handleDownload(tData) }><span style={{ color: "black", fontStyle: "normal" }}> {t("My Exports.download_needed")}</span></i>),
                implemented : <input type="checkbox" checked={tData.currentImplementationStatus} onChange={(e) =>  ImplementationCheckBox(tData,e)}></input>  
            };
      }
      );
      const options = {
        filterType: "dropdown",
        responsive: "scroll",
        print: false,
        download: false,
        checkbox: false,
        selectableRows: false,
        showTitle: false,
        filtering: filter,
      };
      
      
      
      const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
      }
      const useStyles = makeStyles({
        tabs: {
          "& .MuiTabs-indicator": {
           display: "none",
          },
          "& .MuiTab-root.Mui-selected": {
            color: 'white !important',
            background:"#00617f !important"
          },
          "& hover ":{
            background:"#89d329 !important"
          }
        },
        table:{
          "& .MuiTableSortLabel-root":{
            fontSize: 16,
            color: "#00617f",
            fontWeight:600,
            fontFamily: 'Neue Helvetica',
        },
            // "& .MTableToolbar-title-10":{
            //   display:"none"
            // }
      }
      })
      const tableRef = React.createRef();
      const classes = useStyles();
    let parentAssetName = "";
    const handleDownload = async(tData) => {
        
        let fileSrc;
        fileSrc = tData.filePath;
        let diffUrlRetailer
       // console.log("tdata",tData)
        parentAssetName = fileSrc.substring(fileSrc.lastIndexOf("/") + 1, fileSrc.length);
        //FileSaver.saveAs(domain + "/" + tData.filePath, parentAssetName);       
        let tempArr = [...downloadedAssets];
        if (!tempArr.includes(tData.SK)) {
            tempArr.push(tData.SK);
            downloadedAssets = tempArr;
           // console.log("eventhandle", downloadedAssets, downloadArray, tempArr);
        }
        updateDownloadedAssetsNew(downloadedAssets)
        let filteringdata ={
            "exportId": tData.SK,
            "gigyaId": userId,
            "emailId": emailId,
            "downloaded": true
        }
        if(!JSON.stringify(downloadArrayData).includes(filteringdata.exportId)){
        
            downloadArrayData.push(filteringdata)
        }
        const response = await axios({
            method: "post",
            url: `${config.baseApi}dc/exports/downloadstatustrack`,
            data: downloadArrayData,
          });
          for(let i=0; i<response.data.message.length; i++){    
            if(tData.SK === response.data.message[i].exportId){            
            diffUrlRetailer=response.data.message[i].presignedUrl
            FileSaver.saveAs(diffUrlRetailer, parentAssetName); 
            }
        }   
       
    };

  return (
    <div className="exportsrow" style={{padding: "2rem"}}> 

    <div className="col-md-9 col-lg-9 col-xl-12" style={{position:"relative"}}>
    
    <div className="col-md-9 col-lg-9 col-xl-12">
    <h3 style={{
    textAlign: "left",
    paddingLeft: "2%",
    fontWeight: "600"
    }} className="viewArchive-heading float-right mb-2">{t(`My Exports.archive`)}
    <br/>  <h5 style={{
    textAlign: "left",
    paddingBottom: "1%",
    fontWeight: "600"
    }} className="viewArchive-data float-right mb-2">{t("My Exports.activity_date")}</h5> </h3>
    
    <div className="buttonAlign">
      <div className="filterIcon" style={{fontStyle:"italic"}}> 
            {iconChange ?
    (<span style={{cursor:"pointer"}} onClick={filterHandler} >Show Filter<FilterListIcon ></FilterListIcon></span>) :
    (<span style={{cursor:"pointer"}}onClick={filterHandler}>Hide Filter<FilterListOffIcon></FilterListOffIcon></span>)}
                   </div>
    <button style={{
        backgroundColor: "#ff3162",
        border: "none",
        borderRadius: "6px",
        fontFamily: "Neue Helvetica Bold Italic",
        fontSize: "12px"
    }} className="detailed-button-view-archive float-right mb-2"> 
     <Link to="/my-exports" style={{ color: "#ffffff", textDecoration: "auto" }}>
      <i class="bi bi-archive" style={{ fontSize: "15px" }}></i>  
      { "\u00a0\u00a0"}{t(`My Exports.view_exports`)}
      </Link>
      </button>   
         </div>  
        
    </div>
    <div className={classes.table}>
    <MaterialTable
      tableRef={tableRef}
      options={options}
      icons={tableIcons}
      columns={labelColumns}
      data={labelData}
    />
    </div>
          <div style={{marginBottom :"20px"}}>
        <button style={{
            backgroundColor: "#ff3162",
            border: "none",
            borderRadius: "0px",
            fontFamily: "Neue Helvetica Bold Italic",
            fontSize: "12px",
            float: 'right',
            marginRight: "1%",
            padding: "10px",
            marginTop: "7px",
            color: "#ffffff"
        }} 
             onClick={saveButton}>
            {/* <Link to="/my-exports" style={{ color: "#ffffff", textDecoration: "auto" }}> */}
             {"\u00A0\u00A0"}{t(`My Exports.save`)}
             {/* </Link> */}
        </button>
        </div>
    
    
    </div>
    
    </div>
  )
}

export default ViewArchive