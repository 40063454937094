import {
  SET_FILTER_BRANDNAMES,
  SET_FILTER_ASSETTYPES,
  SET_FILTER_YEAR,
  SET_FILTER_FILETYPES,
  SET_FILTER_TOGGLEDATA,
  SET_FILTER_BRAND_PARAM,
  SET_FILTER_ASSETTYPE_PARAM,
  SET_FILTER_REM_PARAM
} from "./filterTypes";

const initialState = {
  loading: true,
  fileTypes: [],
  publicationYear: [],
  brand: [],
  assets: [],
  toggleData: [],
  filterRem: {},
  filterBrand: {},
  filterAsset: {}
};

const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTER_BRANDNAMES:
      return { ...state, brand: action.payload };
    case SET_FILTER_ASSETTYPES:
      return { ...state, assets: action.payload };
    case SET_FILTER_YEAR:
      return { ...state, publicationYear: action.payload };
    case SET_FILTER_FILETYPES:
      return { ...state, fileTypes: action.payload };
    case SET_FILTER_TOGGLEDATA:
      return { ...state, toggleData: action.payload };
    case SET_FILTER_BRAND_PARAM:
      return { ...state, filterBrand: action.payload };
    case SET_FILTER_ASSETTYPE_PARAM:
      return { ...state, filterAsset: action.payload };
    case SET_FILTER_REM_PARAM:
      return { ...state, filterRem: action.payload };
    default:
      return state;
  }
};
export default filterReducer;
