import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import "react-image-gallery/styles/css/image-gallery.css";
import "./PdfPreview.scss";
import ReactDOM from "react-dom";

const options = {
  cMapUrl: "cmaps/",
  cMapPacked: true,
  standardFontDataUrl: "standard_fonts/",
};

export default function PdfPreview({ file }) {
  const [shown, setShown] = useState(false);
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageScale, setPageScale] = useState(1.7);
  const [pageScaleInside, setPageScaleInside] = useState(1);
  function onDocumentLoadSuccess({ numPages }) {
    setTotalPages(numPages);
  }

  function handleZoomIn() {
    if (pageScale < 1.7) {
      setPageScale(pageScale + 0.2);
    }
  }
  function handleZoomInInside() {
    if (pageScaleInside < 2) {
      setPageScaleInside(pageScaleInside + 0.2);
    }
  }
  function handleZoomOut() {
    if (pageScale > 0.3) {
      setPageScale(pageScale - 0.2);
    }
  }
  function handleZoomOutInside() {
    if (pageScaleInside > 0.3) {
      setPageScaleInside(pageScaleInside - 0.2);
    }
  }
  function handleNext() {
    if (pageNumber < totalPages) {
      setPageNumber(pageNumber + 1);
    }
  }
  function handlePrevious() {
    if (pageNumber > 0) {
      setPageNumber(pageNumber - 1);
    }
  }

  const modalBody = () => (
    <div
      style={{
        backgroundColor: "#fff",
        flexDirection: "column",
        overflow: "auto",

        /* Fixed position */
        left: 0,
        position: "fixed",
        top: 0,

        /* Take full size */
        height: "100%",
        width: "100%",

        /* Displayed on top of other elements */
        zIndex: 9999,
      }}
    >
      <div
        style={{
          alignItems: "center",
          backgroundColor: "#000",
          color: "#fff",
          display: "flex",
          padding: ".5rem",
          justifyContent :"space-evenly"
        }}
      >
        {/* <div style={{ marginRight: "auto" }}></div> */}
        <div>
        <button  style={{
            border: "none",
            borderRadius: "4px",
            color: "black",
            cursor: "pointer",
            padding: "8px",
            margin: "10px",
          }}
          onClick={handleZoomInInside}
          disabled={pageScaleInside >= 2}>
          <i
            class="bi bi-zoom-in"
          ></i>
          ZoomIn
        </button>
        <button  style={{
            border: "none",
            borderRadius: "4px",
            color: "black",
            cursor: "pointer",
            padding: "8px",
            margin: "10px",
          }}
          onClick={handleZoomOutInside}
          disabled={pageScaleInside <= 0.3}>
          <i
            class="bi bi-zoom-out"  
          ></i>
          ZoomOut
        </button>
        </div>
        
        <div className="page-text" style={{ marginLeft: "auto" }}>
          Page {pageNumber} of {totalPages}
        </div>
        <div style={{ marginLeft: "auto" }}>
        <button
          style={{
            border: "none",
            borderRadius: "4px",
            color: "gray",
            cursor: "pointer",
            padding: "8px",
            margin: "10px",
          }}
          onClick={handlePrevious}
          disabled={pageNumber === 1}
        >
          ‹ Previous
        </button>
        <button
          style={{
            border: "none",
            borderRadius: "4px",
            color: "gray",
            cursor: "pointer",
            padding: "8px",
            margin: "10px",
            marginLeft: "0px",
          }}
          onClick={handleNext}
          disabled={pageNumber === totalPages}
        >
          Next ›
        </button>
        </div>
        <div>
        <button
          style={{
            backgroundColor: "#357edd",
            border: "none",
            borderRadius: "4px",
            color: "#ffffff",
            cursor: "pointer",
            padding: "8px",
          }}
          onClick={() => setShown(false)}
        >
          Back
        </button>
        </div>
      </div>
      <div
        style={{
          flexGrow: 1,
       
        }}
      >
        <div>
          <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} scale={pageScaleInside} />
          </Document>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <div
        style={{
          color: "#0d6efd",
          fontWeight: "bold",
          cursor: "pointer",
          marginBottom: "6px",
        }}
        onClick={() => setShown(true)}
      >
        Enlarge Pdf
      </div>
      {shown && ReactDOM.createPortal(modalBody(), document.body)}
      <div className="footer">
        <div className="button-container">
          <button
            disabled={pageScale >= 1.7}
            onClick={handleZoomIn}
            style={{ cursor: "pointer", marginTop: "-4px" }}
          >
            <i class="bi bi-zoom-in"></i>ZoomIn
          </button>
          <button
            onClick={handleZoomOut}
            disabled={pageScale <= 0.3}
            style={{ cursor: "pointer", marginTop: "-4px" }}
          >
            <i class="bi bi-zoom-out"></i>
            ZoomOut
          </button>
        </div>
        <div className="page-text">
          Page {pageNumber} of {totalPages}
        </div>
        <div className="button-container">
          <button
            onClick={handlePrevious}
            disabled={pageNumber === 1}
            style={{ cursor: "pointer", marginTop: "-4px" }}
          >
            ‹ Previous
          </button>
          <button
            onClick={handleNext}
            disabled={pageNumber === totalPages}
            style={{ cursor: "pointer", marginTop: "-4px" }}
          >
            Next ›
          </button>
        </div>
      </div>
      <div className="outerPDF" style={{maxWidth :"500px !important"}}>
      <Document
        file={file}
        onLoadSuccess={onDocumentLoadSuccess}
        options={options}
      >
        <Page
          pageNumber={pageNumber}
          scale={pageScale}
          renderMode="canvas"
          height={400}
        />
      </Document>
      </div>
    </div>
  );
}
