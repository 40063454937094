import React from "react";
import ListItem from "@material-ui/core/ListItem";
import { NavLink } from "react-router-dom";
import qs from "qs";

const AppMenuItemComponent = props => {
  const { className, onClick, children, value } = props;
  let link;
  let queryObject;
  if (className === "brand-menu") {
    queryObject = { brand: [value] };
    link = `/search-page/?${qs.stringify(queryObject)}`;
  } else {
    queryObject = { assettype: [value] };
    link = `/search-page/?${qs.stringify(queryObject)}`;
  }

  return (
    <ListItem
      button
      className={className}
      children={children}
      value={value}
      component={React.forwardRef((props, ref) => (
        <NavLink exact {...props} innerRef={ref} />
      ))}
      to={link}
      onClick={onClick}
    />
  );
};

export default AppMenuItemComponent;
