import {
  FETCH_ROLESETTINGS_REQUEST,
  FETCH_ROLESETTINGS_SUCCESS,
  FETCH_ROLESETTINGS_ERROR,
  UPDATE_ROLESETTINGS
} from "./roleTypes";

export const fetchRoleSettingsRequest = () => ({
  type: FETCH_ROLESETTINGS_REQUEST
});

export const fetchRoleSettingsSuccess = RoleSettings => {
  return {
    type: FETCH_ROLESETTINGS_SUCCESS,
    payload: RoleSettings
  };
};

export const fetchRoleSettingsError = error => ({
  type: FETCH_ROLESETTINGS_ERROR,
  payload: error
});

export const setRoleSettings = RoleSettings => ({
  type: UPDATE_ROLESETTINGS,
  payload: RoleSettings,
});
export const updateRoleSettings = ownProps => {
  return dispatch => dispatch(setRoleSettings(ownProps));
};
