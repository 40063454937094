import React from "react";
import "./FilterLabel.scss";
import _ from "lodash";
import qs from "qs";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

const FilterLabel = props => {
  const { t } = useTranslation("common");
  const { brand, assetType, fileType } = props;
  let filterLabels = [];

  if (_.has(brand, "brand"))
    brand.brand.forEach(brand =>
      filterLabels.push({ type: "brand", value: brand })
    );
  if (_.has(assetType, "assettype"))
    assetType.assettype.forEach(asset_type =>
      filterLabels.push({ type: "assettype", value: asset_type })
    );
  if (_.has(fileType, "fileType"))
    fileType.fileType.forEach(file_type =>
      filterLabels.push({ type: "fileType", value: file_type })
    );
  const handleLabelClick = event => {
    const value = event.target.parentElement.getAttribute("value");
    const type = event.target.parentElement.getAttribute("type");
    const queryParams = props.location.search;
    let queryObject = qs.parse(queryParams, { ignoreQueryPrefix: true });
    if (_.has(queryObject, "showNewDigitalContent")) {
      queryObject = _.omit(queryObject, ["showNewDigitalContent"]);
    }
    if (_.has(queryObject, type)) {
      const len = queryObject[type].length;
      const index = queryObject[type].indexOf(value);
      switch (index) {
        case 0:
          queryObject[type].shift();
          if (queryObject[type].length === 0) {
            delete queryObject[type];
          }
          break;
        case len - 1:
          queryObject[type].pop();
          break;
        default:
          queryObject[type].splice(index, 1);
          break;
      }
    }
    const searchParams = qs.stringify(queryObject);
    // props.history.replace(`/search-page/?${searchParams}`);
   props.history.replace(searchParams ? `/search-page/?${searchParams}` : `/search-page/?searchKey=`);

  };
  return (
    <React.Fragment>
      <div className="row">
        <div className="filter-label-row">
          {filterLabels.map(filter => (
            <div className={`filter-label ${filter.type}`}>
              <div className="filter-node">
                <div className="filter-name">
                  {filter.type === "assettype"
                    ? t(`Asset Type.${filter.value}`)
                    : filter.value}
                </div>
                <div
                  className="filter-close"
                  value={filter.value}
                  type={filter.type}
                  onClick={e => handleLabelClick(e)}
                >
                  <i className="bi bi-x-square-fill"></i>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(FilterLabel);
