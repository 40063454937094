import {
  FETCH_COUNTRYSETTINGS_REQUEST,
  FETCH_COUNTRYSETTINGS_SUCCESS,
  FETCH_COUNTRYSETTINGS_ERROR
} from "./countryTypes";

export const fetchCountrysettingsRequest = () => ({
  type: FETCH_COUNTRYSETTINGS_REQUEST
});

export const fetchCountrySettingsSuccess = countrySettings => {
  return {
    type: FETCH_COUNTRYSETTINGS_SUCCESS,
    payload: countrySettings
  };
};

export const fetchCountrySettingsError = error => ({
  type: FETCH_COUNTRYSETTINGS_ERROR,
  payload: error
});
