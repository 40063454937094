import { React, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";
import config from "../../config.js";
import "./ProfilePage.scss";
import Accordion from "../../components/Accordion/Accordion.jsx";
import {

  fetchCountrySettingsSuccess,

} from "../../redux";
import store from "../../redux/store";
  // eslint-disable-next-line
  import { getCountryCode } from "../../util/Helper.js";
const ProfilePage = props => {
  const gigya = window.gigya;
  const { title, countrySettings, countryCode } = { ...props };
  const [loginProvider, setLoginProvider] = useState(null);
  const userId = localStorage.email;
  const { t, i18n } = useTranslation("common");
  const [languageSet, setLanguages] = useState(null);
  const [screenLoaded, setScreenLoaded] = useState(false);
  useEffect(() => {
    let user_token = localStorage.getItem("token");
    gigya.accounts.getAccountInfo(user_token, {
      callback: getAccountInfoResp
    });
  }, [gigya]);
  useEffect(() => {
    document.title = title;
  }, [title]);

  const getAccountInfoResp = resp => {
    if (resp.errorCode === 0) {
      let loginProvider = resp.loginProvider;
      setLoginProvider(loginProvider);
    } else {
      console.log("Alert" + resp.errorMessage);
    }
  };
  useEffect(() => {
    const profilePage = () => {
      gigya.accounts.showScreenSet({
        screenSet: "bayer-ProfileUpdate",
        startScreen: "bayer-update-profile-screen",
        containerID: "profilePageScreen",
        lang: t("lang_code"),
        onHide: handleResult,
        onAfterScreenLoad: function(event) {
          let email = event.profile.email;
          let user_profile = JSON.parse(localStorage.getItem("userProfile"));
          if (
            email.slice(email.lastIndexOf("@") + 1, email.length) ===
              "bayer.com" &&
            loginProvider === "saml-azure_dev"
          ) {
            setScreenLoaded(true);
          } else if (Object.keys(user_profile).includes("loginProvider")) {
            if (
              email.slice(email.lastIndexOf("@") + 1, email.length) ===
                "bayer.com" &&
              user_profile.loginProvider === "saml-azure_dev"
            ) {
              setScreenLoaded(true);
            }
          }
        }
      });
    };
    profilePage();
  }, [t, gigya, loginProvider]);
  useEffect(() => {
    if (screenLoaded) {
      if (document.querySelectorAll("#profilePageScreen_content").length > 0) {
        document
          .querySelector("#profilePageScreen_content")
          .classList.add("hide_reset_pwd");
      }
    }
  }, [screenLoaded]);
  useEffect(() => {
    let country_data
    const countrySettings = async () => {
      const countrySettingsUrl = `${config.baseApi}dc/config/country`;
      let country_settings = await axios({
        method: "get",
        url: `/${countryCode ? countryCode : getCountryCode()}`,
        baseURL: countrySettingsUrl,
        headers: { Authorization: "Bearer" }
      });
     country_data = country_settings.data.data;
      store.dispatch(fetchCountrySettingsSuccess(country_data)); 
      setLanguages(country_data?.languageDropdownData.filter(country =>Object.keys(country_data?.accountRequestPageTranslations[0]).some(item => item === country.countryWithLangCode)));
    };
    countrySettings()
    setLanguages(country_data?.languageDropdownData.filter(country =>Object.keys(country_data?.accountRequestPageTranslations[0]).some(item => item === country.countryWithLangCode)));
  }, [countryCode]);

  const languageSelector = () => {
    let languages = [];
    if (languageSet !== undefined && languageSet !== null) {
      languageSet.forEach(language =>
        languages.push(
          <li
            lang-code={language.langCode}
            language={language.lang}
            countryWithLangCode= {language.countryWithLangCode}
            onClick={languageSetter}
          >
            {language.lang}
          </li>
        )
      );
    }
    async function languageSetter(e) {
      let lang_code = e.target.getAttribute("lang-code");
      const defaultLangres = await axios({
        method: "get",
        url: `${config.favApi}dc/userdata/${userId}/settings`,
      });
    let intialLocale = defaultLangres?.data?.data?.settings?.defaultLocale
     // let lang_code = e.target.getAttribute("countryWithLangCode");
      i18n.changeLanguage(lang_code);
      axios({
        method: "post",
        url: `${config.favApi}dc/userdata/${userId}/settings`,
        data: {
          settings: {
            defaultLang: lang_code,
            defaultLocale : intialLocale

          }
        },
        headers: {
          "X-Country-Code": `${countryCode ? countryCode : getCountryCode()}`
        }
      });
      localStorage.setItem("LegalTextLanguageFilter",countrySettings && countrySettings?.languages?.some(language => language?.langCode === lang_code) ? countrySettings?.languages?.filter(language => language?.langCode === lang_code)[0]?.lang : "English")
    }
    return languages;
  };

  function handleResult(eventObj) {
    if (eventObj.reason === "missing" || eventObj.reason === "finished") {
      window.location.href = "/";
    }
  }
  return (
    
  <div className="profilePageContainer container">
    <div>
   <div className="translatedTitle">
    <span>{t(`Profile Page.profile_details`)}</span></div>
    <div id="profilePageScreen" className="profilePageScreen"></div>
    </div>
         <div className="dc-user-settings">
           <span className="title">{t(`Profile Page.settings_title`)}</span>
           <div className="lang-switcher">
             <Accordion
               title={t(`Profile Page.language_switcher`).toUpperCase()}
               content={languageSelector()}
             />
           </div>
         </div>
  </div>
  
  );
};

const mapStateToProps = state => ({
  countrySettings: state.country.countryData,
  countryLocale: state.locale.countryLocale,
  countryCode: state.countryCode.countryCode,
});
export default connect(mapStateToProps)(ProfilePage);
