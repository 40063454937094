import {
  SET_FILTER_BRANDNAMES,
  SET_FILTER_ASSETTYPES,
  SET_FILTER_YEAR,
  SET_FILTER_FILETYPES,
  SET_FILTER_TOGGLEDATA,
  SET_FILTER_BRAND_PARAM,
  SET_FILTER_ASSETTYPE_PARAM,
  SET_FILTER_REM_PARAM
} from "./filterTypes";

export const setBrandNames = brandNames => ({
  type: SET_FILTER_BRANDNAMES,
  payload: brandNames
});

export const setAssetTypes = assettypes => ({
  type: SET_FILTER_ASSETTYPES,
  payload: assettypes
});

export const setYear = year => ({
  type: SET_FILTER_YEAR,
  payload: year
});

export const setFileTypes = fileTypes => ({
  type: SET_FILTER_FILETYPES,
  payload: fileTypes
});

export const setToggleData = toggleCategory => ({
  type: SET_FILTER_TOGGLEDATA,
  payload: toggleCategory
});

export const setFilterBrandParam = brand => ({
  type: SET_FILTER_BRAND_PARAM,
  payload: { ...brand }
});

export const setFilterAssetTypeParam = assetType => ({
  type: SET_FILTER_ASSETTYPE_PARAM,
  payload: { ...assetType }
});

export const setFilterRemParam = filterRem => ({
  type: SET_FILTER_REM_PARAM,
  payload: { ...filterRem }
});

export const setFilterBrandName = ownProps => {
  return dispatch => {
    dispatch(setBrandNames(ownProps));
  };
};
export const setFilterAssetTypes = ownProps => {
  return dispatch => {
    dispatch(setAssetTypes(ownProps));
  };
};
export const setFilterYear = ownProps => {
  return dispatch => {
    dispatch(setYear(ownProps));
  };
};
export const setFilterFileTypes = ownProps => {
  return dispatch => {
    dispatch(setFileTypes(ownProps));
  };
};
export const setFilterToggleData = ownProps => {
  return dispatch => {
    dispatch(setToggleData(ownProps));
  };
};
export const updateFilterBrandParam = ownProps => {
  return dispatch => {
    dispatch(setFilterBrandParam(ownProps));
  };
};
export const updateFilterAssetTypeParam = ownProps => {
  return dispatch => {
    dispatch(setFilterAssetTypeParam(ownProps));
  };
};
export const updateFilterRemParam = ownProps => {
  return dispatch => {
    dispatch(setFilterRemParam(ownProps));
  };
};
