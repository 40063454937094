const devConfig = {
  baseApi: "https://3kvzvhjhql.execute-api.eu-central-1.amazonaws.com/Stage/",
  favApi: `https://3kvzvhjhql.execute-api.eu-central-1.amazonaws.com/qa/`,
  loginProvider: "saml-azure_dev",
  currVersionOfTerms : "3.0.0"
};

const prodConfig = {
  baseApi: "https://ks9384wozf.execute-api.eu-central-1.amazonaws.com/prod/",
  favApi: "https://ks9384wozf.execute-api.eu-central-1.amazonaws.com/prod/",
  loginProvider: "saml-azure_prod",
  currVersionOfTerms : "3.0.0"
};

export default process.env.REACT_APP_ENVIRONMENT === "PROD"
  ? prodConfig
  : devConfig;
