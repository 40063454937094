import {
  FETCH_PRODUCTS_REQUEST,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_ERROR,
  FETCH_FAVPRODUCTS_SUCCESS,
  FETCH_FAVPRODUCTS_ERROR,
  UPDATE_RENDER_PRODUCT_LIST,
  UPDATE_FAVPRODUCTS_LIST
} from "./productTypes";

export const fetchProductsRequest = () => {
  return {
    type: FETCH_PRODUCTS_REQUEST
  };
};

export const fetchProductsSuccess = products => {
  return { type: FETCH_PRODUCTS_SUCCESS, payload: products };
};

export const fetchProductsError = error => {
  return {
    type: FETCH_PRODUCTS_ERROR,
    payload: error
  };
};

export const setRenderProductList = products => ({
  type: UPDATE_RENDER_PRODUCT_LIST,
  payload: products
});

export const fetchFavProductsSuccess = products => ({
  type: FETCH_FAVPRODUCTS_SUCCESS,
  payload: products
});

export const fetchFavProductsError = error => ({
  type: FETCH_FAVPRODUCTS_ERROR,
  payload: error
});

export const setFavProductsList = products => ({
  type: UPDATE_FAVPRODUCTS_LIST,
  payload: products
});

export const updateRenderProductList = ownProps => {
  return dispatch => dispatch(setRenderProductList(ownProps));
};

export const updateFavProductsList = ownProps => {
  return dispatch => dispatch(setFavProductsList(ownProps));
};
export const updateSetRenderProductList = ownProps => {
  return dispatch => dispatch(fetchProductsSuccess(ownProps));
};