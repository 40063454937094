import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import "./App.scss";
// import { Link } from "react-router-dom";
import { Provider } from "react-redux";
import store from "../src/redux/store";
import {
  fetchProductsSuccess,
  fetchAssetsSuccess,
  setFilterBrandName,
  setFilterAssetTypes,
  setFilterFileTypes,
  fetchCountrySettingsSuccess,
  fetchRoleSettingsSuccess
} from "./redux";
import axios from "axios";
import config from "./config.js";
import _ from "lodash";
import UploadFile from "./pages/MyExports/UploadFile";
import HomePage from "./pages/HomePageView/HomePage";
import LoginPage from "./pages/LoginPageView/LoginPage";
import SearchPage from "./pages/SearchPageView/SearchPage";
import ConditionsOfUse from "./pages/FooterView/ConditionsOfUse";
import PrivacyStatement from "./pages/FooterView/Privacy";
import Imprint from "./pages/FooterView/Imprint";
import AuthContext from "./components/Auth/Auth-context";
import Footer from "./components/Footer/footer";
import Header from "./components/Header/header";
import RedirectRoute from "./components/RedirectRoute/RedirectRoute";
import PasswordReset from "./pages/PasswordResetPageView/PasswordResetPage";
import ProfilePage from "./pages/ProfilePageView/ProfilePage";
import FavoritesPage from "./pages/FavoritesPageView/FavoritesPage";
import ProductDetailPage from "./pages/ProductDetailPageView/ProductDetailPage";
import AssetDetailPage from "./pages/AssetDetailPageView/AssetDetailPage";
import FeedbackPage from "./pages/FeedbackPageView/feedbackPage";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
// import common_de from "./translations/de/common.json";
// import common_en from "./translations/en/common.json";
// import common_nl from "./translations/nl/common.json";
import { trackPromise } from "react-promise-tracker";
// import { CheckPost } from "./components/checkPost/checkPost";
import SimpleBackdrop from "./components/Backdrop/backdrop";
import { getCountryCode, loadCookie, legalTest } from "./util/Helper.js";
import { getUserSettings } from "./Services/service.js";
import { Helmet } from "react-helmet";
import MyExports from "./pages/MyExports/MyExports";
import ViewArchive from "./pages/MyExports/ViewArchive";
import Administration from './pages/AdministrationPageView/Administration';
import DetailedReportPage from './pages/AdministrationPageView/DetailedReportPage';
import { LinkProducts } from "./pages/MyExports/LinkProducts";
import ErrorBoundary from "./Services/ErrorBoundary";
//import {countries} from 'country-data';
import "./components/Footer/footer.scss";
const brandNameUrl = `${config.baseApi}dc/brands`;
//const assetTypeUrl = `${config.baseApi}dc/assets/types`;
const dataUrl = `${config.baseApi}dc/entities`;
const fileTypesUrl = `${config.baseApi}dc/assets/filetypes`;
const countrySettingsUrl = `${config.baseApi}dc/config/country`;
let title = "";
let gigyaRole=""
//content = "";
let outputArray =""
const outputArrayDE = JSON.parse(localStorage.getItem('outputArrayDE')) || {};
const outputArrayNL = JSON.parse(localStorage.getItem('outputArrayNL')) || {};
const outputArrayFR = JSON.parse(localStorage.getItem('outputArrayFR')) || {};
const outputArrayUS = JSON.parse(localStorage.getItem('outputArrayUS')) || {};

const initLanguageSettings = async (langCode) => {
  i18next.changeLanguage(langCode);
  
  // Check if the data is already stored in localStorage
  if (Object.keys(outputArrayDE).length && Object.keys(outputArrayNL).length && Object.keys(outputArrayFR).length && Object.keys(outputArrayUS).length) {
    return; // Data already loaded, no need to fetch
  }

  const translationDataListUrl = `${config.baseApi}dc/translation/getTranslations`;
  const response = await axios.get(translationDataListUrl, {
    headers: {
      "X-Country-Code": `${getCountryCode()}`
    }
  });
  
  let inputarray = response?.data?.data;

  inputarray.forEach(item => {
    const location = item.location;
    const labels = item.labels;
    const valueDE = item["de-DE"];
    const valueNL = item["nl-NL"];
    const valueFR = item["fr-BE"];
    const valueUS = item["en-US"];

    if (!outputArrayDE[location]) {
      outputArrayDE[location] = {};
    }
    outputArrayDE[location][labels] = valueDE;

    if (!outputArrayNL[location]) {
      outputArrayNL[location] = {};
    }
    outputArrayNL[location][labels] = valueNL;

    if (!outputArrayFR[location]) {
      outputArrayFR[location] = {};
    }
    outputArrayFR[location][labels] = valueFR;

    if (!outputArrayUS[location]) {
      outputArrayUS[location] = {};
    }
    outputArrayUS[location][labels] = valueUS;
  });

  // Store the updated arrays in localStorage
  localStorage.setItem('outputArrayDE', JSON.stringify(outputArrayDE));
  localStorage.setItem('outputArrayNL', JSON.stringify(outputArrayNL));
  localStorage.setItem('outputArrayFR', JSON.stringify(outputArrayFR));
  localStorage.setItem('outputArrayUS', JSON.stringify(outputArrayUS));
};

// Initialize i18next with default language resources
i18next.init({
  interpolation: { escapeValue: false },
  lng: "en", // default language to use
  fallbackLng: "en", // default language to use
  resources: {
    en: {
      common: outputArrayUS
    },
    de: {
      common: outputArrayDE
    },
    nl: {
      common: outputArrayNL
    },
    fr: {
      common: outputArrayFR
    }
  }
});

function brandList() {
  return axios.get(brandNameUrl, {
    headers: {
      "X-Country-Code": `${getCountryCode()}`
    }
  }).then(res => res.data).then(response => {
    let products = [];
    let assets = [];
    const brands = response.data.filter;
   
  
    // Function to format brand name
    const formatBrandName=(name)=> {
      return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    }  
   
      const brandMap = new Map();
  
      brands.forEach((brand) => {
        const name = brand.brandName.toLowerCase(); // handle case-insensitivity
        if (!brandMap.has(name)) {
          brandMap.set(name, { ...brand });
        } else {
          const existing = brandMap.get(name);
          // Merge counts
          brandMap.set(name, { ...existing, count: existing.count + brand.count });
        }
      });
  
      // Filter brands where count > 0
    let finalValue = Array.from(brandMap.values()).filter((brand) => brand.count > 0);
  
    let formattedBrands = finalValue.map(brand => ({
      brandName: formatBrandName(brand.brandName),
      filterType: brand.filterType,
      count: brand.count
    }));

    store.dispatch(setFilterBrandName(formattedBrands));
    const brandSets = _.chunk(brands, 1);
    const promiseArray = brandSets.map(brands =>
      axios.post(
        dataUrl,
        {
          brandNames: brands.map(brand => brand.brandName)
        },
        {
          headers: {
            "X-Country-Code": `${getCountryCode()}`
          }
        }
      )
    );
    return Promise.allSettled(promiseArray).then(response => {
      response.forEach(obj => {
        if (obj.status === "fulfilled") {
          products.push(...obj.value.data.data.products);
          (gigyaRole === "Global Admin" || gigyaRole === "Country Admin")
          ? products = products.filter(item => typeof item?.plcstatus === "string" ?  item?.plcstatus?.toLowerCase().includes("ready for publication") : item?.plcstatus?.['en-ZZ']?.toLowerCase().includes("ready for publication"))
          : products = products.filter(item => typeof item?.plcstatus === "string" ?  item?.plcstatus?.toLowerCase().includes("ready for publication") : item?.plcstatus?.['en-ZZ']?.toLowerCase().includes("ready for publication") &&  (item?.hideShowStatus?.[0]?.currentStatus ==="show" || !item?.hideShowStatus) )
          assets.push(...obj.value.data.data.assets);
           // eslint-disable-next-line no-unused-expressions
          (gigyaRole === "Global Admin" || gigyaRole === "Country Admin")
          ? assets 
          : assets = assets.filter(item =>  !item?.hideShowStatus || item?.hideShowStatus?.[0]?.currentStatus ==="show"  )

        }
        if (obj.status === "rejected") {
          console.log(obj.reason);
        }
      });
      store.dispatch(fetchProductsSuccess(products));
      store.dispatch(fetchAssetsSuccess(assets));
      let asset_types = [];
      assets.forEach(asset =>
        asset.asset_type.forEach(val =>
          asset_types.push({ asset_type: val, filterType: "assetType" })
        )
      );   
      let unique_asset_types = _.uniqBy(asset_types, "asset_type.dcAssetType");

      const updatedArray = unique_asset_types.map(item => {
        if (
          item.asset_type &&
          item.asset_type.dcAssetType === 'eCommerce Product Shots'
        ) {
          // If the condition is met, update the attribute
          return {
            ...item,
            asset_type: {
              ...item.asset_type,
              dcAssetType: 'Product Shot',
            },
          };
        } else {
          // If the condition is not met, return the item as it is
          return item;
        }
      });
      let updatedArray_unique_asset_types = _.uniqBy(updatedArray, "asset_type.dcAssetType");

      store.dispatch(setFilterAssetTypes(updatedArray_unique_asset_types));
   
    });
  });

}
/* async function assettype() {
  const response = await fetch(assetTypeUrl, {
    headers: {
      "X-Country-Code": `${getCountryCode()}`
    }
  });
  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }
  let assetTypeList = await response.json();
  const assets = assetTypeList.data;
  store.dispatch(setFilterAssetTypes(assets));
  return;
}
assettype().catch(error => {
  console.log(error.message);
}); */
function fileType() {
  return axios.get(fileTypesUrl, {
    headers: {
      "X-Country-Code": `${getCountryCode()}`
    }
  }).then(res => res.data).then(response => {
    const fileTypes = response.data;
    store.dispatch(setFilterFileTypes(fileTypes));
  }).catch(error => {
    console.log(error.message);
  });;
}

const countrySettings = async load => {
  let country_settings = await axios({
    method: "get",
    url: `/${getCountryCode()}`,
    baseURL: countrySettingsUrl,
    headers: { Authorization: "Bearer" }
  });
  let country_data = country_settings.data.data;
  loadCookie(country_data);
  store.dispatch(fetchCountrySettingsSuccess(country_data));

  // if (!load) {
  //   initLanguageSettings(country_data.defaultLangCode);
  // }
  return country_data;
};

function App() {
  const AuthCtx = useContext(AuthContext);


  const [loadUserData, setUserData] = useState(false);
  let country_code = getCountryCode();
  if (country_code === "nl") {
    title = "Download Center | Bayer B.V";
    /*  content =
      "Login to download product information and marketing materials for all Bayer Consumer Health brands and products."; */
  } else {
    title = "Download-Center | Bayer Vital";
    /* content =
      "Jetzt einloggen und alle benötigen Produktinformationen und Marketing-Materialien zu Bayer-Produkten für Ihren Vermarktungserfolg kostenlos herunterladen."; */
  }
  axios.interceptors.request.use(async function (config) {
    try {
      const token = await AuthCtx.getIdToken();
      // console.log("idTOken retreived....", token);
      config.headers.Authorization = "Bearer " + token;
      return config;
    } catch (e) {
      // console.log("Token retreival failed !!!!!!");
      config.headers.Authorization = "Bearer TOKEN_NOT_LOADED";
      return config;
    }

  });
  let userIdforCalling= localStorage.email
  useEffect(() => {
     //console.log("Am I Logged in ? " + AuthCtx.isLoggedIn);
     localStorage.setItem("LoggedIn", AuthCtx.isLoggedIn)
     let url= window.location.origin.toLowerCase()
    async function fetchToken() {
      if (AuthCtx.isLoggedIn) {
        //const userToken = AuthCtx.giveToken();
        const userId = localStorage.email;
        if (AuthCtx?.userProfile?.email?AuthCtx?.userProfile?.email : AuthCtx?.userProfile?.email?AuthCtx?.userProfile?.email : AuthCtx?.userProfile?.email?AuthCtx?.userProfile?.email : userId !== undefined && userId !== null && userId.length > 0) {
          let langSettings = getUserSettings(AuthCtx?.userProfile?.email?AuthCtx?.userProfile?.email : userId);
          langSettings
            .then(resp => {
              setUserData(true);
              if(resp && resp.settings){
                if (resp.settings.hasOwnProperty("defaultLang")) {
                  initLanguageSettings(resp.settings.defaultLang);
                }
              }             
              else {
                if(url.includes("localhost") || url.includes("downloadcenter")){                 
                  initLanguageSettings("en");
                }
                else{
                  initLanguageSettings("de");
                }
               
              }
            })
            .catch(error => console.log(error));

        }

      }
    }
    const postGigyaID = async () => {
     // const userId = AuthCtx.giveToken();
      const userId= localStorage.email
      const response = await axios({
        method: "get",
        url: `${config.baseApi}dc/exports/getrolepermission/${AuthCtx?.userProfile?.email?AuthCtx?.userProfile?.email : userId}`,
        data: {
          "useAdministration": {
            "Gigya_ID": AuthCtx?.userProfile?.email?AuthCtx?.userProfile?.email : userId,
          }
        },
        headers: {
          "X-Country-Code": `${getCountryCode()}`
        }
      })
      gigyaRole=response.data.data?.roleName;
      store.dispatch(fetchRoleSettingsSuccess(response.data.data?.viewPermission));
    }
    fetchToken();
    postGigyaID()
     //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AuthCtx, AuthCtx.isLoggedIn, userIdforCalling, AuthCtx.giveToken(), AuthCtx.giveEmail()]);


  useEffect(() => {
    // console.log("PROMISE ALL LOADING BRANCHLIST----" + AuthCtx.isLoggedIn);
    if (loadUserData) {
      trackPromise(
        Promise.all([
          countrySettings(true),
          (AuthCtx.isLoggedIn ? brandList() : Promise.resolve()),
          //assettype(),
          (AuthCtx.isLoggedIn ? fileType() : Promise.resolve())
        ]),
        "initial-loading"
      );
    } else {
      // console.log("LOAING COUNTRY SETTINGS......");
      trackPromise(Promise.all([countrySettings(false)]),
        "initial-loading");
    }
//    console.log("Loading dependencies: ", loadUserData, outputArrayFR, outputArrayNL, outputArrayDE, outputArrayUS, AuthCtx.isLoggedIn, localStorage.getItem('previousLanguage'), getCountryCode());
    // ... rest of the useEffect code
  
        //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadUserData,outputArrayFR,outputArrayNL,outputArrayDE, outputArrayUS,  AuthCtx.isLoggedIn,localStorage.getItem('previousLanguage'), getCountryCode()]);
//  const [conditions, setConditions]= useState("Conditions of Use")
//  const [privacy, setPrivacy]= useState("Privacy Statement")
//  const [imprint, setImprint]= useState("Imprint")
  // let conditionsOfUse= "Conditions of Use"
  // let privacy = "Privacy Statement"
  // let imprint = "Imprint"

  useEffect(() => {
    legalTest()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCountryCode(),localStorage.getItem('previousLanguage')])
 
  const userId = localStorage.email;
  let userLanguage= "";
   const getData= async()=>{
  
    // const translationDataListUrl = `${config.baseApi}dc/translation/getTranslations`;

    const userSettingsApi = `${config.favApi}dc/userdata/${AuthCtx?.userProfile?.email?AuthCtx?.userProfile?.email : userId}/settings`;
    const userSettingsData = await axios.get(userSettingsApi).then(res => res.data);
    let url= window.location.origin.toLowerCase()
    console.log("userSettingsData",userSettingsData)
    if(userSettingsData && userSettingsData.data.settings){
      if (userSettingsData.data.settings.hasOwnProperty("defaultLang")) {
        initLanguageSettings(userSettingsData.data.settings.defaultLang);
      }
    }             
    else {
      if(url.includes("localhost") || url.includes("downloadcenter")){                 
        initLanguageSettings("en");
      }
      else{
        initLanguageSettings("de");
      }
     
    }
    //console.log("langugage", userSettingsData.data.settings.defaultLang)
  userLanguage= userSettingsData?.data?.settings?.defaultLang
    const translationDataListUrl = `${config.baseApi}dc/translation/getTranslations`;
    const response = await axios.get(translationDataListUrl, {
      headers: {
        "X-Country-Code": `${getCountryCode()}`
      }
    })
    const filteredData = response?.data?.data.filter(item => item.location.toLowerCase() === 'footer');
    //console.log("langugage filteredData", filteredData)
    // const valueDE = item["dape-DE"];
    // const valueNL = item["nl-NL"];
    // const valueFR = item["fr-BE"];
    // const valueUS = item["en-US"];
    filteredData.forEach(item => {
      Object.keys(item).forEach(key => {
        // Check if the key is a language attribute (e.g., "fr-BE", "de-DE")
        if (key !== 'location' && key !== 'labels') {
          // Initialize the language-specific output arrays if not already done
          outputArray[key] = outputArray[key] || {};
          // outputArrayDE[key] = outputArrayDE[key] || {};
          // outputArrayUS[key] = outputArrayUS[key] || {};
          // Add the corresponding value to the output arrays
          outputArray[key][item.labels] = item[key];
          // outputArrayDE[key][item.labels] = item[key];
          // outputArrayUS[key][item.labels] = item[key];
        }
      });
    });
  
  // console.log('Output Array (fr):', outputArray);
    // console.log('Output Array (de):', outputArrayDE);
    // console.log('Output Array (us):', outputArrayUS);
    // filteredData.forEach(item => {
    //   console.log("item", item)
    //   const location = item.location;
    //   const labels = item.labels;
    //   const valueDE = item["de-DE"];
    //   const valueNL = item["nl-NL"];
    //   const valueFR = item["fr-BE"];
    //   const valueUS = item["en-US"];
  
  
    //   if (!outputArrayDE[location]) {
    //     outputArrayDE[location] = {};
    //   }
  
    //   outputArrayDE[location][labels] = valueDE;
  
    //   if (!outputArrayNL[location]) {
    //     outputArrayNL[location] = {};
    //   }
  
    //   outputArrayNL[location][labels] = valueNL;
  
    //   if (!outputArrayFR[location]) {
    //     outputArrayFR[location] = {};
    //   }
  
    //   outputArrayFR[location][labels] = valueFR;
  
    //   if (!outputArrayUS[location]) {
    //     outputArrayUS[location] = {};
    //   }
  
    //   outputArrayUS[location][labels] = valueUS;
  
  
    // });
  
  
   // console.log(filterData);
    
    // if(userSettingsData?.data?.settings?.defaultLang?.toLowerCase()==="en"){
    //   setConditions(outputArray["en-US"].conditions_of_use)
    //   setPrivacy(outputArray["en-US"].privacy)
    //   setImprint(outputArray["en-US"].imprint)
    // }
    // else if(userSettingsData?.data?.settings?.defaultLang?.toLowerCase()==="de"){
    //   setConditions(outputArray["de-DE"].conditions_of_use)
    //   setPrivacy(outputArray["de-DE"].privacy)
    //   setImprint(outputArray["de-DE"].imprint)
    // }
    // else if(userSettingsData?.data?.settings?.defaultLang?.toLowerCase()==="nl"){
    //   setConditions(outputArray["nl-NL"].conditions_of_use)
    //   setPrivacy(outputArray["nl-NL"].privacy)
    //   setImprint(outputArray["nl-NL"].imprint)
    // }
    // else if(userSettingsData?.data?.settings?.defaultLang?.toLowerCase()==="fr"){
    //   setConditions(outputArray["fr-BE"].conditions_of_use)
    //   setPrivacy(outputArray["fr-BE"].privacy)
    //   setImprint(outputArray["fr-BE"].imprint)
    // }
    // else{
    //   setConditions(outputArray.defaultValue.conditions_of_use)
    //   setPrivacy(outputArray.defaultValue.conditions_of_use)
    //   setImprint(outputArray.defaultValue.conditions_of_use)
    // }
   // console.log("translation footer",conditions, privacy, imprint)
   // console.log("langugage",filterData)
   }
  
  
    useEffect(() => {
  
      getData()
      // FooterComponent()
       //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ userLanguage,outputArray,loadUserData,outputArrayFR,outputArrayNL,outputArrayDE, outputArrayUS,userId,AuthCtx, localStorage.getItem('previousLanguage')])
    // const FooterComponent = ()=>{
    //   return(
    //     <div className="footer-container">
    //     {/* <div className="projectLogo">
    //       <img
    //         className="downloadCenterLogo"
    //         src={downloadCenterLogo}
    //         alt="logo"
    //       />
    //     </div> */}
    //     <div className="overlay">Copyright 2022 Bayer AG</div>
    //     {/* <div className="footer-links-main"> */}
    //       <div className="footer-links">
    //         {/* <span className="link-border"></span> */}
    //         <div className="link-container">
    //           <span className="link">
              
              
    //             <Link to="/conditions">{conditions}</Link>
    //           </span>
    //           <span className="link">
    //             <Link to="/privacy">{privacy}</Link>
    //           </span>
    //           <span className="link">
    //             <Link to="/imprint">{imprint}</Link>
    //           </span>
    //         </div>
    //       </div>
    //     {/* </div> */}
    //   </div>
  
    //   )
    // }
  // const [state, setState] = useState({
  //   ip: "",
  //   countryName: "",
  //   countryCode: "",
  //   city: "",
  //   timezone: ""
  // });

  // const getGeoInfo = () => {

  // let dummycountryCode =  navigator.language.length === 5 ? navigator.language.substring(3, 5).toLowerCase():navigator.language.substring(0, 2).toLowerCase() 
 
  //  console.log("usercountryfromApi",   localStorage.getItem('checkUser') , "dummy", dummycountryCode)
  //    if(localStorage.getItem('checkUser') === dummycountryCode) { 
  //     localStorage.setItem('previousLanguage', localStorage.getItem('checkUser'))   
  //   }
  //   else{
  //     if(localStorage.getItem('checkUser')){
  //       localStorage.setItem('previousLanguage', localStorage.getItem('checkUser')) 
  //     }
      
  //      else{
  //         // eslint-disable-next-line no-unused-expressions
  //     AuthCtx.isLoggedIn === true ? !localStorage.getItem('checkUser') && localStorage.setItem('previousLanguage', dummycountryCode):""
  //      }
  
  //   }
     


  //   axios
  //     .get("https://ipapi.co/json/")
  //     .then((response) => {
  //       let data = response.data;
  //       setState({
  //         ...state,
  //         ip: data.ip,
  //         countryName: data.country_name,
  //         countryCode: data.country,
  //         city: data.city,
  //         timezone: data.timezone
  //       });
        
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });


  // };

  // useEffect(() => {

  //   const  getIndividualRole= async () => {
  //     const user_token = AuthCtx.giveToken();
  //     let userMail = "";
  //     let userFirstName = "";
  //     let userLastName = "";
  //     let profileData = localStorage.getItem("userProfile");
  //     //let user_token = localStorage.getItem("token");
  //     let userProfile =
  //       profileData !== undefined && profileData !== null
  //         ? JSON.parse(profileData)
  //         : "";
  //     // }
    
  //     userMail = userProfile.email;
  //     userFirstName = userProfile.firstName;
  //     userLastName = userProfile.lastName;
  //     const individualRolesUrl = `${config.baseApi}dc/userdata/${user_token}/checkUser`;
    
      
  //     const response = await 
  //     axios({
  //       method: "post",
  //       url: individualRolesUrl,
  //       data: {
  //         "EMAILID": userMail,
  //         "COUNTRYCODE": `${getCountryCode()}`, 
  //         "USERNAME": userFirstName + " " +userLastName
  //       },
  //       headers: {
  //         "X-Country-Code": `${getCountryCode()}`
  //       }
  //     });
  //     setLoadUserCountry(response.data.data.countryCode.toLowerCase())
  //    apicountry= response.data.data.countryCode.toLowerCase();
  //    getGeoInfo();
  //   };
  //   getIndividualRole()

   
  //   //eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [loadUserCountry]);
  return (
    <I18nextProvider i18n={i18next}>
      <Provider store={store}>
       <ErrorBoundary error="Failed to load data."> 
      
        <Helmet>
          <title>{title}</title>
          {/* <meta name="description" content={content} /> */}
        </Helmet>
        <div className="App">
          <div className="main-container">
          <BrowserRouter>
              {AuthCtx.isLoggedIn && <Header />}
              <SimpleBackdrop />
              <Switch>          
 {AuthCtx.isLoggedIn && <Route exact path="/profile-page"> 
 <ProfilePage title="Profile Page" />                 
</Route>}
{AuthCtx.isLoggedIn && <Route exact path="/favorites-page"> 
           <FavoritesPage title="My Favorites" />                 
          </Route>}
          {AuthCtx.isLoggedIn && <Route exact path="/home"> 
           <HomePage title="Home" />                 
          </Route>}
          {AuthCtx.isLoggedIn && <Route exact path="/search-page/"> 
           <SearchPage title="Search Result Page" />                 
          </Route>}
          {AuthCtx.isLoggedIn && <Route exact path="*/my-exports" > 
           <MyExports title="My Exports" />                 
          </Route>}
          {AuthCtx.isLoggedIn && <Route exact path="*/my-exports/view-archive"> 
          <ViewArchive title="View Archive" />                 
         </Route>}
          {AuthCtx.isLoggedIn && <Route exact path="*/my-exports/upload-file"> 
          <UploadFile title="Upload File" />                 
         </Route>}
          {AuthCtx.isLoggedIn && <Route exact path="*/my-exports/link-products" > 
          <LinkProducts title="Link Products" />                 
         </Route>}
          {AuthCtx.isLoggedIn && <Route exact path="*/administration" > 
          <Administration title="Administration" />                 
         </Route>}
          {AuthCtx.isLoggedIn && <Route exact path="*/administration/detailedreport/*" > 
          <DetailedReportPage title="Detailed Report" />                 
         </Route>}
          {AuthCtx.isLoggedIn && <Route exact path="*/product-detail-page/:id" > 
          <ProductDetailPage title="Product Detail Page" />                 
         </Route>}
          {AuthCtx.isLoggedIn && <Route exact path="*/asset-detail-page/:id" > 
          <AssetDetailPage title="Asset Detail Page" />                 
         </Route>}
 
         <Route path="/conditions">
            <ConditionsOfUse title="Conditions of Use" />
          </Route>
          <Route path="/privacy">
            <PrivacyStatement title="Privacy Statement" />
          </Route>
          <Route path="/imprint">
            <Imprint title="Imprint" />
          </Route>
          <Route path="/feedback">
            <FeedbackPage title="Give Feedback" />
          </Route>
          <Route path="/reset-password">
                  <PasswordReset title="Reset Password" />
                </Route>
          {AuthCtx.isLoggedIn && <Route  path="/:id" > 
          <RedirectRoute />                
         </Route>}
          <Route path="/" exact>
            {AuthCtx.isLoggedIn && <Redirect to="/home" />}
            {!AuthCtx.isLoggedIn && (
              <LoginPage title="Bayer Download Center" />
            )}
          </Route>
          <Route path="*">
          <Redirect to="/" />
          </Route>
          {!AuthCtx.isLoggedIn && <Redirect to="/" />}
        </Switch>
              <Footer outputArray={outputArray} />
            </BrowserRouter>
          </div>
        </div>
    
      </ErrorBoundary>
      </Provider>
    </I18nextProvider>
  );
}
export default App;