import React,{ useEffect, useState}  from "react";
import { Container } from 'react-bootstrap'
import "./DetailedReport.scss";
import config from "../../config.js";
// import {getCountryCode} from "../../util/Helper.js";
import MaterialTable from "material-table";
import { forwardRef } from 'react';
//import IconButton from "@mui/material/IconButton";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { makeStyles} from '@mui/styles';
 //import { Download, DownloadDone } from "@mui/icons-material";
// import FileSaver from "file-saver";
// import { Routes, Route, Link, useLocation } from "react-router-dom";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { useTranslation } from "react-i18next";

const axios = require("axios");
// const detailReportUrl = `${config.baseApi}dc/entities/detailedReport?brandName=`;
let downloadMultipleExportProductData = []
let customProductHeadingsForMulti=[]
let firstProductObj= []
let downloadMultipleExportAssetData = []
let customAssetHeadingsForMulti=[]
let firstAssetObj= []
// let selectedRowNo  =0
//let keyingVar

const DetailedReportPage= (props) => {
  // console.log("globalstate",  props.location)
 // console.log("loc:", window.location.href)
const { t } = useTranslation("common");
const detailedUrl = window.location.href.split("/");
const detailedCountry = window.location.href.split("?");
const detailedCountryName = detailedCountry[detailedCountry.length -1]
const detailedBrandName = detailedUrl[detailedUrl.length -1].replace("?"+detailedCountryName,'')
// console.log("detailedUrl:", detailedUrl,":::", detailedBrandName, "detailedCountry", detailedCountryName)
 // const { state: { infoId } = {} } = useLocation();
//  const location = useLocation();
const [value, setValue] = useState('1');
const [brandReport, setBrandReport] = useState([]);
const [assetReport, setAssetReport] = useState([]);


const handleChange = (event,newValue) => {
  setValue(newValue);
};

const getDetailData = async () => {
  const response = await axios
  .get(
    `${config.baseApi}dc/entities/detailedReport?brandName=`+ encodeURIComponent(detailedBrandName).replace('25',''), 
    {
    headers: {
      "X-Country-Code": detailedCountryName
    }
  })
 
  setBrandReport(response.data.data.products);
  setAssetReport(response.data.data.assets);
   //console.log("detail",response.data,brandReport);
  return response.data;
  };

useEffect(() => {
  getDetailData() 
  //eslint-disable-next-line react-hooks/exhaustive-deps
}, [ ]);

const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (event,apiData, fileName) => {

     event?.currentTarget.classList.add("done");
     event?.currentTarget.classList.remove(
       "bi-bi-download-downloadicondetailpage-done"
     );
     event?.currentTarget.classList.add("bi-check-lg");
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    
  };
  const exportToMultiCSV = (event,apiData, fileName) => {
   const ws = XLSX.utils.json_to_sheet(apiData);
   const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
   //console.log("hello", wb, "ws", ws)
   const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
   const data = new Blob([excelBuffer], { type: fileType });
   FileSaver.saveAs(data, fileName + fileExtension);
   
 };
const productColumns = [
  { title: "Name (Link to DLC)", field: "name" },
  { title: "E-Com Paket",field: "ecom"},
  { title: "Printbilder", field: "print"},
  { title: "Beipackzett", field: "pdf"},
  { title: "Quality Index", field: "QI"},
  { title: "Download Quality Analysis", field: "download"},
  { title: "Dummy Button", field: "dummyButton",hidden: true},
]

const productData =  brandReport &&  brandReport.map((item,index) =>{
  
  const customHeadings = item.attributesConstitutingToProductQuality.map(item=>({
    [t('Excel Header.category')] : item.category,
    [t('Excel Header.attribute')] : item.qaulityMarkerName,
    [t('Excel Header.total_weight')] : item.scoreAssigned + "%",
    // eslint-disable-next-line no-useless-concat
    [t('Excel Header.current_weight')]: item.scoreObtained + "%" +" " +"of" +" "  + item.scoreAssigned + "%",
    [t('Excel Header.available')]: item.scoreObtained === "0" ? "Nein" : "Ja"

  }))
  return {
    key: index, 
    name: item.productName,
    ecom: item.ecomPacket ?
    (<span style={{ color: "green", fontStyle: "normal" }}> Available</span>) :
    (<span style={{ color: "black", fontStyle: "normal" }}> Not Available</span>),
    print: item.prinbilder ?
    (<span style={{ color: "green", fontStyle: "normal" }}> Available</span>) :
    (<span style={{ color: "black", fontStyle: "normal" }}> Not Available</span>),
    pdf: item.beipackzett ?
    (<span style={{ color: "green", fontStyle: "normal" }}> Available</span>) :
    (<span style={{ color: "black", fontStyle: "normal" }}> Not Available</span>),
    QI: item.qualityCount,
    dummyButton: item.attributesConstitutingToProductQuality,
    download:   <i  
                color="inherit"
                style={{color:"#00617f"}} 
                onClick={(e) => exportToCSV(e,customHeadings, item?.productName)} 
                className="bi bi-download downloadicondetailpage">
              </i>   

            
  }
})

const assetsColumns = [
  { title: "Name (Link to DLC)", field: "name" },
  { title: "DLC Asset Type",field: "dlc"},
  { title: "eDam Asset Type", field: "eDam"},
  { title: "Sub Brand", field: "subBrand"},
  { title: "Quality Index", field: "QI"},
  { title: "Title Enhancement", field: "titleEnhancement"},
  { title: "EndUser Description", field: "endUserDescription"},
  { title: "Download Quality Analysis", field: "download"},
  { title: "Dummy Button", field: "dummyButton",hidden: true},
]

const assetsData =  assetReport &&  assetReport.map((item,index) =>{
  const customHeadings = item.attributesConstitutingToAssetQuality.map(item=>({
    [t('Excel Header.category')]: item.category,
    [t('Excel Header.total_weight')]: item.scoreAssigned + "%",
    // eslint-disable-next-line no-useless-concat
    [t('Excel Header.current_weight')]: item.scoreObtained + "%" +" " +"of" +" "  + item.scoreAssigned + "%",
    [t('Excel Header.available')]: item.scoreObtained === "0" ? "Nein" : "Ja"

  }))
  return {
    key: index, 
    name: item.assetName,
    dlc: item.dlcAssetType,
    eDam: item.edamAssetType,
    subBrand: item.subbrand,
    QI: item.qualityCount,
    titleEnhancement: item.titleEnhancement?
    (<span style={{ color: "green", fontStyle: "normal" }}> Available</span>) :
    (<span style={{ color: "black", fontStyle: "normal" }}> Not Available</span>),
    endUserDescription: item.enduserDescription,
    dummyButton: item.attributesConstitutingToAssetQuality,
    download: <i    
              color="inherit"
              style={{color:"#00617f"}}
              onClick={(e) => exportToCSV(e,customHeadings, item?.assetName)} 
              className="bi bi-download downloadicondetailpage">
            </i>  
            
            

  }
})


const multiExcelProductFunc= ()=>{
  customProductHeadingsForMulti=[]
  firstProductObj= []

  firstProductObj.push(...downloadMultipleExportProductData[0].dummyButton.map((item,index)=>{
    const obj = {}
    downloadMultipleExportProductData.forEach(data => {
        obj[data.name] = data.dummyButton[index].scoreObtained === "0" ? "Nein" : "Ja";
    })
  return {
    [t('Excel Header.category')] : item.category,
    [t('Excel Header.attribute')] : item.qaulityMarkerName,
    [t('Excel Header.total_weight')] : item.scoreAssigned + "%",
    ...obj
  }})  )


  return(
  downloadMultipleExportProductData.slice(1,downloadMultipleExportProductData.length ) && 
  downloadMultipleExportProductData.slice(1,downloadMultipleExportProductData.length ).map((itemNew, index)=>(
    downloadMultipleExportProductData[0].dummyButton?
    customProductHeadingsForMulti=firstProductObj
   :

  console.log("firstElement")
  ))
  )

}

const multiExcelAssetFunc= ()=>{
  customAssetHeadingsForMulti=[]
  firstAssetObj= []
 
  firstAssetObj.push(...downloadMultipleExportAssetData[0].dummyButton.map((item,index)=>{
    const obj = {}
    downloadMultipleExportAssetData.forEach(data => {
        obj[data.name] = data.dummyButton[index].scoreObtained === "0" ? "Nein" : "Ja";
    })
  return {
    [t('Excel Header.category')] : item.category,
    [t('Excel Header.attribute')] : item.qaulityMarkerName,
    [t('Excel Header.total_weight')] : item.scoreAssigned + "%",
    ...obj
  }})  )
 
  return(
    downloadMultipleExportAssetData.slice(1,downloadMultipleExportAssetData.length ) && downloadMultipleExportAssetData.slice(1,downloadMultipleExportAssetData.length ).map((itemNew, index)=>(

      downloadMultipleExportAssetData[0].dummyButton?
      customAssetHeadingsForMulti=firstAssetObj
       :

      console.log("firstElement" )
   
    ),
   
    )
  )

}
const options = {
  filterType: "dropdown",
  responsive: "scroll",
  print: false,
  download: false,
  checkbox: true,
  selectableRows: false,
  selection: true,
  search: true,
  showTitle: false
};

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}

const useStyles = makeStyles({
  tabs: {
    "& .MuiTabs-indicator": {
     display: "none",
    },
    "& hover ":{
     color: "#00617f !important"
    }
  },
  table:{
    "& .MuiTableSortLabel-root":{
      fontSize: 16,
      color: "#00617f",
      fontWeight:600,
      fontFamily: 'Neue Helvetica',
  },
      // "& .MTableToolbar-title-10":{
      //   display:"none"
      // }
}
})

const tableRef = React.createRef();
const classes = useStyles();
  return (  
    <div>
      <Container style={{ paddingBottom: "10%" }}>
      <div className="title-heading">
          <span>Detailed Report</span>
        </div>
        <div className="row">
        <TabContext value={value}>
          <div className="col-md-3 col-lg-3 col-xl-6">
              <div
                className="d-flex flex-column flex-shrink-0 "
              >
                <div className="nav nav-pills flex-column ">
                <TabList  orientation="horizontal" onChange={handleChange}
                  >
                  <Tab className="nav-link " label= {<span className="tab" >Products</span>} value="1" />
                  <Tab className="nav-link"   label={<span className="tab" >Assets</span>}  value="2" />
                </TabList>                
                </div>
              </div>
          </div>
          <div className="col-md-3 col-lg-3 col-xl-6">
        </div>
          <div className="col-md-9 col-lg-9 col-xl-12">
            <TabPanel className={classes.table} value="1">
            <div className="col-md-9 col-lg-9 col-xl-12">
            <button className="detailed-button float-right mb-2" onClick={ (e)=>{ 
              if(downloadMultipleExportProductData.length> 0){
                multiExcelProductFunc()
                exportToMultiCSV(e,customProductHeadingsForMulti.map((item, index)=>  item), "Quality Index Multiple Selection Products")
              }
             else{
              console.log("no checkbox selected for product")
             }
            
          }} > Download</button>
          </div>
               <MaterialTable
                  tableRef={tableRef}
                  options={options}
                  icons={tableIcons}
                  columns={productColumns}
                  data={productData}
                  onSelectionChange={(rows) => {downloadMultipleExportProductData= rows} }
              />
          
            </TabPanel>
            <TabPanel className={classes.table} value="2">
            <div className="col-md-9 col-lg-9 col-xl-12">
            <button className="detailed-button float-right mb-2" onClick={ (e)=>{ 
              if(downloadMultipleExportAssetData.length> 0){
                multiExcelAssetFunc()  
                exportToMultiCSV(e,customAssetHeadingsForMulti.map((item, index)=>  item), "Quality Index Multiple Selection Assets")
              }  
              else{
                console.log("no checkbox selected for asset")
               } 
          }} >Download</button>
          </div>
            <MaterialTable
                  tableRef={tableRef}
                  options={options}
                  icons={tableIcons}
                  columns={assetsColumns}
                  data={assetsData}
                  onSelectionChange={(rows) => {downloadMultipleExportAssetData= rows } }
              />
            </TabPanel>
          </div>
        </TabContext>
          </div>
      </Container>
    </div>
  );
}

export default DetailedReportPage;
