import React, { Component } from "react";
import { withRouter, Link, Redirect } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { updateFavAssetsList, updateSetRenderAssetList, updateSetRenderCartAssetList,updateCartAssetsList } from "../../redux";
import DotLoader from "react-spinners/DotLoader";
import { css } from "@emotion/react";
import { downloadOnClick } from "../../util/Helper";
//import Carousel from "../../components/CustomCarousel/Carousel.jsx";
import "./AssetDetailPage.scss";
// import CarouselComponent from "../components/Carousel/carousel";
import Slider from "react-slick";
import { withTranslation } from "react-i18next";
import qs from "qs";
import _ from "lodash";
import CardComponent from "../../components/Card/card.jsx";
import config from "../../config.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import MediaQuery from "react-responsive";
import {
  assetCategories,
  getCountryCode,
  getProductTitle
} from "../../util/Helper.js";
//import Accordion from "../../components/Accordion/Accordion.jsx";
import { getUserSettings } from "../../Services/service.js";
import { OverlayTrigger, Popover, Button } from "react-bootstrap";
import Backdrop from "@material-ui/core/Backdrop";
import { withStyles } from "@material-ui/styles";
import ReactPlayer from "react-player";
// import { SpecialZoomLevel } from '@react-pdf-viewer/core';
import PdfPreview from "../PdfPreview/PdfPreview";
import { IconButton } from "@material-ui/core";
import { MoreVert } from "@mui/icons-material";
 import { Menu, MenuItem } from "@mui/material";
import AuthContext from "../../components/Auth/Auth-context";
import { getCartList } from "../../Services/service.js";
const styles = theme => ({
  backdrop: {
    zIndex: 10,
    color: "#fff"
  }
});

const axios = require("axios");
const assetlistUrl = `${config.baseApi}dc/entities/asset`;
const env = process.env.REACT_APP_ENVIRONMENT;
let ga_env = env === "PROD" ? "Production" : "Testing";
const countryCode = getCountryCode();
window.dataLayer = window.dataLayer || [];
const override = css`
  display: block;
  margin: 0 auto;
`;
const divStyle = {
  height: "calc(100vh - 200px)"
};
const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        dots: false,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
      }
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
      }
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
      }
    }
  ]
};

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="prev-arrow" onClick={onClick}>
      <i className="bi bi-chevron-left"></i>
    </div>
  );
}

function NextArrow(props) {
  const { onClick } = props;
  return (
    <div className="next-arrow" onClick={onClick}>
      <i className="bi bi-chevron-right"></i>
    </div>
  );
}

class AssetDetail extends Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.dropdownRef = React.createRef();
    this.state = {
      isLoading: true,
      wrongId:false,
      copyText: "Asset Detail Page.copy-link",
      copyLinkText: "Asset Detail Page.copy-link",
      emailLinkText: "Asset Detail Page.sendEmail",
      requestText: "Asset Detail Page.request",
      downloadNow: "Asset Detail Page.download-now",
      downloadUnavailable: "Asset Detail Page.download-unavailable",
      assetDetailData: {},
      imageSet: [],
      assetTitle: "",
      assetBrand: [],
      assetType: [],
      assetDescription: "",
      assetFileSize: "",
      assetFileType: "",
      assetCampaignYear: "",
      availableAssets: [],
      showShareOptions: false,
      assetCategory: [],
      assetMap: [],
      showBackdrop: false,
      popOver: false,
      issueMap: {
        ds: {
          error_code: "ds",
          error_text: "Download doesn't start",
          reported: false
        },
        fe: {
          error_code: "fe",
          error_text: "File is erroneous",
          reported: false
        },
        wf: { error_code: "wf", error_text: "Wrong Format", reported: false },
        md: { error_code: "md", error_text: "Missing Data", reported: false },
        others: { error_code: "others", error_text: "Others", reported: false }
      },
      showDisclaimer: false,
      enableDownload: true,
      license_expiry_date: null,
      show_hcp_disclaimer: false,
      anchorEl : null,
      grayColorOut : false,
      gigyaRole : ""
    };
    this.myRef = React.createRef();
    this.disclaimerCheckboxRef = React.createRef();
    this.productsList = props.productsList;
    this.countryLocale= this.props.countryLocale;
  }
  handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({anchorEl : event.currentTarget})

  };
   handleClose = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({anchorEl : !event.currentTarget})
  };
   postGigyaID = async () => {
    //const userId = this.context.giveToken();
    const userId= localStorage.email
    const response = await axios({
      method: "get",
      url: `${config.baseApi}dc/exports/getrolepermission/${userId}`,
      data: {
        "useAdministration": {
          "Gigya_ID": userId,
        }
      },
      headers: {
        "X-Country-Code": `${getCountryCode()}`
      }
    })
    this.setState({gigyaRole: response.data.data?.roleName })
  }
   hideShowFunction = async (item, status) => {
    const userGigyaId = this.context.giveToken();
    const userId = localStorage.email
    const response = await axios({
      method: "POST",
      url: `${config.favApi}dc/exports/hsproductsassetsbyadmin`,
      data: {
        "gigyaId": userGigyaId,
        "emailId": userId,
        "PK": item?.PK,
        "SK": item?.SK,
        "status": status, // show
      },
      headers: {
        "X-Country-Code": `${getCountryCode()}`
      }
    })
    const responseUpdated = await axios({
      method: "get",
      url: `${config.baseApi}dc/entities`,
     
      headers: {
        "X-Country-Code": `${getCountryCode()}`
      }
    })
     this.props.updateSetRenderAssetList(responseUpdated.data.data.assets)

    return response

  }


  getQueryParams = id => {
    let asset = this.props.assetsList.filter(asset => asset.id === id);
    if (asset.length > 0) {
      return {
        title: asset[0].ptitle,
        brandName: asset[0].brand.trim(),
        id: asset[0].id
      };
    }
  };
  homePageRedirectFunc=()=>{
    setTimeout(() => {
      if(!this.getQueryParams(this.props.match.params.id)){
        // <Redirect to="/home" />
     //   const wrongId = true;
          this.setState({ wrongId: true }); 
      }
    },15000 );
    
  }
  async componentDidMount() {
    const appContainer = document.querySelector(".App");
    appContainer.scrollTo(0, 0);
    document.title = this.props.title;
    const userId = localStorage.email;
    let { issueMap } = { ...this.state };
    const { assetsList, countrySettings } = { ...this.props };
    console.log("countrylocaleadp",this.props.countryLocale)
    let assetId;
    //let params = this.props.location.search;
    let params = this.getQueryParams(this.props.match.params.id);
    //console.log("queryparamsM", params)
    // if(!params){
    //   const wrongId = true;
    //   this.setState({ wrongId }); 
    //   console.log("queryparamsM", this.state.wrongId )   
    // }
    this.homePageRedirectFunc()
    this.postGigyaID()
    //const parsedParams = qs.parse(params, { ignoreQueryPrefix: true });
    const response = await axios.get(assetlistUrl, {
      params: params,
      headers: {
        "X-Country-Code": `${getCountryCode()}`
      }
    });

    const assetData = response.data.data.asset;
    const assetDetailData = assetData;
    if (assetDetailData !== undefined) {
      const isLoading = false;
      const availableAssets = this.availableAssets(assetsList, assetDetailData);
      assetId = assetDetailData.assetId;
      this.setState({ availableAssets: availableAssets });
      this.setState({ assetDetailData });
      this.setState({ isLoading });
      this.setState({ assetMap: countrySettings.assetTypeMap });
      this.assetDetailPageInfo(assetDetailData);
    }
    document.addEventListener("mousedown", this.handleClickOutside);
    document.addEventListener("click", e => {
      if (!e.target.parentElement?.classList.contains("reportIssue")) {
        if (this.state.popOver === true) {
          this.setState({ popOver: false });
        }
      }
    });
    if (userId !== undefined && userId !== null && userId.length > 0) {
      let errorSettings = getUserSettings(userId);
      errorSettings
        .then(resp => {
          if (resp.settings.hasOwnProperty("asset_detail_page_issue")) {
            if (resp.settings.asset_detail_page_issue.asset_id === assetId) {
              let issue_codes =
                resp.settings.asset_detail_page_issue.issue_codes;
              issue_codes.forEach(ec => {
                issueMap[ec].reported = true;
              });
              this.setState({ issueMap });
            }
          }
        })
        .catch(error => console.log(error));
    }
  }

  normalValueShow =(item, particularAttribute, countryLocale)=>{
    return(
      typeof item?.[particularAttribute] === "string"
      ? item?.[particularAttribute]  : countryLocale &&  item?.[particularAttribute]?.[countryLocale] ?
      item?.[particularAttribute]?.[countryLocale]  : item?.[particularAttribute]?.['en-ZZ']
    )
  }

  assetDetailPageInfo = assetDetailData => {
    const { t } = this.props;
    let assetTitle = [];
    let assetBrand = "N/A";
    let assetDescription;
    let assetFileSize;
    let assetFileType;
    let assetCampaignYear;
    let assetType;
    let assetCategory = [];
    let showDisclaimer = "";
    let show_hcp_disclaimer;

    if (
      assetDetailData !== undefined &&
      Object.keys(assetDetailData).length > 0
    ) {
      if (Object.keys(assetDetailData).includes("upc")) {
        assetBrand = assetDetailData.sub_brand;
        let ptitle = getProductTitle(assetDetailData.upc, this.productsList, this.props.countryLocale);

        ptitle !== undefined
          ? assetTitle.push(ptitle)
          : assetTitle.push(assetDetailData.title);

        if (Object.keys(assetDetailData).includes("asset_type")) {
          assetType = assetDetailData.asset_type;
          assetTitle.push(t(`Asset Type.${assetType[0].dcAssetType}`));
          assetType.forEach(asset_type =>
            assetCategory.push(asset_type.dcAssetCategory)
          );
        }
      } else {
        if (Object.keys(assetDetailData).includes("sub_brand")) {
          assetBrand = assetDetailData.sub_brand;
          assetTitle.push(assetBrand);
        }

        if (Object.keys(assetDetailData).includes("asset_type")) {
          assetType = assetDetailData.asset_type;
          assetTitle.push(t(`Asset Type.${assetType[0].dcAssetType}`));
          assetType.forEach(asset_type =>
            assetCategory.push(asset_type.dcAssetCategory)
          );
        }

        if (Object.keys(assetDetailData).includes("customer_explanation")) {
          let asset_customer_explanation = assetDetailData.customer_explanation;
          assetTitle.push(asset_customer_explanation);
        }
      }

      if (assetTitle.length === 0) {
        assetType.push(<span>{t("Miscellaneous.notAvailable")}</span>);
      }

      assetDescription =
        Object.keys(assetDetailData).includes("enduser_description") &&
          assetDetailData.enduser_description.length > 0
          ? assetDetailData.enduser_description
          : "";

      assetFileSize = Object.keys(assetDetailData).includes("file_size")
        ? assetDetailData.file_size
        :t("Miscellaneous.notAvailable");

      assetFileType = Object.keys(assetDetailData).includes("file_type")
        ? assetDetailData.file_type
        :t("Miscellaneous.notAvailable");

      assetCampaignYear = Object.keys(assetDetailData).includes("campaign_year")
        ? assetDetailData.campaign_year
        :t("Miscellaneous.notAvailable");

      assetTitle = assetTitle.join(" ").replace(/,/g, " ");

      showDisclaimer = Object.keys(assetDetailData).includes("license_expiry_date")
        ? true
        : false;

      showDisclaimer &&
        this.setState({ license_expiry_date: assetDetailData.license_expiry_date });

      let uniqueAssetCategory = [...new Set(assetCategory)];

      show_hcp_disclaimer =
        Object.keys(assetDetailData).includes("target_audience") &&
        assetDetailData.target_audience.includes("HCPs") &&
        assetDetailData.target_audience.length === 1;

      this.setState({
        assetTitle,
        assetBrand,
        assetType,
        assetDescription,
        assetCampaignYear,
        assetFileType,
        assetFileSize,
        assetCategory: uniqueAssetCategory,
        showDisclaimer,
        enableDownload: !showDisclaimer,
        show_hcp_disclaimer
      });
    }
  };

  async componentDidUpdate(prevProps, prevState) {
    const { assetsList, countrySettings } = { ...this.props };
    const appContainer = document.querySelector(".App");
    // let params = this.props.location.search;
    // let prevParams = prevProps.location.search;
    // const parsedParams = qs.parse(params, { ignoreQueryPrefix: true });
    //  const prevparsedParams = qs.parse(prevParams, { ignoreQueryPrefix: true });
    /* if (parsedParams.id !== prevparsedParams.id) */
    if (this.props !== prevProps) {
      //  this.setState({ isLoading: true });
      //console.log("queryparamsU", this.getQueryParams(this.props.match.params.id))
      // if(!this.getQueryParams(this.props.match.params.id)){
      //   const wrongId = true;
      //   this.setState({ wrongId }); 
      //   console.log("queryparamsU", this.state.wrongId)  
      // }
      this.homePageRedirectFunc()
      const response = await axios.get(assetlistUrl, {
        params: this.getQueryParams(this.props.match.params.id),
        headers: {
          "X-Country-Code": `${getCountryCode()}`
        }
      });
      const assetData = response.data.data.asset;
      const assetDetailData = assetData;
      if (assetDetailData !== undefined) {
        const availableAssets = this.availableAssets(
          assetsList,
          assetDetailData
        );
        this.setState({ availableAssets: availableAssets });
        this.setState({ assetDetailData });
        this.setState({ isLoading: false });
        this.setState({ assetMap: countrySettings.assetTypeMap });
        this.assetDetailPageInfo(assetDetailData);
      }
      appContainer.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
    document.addEventListener("mousedown", this.handleClickOutside);
    if (prevState.showBackdrop !== this.state.showBackdrop) {
      setTimeout(() => {
        this.setState({ showBackdrop: false });
      }, 2000);
    }
  }

  getKeywords = keywordsAttribute => {
    let keywords = [];
    if (Array.isArray(keywordsAttribute)) {
      keywords = keywordsAttribute[0].split(",");
    } else if (keywordsAttribute.includes(",")) {
      keywords = keywordsAttribute.split(",");
    } else if (keywordsAttribute.includes(" ")) {
      keywords = keywordsAttribute.split(" ");
    } else {
      keywords.push(keywordsAttribute);
    }
    return keywords;
  };

  availableAssets = (assetList, currAssetDetail) => {
    const assetbrand = currAssetDetail.brand;
    const assettype = currAssetDetail.asset_type;
    let similarassettypeAssets = [];
    let similarkeywordAssets = [];
    let similarbrandAssets = [];
    let availableAssets = [];
    similarbrandAssets = assetList.filter(
      asset =>
        asset.brand === assetbrand && asset.assetId !== currAssetDetail.assetId
    );
    let currkeywordSet = Object.keys(currAssetDetail).includes("keywords")
      ? this.getKeywords(currAssetDetail.keywords)
      : [];
    similarkeywordAssets = assetList.filter(asset => {
      let keywordSet = Object.keys(currAssetDetail).includes("keywords")
        ? this.getKeywords(asset.keywords)
        : [];
      let resFlag;
      resFlag =
        keywordSet.some(keyword => currkeywordSet.includes(keyword)) &&
        asset.assetId !== currAssetDetail.assetId;

      return resFlag;
    });
    similarassettypeAssets = assetList.filter(
      asset =>
        asset.asset_type.some(elemtype => assettype.includes(elemtype)) &&
        asset.assetId !== currAssetDetail.assetId
    );
    availableAssets = [
      ...new Set([
        ...similarbrandAssets,
        ...similarassettypeAssets,
        ...similarkeywordAssets
      ])
    ];
    return availableAssets;
  };

  copyLink = e => {
    navigator.clipboard.writeText(window.location.href);
    this.setState({ copyText: "Asset Detail Page.link-copied" });
    e.currentTarget.classList.add("done");
    window.dataLayer.push({
      event: "gaGenericEvent",
      eventCategory: "Share Link(local)",
      eventAction: this.state.assetTitle,
      eventLabel: "asset",
      eventValue: window.location.href,
      environment: ga_env
    });
  };

  handleDelete = () => {
    this.setState({ showChip: false });
  };

  createImageList = assetImageList => {
    let imageObject = {};
    let imageSet = [];
    const imageHeight = "320px";
    if (assetImageList.length > 0) {
      imageObject["original"] = assetImageList;
      imageObject["thumbnail"] = assetImageList;
      imageObject["originalHeight"] = imageHeight;
      imageSet.push(imageObject);
    }
    this.setState({ imageSet });
  };
 
  assetImageRender = assetDetailData => {
    let file_type = ["mp4", "mov"];
    let isPreviewAvailable = false;
    let previewFileSrc;
    let imageUrl;
     const domain = window.location.origin;
   // const domain ="https://qa-downloadcenter.bayer.com"
    
    let showVideo = false;
    let video_url = "";
    if (file_type.includes(assetDetailData.file_type)) {
      if (_.has(assetDetailData, "asset_location")) {
        let url = assetDetailData.asset_location;
        if (url.indexOf(".mp4") > -1 || url.indexOf("mov") > -1) {
          showVideo = true;
          video_url = `${domain}/${url}`;
        }
      }
    }
    

    if (showVideo === false) {
      if (_.has(assetDetailData, "preview_location")) {
        previewFileSrc = assetDetailData.preview_location;
        isPreviewAvailable = true;
      } else if (_.has(assetDetailData, "asset_location")) {
        if (Array.isArray(assetDetailData.asset_location)) {
          previewFileSrc = assetDetailData.asset_location.filter(
            file =>
              file.includes("Primary_Image") && file.indexOf("_MACOSX") === -1
          );
          if (previewFileSrc.length > 0) {
            isPreviewAvailable = true;
          } else {
            const imageTypes = ["png", "jpg", "jpeg"];
            previewFileSrc = assetDetailData.asset_location.filter(file => {
              const check = imageTypes.some(imgtype => file.includes(imgtype));
              return check;
            });
            if (previewFileSrc.length === 1) {
              isPreviewAvailable = true;
            }
          }
        } else {
          previewFileSrc = assetDetailData.asset_location;
          const extension = previewFileSrc.slice(
            ((previewFileSrc.lastIndexOf(".") - 1) >>> 0) + 2
          );
          const imageTypes = ["png", "jpg", "jpeg"];
          if (imageTypes.includes(extension)) {
            isPreviewAvailable = true;
          }
        }
      }
      if (isPreviewAvailable) {
        imageUrl = `${domain}/${previewFileSrc}`;
        return <img alt="" src={imageUrl} height="100%" width="100%"></img>;
      } else {
        return (
          <React.Fragment>
            <div className="no-preview">
              <div className="bayer-logo-background"></div>
              <span className="text">Keine Vorschau vorhanden</span>
            </div>
          </React.Fragment>
        );
      }
    } else {
      return (
        <ReactPlayer
          url={video_url}
          playing={false}
          controls={true}
          width={"100%"}
          height={"300px"}
        />
      );
    }
  };

  downloadButton = assetDetailData => {
    const { t } = this.props;
    if (
      Object.keys(assetDetailData).includes("asset_location") &&
      assetDetailData.asset_location != null &&
      assetDetailData.asset_location.length > 0
    ) {
      let class_name = "";
      class_name = this.state.enableDownload
        ? "download-button active"
        : "download-button";
      return (
        <div className={class_name}>
          <button
            className="downloadButton"
            onClick={e => downloadOnClick(e, assetDetailData, "asset")}
            data-brand={assetDetailData.brand}
          >
            <span>{t(this.state.downloadNow)}</span>
            <FontAwesomeIcon icon={faChevronRight} />
            {/* <i className="bi bi-file-earmark-arrow-down"></i> */}
          </button>
        </div>
      );
    }
  };

  getCopyLinkButton = () => {
    return (
      <button
        className="copyLinkButton"
        onClick={event => {
          this.copyLink(event);
        }}
      >
        <i className="bi bi-share"></i>
      </button>
    );
  };

  getRequestButton = asset_title => {
    const { t } = this.props;
    return (
      <a
        href={
          "mailto:digital.merchandiser.bayer@mainteam.de?subject=Bayer Download Center Anfrage: " +
          asset_title +
          "&body=Sehr geehrte Damen und Herren,%0D%0A%0D%0Abitte stellen Sie uns folgendes Marketing-Assets zur Verfügung:%0D%0A%0D%0AWichtig: bitte diese Fragen direkt beantworten in der E-Mail!%0D%0A%0D%0A %E2%80%A2 Um welches Bayer Produkt / Produkte handelt es sich?%0D%0A %E2%80%A2 Welche PZN hat das Produkt / die Produkte?%0D%0A %E2%80%A2 Welche konkrete Art von Asset wird benötigt? Beispielsweise eine A5-Printanzeige,%0D%0A %20%20%20%20Endverbraucher-Pflichtext oder ein Webshop-Banner%0D%0A %E2%80%A2 In welchem Dateiformat, Größenformat und Formatierung wird das Asset benötigt?%0D%0A %E2%80%A2 Bis wann soll das Asset angeliefert werden?.%0D%0A%0AMit freundlichen Gr%C3%BC%C3%9Fen"
        }
      >
        <button
          className="requestAssetButton"
          onClick={() => {
            window.dataLayer.push({
              event: "gaGenericEvent",
              eventCategory: "Request Asset E-mail(local)",
              eventAction: asset_title,
              eventLabel: "asset",
              environment: ga_env
            });
          }}
        >
          <span>{t("Asset Detail Page.request")}</span>
          {/* <i className="bi bi-envelope"></i> */}
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </a>
    );
  };

  addFavorites = event => {
    let favSet = event.currentTarget.getAttribute("data-favset");
    const type = event.currentTarget.getAttribute("data-type");
    const id = event.currentTarget.getAttribute("data-id");
    //const userId = localStorage.token;
    const userId= localStorage.email
    let assetidList = this.props.favAssetsList;
    if (favSet === "false") {
      event.currentTarget.setAttribute("data-favset", "true");
      event.currentTarget.firstElementChild.classList.remove("bi-bookmark");
      event.currentTarget.firstElementChild.classList.add("bi-bookmark-fill");
      if (assetidList !== undefined) {
        assetidList.push(id);
        this.props.updateFavAssetsList(assetidList);
      }
    } else {
      event.currentTarget.setAttribute("data-favset", "false");
      event.currentTarget.firstElementChild.classList.remove("bi-bookmark-fill");
      event.currentTarget.firstElementChild.classList.add("bi-bookmark");
      if (assetidList !== undefined) {
        assetidList = assetidList.filter(assetId => assetId !== id);
        this.props.updateFavAssetsList(assetidList);
      }
    }
    if (type === "asset") {
      axios({
        method: "post",
        url: `${config.favApi}dc/userdata/${userId}/favorites`,
        data: {
          favorites: {
            assets: assetidList
          }
        },
        headers: {
          "X-Country-Code": `${getCountryCode()}`
        }
      });
    }
    window.dataLayer.push({
      event: "gaGenericEvent",
      eventCategory: "Mark Favorites(local)",
      eventAction: this.state.assetTitle,
      eventLabel: "asset",
      eventValue: window.location.href,
      environment: ga_env
    });
  };

  favouriteButton = item => {
    let favSet;
    let favAssets = this.props.favAssetsList;
    if (favAssets !== undefined) {
      favSet = favAssets.includes(item.assetId) ? true : false;
    }
    const icon = favSet === true ? "bi-bookmark-fill" : "bi-bookmark";
    return (
      <div
        className="fav-icon"
        data-type={item.type}
        data-id={item.assetId}
        data-favset={favSet}
        onClick={e => this.addFavorites(e)}
      >
      {this.props.RoleSettings && this.props.RoleSettings['Mark favourites'] === true?   <i className={`bi ${icon}`}></i> : ""}
      </div>
    );
  };
  addToCart = (event, item) => {
    event.preventDefault();

    let cartSet = event.currentTarget.getAttribute("data-cartSet");
    const type = event.currentTarget.getAttribute("data-Carttype");
    const id = event.currentTarget.getAttribute("data-Cartid");
    const title = event.currentTarget.getAttribute("data-Carttitle");
    //const userId = localStorage.token;
    const userId= localStorage.email
    let productidList = this.props.favProductsList;
    let assetidList = this.props.CartAssetsList;
    if (cartSet === "false") {
      let currentDate = new Date().toJSON().slice(0, 10);
      event.currentTarget.setAttribute("data-cartSet", "true");
      event.currentTarget.firstElementChild.classList.remove("bi-cart-plus");
      event.currentTarget.firstElementChild.classList.add("bi-cart-check-fill");
      switch (type) {
        case "product":
          if (productidList !== undefined) {
            //console.log("currentDate",currentDate); // "2022-06-17"
            let dataList={
              "notificationFlag": false,
              "productId": id,
              "updatedAttributes": [],
              "addedToFavoritesOn": currentDate,
              "notificationCheckedOn": currentDate
          }
         // console.log("productidList00",productidList, dataList.productId )
            productidList.push(dataList);
            this.props.updateFavProductsList(productidList);
          }
          break;
        case "asset":
          if (assetidList !== undefined) {
          //   let dataList={
          //     "notificationFlag": "",
          //     "assetId": id,
          //     "updatedAttributes": [],
          //     "addedToFavoritesOn": currentDate,
          //     "notificationCheckedOn": ""
          // }
          //   assetidList.push(dataList);
            assetidList.push(id);
            this.props.updateCartAssetsList(assetidList);
  
          }
          break;
        default:
          break;
      }
    } else {
      let currentDate = new Date().toJSON().slice(0, 10);
      event.currentTarget.setAttribute("data-cartSet", "false");
      event.currentTarget.firstElementChild.classList.remove("bi-cart-check-fill");
      event.currentTarget.firstElementChild.classList.add("bi-cart-plus");
      switch (type) {
        case "product":
          if (productidList !== undefined) {
            //console.log("cartSet2", cartSet)
            let dataList={
              "notificationFlag": false,
              "productId": id,
              "updatedAttributes": [],
              "addedToFavoritesOn": currentDate,
              "notificationCheckedOn": currentDate
          }
          //productidList = productidList.filter(productId => productId !== id);

            productidList = productidList.filter(productId => productId.productId !== dataList.productId  );
             // console.log("productidList",productidList, dataList.productId )

              // if(productidList?.[0].productId  !== dataList?.productId ){
              //   console.log("productidList",productidList?.[0].productId, dataList.productId )
              //   productidList.push(dataList);
              // }
              //productidList.push(dataList);
              //console.log("productidList123",productidList?.[0].productId, dataList.productId )

          
              this.props.updateFavProductsList(productidList);
          }
          break;
        case "asset":
          if (assetidList !== undefined) {
          //   let dataList={
          //     "notificationFlag": "",
          //     "assetId": id,
          //     "updatedAttributes": [],
          //     "addedToFavoritesOn":currentDate,
          //     "notificationCheckedOn": ""
          // }
          // assetidList = assetidList.filter(assetId => assetId.assetId !== dataList.assetId);
          assetidList = assetidList.filter(assetId => assetId !== id);
          this.props.updateCartAssetsList(assetidList);
          }
          break;
        default:
          break;
      }
    }

    // if (type === "product") {
    //   axios({
    //     method: "post",
    //     url: `${config.favApi}dc/assets/addAssetsToCart/${userId}`,
    //     data: {
    //       favorites: {
    //         products: productidList
    //       }
    //     },
    //     headers: {
    //       "X-Country-Code": `${getCountryCode()}`
    //     }
    //   });
    // }
    let flag= false
    if (type === "asset" && cartSet=== "false") {
      axios({
        method: "post",
        url:  `${config.favApi}dc/assets/addAssetsToCart/${userId}`,
        data: {         
        country: item.country_code,
        assetId: item.id,
        assetName: item.title,
        brand: item.brand,
        type: item.type
        },
        headers: {
          "X-Country-Code": `${getCountryCode()}`
        }
      })
      .then(async() => {
        
        flag = true;
        await getCartList(userId);
      })
      .catch((error) => {
        // Handle error if needed
        console.error("Error in Axios request", error);
      });
    }
    if (type === "asset" && cartSet=== "true") {
      axios({
        method: "post",
        url:  `${config.favApi}dc/assets/deleteAssetsFromCart/${userId}`,
        data: {         
        country: item.country_code,
        assetId: item.id,
        assetName: item.title,
        brand: item.brand
        },
        headers: {
          "X-Country-Code": `${getCountryCode()}`
        }
      })
      .then(async() => {
        
        flag = true;
        await getCartList(userId);
      })
      .catch((error) => {
        // Handle error if needed
        console.error("Error in Axios request", error);
      });
    }
    window.dataLayer.push({
      event: "gaGenericEvent",
      eventCategory: "Mark Favorites(local)",
      eventAction: title,
      eventLabel: type,
      eventValue: window.location.href,
      environment: ga_env
    });
    flag && getCartList(userId)
  };

   assetCartButton = item => {
    let cartSet=false;
   // let favProducts = this.props.favProductsList;
    let favAssets = this.props.CartAssetsList.map(asset => asset?.assetId);
   // let filteredProductList;
    //let filteredAssettList;
    let itemTitle;
    switch (item.type) {
      // case "product":
      //   if (favProducts !== undefined) {
      //     filteredProductList= favProducts.map((prodId)=>prodId.productId)
      //     cartSet = filteredProductList.includes(item.id) ? true : false;
      //   }
      //   itemTitle = item.ptitle;
      //   break;
      case "asset":
        if (favAssets !== undefined) {
          // filteredAssettList= favAssets.map((AssetId)=>AssetId.assetId)
          // cartSet = filteredAssettList.includes(item.id) ? true : false;
          cartSet = favAssets.includes(item.assetId) ? true : false;
        }
        itemTitle = item.title;
        break;
      default:
        break;
    }
    let icon = cartSet === true ? "bi-cart-check-fill" : "bi-cart-plus";
    return (
      <div
        className="assetCart-icon"
        data-Carttype={item.type}
        data-Cartid={item.id}
        data-cartSet={cartSet}
        data-Carttitle={itemTitle}
        onClick={e => this.addToCart(e, item)}
      >
      {/* {RoleSettings &&  RoleSettings['Mark favourites'] === true?   */}
      <i className={`bi ${icon}`}></i>
      {/* : ""} */}
      </div>
    );
  };
  copyLinkOption = e => {
    this.setState({ copyLinkText: "Asset Detail Page.link-copied" });
    e.target.classList.add("done");
    navigator.clipboard.writeText(window.location.href);
    window.dataLayer.push({
      event: "gaGenericEvent",
      eventCategory: "Share Link(local)",
      eventAction: this.state.productTitle,
      eventLabel: "product",
      eventValue: window.location.href,
      environment: ga_env
    });
  };

  copyLinkEmail = e => {
    e.target.parentElement.classList.add("done");
    window.dataLayer.push({
      event: "gaGenericEvent",
      eventCategory: "Share Link(local)",
      eventAction: this.state.productTitle,
      eventLabel: "product",
      eventValue: window.location.href,
      environment: ga_env
    });
  };

  copyLinkShare = e => {
    let shareOptions = this.state.showShareOptions;
    this.setState({
      showShareOptions: !shareOptions
    });
    e.currentTarget.classList.add("done");
  };

  getCopyLink = () => {
    return (
      <button
        className="copyLinkButton"
        onClick={event => {
          this.copyLinkShare(event);
        }}
      >
        <i className="bi bi-share"></i>
      </button>
    );
  };

  getShareDropDowns = () => {
    const { t } = this.props;
    return (
      <div className="sugg-wrapper" ref={this.dropdownRef}>
        <ul className="suggestions">
          <li onClick={e => this.copyLinkOption(e)}>
            {t(this.state.copyLinkText)}
          </li>
          <li onClick={e => this.copyLinkEmail(e)}>
            <a
              href={
                "mailto:?subject=" +
                this.state.assetTitle +
                " | Bayer Download Center&body=" +
                encodeURIComponent(window.location.href)
              }
            >
              {t(this.state.emailLinkText)}
            </a>
          </li>
        </ul>
      </div>
    );
  };

  handleClickOutside = event => {
    if (event.target.getAttribute("class") !== "bi bi-share") {
      if (
        this.dropdownRef.current &&
        !this.dropdownRef.current.contains(event.target)
      ) {
        let showDropdown = this.state.showShareOptions;
        this.setState({ showShareOptions: !showDropdown });
      }
    }
  };

  getAssetType = () => {
    const { t } = this.props;
    let assetType = this.state.assetType;
    let concat_asset_type = [];
    assetType.forEach(asset_type => {
      let queryObject = { assettype: [asset_type.dcAssetType] };
      let link = `/search-page/?${qs.stringify(queryObject)}`;

      concat_asset_type.push({
        name: t(`Asset Type.${asset_type.dcAssetType}`),
        link: link
      });
    });

    return concat_asset_type;
  };

  getAssetCategory = () => {
    const { t } = this.props;
    let assetCategoryList = assetCategories(
      this.state.assetMap,
      this.props.assetType
    ),
      assetType = this.state.assetType,
      assetCategory = this.state.assetCategory,
      concat_asset_type = [],
      concat_array = [];
    if (assetType.length > 0 && assetCategoryList !== undefined) {
      assetType.forEach(asset_type => {
        if (assetCategory.includes(asset_type.dcAssetCategory)) {
          let asset_type_list =
            assetCategoryList[asset_type.dcAssetCategory]?.asset_list;
          let queryObj = {};
          const paramType = "assettype";
          queryObj[paramType] = [];
          if (asset_type_list !== undefined) {
            asset_type_list.forEach(asset =>
              queryObj[paramType].push(asset.dcAssetType)
            );
          }

          let link = `/search-page/?${qs.stringify(queryObj)}`;
          concat_asset_type.push({
            name: t(`Asset Category.${asset_type.dcAssetCategory}`),
            link: link
          });
        }
      });
      let arrValues = concat_asset_type.map(item => {
        return item.name;
      });
      concat_array = concat_asset_type.filter(
        (item, pos) => arrValues.indexOf(item.name) === pos
      );
    }
    return concat_array;
  };

  reportIssueDropdown() {
    const { t } = this.props;
    const userId = localStorage.email;
    let { issueMap, assetDetailData } = { ...this.state };
    let dropdown = [];
    let storeReportedIssue = [];
    let triggerGAEvent = e => {
      let error_code = e.target.getAttribute("value");
      let error_text = issueMap[error_code].error_text;
      issueMap[error_code].reported = true;
      window.dataLayer.push({
        event: "gaGenericEvent",
        eventCategory: "Report Issue",
        eventAction: error_text,
        eventLabel: "Asset Detail Page",
        eventValue: error_code,
        environment: ga_env
      });

      for (let ec in issueMap) {
        if (issueMap[ec].reported === true) {
          storeReportedIssue.push(ec);
        }
      }

      axios({
        method: "post",
        url: `${config.favApi}dc/userdata/${userId}/settings`,
        data: {
          settings: {
            asset_detail_page_issue: {
              asset_id: assetDetailData.assetId,
              issue_codes: storeReportedIssue
            }
          }
        },
        headers: {
          "X-Country-Code": `${getCountryCode()}`
        }
      });
      this.setState({ issueMap: issueMap, showBackdrop: true });
    };
    for (let ec in issueMap) {
      dropdown.push(
        <li
          //className={issueMap[ec].reported ? "issue-reported" : "report-issue"}
          className="report-issue"
          value={ec}
          onClick={triggerGAEvent}
        >
          {" "}
          <i class="bi bi-flag-fill"></i>
          {t(`Asset Detail Page.${issueMap[ec].error_code}`)}
        </li>
      );
    }

    return dropdown;
  }

  popover = props => {
    return (
      <Popover id="popover-basic" {...props}>
        <div className="issue-list">
          <div className="close-button">
            <Button>
              <i class="bi bi-x"></i>
            </Button>
          </div>
          {this.reportIssueDropdown(props.show)}
        </div>
      </Popover>
    );
  };

  clearReportedIssue = () => {
    let { issueMap, assetDetailData } = { ...this.state };
    const userId = localStorage.email;
    for (let ec in issueMap) {
      issueMap[ec].reported = false;
    }
    axios({
      method: "post",
      url: `${config.favApi}dc/userdata/${userId}/settings`,
      data: {
        settings: {
          asset_detail_page_issue: {
            asset_id: assetDetailData.assetId,
            issue_codes: []
          }
        }
      },
      headers: {
        "X-Country-Code": `${getCountryCode()}`
      }
    });
    this.setState({ issueMap });
  };

  disclaimerCheckbox = () => {
    const { t } = this.props;
    if (this.state.showDisclaimer) {
      return (
        <div className="disclaimer-text-container">
          <div className="disclaimer-checkbox">
            <input
              type="checkbox"
              ref={this.disclaimerCheckboxRef}
              onClick={() => {
                this.setState({ enableDownload: !this.state.enableDownload });
              }}
            ></input>
          </div>
          <div className="disclaimer-text" onClick={() => {
            this.disclaimerCheckboxRef.current.click()
          }}>
            {t("Miscellaneous.license-expiry-text")} {this.state.license_expiry_date}
          </div>
        </div>
      );
    }
  };
  provideLoader = () => {
    return (
      <div className="spinner d-flex align-items-center" style={divStyle}>
      <DotLoader color={"#0091df"} css={override} size={65} margin={2} />
    </div>
    );
  };
  wrongLoader =()=>{
      return (
        <Redirect to="/home" />
      )   
  }
  grayOut =(evt, item, hide)=>{
    evt?.preventDefault();
    evt?.stopPropagation();
    this.setState({grayColorOut : true})
     this.hideShowFunction(item, hide); 
 
  }
   closeGrayOut =(evt, item, show)=>{
    evt?.preventDefault();
    evt?.stopPropagation();
    this.setState({grayColorOut : false})
    this.hideShowFunction(item, show); 

  }

  render () {
    let domain=window.location.origin;
    const { t } = this.props;
    const { classes } = this.props;
    const open = Boolean(this.state?.anchorEl);
    return (  
    <React.Fragment>
     {this.state.wrongId && this.wrongLoader()}
     {this.state.isLoading && this.provideLoader()} 
     {!this.state.isLoading && (
      <React.Fragment>
        <div className="assetDetailPage">
          <Backdrop className={classes.backdrop} open={this.state.showBackdrop}>
            <div className="report-issue-message-box">
              <div className="report-issue-title">
                {t(`Asset Detail Page.report-title`)}
              </div>
              <i class="bi bi-hand-thumbs-up-fill"></i>
              <div className="message-content">
                {t(`Asset Detail Page.report-text`)}
              </div>
            </div>
          </Backdrop>

          <div className="container">
            <div className="asset-detail-content">
              <div className="asset-image-container">
                <MediaQuery maxWidth={480}>
                  <div className="wrapper-container">
                    <div className="asset-headline">
                      <span className="asset-title">
                        {this.state.assetTitle}
                      </span>
                    </div>
                    {/* <div className="issue-button">
                      <Accordion
                        title={t(`Asset Detail Page.title`)}
                        content={this.reportIssueDropdown()}
                      />
                    </div> */}
                    {/*  <div className="clear-issue-button">
                      <button
                        className="clear-button"
                        onClick={this.clearReportedIssue}
                      >
                        {t(`Asset Detail Page.clear`)}
                      </button>
                    </div> */}
                    <div className="icon-wrapper">
                      <div className="icon-set-one">
                      {this.assetCartButton(this.state.assetDetailData)}
                        {this.favouriteButton(this.state.assetDetailData)}
                        <div className="copy-link-button">
                          {this.getCopyLink()}
                        </div>
                        {(this.state.gigyaRole === "Global Admin" || this.state.gigyaRole === "Country Admin") ? (
                          <div>
                              <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-haspopup="true"
                                onClose={this.handleClose}
                                onClick={this.handleClick}
                              >
                                <MoreVert />
                              </IconButton>
                              <Menu
                                id="long-menu"
                                MenuListProps={{
                                'aria-labelledby': 'long-button',
                                }}
                                anchorEl={this.state.anchorEl}
                                open={open}
                                onClose={this.handleClose}
                                PaperProps={{
                                  style: {
                                    maxHeight: 150,
                                    width: '10ch',
                                  },
                                }}
                              >
                                <MenuItem onClick={(evt)=>this.grayOut(evt, this.state.assetDetailData, "hide")}   onClose={this.handleClose}> 
                                  Hide
                                </MenuItem>

                                <MenuItem onClick={(evt)=>this.closeGrayOut(evt, this.state.assetDetailData, "show")}  onClose={this.handleClose}>   
                                show
                                </MenuItem>
                              </Menu>
                          </div>)
                          :""}
                      </div>
                      {this.state.showShareOptions && this.getShareDropDowns()}
                      {/* <OverlayTrigger
                        trigger="click"
                        placement="bottom"
                        overlay={this.popover}
                        onToggle={show => {
                          this.setState({ popOver: show });
                        }}
                        show={this.state.popOver}
                      >
                       
                        <div className="report-flag" ref={this.myRef}>
                          <FontAwesomeIcon icon={faFlag} />
                        </div>
                      </OverlayTrigger> */}
                    </div>
                  </div>
                </MediaQuery>

                <div className="carousel-container">
                  {/* <CarouselComponent item={this.state.assetDetailData} /> */}
                  {this.state.assetFileType !== "pdf" ? this.assetImageRender(this.state.assetDetailData) : null}
                  {/* {this.downloadButton(this.state.assetDetailData)} */}
                  {/* {this.state.assetFileType === "pdf" ? <PdfPreview file={'https://mozilla.github.io/pdf.js/web/compressed.tracemonkey-pldi-09.pdf'}/> : null} */}
                  
                  {this.state.assetFileType === "pdf" ? <PdfPreview file={domain+ "/" +this.state.assetDetailData.asset_location}/> : null}
                  
                </div>
                {this.disclaimerCheckbox()}
              </div>
              <div className="asset-detail-container">
                <MediaQuery minWidth={481}>
                  <div className="wrapper-container">
                    {/*   <div className="issue-button">
                      <Accordion
                        title={t(`Asset Detail Page.title`)}
                        content={this.reportIssueDropdown()}
                      />
                    </div> */}
                    {/*          <div className="clear-issue-button">
                      <button
                        className="clear-button"
                        onClick={this.clearReportedIssue}
                      >
                        {t(`Asset Detail Page.clear`)}
                      </button>
                    </div> */}

                    <div className="icon-wrapper">
                    {this.assetCartButton(this.state.assetDetailData)}                   
                      {this.favouriteButton(this.state.assetDetailData)}
                      <div className="copy-link-button">
                        {this.getCopyLink()}
                      </div>
                      {(this.state.gigyaRole === "Global Admin" || this.state.gigyaRole === "Country Admin") ? (
                          <div>
                              <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-haspopup="true"
                                onClose={this.handleClose}
                                onClick={this.handleClick}
                              >
                                <MoreVert />
                              </IconButton>
                              <Menu
                                id="long-menu"
                                MenuListProps={{
                                'aria-labelledby': 'long-button',
                                }}
                                anchorEl={this.state.anchorEl}
                                open={open}
                                onClose={this.handleClose}
                                PaperProps={{
                                  style: {
                                    maxHeight: 150,
                                    width: '10ch',
                                  },
                                }}
                              >
                                <MenuItem onClick={(evt)=>this.grayOut(evt, this.state.assetDetailData, "hide")}   onClose={this.handleClose}> 
                                  Hide
                                </MenuItem>

                                <MenuItem onClick={(evt)=>this.closeGrayOut(evt, this.state.assetDetailData, "show")}  onClose={this.handleClose}>   
                                show
                                </MenuItem>
                              </Menu>
                          </div>)
                          :""}
                    </div>
                    {this.state.showShareOptions && this.getShareDropDowns()}
                    {/*  <OverlayTrigger
                      trigger="click"
                      placement="bottom"
                      overlay={this.popover}
                      onToggle={show => {
                        this.setState({ popOver: show });
                      }}
                      show={this.state.popOver}
                    >                  
                      <div className="report-flag" ref={this.myRef}>
                        <FontAwesomeIcon icon={faFlag} />
                      </div>
                    </OverlayTrigger> */}
                  </div>
                  <div className="asset-headline">
                    <span className="asset-title">{this.state.assetTitle}</span>
                  </div>
                </MediaQuery>
                <div className="brandName">
                  <span className="label">{t("Asset Detail Page.brand")}:</span>
                  <span className="content">
                    <Link
                      to={`/search-page/?${qs.stringify({
                        brand: [this.state.assetDetailData.brand]
                      })}`}
                    >
                      <span>{this.state.assetBrand}</span>
                    </Link>
                  </span>
                </div>
                <div className="assetCategory">
                  <span className="label">
                    {t("Asset Detail Page.asset-category")}:
                  </span>
                  <span className="content">
                    {this.getAssetCategory().map((obj, index) => {
                      if (index === this.getAssetCategory().length - 1) {
                        return (
                          <React.Fragment>
                            <Link to={obj.link}>{obj.name}</Link>
                          </React.Fragment>
                        );
                      }
                      return (
                        <React.Fragment>
                          <Link to={obj.link}>{obj.name}</Link>,{" "}
                        </React.Fragment>
                      );
                    })}
                  </span>
                </div>
                <div className="assetType">
                  <span className="label">{t("Asset Detail Page.asset-type")}:</span>
                  <span className="content">
                    {this.getAssetType().map((obj, index) => {
                      if (index === this.getAssetType().length - 1) {
                        return (
                          <React.Fragment>
                            <Link to={obj.link}>{obj.name}</Link>
                          </React.Fragment>
                        );
                      }
                      return (
                        <React.Fragment>
                          <Link to={obj.link}>{obj.name}</Link>,{" "}
                        </React.Fragment>
                      );
                    })}
                  </span>
                </div>
                {this.state.assetDescription && (
                  <div className="asset-description">
                    <div className="asset-desc-container">
                      <span className="label">
                        {t("Asset Detail Page.description")}:
                      </span>
                    </div>
                    <span className="description-text">
                      {this.state.assetDescription}
                    </span>
                  </div>
                )}
                {/* <div className="campaign-year">
                  <span className="label">
                    {t("Asset Detail Page.campaign-year")}:
                  </span>
                  <span className="content">
                    {this.state.assetCampaignYear}
                  </span>
                </div> */}
                <div className="file-size">
                  <span className="label">{t("Asset Detail Page.file-size")}:</span>
                  <span className="content">{this.state.assetFileSize}</span>
                </div>
                <div className="file-type">
                  <span className="label">{t("Asset Detail Page.file-type")}:</span>
                  <span className="content">{this.state.assetFileType}</span>
                </div>

                {this.state.show_hcp_disclaimer && (
                  <div className="hcp-disclaimer-text">
                    <span className="icon">
                      <i class="bi bi-exclamation-triangle"></i>
                    </span>
                    <span className="text">
                      {t("Asset Detail Page.hcp-disclaimer")}
                    </span>
                  </div>
                )}
                <div className="button-wrapper">
                  {countryCode === "de" && (
                    <div className="request-button" style={{fontFamily:"Neue Helvetica Bold Italic !important"}}>
                    {this.getRequestButton(this.state.assetTitle)}
                    </div>
                  )}
                  { this.props.RoleSettings && this.props.RoleSettings['Download Content'] === true? this.downloadButton(this.state.assetDetailData): ""}
                </div>

                <div>
                  <OverlayTrigger
                    trigger="click"
                    placement="bottom"
                    overlay={this.popover}
                    onToggle={show => {
                      this.setState({ popOver: show });
                    }}
                    show={this.state.popOver}
                  >
                    {/*<Button variant="success">Click me to see</Button>*/}
                    {/* <div className="report-flag" ref={this.myRef}>
                        <FontAwesomeIcon icon={faFlag} />
                      </div> */}
                    <div
                      className="reportIssue my-2"
                      style={{
                        marginLeft: "10px",
                        color: "#0091df",
                        cursor:"pointer",
                        fontSize: "18px",
                        fontFamily: "Neue Helvetica",
                        fontWeight: "600",
                        textDecoration: "underline"
                      }}
                      ref={this.myRef}
                    >
                      <span>{t("Miscellaneous.reportIssue")} </span>
                      <i class="bi bi-exclamation-octagon"></i>
                    </div>
                  </OverlayTrigger>
                </div>              
              </div>
            </div>
            <div className="asset-carousel">
              <div className="carousel-items-count">
                {this.state.availableAssets.length > 0 && (
                  <span>
                    {t("Asset Detail Page.also-available")}
                    {" : "}
                  </span>
                )}
              </div>
              <Slider {...settings}>
                {this.state.availableAssets.map((asset, index) => {
                  return <CardComponent item={asset} index={index} />;
                })}
              </Slider>
            </div>
          </div>
        </div>
      </React.Fragment>
     )}
     </React.Fragment>
    )
    
  }
}
const AssetDetailPage = withTranslation("common")(AssetDetail);
const mapStateToProps = state => {
  return {
    assetType: state.filter.assets,
    productsList: state.product.productsList,
    assetsList: state.asset.assetsList,
    favAssetsList: state.asset.favAssetsList,
    countrySettings: state.country.countryData,
    RoleSettings: state.role.countryData,
    CartAssetsList: state.asset.CartAssetsList,
    countryLocale: state.locale.countryLocale
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateFavAssetsList: ownProps => dispatch(updateFavAssetsList(ownProps)),
    updateSetRenderAssetList:ownProps => dispatch(updateSetRenderAssetList(ownProps)),
    updateCartAssetsList: ownProps => dispatch(updateCartAssetsList(ownProps)),
    updateSetRenderCartAssetList: ownProps => dispatch(updateSetRenderCartAssetList(ownProps))
  };
};
export default compose(
  withStyles(styles),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(AssetDetailPage);
