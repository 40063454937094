import {
  FETCH_ASSETS_REQUEST,
  FETCH_ASSETS_SUCCESS,
  FETCH_ASSETS_ERROR,
  UPDATE_ASSETS,
  UPDATE_RENDER_ASSET_LIST,
  FETCH_FAVASSETS_SUCCESS,
  FETCH_FAVASSETS_ERROR,
  UPDATE_FAVASSETS_LIST,
  FETCH_CARTASSETS_SUCCESS,
  FETCH_CARTASSETS_ERROR,
  UPDATE_CARTASSETS_LIST
} from "./assetTypes";

const initialState = {
  loading: true,
  assetsList: [],
  renderAssetList: [],
  favAssetsList: [],
  CartAssetsList:[],
  error: ""
};

const assetReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ASSETS_REQUEST:
      return { ...state, loading: true };

    case FETCH_ASSETS_SUCCESS:
      return {
        ...state,
        loading: false,
        assetsList: action.payload,
        error: ""
      };

    case FETCH_ASSETS_ERROR:
      return {
        loading: false,
        assetsList: [],
        renderAssetList: [],
        error: action.payload
      };
    case UPDATE_ASSETS:
      return {
        ...state,
        assetsList: action.payload
      };
    case UPDATE_RENDER_ASSET_LIST:
      return {
        ...state,
        renderAssetList: action.payload
      };
    case FETCH_FAVASSETS_SUCCESS:
      return {
        ...state,
        favAssetsList: action.payload
      };
    case FETCH_FAVASSETS_ERROR:
      return {
        ...state,
        favAssetsList: action.payload
      };
    case UPDATE_FAVASSETS_LIST:
      return {
        ...state,
        favAssetsList: action.payload
      };
      case FETCH_CARTASSETS_SUCCESS:
        return {
          ...state,
          CartAssetsList: action.payload
        };
      case FETCH_CARTASSETS_ERROR:
        return {
          ...state,
          CartAssetsList: action.payload
        };
      case UPDATE_CARTASSETS_LIST:
        return {
          ...state,
          CartAssetsList: action.payload
        };
    default:
      return state;
  }
};

export default assetReducer;
