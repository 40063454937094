import React, { Component } from "react";
//import Fuse from "fuse.js";
import { connect } from "react-redux";
import { Accordion, Card, Button, Form } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import {
  setFilterBrandName,
  setFilterAssetTypes,
  setFilterYear,
  setFilterFileTypes,
  updateAssets,
  updateRenderProductList,
  updateRenderAssetList,
  updateFilterBrandParam,
  updateFilterAssetTypeParam,
  updateFilterRemParam
} from "../../redux";
import CheckBox from "../checkBox/checkBox";
import "./searchFilter.scss";
import { Scrollbars } from "react-custom-scrollbars";
import qs from "qs";
import _ from "lodash";
import "./searchFilter.scss";
import { withTranslation } from "react-i18next";
import MediaQuery from "react-responsive";

const env = process.env.REACT_APP_ENVIRONMENT;
let ga_env = env === "PROD" ? "Production" : "Testing";
window.dataLayer = window.dataLayer || [];

class SearchFilter extends Component {
  constructor(props) {
    super(props);
    const { t } = props;
    this.state = {
      filterNames: [
        { name: t("Filter and Sort.brand"), value: "brand", id: "1" },
        { name: t("Filter and Sort.asset_type"), value: "asset_type", id: "2" },
        { name: t("Filter and Sort.file_type"), value: "file_types", id: "3" }
      ],
      input: ""
    };
  }

  inputRefs = [];
  setRef = ref => {
    this.inputRefs.push(ref);
  };

  async componentDidMount() {
    let asset_type = this.props.assetType;
    this.props.setFilterAssetTypes(asset_type);
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  setFilterOnload = assets => {
    const brandFilter = this.props.filterBrand;
    const brandList = [].concat(this.props.brandList);

    if (brandFilter.hasOwnProperty("brand")) {
      brandList.forEach(brand => {
        for (let key in brandFilter) {
          if (
            brandFilter[key].includes(brand.brandName) ||
            brandFilter[key].includes(brand.sub_brand)
          ) {
            brand.itemChecked = true;
          } else {
            brand.itemChecked = false;
          }
        }
      });
    }
    this.props.setFilterBrandName(brandList);
    const assetParams = this.props.filterAsset;
    let assetTypes;
    if (assetParams.hasOwnProperty("assettype")) {
      const paramType = Object.getOwnPropertyNames(this.props.filterAsset)[0];
      const paramValue = assetParams[paramType];
      const paramObj = {};
      paramObj[paramType] = [];
      paramObj[paramType].push(paramValue[0]);
      let assetState = [].concat(assets);
      assetTypes = this.setFilterChecked(assetState, paramValue[0], true);
      this.props.setFilterAssetTypes(assetTypes);
    }
  };
   urlHandlingFunction =(pathValue)=>{
    if( pathValue.includes("searchKey=")){
      if(pathValue.includes("assettype") && pathValue.includes("brand")  ){

        const indexOfAssettype = pathValue.indexOf("assettype");
        const indexOfBrandtype = pathValue.indexOf("brand");

        const SearchKeyType= pathValue.slice(0, 10 )
       
        if(indexOfBrandtype < indexOfAssettype){
          let indexOfAssettype = pathValue.indexOf("assettype");
          //let indexOfBrandtype = pathValue.indexOf("brand");
          const afterAssettype = pathValue.slice(indexOfAssettype);
          const beforeAssettype = pathValue.slice(10, indexOfAssettype);

          const resultString = SearchKeyType + "&" + beforeAssettype + afterAssettype;
          this.props.history.replace(`/search-page/?${resultString}`);

        }
        else{
          //let indexOfAssettype = pathValue.indexOf("assettype");
          let indexOfBrandtype = pathValue.indexOf("brand");
          const beforeAssettype = pathValue.slice(10, indexOfBrandtype);
          const afterAssettype = pathValue.slice(indexOfBrandtype);
    
          const resultString = SearchKeyType +"&" +  afterAssettype + beforeAssettype;
          this.props.history.replace(`/search-page/?${resultString}`);
         
        }
       
    
      }
      else{
        this.props.history.replace(pathValue ? `/search-page/?${pathValue}` : `/search-page/?searchKey=`);
               
      }
    }
    else{
      if(pathValue.includes("assettype") && pathValue.includes("brand")  ){

        const indexOfAssettype = pathValue.indexOf("assettype");
        const indexOfBrandtype = pathValue.indexOf("brand");
 
        if(indexOfBrandtype < indexOfAssettype){
          let indexOfAssettype = pathValue.indexOf("assettype");
          //let indexOfBrandtype = pathValue.indexOf("brand");
          const afterAssettype = pathValue.slice(indexOfAssettype);
          const beforeAssettype = pathValue.slice(0, indexOfAssettype);
 
          const resultString = beforeAssettype + "&" + afterAssettype;
          this.props.history.replace(`/search-page/?${resultString}`);
        }
        else{
          //let indexOfAssettype = pathValue.indexOf("assettype");
          let indexOfBrandtype = pathValue.indexOf("brand");
          const beforeAssettype = pathValue.slice(0, indexOfBrandtype);
          const afterAssettype = pathValue.slice(indexOfBrandtype);
    
          const resultString =  afterAssettype +"&" + beforeAssettype;
          this.props.history.replace(`/search-page/?${resultString}`);

        }
       
    
      }
      else{
        this.props.history.replace(pathValue ? `/search-page/?${pathValue}` : `/search-page/?searchKey=`);        
      }
    }
   
  }
  checkAllFilter = (obj, checkVal) => {
    let checkedVal = checkVal;
    if (obj.hasOwnProperty("items")) {
      let subFilter = obj.items;
      subFilter.forEach(obj => {
        obj.itemChecked = checkedVal;
        this.checkAllFilter(obj, checkedVal);
      });
    }
  };

  setFilterChecked = (objectState, paramValue, checkVal) => {
    let val = paramValue;
    let checkedVal = checkVal;
    objectState.forEach(obj => {
      if (obj.asset_type === val) {
        obj.itemChecked = checkedVal;
        this.checkAllFilter(obj, checkedVal);
      } else {
        if (obj.hasOwnProperty("items")) {
          this.setFilterChecked(obj.items, val, checkedVal);
        }
      }
    });
    return objectState;
  };

  handleFilterRemOnChange = event => {
    const queryParams = this.props.location.search;
    let filterObject = qs.parse(queryParams, { ignoreQueryPrefix: true });
    if (_.has(filterObject, "showNewDigitalContent")) {
      filterObject = _.omit(filterObject, ["showNewDigitalContent"]);
    }
    const paramValue =event.target.value;
    const paramType = event.target.getAttribute("filtertype");
    const paramObj = {};
   
    if (event.target.checked === true) {
      paramObj[paramType] = [];
      paramObj[paramType].push(paramValue);
      if (filterObject.hasOwnProperty(paramType)) {
        if (!filterObject[paramType].includes(paramValue)) {
          filterObject[paramType].push(paramValue);
        }
      } else {
        Object.assign(filterObject, paramObj);
      }
      window.dataLayer.push({
        event: "gaGenericEvent",
        eventCategory: "Filter",
        eventAction: paramValue,
        eventLabel: "File Type",
        environment: ga_env
      });
    } else {
      if (_.has(filterObject, paramType)) {
        const len = filterObject[paramType].length;
        const index = filterObject[paramType].indexOf(paramValue);
        switch (index) {
          case 0:
            filterObject[paramType].shift();
            if (filterObject[paramType].length === 0) {
              delete filterObject[paramType];
            }
            break;
          case len - 1:
            filterObject[paramType].pop();
            break;
          default:
            filterObject[paramType].splice(index, 1);
            break;
        }
      }
    }

    const searchParams = qs.stringify(filterObject);
    this.urlHandlingFunction(searchParams)
     
  };

  fetchBrand = event => {
    const queryParams = this.props.location.search;
    let queryObject = qs.parse(queryParams, { ignoreQueryPrefix: true });
    if (_.has(queryObject, "showNewDigitalContent")) {
      queryObject = _.omit(queryObject, ["showNewDigitalContent"]);
    }

    if (event !== undefined) {
      const paramValue = event.target.value;
      const paramType = "brand";
      const paramObject = {};
      if (event.target.checked === true) {
        paramObject[paramType] = [];
        paramObject[paramType].push(paramValue);
        if (_.has(queryObject, "brand")) {
          if (!queryObject[paramType].includes(paramValue)) {
            queryObject[paramType].push(paramValue);
          }
        } else {
          Object.assign(queryObject, paramObject);
        }
        window.dataLayer.push({
          event: "gaGenericEvent",
          eventCategory: "Filter",
          eventAction: paramValue.charAt(0).toUpperCase()+paramValue.slice(1).toLowerCase(),
          eventLabel: "Brand",
          environment: ga_env
        });
      } else {
        if (_.has(queryObject, "brand")) {
          const len = queryObject[paramType].length;
          const index = queryObject[paramType].indexOf(paramValue);
          switch (index) {
            case 0:
              queryObject[paramType].shift();
              if (queryObject[paramType].length === 0) {
                delete queryObject[paramType];
              }
              break;
            case len - 1:
              queryObject[paramType].pop();
              break;
            default:
              queryObject[paramType].splice(index, 1);
              break;
          }
        }
      }
    }

    const searchParams = qs.stringify(queryObject);
    this.urlHandlingFunction(searchParams)
     
  };

  fetchAsset = event => {
    const queryParams = this.props.location.search;
    let queryObject = qs.parse(queryParams, { ignoreQueryPrefix: true });
    if (_.has(queryObject, "showNewDigitalContent")) {
      queryObject = _.omit(queryObject, ["showNewDigitalContent"]);
    }

    if (event !== undefined) {
      const paramValue = event.target.value;
      const paramType = "assettype";
      const paramObject = {};
      if (event.target.checked === true) {
        paramObject[paramType] = [];
        paramObject[paramType].push(paramValue);
        if (_.has(queryObject, "assettype")) {
          if (!queryObject[paramType].includes(paramValue)) {
            queryObject[paramType].push(paramValue);
          }
        } else {
          Object.assign(queryObject, paramObject);
        }
        window.dataLayer.push({
          event: "gaGenericEvent",
          eventCategory: "Filter",
          eventAction: paramValue,
          eventLabel: "Asset Type",
          environment: ga_env
        });
      } else {
        if (_.has(queryObject, "assettype")) {
          const len = queryObject[paramType].length;
          const index = queryObject[paramType].indexOf(paramValue);
          switch (index) {
            case 0:
              queryObject[paramType].shift();
              if (queryObject[paramType].length === 0) {
                delete queryObject[paramType];
              }
              break;
            case len - 1:
              queryObject[paramType].pop();
              break;
            default:
              queryObject[paramType].splice(index, 1);
              break;
          }
        }
      }
    }
    const searchParams = qs.stringify(queryObject);
    this.urlHandlingFunction(searchParams)
     
  };

  createAssetParam = (objectState, assetParam) => {
    let paramValue;
    const paramType = "assettype";
    const paramObj = {};
    let fetchAssetParam = assetParam;
    paramObj[paramType] = [];
    objectState.forEach(obj => {
      if (obj.itemChecked === true) {
        if (obj.hasOwnProperty("items")) {
          this.createAssetParam(obj.items, fetchAssetParam);
        } else {
          paramValue = obj.asset_type;
          paramObj[paramType].push(paramValue);
          if (fetchAssetParam.hasOwnProperty(paramType)) {
            if (!fetchAssetParam[paramType].includes(paramValue)) {
              fetchAssetParam[paramType].push(paramValue);
            }
          } else {
            Object.assign(fetchAssetParam, paramObj);
          }
        }
      } else {
        if (obj.hasOwnProperty("items")) {
          this.createAssetParam(obj.items, fetchAssetParam);
        } else {
          paramValue = obj.asset_type;
          if (fetchAssetParam.hasOwnProperty(paramType)) {
            if (fetchAssetParam[paramType].includes(paramValue)) {
              const len = fetchAssetParam[paramType].length;
              const index = fetchAssetParam[paramType].indexOf(paramValue);
              switch (index) {
                case 0:
                  fetchAssetParam[paramType].shift();
                  if (fetchAssetParam[paramType].length === 0) {
                    delete fetchAssetParam[paramType];
                  }
                  break;
                case len - 1:
                  fetchAssetParam[paramType].pop();
                  break;
                default:
                  fetchAssetParam[paramType].splice(index, 1);
                  break;
              }
            }
          }
        }
      }
    });

    return fetchAssetParam;
  };

  createAssetFilter = assetTypeList => {
    let subAssetFilter = [];
    const assetList = assetTypeList.filter(assetType =>
      assetType.translated.includes(this.state.input)
    );

    for (let asset in assetList) {
      const assetItem = assetList[asset];
      if (assetItem.hasOwnProperty("items")) {
        subAssetFilter.push(
          <div className="parent-filter">
            <CheckBox
              handleFilterRemOnChange={event => this.fetchAsset(event)}
              {...assetItem}
            />
            <ul>{this.createAssetFilter(assetItem.items)}</ul>
          </div>
        );
      } else {
        subAssetFilter.push(
          <li>
            <CheckBox
              handleFilterRemOnChange={event => this.fetchAsset(event)}
              {...assetItem}
            />
          </li>
        );
      }
    }
    return subAssetFilter;
  };

  renderFilter = (param, props) => {
    switch (param) {
      case "brand":
        const list = this.props.brandList
       
          .filter(
            brand =>
              this.state.input === "" ||
              brand.brandName.includes(this.state.input)
          
              // brand.brandName.charAt(0).toUpperCase() +brand.brandName.slice(1).toLowerCase()
          )
          .map(item => {
            return (
              <CheckBox
                handleFilterRemOnChange={event => this.fetchBrand(event, props)}
                {...item}
              />
            );
          });

        return list;

      /*  case "publication_year":
        return this.props.publicationYear.map(item => {
          return (
            <CheckBox
              handleFilterRemOnChange={event => this.handleFilterRemOnChange(event)}
              {...item}
            />
          );
        }); */
      case "file_types":
        return this.props.fileTypes
          .filter(
            fileType =>
              this.state.input === "" ||
              fileType.file_type.includes(this.state.input)
          )
          .map(item => {
            return (
              <CheckBox
                handleFilterRemOnChange={event =>
                  this.handleFilterRemOnChange(event)
                }
                {...item}
              />
            );
          });

      case "asset_type":
        const { t } = this.props;
        const assetList = [...this.props.assetType];
        const translatedAssetList = assetList.map(i => ({
          ...i,
          translated: t(`Asset Type.${i.asset_type.dcAssetType}`)
        }));

        const sortedAssetList = Array.from(translatedAssetList).sort((a, b) => {
          return a.translated.localeCompare(b.translated, "de", {
            sensitivity: "base"
          });
        });
        return this.createAssetFilter([...sortedAssetList]);
      default:
        return null;
    }
  };

  handleToggle = (e, id) => {
    this.inputRefs
      .filter((elem, index) => index !== id)
      .forEach(elem => elem.parentElement.classList.remove("open"));

    this.inputRefs[id].parentElement.classList.toggle("open");
    this.setState({ input: "" });
    this.inputRefs[id].firstElementChild.value = "";
  };

  onChangeHandler = e => {
    this.setState({
      input: e.target.value
    });
  };

  handleClickOutside = event => {
    this.inputRefs.forEach(ref => {
      if (
        ref != null &&
        ref.parentElement.parentElement.childElementCount > 1 &&
        !ref.parentElement.parentElement.contains(event.target)
      ) {
        ref.parentElement.classList.contains("open") && ref.click();
      }
    });
  };

  render() {
    return (
      <React.Fragment>
        <div className="filterBar">
          <MediaQuery minWidth={992}>
            <ul>
              {this.state.filterNames.map((filterName, index) => {
                return (
                  <li className="filter-set">
                    <div>
                      <div className="filter-name">{filterName.name}</div>
                      <div className="filter-options">
                        {this.renderFilter(filterName.value)}
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </MediaQuery>
          <MediaQuery maxWidth={991}>
            <Accordion>
              {this.state.filterNames.map((filterName, index) => {
                return (
                  <Card className={filterName.value} key={filterName.id}>
                    <Card.Header className={filterName.value}>
                      <Accordion.Toggle
                        as={Button}
                        variant="link"
                        eventKey={index + 1}
                        onClick={e => this.handleToggle(e, index)}
                        id={index}
                        key={index}
                        ref={this.setRef}
                      >
                        <Form.Control
                          placeholder={filterName.name}
                          onChange={this.onChangeHandler}
                          //onClick={e => this.handleClickInput(e, index)}
                        />
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={index + 1}>
                      <Card.Body>
                        <Scrollbars style={{ width: "100%", height: 300 }}>
                          {this.renderFilter(filterName.value)}
                        </Scrollbars>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                );
              })}
            </Accordion>
          </MediaQuery>
        </div>
      </React.Fragment>
    );
  }
}
const SearchFilterPage = withTranslation("common")(SearchFilter);
const mapStateToProps = state => {
  return {
    productsList: state.product.productsList,
    assetsList: state.asset.assetsList,
    brandList: state.filter.brand,
    assetType: state.filter.assets,
    publicationYear: state.filter.publicationYear,
    fileTypes: state.filter.fileTypes,
    filterBrand: state.filter.filterBrand,
    filterAsset: state.filter.filterAsset,
    filterRem: state.filter.filterRem,
    renderProductList: state.product.renderProductList,
    renderAssetList: state.asset.renderAssetList
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setFilterBrandName: ownProps => dispatch(setFilterBrandName(ownProps)),
    setFilterAssetTypes: ownProps => dispatch(setFilterAssetTypes(ownProps)),
    setFilterYear: ownProps => dispatch(setFilterYear(ownProps)),
    setFilterFileTypes: ownProps => dispatch(setFilterFileTypes(ownProps)),
    updateAssets: ownProps => dispatch(updateAssets(ownProps)),
    updateRenderProductList: ownProps =>
      dispatch(updateRenderProductList(ownProps)),
    updateRenderAssetList: ownProps =>
      dispatch(updateRenderAssetList(ownProps)),
    updateFilterBrandParam: ownProps =>
      dispatch(updateFilterBrandParam(ownProps)),
    updateFilterAssetTypeParam: ownProps =>
      dispatch(updateFilterAssetTypeParam(ownProps)),
    updateFilterRemParam: ownProps => dispatch(updateFilterRemParam(ownProps))
  };
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SearchFilterPage);
