import {
  FETCH_COUNTRY_CODE_REQUEST,
  FETCH_COUNTRY_CODE_SUCCESS,
  FETCH_COUNTRY_CODE_ERROR,
  UPDATE_COUNTRY_CODE
} from "./countryCodeTypes";

export const fetchCountryCodeRequest = () => ({
  type: FETCH_COUNTRY_CODE_REQUEST,
});

export const fetchCountryCodeSuccess = countryCode => ({
  type: FETCH_COUNTRY_CODE_SUCCESS,
  payload: countryCode,
});

export const fetchCountryCodeError = error => ({
  type: FETCH_COUNTRY_CODE_ERROR,
  payload: error,
});

export const setRenderCountryCode = countryCode => ({
  type: UPDATE_COUNTRY_CODE,
  payload: countryCode,
});
export const updateRenderCountryCode = ownProps => {
  return dispatch => dispatch(setRenderCountryCode(ownProps));
};




