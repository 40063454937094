import React, { useEffect } from 'react'
import DropDownFilters from './DropDownFilters'

const Privacy = (props) => {
  const { title } = { ...props };
  useEffect(() => {
    document.title = title;
  }, [title]);
  return (
    <div>
      <DropDownFilters/>
    </div>
  )
}

export default Privacy