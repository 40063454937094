import React, { Component } from 'react';
import errorPage from "../sass/assets/errorPage.png"
import DCLogo from "../assets/DC-logo.png";
import config from "../config.js";
import axios from "axios";
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
const env = process.env.REACT_APP_ENVIRONMENT; 
let ga_env = env === "PROD" ? "Production" : "QA";

class ErrorBoundaryComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      userId: localStorage.email,
      lang: ""
    };
  }



  sendErrorResponse= async () => {
    const { userId } = this.state; 
     const response = await axios({
        method: "post",
        url: `${config.baseApi}dc/config/reprtAndRedrct`,
        data: {
          mailAddressOfUser: userId,
          errorDescription: this.state.error ? this.state.error.toString() : "Not Available",
          pageWhereErrorOccured: window.location.pathname,
          environment: ga_env
        },
      })
    return response
}

componentDidMount() {
  this.langTranslation(this.props.countrySettings);
}

componentDidUpdate(prevProps) {
  const { countrySettings } = this.props;
  if (countrySettings !== prevProps.countrySettings) {
    this.langTranslation(countrySettings);
  }
}

setLang(lang) {
  this.setState({ lang });
}

langTranslation(userCountry) {
  this.setLang(userCountry?.locale);
  // if (userCountry === "de") {
  //   this.setLang("de-DE");
  // } else if (userCountry === "fr") {
  //   this.setLang("fr-BE");
  // } else if (userCountry === "nl") {
  //   this.setLang("nl-NL");
  // } else if (userCountry === "us") {
  //   this.setLang("en-US");
  // } else if (userCountry === "be") {
  //   this.setLang("nl-NL");
  // } else {
  //   this.setLang("en-US");
  // }
}

getTranslation(lang, label) {
  const translation = this.props.countrySettings?.accountRequestPageTranslations?.find(item =>
    item?.location === "Account Request Page" &&
    item?.labels === label &&
    item[lang]
  );
  if (translation) {
    return translation[lang];
  } else {
    const defaultTranslation = this.props.countrySettings?.accountRequestPageTranslations?.find(item =>
      item?.location === "Account Request Page" &&
      item?.labels === label &&
      item?.defaultValue
    );
    if (defaultTranslation) {
      return defaultTranslation.defaultValue;
    } else {
      return "";
    }
  }
}

  componentDidCatch(error, info) {
    this.setState({ hasError: true, error: error });
  }

  handleClick = () => {
    this.sendErrorResponse()
      .then(response => {
        localStorage.setItem('errorData', JSON.stringify(response.data)); 
        window.location.href = '/'; 
      })
      .catch(error => {
        console.error("Error in POST call:", error);
        window.location.href = '/';
      });
  };

  render() {
    const { t } = this.props;
    const { lang } = this.state;
    if (this.state.hasError) {
      return (
        <div style={{ position: "relative", overflow: "hidden" }}>
          <img src={DCLogo} alt="DC-Logo" style={{ position: "absolute", opacity: 0.1, width: "-webkit-fill-available", height: "-webkit-fill-available", zIndex: -1, transform: "rotate(-8deg)" }} />
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "auto" }}>
            <img src={errorPage} alt="Error" />
            <div style={{ textAlign: "center" }}>
              <h1>{localStorage.getItem("LoggedIn") === "false" ? this.getTranslation(lang, "errorFirstLine"): t("Account Request Page.errorFirstLine")}</h1>
              <h3>{localStorage.getItem("LoggedIn") === "false" ? this.getTranslation(lang, "errorSecondLine"): t("Account Request Page.errorSecondLine")}</h3>
              <p>{localStorage.getItem("LoggedIn") === "false" ? this.getTranslation(lang, "errorLoginThirdLine"): t("Account Request Page.errorThirdLine")}</p>
              <button style={{ backgroundColor: "#0091df", color: "white", border: "12px solid rgb(0, 145, 223)", borderRadius: "25px", cursor: "pointer" }} onClick={this.handleClick}>
              {localStorage.getItem("LoggedIn") === "false" ? this.getTranslation(lang, "errorLoginRedirect"): t("Account Request Page.errorRedirectHome")}
                </button>
            </div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

const ErrorBoundary = withTranslation("common")(ErrorBoundaryComponent);

const mapStateToProps = state => ({
  countrySettings: state.country.countryData
});

export default connect(mapStateToProps)(ErrorBoundary);

