import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./feedbackPage.scss";

const FeedbackPage = props => {
  const { title } = { ...props };
  useEffect(() => {
    document.title = title;
  }, [title]);
  const { t } = useTranslation("common");
  return (
    <Container fluid className="feedback">
      <Row className="back justify-content-center align-content-center">
        <div className="content col-6">
          <div className="message">
            <h2>{t("Miscellaneous.thank-you-text")}</h2>
            <span>{t("Miscellaneous.fill-survey-text")}</span>
            <button>
              <a href={document.location.origin}>
                {t("Miscellaneous.back-to-login-button")}
              </a>
            </button>
          </div>
        </div>
      </Row>
    </Container>
  );
};
export default FeedbackPage;
