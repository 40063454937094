import React, { useState } from "react";

const AuthContext = React.createContext({
  token: "",
  email:"",
  userProfile: {},
  isLoggedIn: false,
  getIdToken: () => { },
  login: token => { },
  logout: token => { },
  giveProvider: () => { },
  giveToken: () => { },
  giveEmail: () => { },
});


const isTokenValid = (token) => {
  try {
    const decodedJwt = JSON.parse(atob(token.split(".")[1]));
    if (decodedJwt.exp * 1000 > Date.now()) {
      // console.log("TOKEN IS VALIDDDDDDDD FOR ", (decodedJwt.exp * 1000 - Date.now()) / 1000 + " seconds")
      return true;
    } else {
      // console.log("TOKEN IS INNNNNVALIDDDDDDDD", decodedJwt.exp * 1000, Date.now())
      return false;
    }
  } catch (e) {
    // console.log("TOKEN IS INNNNNVALIDDDDDDDD", e)
    return false;
  }
};

export const AuthContextProvider = props => {
  const initialToken = localStorage.getItem("token");
  //const initialEmail = localStorage.getItem("email");
  const [token, setToken] = useState(initialToken);
  const [email, setEmail] = useState("");
  const [userProfile, setProfile] = useState();
  const [provider, setProvider] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(!!token);

  // useEffect(() => {
  //   console.log("idtoken value here is ->", idToken);
  //   const intervalId = setInterval(() => {
  //     if (idToken && !isTokenValid(idToken)) {
  //       console.log("token is invalid");
  //       setIsLoggedIn(false);
  //     }
  //   }, 3000);
  //   return () => clearInterval(intervalId);

  // }, [idToken]);

  const gigya = window.gigya;
  const loginHandler = (token, provider, userProfile, idToken, email) => {
    setToken(token);
    setProvider(provider);
    setProfile(userProfile);
    setIsLoggedIn(true);
    setEmail(email)
    localStorage.setItem("token", token);
    localStorage.setItem("idToken", idToken);
    localStorage.setItem("mass-download", true)
    localStorage.setItem("email", userProfile?.email);
    localStorage.setItem("stringEmail", JSON.stringify(userProfile?.email));
    localStorage.setItem("userProfile", JSON.stringify(userProfile));
  };
  const logoutHandler = () => {
    setToken(null);
    setEmail(null)
    setProvider(null);
    setProfile(null);
    setIsLoggedIn(false);
    localStorage.removeItem("token");
    localStorage.removeItem("idToken");
    localStorage.removeItem("email");
    localStorage.removeItem("stringEmail");
    localStorage.removeItem("userProfile");
    localStorage.removeItem("mass-download")
  };
  const giveProvider = () => {
    return provider;
  };
  const giveToken = () => {
    return token;
  };
  const giveEmail = () => {
    return email;
  };
  const getIdToken = () => {
    return new Promise((resolve, reject) => {
      const storedToken = localStorage.getItem("idToken");
      if(storedToken && isTokenValid(storedToken)) {
        resolve(storedToken);
      } else {
        gigya.accounts.getJWT({
          "UID": token, callback: function (response) {
            if (response.id_token) {
              localStorage.setItem("idToken", response.id_token);
              resolve(response.id_token);
            } else {
              setIsLoggedIn(false);
              reject(response);
            }
          }
        });
      }
    });
  };

  const contextValue = {
    token: token,
    email: email,
    userProfile: userProfile,
    isLoggedIn: isLoggedIn,
    login: loginHandler,
    getIdToken: getIdToken,
    logout: logoutHandler,
    giveProvider: giveProvider,
    giveToken: giveToken,
    giveEmail: giveEmail
  };


  // useEffect(() => {
  //   gigya.accounts.getJWT({
  //     "UID": token, callback: function (response) {
  //       if(response.id_token) {
  //         setIdToken(response.id_token);
  //       } else {
  //         console.log("#####", response);
  //       }
  //     }
  //   });

  // }, [gigya.accounts, token]);

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
