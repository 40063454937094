import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";
import "./backdrop.scss";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const SimpleBackdrop = () => {
  const classes = useStyles();
 // const [open, setOpen] = React.useState(true);
 var open = true;
  function getCookie(name) {
    var dc = document.cookie;
    var prefix = name + "=";
    var begin = dc.indexOf("; " + prefix);
    if (begin === -1) {
      begin = dc.indexOf(prefix);
      if (begin !== 0) return null;
    } else {
      begin += 2;
      var end = document.cookie.indexOf(";", begin);
      if (end === -1) {
        end = dc.length;
      }
    }
    return decodeURI(dc.substring(begin + prefix.length, end));
  }
  const status = getCookie("cookieconsent_status");
  if(status){
      open=false;
  }
  return <Backdrop className={classes.backdrop +" cookieBackdrop"} open={open} />;
};

export default SimpleBackdrop;
