import React,{ useEffect, useState, useContext, useRef, useCallback }  from "react";
import { Button, Pagination, Modal, Form} from 'react-bootstrap'
import { Box, Container, FormControl, InputLabel, MenuItem, Select, TextField} from "@material-ui/core";
import "./MyExports.scss";
import jsonData from './MyExports.json'
import FileSaver from "file-saver";
import { useTranslation } from "react-i18next";
import AuthContext from "../../components/Auth/Auth-context";
import config from "../../config.js";
import {
  getCountryCode,
} from "../../util/Helper.js";
import { compose } from "redux";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import MaterialTable from "material-table";
import { forwardRef } from 'react';
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { makeStyles} from '@mui/styles';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import { Select as MuiSelect, FormControl as MuiFormControl, InputLabel as MuiInputLabel} from "@mui/material";
import { IconButton } from "@material-ui/core";
import {  useNavigate} from "../../hooks";
import { Delete } from "@mui/icons-material";
import CloudUpload from "@mui/icons-material/CloudUpload";
import ReactTags from 'react-tag-autocomplete'
// import { ToastContainer, toast } from 'react-toast'
import toast, { Toaster } from 'react-hot-toast';

let downloadedAssets=[]
let downloadArray=[]
let implementationArray =[]
let downloadArrayData =[]
let getRoleName =""
let getRoleGroup=""
// let downloadArrayDataNew =[]
const  MyExports=(props)=> {
    const { navigate } = useNavigate();
    let { RoleSettings } = { ...props };
    const { t } = useTranslation("common");
    const [curPage, setCurPage] = useState(1);
   // const dataFromJson = jsonData.data.Items;
    const [show, setShow] = useState(false);
    const [showMailModal, setShowMailModal] = useState(false);
    const [showSecondModal, setShowSecondModal] = useState(false);
    const [retailerModal, setRetailerModal] = useState(false);
    const [responseModal, setResponseModal] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(false)
    const [data, setData]= useState([]);
    const [retailerViewData, setRetailerViewData]= useState([]);
    const [editIndex, setEditIndex] = useState();
    const [editAssetName, setEditAssetName] = useState()
    // const [roleName, setRoleName] = useState("");
    // const [retailerGroup, setRetailerGroup] = useState("");
    const [retailerCode, setRetailerCode] = useState("")
    const [retailerCodeResponse, setRetailerCodeResponse] = useState("");
const [retailerGroupName,setRetailerGroupName]=useState();
    //const [rowsData, setRowsData] = useState(dataFromJson);
    const [rowsData, setRowsData] = useState([]);
      // eslint-disable-next-line
    const [gigyaRole, setGigyaRole] = useState("")
    const [storingData, setstoringData] = useState("")
const [notification,setNotification]=useState({})
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);
    const [flag, setFlag] = useState(false);
    const axios = require("axios")
    const AuthCtx = useContext(AuthContext);
    const userGigyaId = AuthCtx.giveToken();
    const userId= localStorage.email
    const [downloadedAssetsNew, updateDownloadedAssetsNew] = useState([]);
    const [filter, setFilter] = useState(false)
    const [iconChange, setIconChange] = useState(true)
     const [countryFilter, setcountryFilter] = useState("All");
     const [countryOptions, setCountryOptions] = useState([]);
     const [originalCountryOptions, setOriginalCountryOptions] = useState([]);
  const [dragActive, setDragActive] = useState(false);
  const [optionList, setOptionList] = useState([]);
  const [fName, setFName] = useState("")
  // const [allUpcCodes, setAllUpcCodes] = useState([]);
  const [text, setText] = useState([]);
  // const [suggestions, setSuggestions] = useState([]);
  //  const [addedUpc, setAddedUpc] = useState([]);
  //  const [fileName, setFileName] = useState("");
   const [selectedRetailer, setSelectedRetailer] = useState("");
   const [inputValue, setInputValue] = useState('');
   const [fType, setFType] = useState("")
   const [fSize, setFSize] = useState("")
   const [uploadFile, setUploadFile] = useState(false)
  //  const [showCancel, setShowCancel] = useState(false);
   const [countryFilterEdit, setcountryFilterEdit] = useState("");
  //  const [fCountry, setFCountry] = useState("");
   const [sK, setSK] = useState("");
   const [totalUpcCount, setTotalUpcCount]= useState(0)
   const [suggestionCodes, setSuggestionCodes] = useState([]);
   const [clearValue, setClearValue] = useState(''); // State to hold the input value
   const inputRefNew = useRef(null);
  const inputRef = useRef(null);

    const countryCode = getCountryCode();
    const filterHandler = () => {
        setFilter(!filter)
        setIconChange(!iconChange)
      }

    const mailHandlerClose = () => setShowMailModal(false); 
    const handleClose = () => setShow(false);
    const handleCloseSecond = () => setShowSecondModal(false);
    const handleCloseRetailerModal = () => {
        setRetailerModal(false);}
    const handleResponseModal = () => setResponseModal(false)
    const handleShow = (index) => {
        setDeleteIndex(index)
        setShow(true);
    }

    const postGigyaID = async () => {
        const response = await axios({
          method: "get",
          url: `${config.baseApi}dc/exports/getrolepermission/${userId}`,
          data: {
            "myExportsRetailer": {
              "Gigya_ID": userId,
            }
          },
          headers: {
            "X-Country-Code": `${getCountryCode()}`
          }
        });
        setGigyaRole(response.data.data?.roleName)
        getRoleAndGroup()
    }
  const fetchingData = async () => {
    let response
    if(getRoleName === "Retailer" && getRoleGroup !== ""){
        response = await axios({
            method: "get",
            url: `${config.baseApi}dc/exports/getexports?archived=false`,
          //   data: {
          //     "myExportsRetailer": {
          //       "Gigya_ID": userId,
          //     }
          //   },
            headers: {
              "X-Country-Code": `${getCountryCode()}`,
              "X-Gigya-Id" :`${userGigyaId}`,
              "X-USER-EMAILID" :`${userId}`
            }
          });
    }
         
        setstoringData(response)

       // let data = prepareRetailerData(response);
        setRetailerViewData(response);
    }
   // console.log("response1234",storingData,userId)
    const getData = async () => {
        let response
        if(JSON.parse(localStorage.getItem('user_role'))=== "Global Admin"){
            response = await axios
            .get(
                `${config.baseApi}dc/exports/getexports`,
                {
                    headers: {
                       // "X-Country-Code": `${getCountryCode()}`,
                       "X-Gigya-Id" :`${userGigyaId}`,
                       "X-USER-EMAILID" :`${userId}`
                    }
                }
            )
        } 
         else{
            response = await axios
            .get(
                `${config.baseApi}dc/exports/getexports`,
                {
                    headers: {
                        "X-Country-Code": `${getCountryCode()}`,
                        "X-Gigya-Id" :`${userGigyaId}`,
                        "X-USER-EMAILID" :`${userId}`
                    }
                }
            )
         }
             
        //  console.log("country",getCountryCode)
       // console.log("allexports", response)
       setRowsData(response.data.message)
       let data = prepareAdminData(response.data.message);
       setData(data);
        return response.data;
    };
    const getCountries = async () => {
        const response = await axios
            .get(
                `${config.baseApi}dc/config/getCountries`,
            
            )
         
            const obj = {countryCode: "All", region: "All"};
            setOriginalCountryOptions(response.data.data)
            setCountryOptions([...response.data.data, obj])
        return response.data;
    };
    const getRoleAndGroup = async () => {
       // console.log("userid", userId)

        const response = await axios({
            method: "post",
            url: `${config.baseApi}dc/exports/exportsauthenticate`,
            data:
            {
                "gigyaId": userGigyaId,
                "emailId": userId

            },
            headers: {
                // "Content-Type": `application/json`
                "X-Country-Code": `${getCountryCode()}`,
                "X-User-Role": "Role"
            }

        });
            getRoleName=  response && response.data && response.data.message && response.data.message.RoleName
            getRoleGroup = response && response.data && response.data.message && response.data.message.RetailerGroup
            setRetailerGroupName(response && response.data && response.data.message && response.data.message.RetailerGroup) 
            if ( getRoleName === "Retailer" && getRoleGroup === "") {
    
                    setRetailerModal(true)
               
            }
            else{
                fetchingData()
               }
        return response.data.message;

    }
  
    // console.log("resp123456", retailerGroup, "resp12345678" ,retailerModal)
    const sendRetailerCode = async () => {
       // console.log("value", retailerCode)

        const response = await axios({
            method: "post",
            url: `${config.baseApi}dc/exports/exportsauthenticate`,
            data:
            {
              "gigyaId": userGigyaId,
              "emailId": userId

            },
            headers: {
                // "Content-Type": `application/json`
                "X-Country-Code": `${getCountryCode()}`,
                "X-Retailer-Code": retailerCode
            }

        });
      //  console.log("new re", response.data.message.msg)
        setRetailerCodeResponse(response.data.message.msg)
        setRetailerGroupName(response.data.message.groupName)
        setResponseModal(true)
        handleCloseRetailerModal()

        return response.data.message;

    }

    const confirmRetailer = async () => {
        const response = await axios({
            method: "post",
            url: `${config.baseApi}dc/exports/exportsauthenticate`,
            data:
            {
                "gigyaId": userGigyaId,
                "emailId": userId,
                "RetailerGroup":retailerGroupName
            },
            headers: {
                "X-Country-Code": `${getCountryCode()}`,
                "X-Confirm-Process": "YES"
            }

        });
        handleResponseModal();
        fetchingData()
        // console.log("confirm response", response)
        return response.data.message;
       
    }

    const sendMailNotification = async (tData) => {
      //  const nameOfRetailer=tData.Retailer.slice(14)
        const subject= tData.fileName
       // console.log("tData", tData)
        //console.log("mail",nameOfRetailer,subject)
        const response = await axios({
            method: "post",
            url: `${config.baseApi}dc/exports/sendemailnotificationusingnodemailer`,
            data:
            {
                emailSubject: subject,
                retailerName: tData.retailerName,
                countryCode: tData.countryCode,
                retailer: tData.Retailer,
                SK: tData.SK
            },
            headers: {
                "X-Country-Code": `${getCountryCode()}`,

            }

        });
        setNotification(response.data.data)
       // console.log("mailmsg",notification)
        mailHandlerOpen()
        return response;
       
    }
 const mailHandlerOpen=()=>{
    setShowMailModal(true)
 }


      useEffect(() => {
        postGigyaID()
        //fetchingData()
        //getData();
        getDataCountry(countryFilterEdit);
    getUpc();
    getCountries();
        //eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);


      useEffect(() => {
        getCountries()
        //fetchingData()
       getData();
       if ( getRoleName === "Retailer" && getRoleGroup !== "") {
        fetchingData()     
        }
  
         //eslint-disable-next-line react-hooks/exhaustive-deps
      }, [flag,downloadedAssetsNew,deleteIndex, isCheckAll, isCheck, getRoleName, getRoleGroup]);

    const handleShowEdit = (e,item,index) => {
        const stringObjects = item.upc.map((string, index) => {
          return { id: index + 1, name: string };
      });
      
     
        e.preventDefault();
        setEditIndex(index);
        setEditAssetName(item.fileName);
        setShowSecondModal(true);
        setFName(item.filePath)
        setText(stringObjects)
        setcountryFilterEdit(item.countryCode)
        setSelectedRetailer(item.Retailer)
        setSK(item.SK)
      }

   // console.log("data", rowsData)
    let pageCount = (jsonData.data.total_count.Count) / (jsonData.data.Count);
   // console.log("page count", pageCount)
    let active = curPage;
    let items = [];
    

    // var files = []
    // var productData
    
    const handleOnClick = (number) => {
        setCurPage(number)
       // console.log("cur page", setCurPage)
    }

    for (let number = 1; number <= Math.ceil(pageCount); number++) {
        items.push(
            <Pagination.Item key={number} active={number === active} onClick={() => handleOnClick(number)}>
                {number}
            </Pagination.Item>,
        );
    }


    const deleteTableRows = async (index) => {
        const rows = [...rowsData];
        const eachrowdelete ={...rows[index],action:"delete"}
        rows.splice(index, 1);
         const response = await axios({
            method: "post",
            url: `${config.baseApi}dc/exports/updateordeleteexportsrecord`,
            data: eachrowdelete,
            headers: {
              "X-Country-Code": `${getCountryCode()}`
            }
          })
        setRowsData(rows);
        setData(rows);
        getData()
        handleClose()
        return response
    }

    const editModal = async (fileName, index) => {
        const rows = [...rowsData]
        rows[index].fileName = editAssetName;
        const eachrow ={...rows[index],action:"update"}
        const response = await axios({
            method: "post",
            url: `${config.baseApi}dc/exports/updateordeleteexportsrecord`,
            data: eachrow,
            headers: {
              "X-Country-Code": `${getCountryCode()}`
            }
          })
          setData(rows);
            getData()
            handleCloseSecond();
            return response
    }


   // console.log("fetchingData", storingData?.data?.message, rowsData)
    
    
    //const domain ="https://qa-downloadcenter.bayer.com"
    let parentAssetName = "";
    const handleDownload = async(tData) => {
        let fileSrc;
        fileSrc = tData.filePath;
        let diffUrlRetailer
       // console.log("tdata",tData)
        parentAssetName = fileSrc.substring(fileSrc.lastIndexOf("/") + 1, fileSrc.length);
        //FileSaver.saveAs(domain + "/" + tData.filePath, parentAssetName);       
        const tempArr = [...downloadedAssets];
        if (!tempArr.includes(tData.SK)) {
            tempArr.push(tData.SK);
            downloadedAssets = tempArr;
          //  console.log("eventhandle", downloadedAssets, downloadArray, tempArr);
        }
        updateDownloadedAssetsNew(downloadedAssets)
        let filteringdata ={
            "exportId": tData.SK,
            "gigyaId": userGigyaId,
            "emailId": userId,
            "downloaded": true
        }
        // console.log("downloadArrayData",downloadArrayData)
        if(!JSON.stringify(downloadArrayData).includes(filteringdata.exportId)){
        
        downloadArrayData.push(filteringdata)
        }
        //console.log("downloadArrayData123",downloadArrayData)
        //downloadArrayData= downloadArrayData?.filter(ele => ele.exportId === tData.SK ) 
        //console.log("downloadArrayData123456",downloadArrayData)
        const response = await axios({
            method: "post",
            url: `${config.baseApi}dc/exports/downloadstatustrack`,
            data: downloadArrayData,
          });
       for(let i=0; i<response.data.message.length; i++){    
        if(tData.SK === response.data.message[i].exportId){            
        diffUrlRetailer=response.data.message[i].presignedUrl
        FileSaver.saveAs(diffUrlRetailer, parentAssetName); 
        } 
      //  FileSaver.saveAs(diffUrlRetailer, parentAssetName);     
    }  
       
    };
    // const isThisFileDownloaded = (assetId) => {
    //     if (downloadedAssets.includes(assetId)) {           
    //         return true;
    //     }
    //     return false;
    // };
    const checkAllBoxes = () => {
       // console.log("downloadarray123", isCheck.length , storingData.data?.message.length-1)
       // eslint-disable-next-line no-unused-expressions
        isCheck.length > 0 && storingData.data?.message.length > 0 && isCheck.length === (storingData.data?.message.length-1)  ? setIsCheckAll(true): null
  
      }
    const onCheckBoxChange = (e, tData)=>{
        const { id, checked } = e.target;
        setIsCheck([...isCheck, id]);
        checkAllBoxes();
        if(checked){
            downloadArray.push(tData)
          
        }
        else{
            isCheckAll ? setIsCheckAll(!isCheckAll) : setIsCheckAll(isCheckAll);
            setIsCheck(isCheck.filter((item) => item !== id));
            downloadArray= downloadArray?.filter(ele => ele.SK !== tData.SK ) 
           
        }             
       // console.log("downloadarray", downloadArray)
    }
    const downloadButton = ()=>{
        setIsCheckAll(false)
        setIsCheck([])
        downloadArray.forEach((item)=> {handleDownload(item)})
        downloadArray=[]
       
    } 
    const  ImplementationCheckBox =(item, e) =>{
         const data = Object.assign({}, retailerViewData)
        // console.log("check",data,retailerViewData)
         data.data?.message.forEach((ele, index) => {
            if(ele.SK === item.SK) {
                data.data.message[index].currentImplementationStatus = e.target.checked
            }})
            setRetailerViewData(data)
        if(e.target.checked) {
            let filteringdata ={
                "exportId": item.SK,
                "gigyaId": userId,
                "implemented": true}
                implementationArray.push(filteringdata)
        } else {
            implementationArray = implementationArray.filter(ele => {
                return ele.exportId !== item.SK
            })
        }
        
        
    }
    const saveButton = async()=>{
        if (implementationArray.length !== 0) {
            const response = await axios({
                method: "post",
                url: `${config.baseApi}dc/exports/implementstatustrack`,
                data: implementationArray,
              });
              implementationArray = [];
              setFlag(true)
              console.log(response) 
        } 
      setTimeout(() => {
        navigate("/my-exports/view-archive")
      }, 1000);
      
        //window.location.reload(true)
       // console.log("flagusesave", flag) 
    }
    const handleSelectAll = () => {
        downloadArray= [...storingData.data?.message]
        setIsCheck(storingData.data?.message.map((tData) => tData.SK));
        setIsCheckAll(!isCheckAll);
        if (isCheckAll) {
          setIsCheck([]);
          downloadArray=[]
        }
      };
 
      const columns = [
        { title: "Retailer", field: "retailer" },
        { title: "Name",field: "name",customSort: (a, b) => a.name?.props?.children[0]?.props?.children?.length - b.name?.props?.children[0]?.props?.children?.length},
        { title: "DateiTyp", field: "dateiTyp"},
        { title: "CreatedDate", field: "createdDate"},
        { title: "FileSize", field: "fileSize"},
        { title: "Download Status", 
          field: "downloadStatus",
          customSort: (a, b) => a.downloadStatus?.props?.children?.props?.children?.length-b.downloadStatus?.props?.children?.props?.children?.length,
          customFilterAndSearch: (term, rowData) =>rowData.downloadStatus?.props?.children?.props?.children?.[1].toLowerCase().includes(term.toLowerCase()) ||
           rowData.downloadStatus?.props?.children?.props?.children.includes(term.charAt(0).toUpperCase()) 
        },
        { title: "Implementation Status", field: "implementationStatus",
        customSort: (a, b) => a.implementationStatus?.props?.children?.props?.children?.length-b.implementationStatus?.props?.children?.props?.children?.length,
        customFilterAndSearch: (term, rowData) =>rowData.implementationStatus?.props?.children?.props?.children?.[1].toLowerCase().includes(term.toLowerCase()) ||
         rowData.implementationStatus?.props?.children?.props?.children.includes(term.charAt(0).toUpperCase()) },
        { title: "Download", field: "download",filtering: false},
        { title: "Edit", field: "edit",filtering: false},
        { title: "Remove", field: "remove",filtering: false},
        { title: "Mail", field: "mail",filtering: false},
        { title: "country", field: "country", hidden:true},
      ];
   
      //console.log("editted",editAssetName)

      function prepareAdminData(dataList) {
        const data = dataList && dataList ==="No Records for this User"  ? "": 
        dataList.map((item, index) => {
          const uploadDateFormat = item.createdDate;
          const uploadDate = new Date(uploadDateFormat);
          const currentUploadDate = uploadDate.getDate() + "-" + (uploadDate.getMonth()+1) + "-" + uploadDate.getFullYear();
          const FileSizeInMB = ( item.fileSize / (1024*1024)).toFixed(3);
          const currentFileSize = FileSizeInMB + " MB";
          if (getRoleName === "Retailer"){
            if(('RETAILERGROUP#'+retailerGroupName)===item.Retailer){
            return {
              key: index, 
              retailer:  item?.retailerName, 
              name: item?.fileName, 
              dateiTyp: item?.fileType.split("application/"),
              createdDate: currentUploadDate,
              fileSize: currentFileSize,
              country: item?.countryCode,
              downloadStatus: String(item.currentDownloadedStatus) === "true" ?
              (<i class="bi bi-check-circle-fill" style={{ color: "green", fontSize: "15px" }}><span style={{ color: "black", fontStyle: "normal" }}> Completed</span></i>) :
              (<i class="bi bi-x-circle" style={{ color: "red", fontSize: "15px" }}><span style={{ color: "black", fontStyle: "normal" }}> {t("My Exports.download_needed")}</span></i>),
 
            //    item?.downloadedInfo?.map((ele)=>ele.gigyaId).includes(userId) && item?.downloadedInfo?.map((ele)=>ele.downloaded=== true) 
            //   ?
            //       (<i class="bi bi-check-circle-fill" style={{ color: "green", fontSize: "15px" }}><span style={{ color: "black", fontStyle: "normal" }}> Completed</span></i>) :
            //       downloadedAssetsNew.includes(item.SK)
            //       ?
            //          (<i class="bi bi-check-circle-fill" style={{ color: "green", fontSize: "15px" }}><span style={{ color: "black", fontStyle: "normal" }}> Completed</span></i>) :
            //       (<i class="bi bi-x-circle" style={{ color: "red", fontSize: "15px" }}><span style={{ color: "black", fontStyle: "normal" }}> {t("My Exports.download_needed")}</span></i>),
              implementationStatus:  String(item.currentImplementationStatus) === "true" ?
              (<i class="bi bi-check-circle-fill" style={{ color: "green", fontSize: "15px" }}><span style={{ color: "black", fontStyle: "normal" }}> Completed</span></i>) :
              (<i class="bi bi-x-circle" style={{ color: "red", fontSize: "15px" }}><span style={{ color: "black", fontStyle: "normal" }}> Pending</span></i>),
              download: <i class="bi bi-download" onClick={() => handleDownload(item) }></i>,
              edit: <IconButton
                        color="inherit"
                        onClick={(e) => (handleShowEdit(e, item, index))}
                      
                      >
                        <Edit />
                    </IconButton>
                     ,
             remove:<IconButton
                        color="inherit"
                        onClick={() => (handleShow(index))}
                      >
                        <Delete/>
                    </IconButton> ,
              
    
            }

          }
          
          else
          return null;
      }
      else{ 
      return {
        key: index, 
              retailer:  item?.retailerName, 
              name: item?.fileName, 
              dateiTyp: item?.fileType.split("application/"),
              createdDate: currentUploadDate,
              fileSize: currentFileSize,
              country: item?.countryCode,
              downloadStatus:  String(item.currentDownloadedStatus) === "true" ?
              (<i class="bi bi-check-circle-fill" style={{ color: "green", fontSize: "15px" }}><span style={{ color: "black", fontStyle: "normal" }}> Completed</span></i>) :
              (<i class="bi bi-x-circle" style={{ color: "red", fontSize: "15px" }}><span style={{ color: "black", fontStyle: "normal" }}> {t("My Exports.download_needed")}</span></i>),
 
            //   item?.downloadedInfo?.map((ele)=>ele.gigyaId).includes(userId) && item?.downloadedInfo?.map((ele)=>ele.downloaded=== true) 
            //   ?
            //       (<i class="bi bi-check-circle-fill" style={{ color: "green", fontSize: "15px" }}><span style={{ color: "black", fontStyle: "normal" }}> Completed</span></i>) :
            //       downloadedAssetsNew.includes(item.SK)
            //       ?
            //          (<i class="bi bi-check-circle-fill" style={{ color: "green", fontSize: "15px" }}><span style={{ color: "black", fontStyle: "normal" }}> Completed</span></i>) :
            //       (<i class="bi bi-x-circle" style={{ color: "red", fontSize: "15px" }}><span style={{ color: "black", fontStyle: "normal" }}> {t("My Exports.download_needed")}</span></i>),
              implementationStatus:  String(item.currentImplementationStatus) === "true" ?
              (<i class="bi bi-check-circle-fill" style={{ color: "green", fontSize: "15px" }}><span style={{ color: "black", fontStyle: "normal" }}> Completed</span></i>) :
              (<i class="bi bi-x-circle" style={{ color: "red", fontSize: "15px" }}><span style={{ color: "black", fontStyle: "normal" }}> Pending</span></i>),
              download: <i class="bi bi-download" onClick={() => handleDownload(item) }></i>,
              edit: <IconButton
              color="inherit"
              onClick={(e) => handleShowEdit(e,item,index)}
            >
              <Edit />
            </IconButton>
           ,
            remove:<IconButton
              color="inherit"
              onClick={() => (handleShow(index))}
            >
              <Delete/>
          </IconButton> ,
              mail: <button className="mailButton" onClick={() =>sendMailNotification(item)}>Send Mail</button>
      }
    }
        })
        return data
      }
      

      const Retailercolumns = [
        { title: <input type="checkbox" onChange={handleSelectAll} checked={isCheckAll} ></input>, field: "retailer" ,filtering: false},
        { title: "Name",field: "name"},
        { title: t("My Exports.file_type"), field: "dateiTyp"},
        { title: t("My Exports.upload_date"), field: "createdDate"},
        { title: t("My Exports.file_size"), field: "fileSize"},
        { title: "Download Status", 
          field: "downloadStatus",
          customSort: (a, b) => a.downloadStatus?.props?.children?.props?.children?.length-b.downloadStatus?.props?.children?.props?.children?.length,
          customFilterAndSearch: (term, rowData) =>rowData.downloadStatus?.props?.children?.props?.children?.[1].toLowerCase().includes(term.toLowerCase()) ||
           rowData.downloadStatus?.props?.children?.props?.children.includes(term.charAt(0).toUpperCase()) 
        },
        { title: t("My Exports.implemented"), field: "implementationStatus",filtering: false},
      ];
      const handleImplementationTableCheckbox=(item)=>{
        return(
          <div>
            <input type="checkbox"  onChange={(e) =>  ImplementationCheckBox(item,e)}></input>
          </div>

        )
      }
      const handleSingleImplementationTableCheckbox=(item)=>{
        return(
          <div>
            <input type="checkbox" id={item.SK}  onChange={(e) =>  onCheckBoxChange(e, item)}   checked={isCheck.includes(item.SK)}></input>
          </div>

        )
      }
    //   function prepareRetailerData(dataList) {
        const retailData = retailerViewData && retailerViewData?.data?.message ==="No Records for this User" ? "":        
        retailerViewData?.data?.message?.map((item, index) => {
        //   const uploadDateFormat = item.createdDate;
        //   const uploadDate = new Date(uploadDateFormat);
        //   const currentUploadDate = uploadDate.getDate() + "-" + (uploadDate.getMonth()+1) + "-" + uploadDate.getFullYear();
          const FileSizeInMB = ( item.fileSize / (1024*1024)).toFixed(3);
          const currentFileSize = FileSizeInMB + " MB"
            return {
              key: index, 
              retailer:handleSingleImplementationTableCheckbox(item), 
              name: item.fileName, 
              dateiTyp: item?.fileType.split("application/"),
              createdDate: countryCode === "de" ? item.updatedDate?.slice(0,10) : item.updatedDate?.slice(0,10),
              fileSize: currentFileSize,
              downloadStatus:   item?.downloadedInfo?.map((ele)=>ele.gigyaId).includes(userId) && item?.downloadedInfo?.map((ele)=>ele.downloaded=== true) 
              ?
                  (<i class="bi bi-check-circle-fill" style={{ color: "green", fontSize: "15px" }}><span style={{ color: "black", fontStyle: "normal" }}> Completed</span></i>) :
                  downloadedAssetsNew.includes(item.SK)
                  ?
                     (<i class="bi bi-check-circle-fill" style={{ color: "green", fontSize: "15px" }}><span style={{ color: "black", fontStyle: "normal" }}> Completed</span></i>) :
                  (<i class="bi bi-x-circle" style={{ color: "red", fontSize: "15px" }}><span style={{ color: "black", fontStyle: "normal" }}> {t("My Exports.download_needed")}</span></i>),
              implementationStatus:handleImplementationTableCheckbox(item)

            }
          
        })
        
    //   }

      const options = {
        filterType: "dropdown",
        responsive: "scroll",
        print: false,
        download: false,
        checkbox: false,
        selectableRows: false,
        showTitle: false,
        filtering: filter,
        isFreeAction:true
      };
      
      const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
      }
      
const useStyles = makeStyles({
  tabs: {
    "& .MuiTabs-indicator": {
     display: "none",
    },
    "& .MuiTab-root.Mui-selected": {
      color: 'white',
      background:"#00617f"
    }
  },
  table:{
    "& .MuiTableSortLabel-root":{
      fontSize: 16,
      color: "#00617f",
      fontWeight:600,
      fontFamily: 'Neue Helvetica'
  },
     
     
}
})

const handlecountryChange = (evt) => {
    setcountryFilter(evt.target.value);
};
const handlecountryChangeEdit = (evt) => {
  setcountryFilterEdit(evt.target.value);
  getDataCountry(evt.target.value)
 // console.log("option1",evt.target.value)
};

const getDataCountry = async (e) => {
  
    //console.log("userProfile",userProfile)
   const response = await axios
      .get(
        `${config.baseApi}dc/exports/getallretailers`,
        {
          headers: {
            "X-Country-Code": (gigyaRole === "Global Admin")? e.toLowerCase() : `${getCountryCode()}`,
          }
        }
      )
    // console.log("country",getCountryCode())
   // console.log("response123", response.data.message)
    setOptionList(response.data.message)
    return response.data;
  };
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      // handleFiles(e.dataTransfer.files);

    //   handleFileAndDrop(e.dataTransfer.files[0])
    }
  };

  // triggers when file is selected with click
  const handleFile = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      // handleFiles(e.target.files);
      // eslint-disable-next-line
      const file = e.target.files[0];
      // const fileLink = URL.createObjectURL(file);

//console.log(file)
      handleFileAndDrop(e.target.files[0])
    }
  };

  
  const handleFileAndDrop = (file) => {
    setFName(file.name)
    setFSize(file.size)
    setFType(file.type)
    setUploadFile(file)
  
    //console.log("file", file)
  }

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };



  const handleChangeRetailer = (e) => {
    setSelectedRetailer(e.target.value)
  }


  const handleInputChange = (e) => {
    setInputValue(e.target.value)
  };
  // function myFunction() {
  //   var x = document.getElementById("snackbar");
  //   x.className = "show";
  //   setTimeout(function(){ x.className = x.className.replace("show", ""); }, 5000);
  // }
 // const wave = () => toast('Hi there 👋')
  const postUploadFile = async () => {
   let upcArray= text?.map(upcObj => upcObj.name)
    const response = await axios({
      method: "post",
      url: `${config.baseApi}/dc/exports/updateuploadedexportfile`,
      data:
      {
        "fileName": editAssetName,
        "gigyaId": userGigyaId,
        "emailId": userId,
        "filePath": fName,
        "fileType": fType,
        "fileSize": fSize,
        "Retailer": optionList && optionList.length > 0 ? selectedRetailer : inputValue,
        "upc": upcArray,
        "SK":sK,
        "uploadedFile": uploadFile ? true :false,
      },
      headers: {
        // "Content-Type": `application/json`
        "X-Country-Code": (gigyaRole === "Global Admin")? countryFilterEdit.toLowerCase() : `${getCountryCode()}`
      }

    });
   
  //   const uploadResponse = await axios.put(
  //     response.data.message,
  //     uploadFile,
  //     {
  //       headers: {
  //         "Content-Type": `application/pdf`
  //       }
  //     }
  //   );
  // }

    const uploadResponse = await fetch(
      response.data.message, 
      {
      method: "PUT",
      // headers:{
      //            "Content-Type": `application/pdf`
      //         },
      body: uploadFile 
    });
    console.log("uploadResponse", uploadResponse)
    cancelHandler()
    //cancelHandlerNew()
    fetchingData()
    getData()

    return response;

  }
  const Successnotify = () => toast.success('Data has been updated successfully..', {
    position: "bottom-center",
    style: {
      // border: '1px solid #713200',
      padding: '16px',
      color: 'white',
      background: '#108000'
    },
    iconTheme: {
      primary: 'white',
      secondary: 'black',
    },
  }
  )
  const cancelnotify = () => toast.error('No Data has been updated', {
    position: "bottom-center",
    style: {
      // border: '1px solid #713200',
      padding: '16px',
      color: 'white',
      background: 'rgb(255, 49, 98)'
    },
    iconTheme: {
      primary: 'white',
      secondary: 'red',
    },
  }
  )
  const cancelHandler = () => {
    setFName("")
  //  setFileName("")
   setText([])
   setSelectedRetailer("")
  //  setAddedUpc([])
   setShowSecondModal(false)
   Successnotify()
 }
 const cancelHandlerNew = () => {
   setFName("")
  // setFileName("")
  setText([])
  setSelectedRetailer("")
  // setAddedUpc('')
  setShowSecondModal(false)
 cancelnotify()
}

const getUpc = async () => {

  const response = await axios
    .get(
      `${config.baseApi}dc/entities/allupc`,
      {
        headers: {
          "X-Country-Code": `${getCountryCode()}`
        }
      }
    )
  //   const stringObjects = response.data.message.map((string, index) => {
  //     return { id: index + 1, name: string };
  // });
  setSuggestionCodes(response.data.message)
  setTotalUpcCount(response.data.message.length)
// setSuggestions(stringObjects)
//   setAllUpcCodes(stringObjects)
  return response.data;
};
// const reactTags = useRef()
const [customSuggestions, setCustomSuggestions] = useState([]); // State to hold custom suggestions

  // Your custom suggestion logic function
   // Your custom suggestion logic function
   const customSuggestionLogic = (input) => {
    // Implement your custom logic here to generate suggestions based on the input
    // For demonstration purposes, let's say suggestions are fetched from an array
    let matches = []
    if (input.length > 1) {
      matches = suggestionCodes.filter(upc => {
        const regex = new RegExp(`${input}`, "gi");
        return upc.match(regex)
      })
    }
    // const customSuggestionsArray = ['suggestion1', 'suggestion2', 'suggestion3']
    //   .filter(suggestion => suggestion.toLowerCase().includes(input.toLowerCase()));

    // Set the custom suggestions state
    setCustomSuggestions(matches);
  };
  const handleInputChangeNew = (input) => {
    // Call custom suggestion logic on input change
    customSuggestionLogic(input);
   // setClearValue(input);
  }
  const handleSuggestionSelect = (index) => {
    // Add the selected suggestion to the tags

    const selectedSuggestion = customSuggestions[index];
  //   const stringObjects = selectedSuggestion.map((string, index) => {
  //     return { id: index + 1, name: string };
  // });
//   text.push({
//     "id": text.length + 1,
//     "name": selectedSuggestion
// });
    setText([...text, {
      "id": text.length + 1,
      "name": selectedSuggestion
  }]);

    
    // Clear the suggestions after selection
    setCustomSuggestions([]);

    if (inputRefNew.current) {
      inputRefNew.current.state.query = ''; // Clear the input field using the ref
    }
    setClearValue('');
  };
// const onChangeSuggestion = (text, suggestion) => {

//  let matches = []
//  if (text.length > 1) {
//    matches = allUpcCodes.filter(upc => {
//      const regex = new RegExp(`${text}`, "gi");
//      return upc.match(regex)
//    })
//  }

//  setSuggestions(matches) 

 

// }
const onDelete = useCallback((tagIndex) => {
//   const stringObjects = text.map((string, index) => {
//     return { id: index + 1, name: string };
// });
  setText(text.filter((_, i) => i !== tagIndex))
}, [text])

const onAddition = useCallback((newTag) => {
 
//   const stringObjects = text.map((string, index) => {
//     return { id: index + 1, name: string };
// });
  setText([...text, newTag])
  if (inputRefNew.current) {
    inputRefNew.current.value = ''; // Clear the input field using the ref
  }
  setClearValue('');
}, [text])


// console.log("CustomSuggestions", suggestions)
//   // Custom Suggestions Component
//   const CustomSuggestions = ({ suggestions }) => {

//     return (
//       <div className="custom-suggestions">
//         {suggestions.map((suggestion, index) => (
//           <div key={index} className="suggestion-item">
//             {suggestion.name}
//           </div>
//         ))}
//       </div>
//     );
//   };
// const onChange =(text) => {
//   //alert(1)
//   let matches = []
//     if (text.length > 1) {
//       matches = allUpcCodes.filter(upc => {
//         const regex = new RegExp(`${text}`, "gi");
//         return upc.match(regex)
//       })
//     }
//     //text.length > 1 && setText(text);
//     // eslint-disable-next-line no-unused-expressions
//     setSuggestions(matches) 
    
//   console.log("suggesting: ", text,"length", text.length,   "suggestions", suggestions)
//  }
 
const tableRef = React.createRef();
const classes = useStyles();


    let presentationComp 
    if(gigyaRole && (gigyaRole=== "Global Admin" ||gigyaRole=== "Country Admin") ){
       presentationComp=(
        <div className="exportsrow" style={{padding: "2rem"}}> 
        <TabContext value={"1"}>

          <div className="col-md-9 col-lg-9 col-xl-12" style={{position:"relative"}}>
            <TabPanel className={classes.table} value="1">
            <div className="col-md-9 col-lg-9 col-xl-12">
            <h3 style={{
                textAlign: "left",
                paddingLeft: "2%",
                fontWeight: "600"
            }} className="export-admin-heading float-right mb-2">{t(`My Exports.admin_heading`)}
            <br/>  <h5 style={{
                textAlign: "left",
                paddingBottom: "1%",
                fontWeight: "600"
            }} className="export-admin-date float-right mb-2">{t("My Exports.activity_date")}</h5> </h3>
            
          <div className="buttonAlign">
          {(gigyaRole === "Global Admin")?
                <div className="selectCountry" style={{ width: "156px" }}>
                <MuiFormControl style={{ width: "100%" }}>
          <MuiInputLabel id="demo-simple-select-label">Select Country</MuiInputLabel>
              <MuiSelect
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={countryFilter}
              label="Select Country"
              onChange={handlecountryChange}
              defaultValue={countryFilter}
              displayEmpty
              >
                 {countryOptions.sort((a, b) => a.region > b.region ? 1 : -1).map((item, index) => {
                return (

                     <MenuItem  className="countryInputRegion"  key={index} value={item?.countryCode}>
                     {item?.region}
                     </MenuItem>

                );
              })}
            
              </MuiSelect>
          </MuiFormControl> 
             
                </div> 
                :""}
          <div className="filterIcon " style={{fontStyle:"italic"}}> 
                {iconChange ?
       (<span style={{cursor:"pointer"}} onClick={filterHandler} >Show Filter<FilterListIcon ></FilterListIcon></span>) :
       (<span style={{cursor:"pointer"}}onClick={filterHandler}>Hide Filter<FilterListOffIcon></FilterListOffIcon></span>)}
                       </div>
            <button style={{
                      backgroundColor: "#ff3162",
                      border: "none",
                      borderRadius: "6px",
                      fontFamily: "Neue Helvetica Bold Italic",
                      fontSize: "12px"
                  }} className="detailed-button-upload"> 
                   <Link to="/home/my-exports/upload-file" style={{ color: "#ffffff", textDecoration: "auto" }}>
                    <i class="bi bi-cloud-arrow-up-fill" style={{ fontSize: "15px" }}></i>  
                    { "\u00a0\u00a0"} UPLOAD NEW
                    </Link>
                    </button>  
            <button style={{
                      backgroundColor: "#ff3162",
                      border: "none",
                      borderRadius: "6px",
                      fontFamily: "Neue Helvetica Bold Italic",
                      fontSize: "12px"
                  }} className="detailed-button-link"> 
                  <Link to="/home/my-exports/link-products" style={{ color: "#ffffff", textDecoration: "auto" }}>
                     <i class="bi bi-link" style={{ fontSize: "15px" }}></i> 
                     { "\u00a0\u00a0"}LINK PRODUCTS
                       </Link>
                       </button> </div> 
                       
                      
          </div>
         

               <MaterialTable
                  tableRef={tableRef}
                  options={options}
                  icons={tableIcons}
                  columns={columns}
                  data={
                    (countryFilter=== "All" || countryFilter=== "" )? data :                    
                    data.filter(
                    (item) => (!countryFilter || item.country === countryFilter )
                    )}
              />
          
            </TabPanel>
          </div>
        </TabContext>
        <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header >
                <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ textAlign: "center" }}>Do you want to delete the file?</Modal.Body>
            <Modal.Footer>
                <Button style={{ backgroundColor: "#89d329" }} onClick={() => (deleteTableRows(deleteIndex))}>
                    Delete
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>

            </Modal.Footer>
        </Modal>

        <Modal show={showSecondModal} onHide={handleCloseSecond} aria-labelledby="contained-modal-title-vcenter" className="edit-modal-myExports">
            <Modal.Header >
                <Modal.Title id="contained-modal-title-vcenter">My Exports Admin Access- Edit</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ textAlign: "center" }}></Modal.Body>
            <Form>
                <Form.Group  >

            <div>

            <Container
            className="border">

            <Container
                style={{
                textAlign: "left",
                paddingLeft: "2%",
                paddingBottom: "1%",
                fontWeight: "600",
                paddingTop: "2%"
                }}>
                
                <TextField
                id="filled-basic"
                label="File Name"
                variant="filled"
                style={{ width: "inherit" }}
                value={editAssetName} 
                onChange={(e) => {
                    setEditAssetName(e.target.value)
                }}
                />
                <Box
                        className="border"
                        style={{ width: "inherit", marginTop: "2%", borderRadius: "5px" }}>
                        <h5 style={{
                        font: "unset",
                        fontWeight: "300", padding: "1%", borderBottom: "1px solid", backgroundColor: "gainsboro"
                        }}>Upload File</h5>
                        {/* <Box className="dotted-border" style={{borderStyle:"dotted"}}>
                                    <CloudUpload fontSize="large"></CloudUpload>
                                    
                                    </Box> */}
                        {/* <DragDropFile></DragDropFile> */}
                        <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
                        <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleFile} />
                        <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : ""}>
                            <div>
                            <CloudUpload color="disabled" style={{ fontSize: "80px" }}></CloudUpload>
                            <p style={{ marginBottom: "0px" }}><i>SELECT A FILE TO UPLOAD</i></p>
                            <button className="upload-button" onClick={onButtonClick}><b>OR DRAG AND DROP IT HERE</b></button>
                            <div style={{wordBreak: "break-all"}}>   {fName} </div>
                            </div>
                        </label>
                        {dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>}

                        </form>
                        <p style={{ marginLeft: "2%" }}>OR UPLOAD FROM A URL</p>
                        <TextField
                        id="filled-basic"
                        label="Add the file URL"
                        variant="filled"
                        style={{ width: "-webkit-fill-available", margin: "2%", marginTop: "0px" }}
                        InputProps={{
                            endAdornment: <Button variant="outlined" style={{ color: "#FF3366", borderColor: "#FF3366" }}
                            onClick={()=> getDataCountry(countryFilter)}>
                            Choose
                            </Button>
                        }}>

                        </TextField>

                    </Box>
                    <div style={{ display: "inline-block", width: "inherit", marginTop: "2%" }}>

             <ReactTags
    //  ref={reactTags}
      tags={text}
      //suggestions={[]}
      onDelete={onDelete}
      onAddition={onAddition}
      label="ADD UPC CODE"
      removeButtonText ='CLICK TO REMOVE THIS UPC CODE'
      placeholderText= "ADD UPC CODE" 
      //noSuggestionsText='NO MATCHING UPC'
      maxSuggestionsLength= {totalUpcCount}
      allowBackspace= {false}
      onInput={handleInputChangeNew}
      inputValue={clearValue} 
      ref={inputRefNew}
      //suggestionsComponent={CustomSuggestions} // Passing custom Suggestions component
     // suggestionsTransform={suggestions => suggestions.map(suggestion => ({ id: suggestion.id, text: suggestion.name }))}
    />
    {/* Render custom suggestions below the ReactTags component */}
    <div>
        {customSuggestions.length> 0 ? <p>Select from the below Suggestions:</p> : " "}
        <ul>
          {customSuggestions.map((suggestion, index) => (
            <li className="suggestion col-md-12 justify-content-md-center" style={{listStyle:"none"}} key={index} onClick={() => handleSuggestionSelect(index)}>{suggestion}</li>
          ))}
        </ul>
      </div>
            {/* <Button variant="contained" style={{ marginLeft: "2%", marginTop: "1%", backgroundColor: "#FF3366" }} onClick={() => pushUpc()}><i>+ ADD UPC CODE</i></Button> */}

            {/* {suggestions.map((suggestion, i) =>
              <div key={i} className="suggestion col-md-12 justify-content-md-center" onClick={() => onChangeSuggestion(text, suggestion)}>{suggestion}</div>
            )} */}
          </div>  
          {(gigyaRole === "Global Admin")?
          <div className="EditCountry">
          
          <FormControl variant="filled" style={{ marginTop: "2%", width: "100%" }}>
          <InputLabel id="demo-simple-select-filled-label" style={{ marginTop: "2%" }}>Select Country</InputLabel>
          <Select
             labelId="demo-simple-select-label"
             id="demo-simple-select"
             value={countryFilterEdit}
             label="Select Country"
             style={{ marginTop: "2%", width: "inherit" }}
             onChange={handlecountryChangeEdit}
             defaultValue={countryFilterEdit}
             displayEmpty
             >
                {originalCountryOptions.sort((a, b) => a.region > b.region ? 1 : -1).map((item, index) => {
               return (

                    <MenuItem  className="countryInputRegionEdit"  key={index} value={item?.countryCode}>
                    {item?.region}
                    </MenuItem>
               );
             })}

          </Select>
        </FormControl> 
          
          <FormControl variant="filled" style={{ marginTop: "2%", width: "100%" }}>
              
          {(countryFilterEdit && optionList && optionList.length > 0  )? 
          <>
            <InputLabel id="demo-simple-select-filled-label" style={{ marginTop: "2%" }}>Select Retailer</InputLabel>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              style={{ marginTop: "2%", width: "inherit" }}
              value={selectedRetailer} onChange={handleChangeRetailer}
            >
              {optionList.map((item, index) => {
                return (
                  <MenuItem key={index} value={item.SK}> {item.retailerName}</MenuItem>
                )
              })}

            </Select></> :
            (countryFilterEdit === "" )? 
            ""
            :
            <TextField
            id="filled-basic"
            label="Retailer"
            variant="filled"
            value={inputValue}
            onChange={handleInputChange}
          ></TextField>
          //   <TextField
          //   fullWidth
          //   disabled = {true}
          //   id="filled-basic"
          //   label="Retailer"
          //   variant="filled"
          //   value="No Retailer Group Available. Go to retailer tab in the Administration to add Retailer"
          // ></TextField>
        }
            <div style={{ display: "inline-block", width: "inherit", marginTop: "2%" ,marginLeft:"72%"}}>
              <Button variant="contained" onClick={() => {
    postUploadFile();
   // myFunction();
}}style={{ width: "13%", marginRight: "2%", marginTop: "3%", marginBottom: "3%", backgroundColor: "#FF3366" }}><i>SAVE</i></Button>
              <Button variant="contained" onClick={cancelHandlerNew} style={{ width: "13%", marginTop: "3%", marginBottom: "3%", backgroundColor: "#FF3366" }}><i>CANCEL</i></Button>
            </div>
          </FormControl>
          </div> :
           <FormControl variant="filled" style={{ marginTop: "2%", width: "100%" }}>
               {(optionList && optionList.length > 0  )? 
               <>
           <InputLabel id="demo-simple-select-filled-label" style={{ marginTop: "2%" }}>Select Retailer</InputLabel>
           <Select
             labelId="demo-simple-select-filled-label"
             id="demo-simple-select-filled"
             style={{ marginTop: "2%", width: "inherit" }}
             value={selectedRetailer} 
             onChange={handleChangeRetailer}
           >

             {optionList.map((item, index) => {
               return (
                 <MenuItem key={index} value={item.SK}> {item.retailerName}</MenuItem>
               )
             })}

           </Select></>
           :
            <TextField
            id="filled-basic"
            label="Retailer"
            variant="filled"
            value={inputValue}
          onChange={handleInputChange}
          ></TextField>
           //   <TextField
          //   fullWidth
          //   disabled = {true}
          //   id="filled-basic"
          //   label="Retailer"
          //   variant="filled"
          //   value="No Retailer Group Available. Go to retailer tab in the Administration to add Retailer"
          // ></TextField>
            
         
        }
           <div style={{ display: "inline-block", width: "inherit", marginTop: "2%" ,marginLeft:"72%"}}>
             <Button variant="contained" onClick={() => {
    postUploadFile();
   // myFunction();
}}style={{ width: "13%", marginRight: "2%", marginTop: "3%", marginBottom: "3%", backgroundColor: "#FF3366" }}><i>SAVE</i></Button>
             <Button variant="contained" onClick={cancelHandlerNew} style={{ width: "13%", marginTop: "3%", marginBottom: "3%", backgroundColor: "#FF3366" }}><i>CANCEL</i></Button>
           </div>
         </FormControl>}

                </Container>


            </Container>

            </div>

                </Form.Group>
            </Form>
            {/* <div id="snackbar">Data has been updated successfully..</div> */}
            {/* <Modal.Footer>
                <Button style={{ backgroundColor: "#89d329" }} onClick={() => (editModal(editAssetName, editIndex))}>
                    Edit
                </Button>
                <Button variant="secondary" onClick={handleCloseSecond} >
                    Close
                </Button>

            </Modal.Footer> */}
        </Modal>
        <Modal  show={retailerModal} onHide={handleCloseRetailerModal} aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header >
                    <Modal.Title id="contained-modal-title-vcenter" style={{ textAlign: "justify" }}>You can now be activated for "My Exports". If you want to access, please enter your personal retailer code here.</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ textAlign: "center" }}></Modal.Body>
                <Form>
                    <Form.Group className="mb-3" style={{ paddingLeft: "15%", paddingRight: "15%" }}>

                        <Form.Control
                            type="password"
                            placeholder="Enter Retailer Code"
                            onChange={(e) => {
                                setRetailerCode(e.target.value)
                            }}

                            value={retailerCode} />

                    </Form.Group>
                </Form>

                <Modal.Footer>
                    <Button style={{ backgroundColor: "#89d329" }} onClick={sendRetailerCode} >
                        Confirm
                    </Button>
                    <Button variant="secondary" onClick={handleCloseRetailerModal} >
                        <Link to="/home" style={{ color: "#ffffff", textDecoration: "auto" }}>
                            Close
                        </Link>

                    </Button>

                </Modal.Footer>
                <p style={{ textAlign: "center", fontSize: "12px" }}>Not sure where to get the retailer code from? Please contact your Bayer representative.</p>
            </Modal>
            <Modal show={responseModal} onHide={handleResponseModal} aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header >
                    <Modal.Title id="contained-modal-title-vcenter">{retailerCodeResponse}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ textAlign: "center" }}></Modal.Body>
                <Form>
                    <Form.Group className="mb-3" style={{ paddingLeft: "3%", paddingRight: "3%" }}>


                    </Form.Group>
                </Form>

                <Modal.Footer>

                    {retailerCodeResponse === "We could not find this retailer code. Please make sure it has been entered correctly. In doubt, please contact your Bayer representative."
                        ?
                        <Button variant="secondary" >
                            <Link to="/home" style={{ color: "#ffffff", textDecoration: "auto" }}>
                                Close
                            </Link>

                        </Button>
                        :
                        <span style={{ display: "flex" }}>
                            <Button style={{ backgroundColor: "#89d329", marginRight: "5%" }} onClick={confirmRetailer} >
                            <Link to="/home/my-exports" style={{ color: "#ffffff", textDecoration: "auto" }}>
                                Confirm
                                </Link>
                            </Button>
                            <Button variant="secondary" >
                                <Link to="/home" style={{ color: "#ffffff", textDecoration: "auto" }}>
                                    Cancel
                                </Link>

                            </Button>
                        </span>
                    }

                </Modal.Footer>
            </Modal>

        <Modal show={showMailModal} onHide={mailHandlerClose} aria-labelledby="contained-modal-title-vcenter"centered >
          <Modal.Header >
              <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
          </Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>{notification}</Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={mailHandlerClose} style={{marginRight:"1%", backgroundColor: "#89d329"}}>
                Close
            </Button>
        </Modal.Footer>
        </Modal>
        <Toaster />
          </div>
          
       )
    }
    if(gigyaRole && gigyaRole.toLowerCase() === "retailer"){
    presentationComp=(
        <div className="exportsrow" style={{padding: "2rem"}}> 
        <TabContext value={"1"}>

<div className="col-md-9 col-lg-9 col-xl-12" style={{position:"relative"}}>
  <TabPanel className={classes.table} value="1">
  <div className="col-md-9 col-lg-9 col-xl-12">
  <h3 style={{
      textAlign: "left",
      paddingLeft: "2%",
      fontWeight: "600"
  }} className="export-admin-heading float-right mb-2">{t(`My Exports.retailer_heading`)}
  <br/>  <h5 style={{
      textAlign: "left",
      paddingBottom: "1%",
      fontWeight: "600"
  }} className="export-admin-date float-right mb-2">{t("My Exports.activity_date")}</h5> </h3>
  
  <div className="buttonAlign">
          <div className="filterIcon" style={{fontStyle:"italic"}}> 
                {iconChange ?
       (<span style={{cursor:"pointer"}} onClick={filterHandler} >Show Filter<FilterListIcon ></FilterListIcon></span>) :
       (<span style={{cursor:"pointer"}}onClick={filterHandler}>Hide Filter<FilterListOffIcon></FilterListOffIcon></span>)}
                       </div>
  <button style={{
            backgroundColor: "#ff3162",
            border: "none",
            borderRadius: "6px",
            fontFamily: "Neue Helvetica Bold Italic",
            fontSize: "12px"
        }} className="detailed-button-view-archive float-right mb-2"> 
         <Link to="/my-exports/view-archive" style={{ color: "#ffffff", textDecoration: "auto" }}>
          <i class="bi bi-archive" style={{ fontSize: "15px" }}></i>  
          { "\u00a0\u00a0"}{t(`My Exports.view_archive`)}
          </Link>
          </button>  
  <button style={{
            backgroundColor: "#ff3162",
            border: "none",
            borderRadius: "6px",
            fontFamily: "Neue Helvetica Bold Italic",
            fontSize: "12px"
        }} className="detailed-button-download float-right mb-2" onClick={downloadButton}> 
           <i class="bi bi-download" style={{ fontSize: "15px" }}></i> 
           { "\u00a0\u00a0"}DOWNLOAD
             </button>  
             </div>  
            
</div>


<MaterialTable
              //  title={t(`my_exports.admin_heading`)}
                  tableRef={tableRef}
                  options={options}
                  icons={tableIcons}
                  columns={Retailercolumns}
                  data={retailData}
              />
              <div style={{marginBottom :"20px"}}>
            <button style={{
                backgroundColor: "#ff3162",
                border: "none",
                borderRadius: "0px",
                fontFamily: "Neue Helvetica Bold Italic",
                fontSize: "12px",
                float: 'right',
                marginRight: "1%",
                padding: "10px",
                marginTop: "7px",
                color: "#ffffff"
            }} 
                 onClick={saveButton}>
                {/* <Link to="/my-exports/view-archive" style={{ color: "#ffffff", textDecoration: "auto" }}> */}
                 {"\u00A0\u00A0"}{t(`My Exports.save`)}
                 {/* </Link> */}
            </button>
            </div>

  </TabPanel>
</div>
</TabContext>

        <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header >
                <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ textAlign: "center" }}>Do you want to delete the file?</Modal.Body>
            <Modal.Footer>
                <Button style={{ backgroundColor: "#89d329" }} onClick={() => (deleteTableRows(deleteIndex))}>
                    Delete
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>

            </Modal.Footer>
        </Modal>

        <Modal show={showSecondModal} onHide={handleCloseSecond} aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header >
                <Modal.Title id="contained-modal-title-vcenter">Edit Asset Name</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ textAlign: "center" }}></Modal.Body>
            <Form>
                <Form.Group className="mb-3" style={{ paddingLeft: "3%", paddingRight: "3%" }}>

                    <Form.Control
                        type="text"
                        placeholder="Enter asset name"
                        onChange={(e) => {
                            setEditAssetName(e.target.value)
                        }}

                        value={editAssetName} />

                </Form.Group>
            </Form>
            <Modal.Footer>
                <Button style={{ backgroundColor: "#89d329" }} onClick={() => (editModal(editAssetName, editIndex))}>
                    Edit
                </Button>
                <Button variant="secondary" onClick={handleCloseSecond} >
                    Close
                </Button>

            </Modal.Footer>
        </Modal>
        <Modal show={retailerModal}  aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header >
                    <Modal.Title id="contained-modal-title-vcenter" style={{ textAlign: "justify" }}>You can now be activated for "My Exports". If you want to access, please enter your personal retailer code here.</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ textAlign: "center" }}></Modal.Body>
                <Form>
                    <Form.Group className="mb-3" style={{ paddingLeft: "15%", paddingRight: "15%" }}>

                        <Form.Control
                            type="password"
                            placeholder="Enter Retailer Code"
                            onChange={(e) => {
                                setRetailerCode(e.target.value)
                            }}

                            value={retailerCode} />

                    </Form.Group>
                </Form>

                <Modal.Footer>
                    <Button style={{ backgroundColor: "#89d329" }} onClick={sendRetailerCode} >
                        Confirm
                    </Button>
                    <Button variant="secondary" onClick={handleCloseRetailerModal} >
                        <Link to="/home" style={{ color: "#ffffff", textDecoration: "auto" }}>
                            Close
                        </Link>

                    </Button>

                </Modal.Footer>
                <p style={{ textAlign: "center", fontSize: "12px" }}>Not sure where to get the retailer code from? Please contact your Bayer representative.</p>
            </Modal>
            <Modal show={responseModal}  aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header >
                    <Modal.Title id="contained-modal-title-vcenter">{retailerCodeResponse}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ textAlign: "center" }}></Modal.Body>
                <Form>
                    <Form.Group className="mb-3" style={{ paddingLeft: "3%", paddingRight: "3%" }}>


                    </Form.Group>
                </Form>

                <Modal.Footer>

                    {retailerCodeResponse === "We could not find this retailer code. Please make sure it has been entered correctly. In doubt, please contact your Bayer representative."
                        ?
                        <Button variant="secondary" >
                            <Link to="/home" style={{ color: "#ffffff", textDecoration: "auto" }}>
                                Close
                            </Link>

                        </Button>
                        :
                        <span style={{ display: "flex" }}>
                            <Button style={{ backgroundColor: "#89d329", marginRight: "5%" }} onClick={confirmRetailer} >
                            <Link to="/home/my-exports" style={{ color: "#ffffff", textDecoration: "auto" }}>
                                Confirm
                                </Link>
                            </Button>
                            <Button variant="secondary" >
                                <Link to="/home" style={{ color: "#ffffff", textDecoration: "auto" }}>
                                    Cancel
                                </Link>

                            </Button>
                        </span>
                    }

                </Modal.Footer>
            </Modal>

        </div>
    )
    }
    if(RoleSettings &&  RoleSettings['Tailored content']=== true){
        return [presentationComp]
    }
    else{return <table class="center" style={{fontSize:"18px",fontFamily: "Neue Helvetica",color:"#00617f",fontWeight:600}}>
    Access Denied, You Don't Have Permission To View.
  </table>}

     
}

const mapStateToProps = state => {
    return {
      brandList: state.filter.brand,
      assetType: state.filter.assets,
      countrySettings: state.country.countryData,
      productsList: state.product.productsList,
      assetsList: state.asset.assetsList,
      RoleSettings: state.role.countryData
    };
  };

export default compose(
    withRouter,
    connect(
      mapStateToProps,
      null
    )
  )(MyExports);

