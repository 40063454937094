import React, { Component } from "react";
import { slide as Menu } from "react-burger-menu";
import Accordion from "../Accordion/Accordion.jsx";
import DCLogo from "../../assets/DC-logo.png";
import { Link } from "react-router-dom";
import qs from "qs";
import { withTranslation } from "react-i18next";
import InternalSearch from "../Search/search";
import { assetCategories } from "../../util/Helper.js";
import "./hamBurger.scss";

class HamBurgerMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assetCategoriesList: {}
    };
  }

  componentDidMount() {
    let assetCategoriesList = assetCategories(this.props.assetTypeNames);
    this.setState({
      assetCategoriesList
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      let assetCategoriesList = assetCategories(this.props.assetTypeNames);
      this.setState({
        assetCategoriesList
      });
    }
  }

  brandAccordion = () => {
    let brandMenu = [],
      queryObj;
    const { brandNames } = { ...this.props };
    brandNames?.forEach(({ brandName }) => {
      queryObj = { brand: [brandName] };
      let link = `/search-page/?${qs.stringify(queryObj)}`;
      brandMenu.push(
        <li>
          <i class="bi bi-chevron-right"></i>
          <Link to={link}>{brandName}</Link>
        </li>
      );
    });

    return brandMenu;
  };

  assetCategoryAccordion = () => {
    const { t } = this.props;
    let assetCategoriesList = assetCategories(this.props.assetTypeNames);
    let assetCategoryMenu = [];
    Object.values(assetCategoriesList).forEach(item => {
      assetCategoryMenu.push(
        <li>
          <Accordion
            title={t(`Asset Category.${item.category}`)}
            content={this.assetTypeAccordion(item.category)}
          />
        </li>
      );
    });
    return assetCategoryMenu;
  };

  assetTypeAccordion = asset_category => {
    const { t } = this.props;
    let assetTypeMenu = [],
      assetNamesTranslations = [],
      queryObject,
      { assetCategoriesList } = this.state;

    if (Object.values(assetCategoriesList).length > 0) {
      assetCategoriesList[asset_category].asset_list.forEach(asset => {
        assetNamesTranslations.push({
          original: asset.asset_type.dcAssetType,
          translation: t(`Asset Type.${asset.asset_type.dcAssetType}`)
        });
      });
    }

    assetNamesTranslations.sort(function(a, b) {
      return a.translation.localeCompare(b.translation, "de");
    });

    assetNamesTranslations.forEach(({ original, translation }) => {
      queryObject = { assettype: [original] };
      let link = `/search-page/?${qs.stringify(queryObject)}`;
      assetTypeMenu.push(
        <li onClick={this.handleAssetType}>
          <i class="bi bi-chevron-right"></i>
          <Link to={link}>{translation}</Link>
        </li>
      );
    });

    return assetTypeMenu;
  };

  render() {
    // NOTE: You also need to provide styles, see https://github.com/negomi/react-burger-menu#styling

    return (
      <Menu right>
        <div className="dc-logo">
          <span className="helper"></span>
          <Link to="/home">
            <img src={DCLogo} alt="DC-Logo" />
          </Link>
        </div>
        <div className="search-bar">
          <InternalSearch />
        </div>
        <div className="navigation-dropdown">
          <li className="accordion">
            <Accordion title={"Marke"} content={this.brandAccordion()} />
          </li>
          <li className="accordion">
            <Accordion
              title={"Werbematerialien"}
              content={this.assetCategoryAccordion()}
            />
          </li>
        </div>
      </Menu>
    );
  }
}
export default withTranslation("common")(HamBurgerMenu);
