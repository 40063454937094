import { useHistory } from 'react-router-dom';
import {useCallback} from 'react';


export const useNavigate  = () => {
    const history = useHistory();
        const navigate = useCallback( (path) => {
            history.replace(path);
        },[history]);

    

    //Function to redirect to specific URL
   const redirectTo = useCallback((URL) => {
        window.location.replace(URL);
        return null;
    },[]);

    return {
        navigate,
        redirectTo
    }
}

export default useNavigate;