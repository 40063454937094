import React, { useState, useEffect } from "react";
//import AuthContext from "../../components/Auth/Auth-context";
import "./notifications.scss";
import config from "../../config.js";
import axios from "axios";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import {getCountryCode} from "../../util/Helper.js";
import NoNotificationIamge from "../../sass/assets/NoNotificationImage.jpg";
let favLength = 0;
let favoritesData = {};
let favAssetsData={}
let favouritesProductsData = {};
let favouritesAssetsData = {};
// eslint-disable-next-line
let flag = false;
//let dummyFlag= false
let changing =false
const NotificationsPageNew = (props) => {
  const { t } = props;
 // const AuthCtx = useContext(AuthContext);
  const [grayColorOut, setGrayColorOut] = useState(true);
  const suggestionWrapper = React.createRef();
  const [fusePattern, setFusePattern] = useState("");
  const [displayAssetList, setdisplayAssetList]= useState([])
  const [displayProductList, setdisplayProductList]= useState([])
  const [combinedList, setcombinedList]= useState([])

  const iconDown = async() => {
    setGrayColorOut(!grayColorOut);
    setFusePattern("value")
    changing= true
    favLength = 0;
    await NotifCallingFunc()
  };
  //const userId = AuthCtx.giveToken();
  const userId= localStorage.email
    // eslint-disable-next-line
  const getNotificationList = async () => {
    const favoritesListUrl = `${config.favApi}dc/userdata/${userId}/favorites`;
   // const favAssetsNotif =  `${config.favApi}dc/userdata/${userId}/getAssetNotification`;
    favoritesData = await axios.get(favoritesListUrl).then((res) => res.data);
    favAssetsData = await axios({
      method: "get",
      url: `${config.baseApi}dc/userdata/${userId}/getAssetNotification`,
      headers: {
        "X-Country-Code": `${getCountryCode()}`
      }
    });
    //console.log("favAssetsData", favAssetsData.data.data)
    // favLength =
    // favoritesData?.data?.[0].assets.filter((asset) => asset.notificationFlag === true)?.length +
    //   favoritesData?.data?.[0].products.filter((product) => product.notificationFlag === true)?.length;
    favLength = (favoritesData?.data?.[0]?.products?.filter((product) => product.notificationFlag === true)?.length)
     + (favAssetsData?.data?.data?.filter((asset) => asset.notificationFlag === true)?.length);
    favouritesProductsData = favoritesData?.data?.[0]?.products;
    favouritesAssetsData = favoritesData?.data?.[0]?.assets;
    flag = false;
    setFusePattern("value");
    changing= true
    return;
  };

  const updateNotificationCheckedDate = async (product) => {
    const notificationCheckedDateUrl = `${config.favApi}dc/userdata/${userId}/favorites`;
    const assetNotifPostUrl=  `${config.baseApi}dc/userdata/${userId}/updateAssetNotification`;

    let currentDate = new Date().toJSON().slice(0, 10);
    //  // let newArr = arr1.map(obj => {
    //     // let newArr = product.map(obj => {
    //   let obj
    // if (product.notificationFlag === true) {
    //   obj= {...product, notificationFlag: false,
    //     notificationCheckedOn: currentDate};
    //    obj={...favouritesProductsData,...obj}

    // }

    //   // });
    //   // });
    //let newProduct ={...obj,favouritesProductsData }
    let newProduct = favouritesProductsData.map((obj) => {
      if (
        obj.productId === product.productId &&
        product.notificationFlag === true
      ) {
        obj = {
          ...product,
          notificationFlag: false,
          notificationCheckedOn: currentDate,
        };
 
      }

      return obj;
    });
    let singleasset = {
      ...product,
      notificationFlag: false,
      notificationCheckedOn: currentDate,
    };
    //console.log("updateNotificationCheckedDate", favAssetsData, "prod",product, "singleasset",singleasset);

    // let newAsset 
    // newAsset= favAssetsData.data.data.map(obj => {
    //   if (
    //     obj.assetId === product.assetId &&
    //     product.notificationFlag === true
    //   ) {
    //     obj = {
    //       ...product,
    //       notificationFlag: false,
    //       notificationCheckedOn: currentDate,
    //     };
 
    //   }
    //   singleasset= obj
    //   return newAsset;
    //  });
    //let data={"data":[{"products": [...newProduct], "assets":favouritesAssetsData}]}

    const response = await axios({
      method: "post",
      url: notificationCheckedDateUrl,
      data: {
        favorites: { products: [...newProduct], assets: favouritesAssetsData },
      },
      //data:{"favorites":{"products": [...newProduct], "assets":[...newAsset]}}
    });
    //console.log("singleasset", singleasset)
    const assetresponse = await axios({
      method: "post",
      url: assetNotifPostUrl,
      data: singleasset,
      
      //data:{"favorites":{"products": [...newProduct], "assets":[...newAsset]}}
    });
    if (
      response !== "" ||response !== undefined ||response !== null ||response !== {} || response !== [] ||
      assetresponse !== "" ||assetresponse !== undefined ||assetresponse !== null ||assetresponse !== {} || assetresponse !== [] 
    ) {
      flag = true;
      againCalling();
      await NotifCallingFunc()
    
    }
    setGrayColorOut(true)
    setFusePattern("value");
    changing= true
  };
    // eslint-disable-next-line
  const NotifCallingFunc=async()=>{
    const favoritesListUrl = `${config.favApi}dc/userdata/${userId}/favorites`;
     favoritesData = await axios.get(favoritesListUrl).then((res) => res.data);
     favAssetsData = await axios({
       method: "get",
       url: `${config.baseApi}dc/userdata/${userId}/getAssetNotification`,
       headers: {
         "X-Country-Code": `${getCountryCode()}`
       }
     });
    setdisplayProductList( favoritesData?.data?.[0]?.products?.filter(
      (product) => product.notificationFlag === true
    ))
    setdisplayAssetList(favAssetsData?.data?.data?.filter((asset) =>asset?.notificationFlag === true))

    setcombinedList(displayProductList&& displayAssetList && displayProductList.concat(displayAssetList))
    //favLength = 0;
  }
useEffect(()=>{
   NotifCallingFunc()
   //eslint-disable-next-line react-hooks/exhaustive-deps
},[flag, grayColorOut, fusePattern, changing])
 
 // const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  useEffect( () => {
    //console.log("flagnotifbefore", flag);
    //await delay(6000);
    // if(flag=== true){
    getNotificationList();
    // }
    //console.log("flagnotifafter", flag);
     //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const againCalling = async () => {
    //console.log("flagnotifout", flag);
    //await delay(6000);
    //  const hiding= setInterval(() => {
    //   if(flag=== true){
    getNotificationList();
    //   }
    //   clearInterval(hiding);
    //   }, 1000);
  };
//   const handleClickOutside = event => {
//     console.log(" event.target.parentElement.getAttribute(class)",  event)
//   };
//  document.addEventListener("mousedown", (e)=>handleClickOutside(e));
const handleClickOutside = event => {
  if (
    suggestionWrapper.current &&
    !suggestionWrapper.current.contains(event.target)
  ) {
    changing= false
    setFusePattern("");
 
  }
};

const emptyNotification = async () => {
  // console.log("value", retailerCode)

   const response = await axios({
       method: "post",
       url:  `${config.favApi}dc/userdata/${userId}/clearNotification`,

   });

    setdisplayAssetList([])
    setdisplayProductList([])
    setcombinedList([])
    return response.data.message;

}


document.addEventListener("mousedown", (e)=>handleClickOutside(e));
  const ifdownloaded = grayColorOut
    ? "btn-group pull-right top-head-dropdown"
    : "btn-group pull-right top-head-dropdown open";
  const ifdownloadedNew = grayColorOut
    ? "dropdown-menu dropdown-menu-right"
    : "dropdown-menu dropdown-menu-right open";

  return (
   
    <div class="panel panel-default">
       {console.log("fusepattern", fusePattern )}
      <div class="panel-body" ref={suggestionWrapper}>
        <div className={ifdownloaded}>
          {/* <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={()=>{iconDown()}}>
        Notification <span class="caret"></span>
      </button> */}
          <button type="button" class="icon-button" onClick={() => iconDown()} >
            <span className="material-icons">notifications</span>
            {favLength > 0 ? (
              <span className="icon-button__badge">{favLength}</span>
            ) : (
              ""
            )}
          </button>
          { changing && 
         <ul className={ifdownloadedNew}  >
              <div className="basket-empty-main">
          <div className="basket">{t("Header.notificationTitle")} </div>
          {(displayProductList && displayProductList.length > 0) || (displayAssetList && displayAssetList.length > 0)  ? 
          <div className="empty-basket" onClick={()=>emptyNotification()}> Mark all as read</div>
          :""}
       
        </div>
        <div className="insideAsset">
         {(displayProductList && displayProductList.length > 0) || (displayAssetList && displayAssetList.length > 0) ? (
          //eslint-disable-next-line
          combinedList&&  combinedList.map((mixitem, index) => {
            if(Object.keys(mixitem).includes("productId"))
             return (
               <li className="py-2">
                 {/* <a href={`product-detail-page/04150009582987`} class="top-text-block"> */}
                 <Link
                   to={`/product-detail-page/${mixitem.productId}`}
                   class="top-text-block"
                   onClick={() => updateNotificationCheckedDate(mixitem)}
                 >
                   <div class="top-text-heading">
                     {t("Header.productnotification")} {mixitem.title}
                     {"("}
                     {mixitem.productId}
                     {")"} on {mixitem?.updatedAttributes?.[0]?.modifiedDate.slice(0,10)}
                   </div>
                 </Link>
                 {/* </a>  */}
               </li>
             )
              if(Object.keys(mixitem).includes("assetId"))
             
              return (
                <li className="py-2">
                  {/* <a href={`product-detail-page/04150009582987`} class="top-text-block"> */}
                  <Link
                    to={`/asset-detail-page/${mixitem.assetId}`}
                    class="top-text-block"
                    onClick={() => updateNotificationCheckedDate(mixitem)}
                  >
                    <div class="top-text-heading">
                     {t("Header.assetnotification")} {mixitem.assetName}
                     {"("}
                     {mixitem.assetId}
                     {")"} on {mixitem.assetUpdatedOn.slice(0,10)}
                   </div>
                   
                  </Link>
                  {/* </a>  */}
                </li>
               
              );
           
           }) 
         )
          : (
          // <div>
          //    <h6 class="jss88 jss99 jss113">{t("Header.emptyNotification")} <br/><br/> <p style={{fontSize:"1rem !important", fontWeight:"600"}}> {t("Header.changeInNotification")} </p> </h6>
          //    {/* <p class="jss88 jss349 jss89 jss114">
          //    {t("Header.changeInNotification")} 
          //    </p> */}
          //    </div>
          <div style={{textAlign:"center",fontSize:"110px"}}>
          {/* <div className="emptyCartBox">
          <i class="bi bi-cart-x"></i>
          </div> */}
          <div style={{textAlign:"center", paddingBottom: "10px"  }}>
          <img style={{width:"40%"}} src={NoNotificationIamge} alt="NoNotificationImage" />
          </div>
          <h5 style={{fontWeight:"bold"}} >{t("Header.emptyNotification")} </h5>
          <h6 style={{fontWeight:"bold"}}>{t("Header.changeInNotification")} </h6>
         {/* <h6 class="jss88 jss99 jss113">{t("Header.emptyNotification")} <br/><br/> <p style={{fontSize:"1rem !important", fontWeight:"600"}}> {t("Header.changeInNotification")} </p> </h6> */}

      </div>
         )}
         </div>
         </ul>
}
        </div>
      </div>
    </div>
  );
};

const NotificationsPage = withTranslation("common")(NotificationsPageNew);
const mapStateToProps = (state) => {
  return {
    productsList: state.product.productsList,
    assetsList: state.asset.assetsList,
    favAssetsList: state.asset.favAssetsList,
    favProductsList: state.product.favProductsList,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, null)
)(NotificationsPage);
