import React, { useState } from "react";
import "./Accordion.scss";

const Accordion = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="accordion-item">
      <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
        <span>{title}</span>
        <div>
          {isActive ? (
            <i className="bi bi-chevron-up"></i>
          ) : (
            <i className="bi bi-chevron-down"></i>
          )}
        </div>
      </div>
      {isActive && (
        <div
          className="accordion-content"
          onClick={() => setIsActive(!isActive)}
        >
          {content}
        </div>
      )}
    </div>
  );
};

export default Accordion;
