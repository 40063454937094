import React, { useState, useEffect ,useContext} from "react";
//import AuthContext from "../../components/Auth/Auth-context";
// import "../../components/Notifications/notifications.scss";
import config from "../../config.js";
import axios from "axios";
import AuthContext from "../../components/Auth/Auth-context";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import {getCountryCode, getProductTitle,pimAttributes} from "../../util/Helper.js";
import { getCartList } from "../../Services/service.js";
import _ from "lodash";
import "./ShoppingCartPage.scss";
import { useMediaQuery } from "react-responsive";
//import Skeleton from "react-loading-skeleton";
import LinesEllipsis from "react-lines-ellipsis";
import { trackPromise } from "react-promise-tracker";
import EmptyCartImage from "../../sass/assets/EmptyCartImage.png";
import DownloadCartImage from "../../sass/assets/DownloadCartImage.png"
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Button , Modal } from 'react-bootstrap'
let favLength = 0;
// let favoritesData = {};
let favAssetsData={}
// let favouritesProductsData = {};
// let favouritesAssetsData = {};
// eslint-disable-next-line
let flag = false;
//let dummyFlag= false
let changing =false
const env = process.env.REACT_APP_ENVIRONMENT;
let ga_env = env === "PROD" ? "Production" : "Testing";
let downloadStatus = localStorage.getItem("mass-download");
const ShoppingCart = (props) => {
  let { match, productsList, countryLocale } = { ...props };
  const { t } = props;
  let oopsText = t("Header.cartOopsText");
  let cartEmptyText = t("Header.cartEmptyText");
  let cartDownloadText = t("Header.cartDownloadText");
  //const AuthCtx = useContext(AuthContext);
  const [grayColorOut, setGrayColorOut] = useState(true);
  const suggestionWrapper = React.createRef();
  // eslint-disable-next-line 
  const [fusePattern, setFusePattern] = useState("");
    // eslint-disable-next-line
  const [gigyaRole, setGigyaRole] = useState();
  const [deletedRows, setDeletedRows] = useState([]);
  const [cartOopsText, setCartOopsText]= useState(oopsText)
  const [cartSubText, setCartSubText]= useState(cartEmptyText)
  const [cartImage, setCartImage]= useState(EmptyCartImage)

  const AuthCtx = useContext(AuthContext);
  
  const postGigyaID = async () => {
    const response = await axios({
      method: "get",
      url: `${config.baseApi}dc/exports/getrolepermission/${AuthCtx?.userProfile?.email?AuthCtx?.userProfile?.email : userId }`,
      data: {
        "useAdministration": {
          "Gigya_ID": AuthCtx?.userProfile?.email?AuthCtx?.userProfile?.email : userId ,
        }
      },
      headers: {
        "X-Country-Code": `${getCountryCode()}`
      }
    })
    setGigyaRole(response.data.data?.roleName);
  }
  useEffect(() => {
    postGigyaID();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);  

  useEffect(() => {
    modalcalling()
    downloadStatus = localStorage.getItem("mass-download")
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadStatus, localStorage.getItem("mass-download"),]); 

  const iconDown = async() => {
    setGrayColorOut(!grayColorOut);
    setFusePattern("value")
    changing= true
    setCartOopsText(oopsText)
    setCartSubText(cartEmptyText)
    setCartImage(EmptyCartImage)
    await getCartList(userId)
  };
  //const userId = AuthCtx.giveToken();
  const userId= AuthCtx?.userProfile?.email?AuthCtx?.userProfile?.email : localStorage.email
  //let favAssets = props.CartAssetsList;
  let displayAssetList;
  let displayProductList;
  let combinedList;
  let allList;
  // let displayProductList;
  //let filteredProductList;
  // let filteredAssetIds;
  //let filteredAssettList;
 // let products = props.productsList;
  let assets = props.assetsList;
  let products= props.productsList;
  var  getProductExcelFile
  const productLastUpdatedDate = (item, attribute) => {
    const updatedDates = item.lastUpdatedDate;
    if(updatedDates === undefined || updatedDates.length < 1){
      return "";
    }
    const date = item.lastUpdatedDate[0];
    const dateLocations = date.updatedAttributes;
    const attributeLists = dateLocations.filter (modifiedLocation => modifiedLocation.attributeList[attribute] !== undefined);
    if(attributeLists !== undefined && attributeLists.length > 0){
      const modifiedDate = attributeLists[0].modifiedDate.slice(0,10);
      return modifiedDate;
    }else{
      return ""
    }
  }
  const excelProductData = (item) =>  {
    let productProperties = Object.keys(item);
    let excelData = [];
    let excelPair = {};
    const attribs = pimAttributes()
    attribs.forEach(attribute => {
      if (productProperties.includes(attribute)) {
        let temp = {};
        temp[t(`Excel Header.attribute`)] = t(`Product Detail Page.${attribute}`);
        temp[t(`Excel Header.content`)] = item[attribute];
        temp[t(`Excel Header.last_update`)] = productLastUpdatedDate(item, attribute);
        excelPair = Object.assign({}, temp);
        excelData.push(excelPair);
      }
    });
    return excelData;
  };

  const getExcelData=(item)=>{
    const fileName = `Produktdaten_${item?.ptitle}_${item?.pharmacentralnum}`
    const excelDatas = excelProductData(item);
    const productBrand = Object.keys(item).includes("brand")
      ? item.brand
      :t("Miscellaneous.notAvailable");
    getProductExcelFile = [fileName, excelDatas, productBrand];
    return getProductExcelFile;
  }
  let favAssets = props.CartAssetsList.map(asset => asset?.assetId);
  displayProductList = products.filter((asset) => favAssets.includes(asset.id));
  displayAssetList = assets.filter((asset) => favAssets.includes(asset.id));
 // getExcelData(displayProductList)
  combinedList = displayProductList&& displayAssetList && displayProductList.concat(displayAssetList)
  allList = displayProductList&& displayAssetList && displayProductList.concat(displayAssetList)
  const getNotificationList = async () => {
    if (!_.isEqual(props.CartAssetsList, favAssetsData)) {
      // Make the API call only if CartAssetsList has changed
      favAssetsData = _.cloneDeep(props.CartAssetsList);

       getCartList(userId)

    }

   // filteredAssetIds = favAssets.map(asset => asset.assetId);
   // filteredProductList= favProducts.map((prodId)=>prodId.productId)
    // displayProductList = products.filter((product) =>
    // filteredProductList.includes(product.id)
    // );
    
    displayProductList = products.filter((asset) => favAssets.includes(asset.id));
    displayAssetList = assets.filter((asset) => favAssets.includes(asset.id));
   // getExcelData(displayProductList)
    combinedList = displayProductList&& displayAssetList && displayProductList.concat(displayAssetList)
    
    favLength = displayProductList&&  displayAssetList &&( displayProductList.length +displayAssetList.length)
    flag = false;
    setFusePattern("value");
    //changing= true
    return;
  };

  useEffect( () => {
    //await delay(6000);
    // if(flag=== true){
    getNotificationList();
    // }
     //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, props.CartAssetsList]);

const handleClickOutside = event => {
  if (
    suggestionWrapper.current &&
    !suggestionWrapper.current.contains(event.target)
  ) {
    changing= false
    setFusePattern("");
 
  }
};

document.addEventListener("mousedown", (e)=>handleClickOutside(e));
  const ifdownloaded = grayColorOut
    ? "btn-group pull-right top-head-dropdownCart cartBox"
    : "btn-group pull-right top-head-dropdownCart open cartBox";
  const ifdownloadedNew = grayColorOut
    ? "dropdown-menu dropdown-menu-right cartBox"
    : "dropdown-menu dropdown-menu-right open cartBox";


const large = useMediaQuery({
  query: "(min-width: 1200px)",
});
const mediumlarge_floor = useMediaQuery({
  query: "(min-width: 992px)",
});
const mediumlarge_ceil = useMediaQuery({
  query: "(max-width: 1199px)",
});
const medium_ceil = useMediaQuery({
  query: "(max-width: 991px)",
});
const medium_floor = useMediaQuery({
  query: "(min-width: 481px)",
});
const small_ceil = useMediaQuery({ query: "(max-width: 480px)" });
const small_floor = useMediaQuery({ query: "(min-width: 376px)" });
const xs_small = useMediaQuery({ query: "(max-width: 375px)" });

const cardCount = () => {
  let count = 0;
  if (large) {
    count = 12;
  } else if (mediumlarge_floor && mediumlarge_ceil) {
    count = 4;
  } else if (medium_floor && medium_ceil) {
    count = 6;
  } else if (small_floor && small_ceil) {
    count = 8;
  } else if (xs_small) {
    count = 10;
  }
  return `col-${count}`;
};
cardCount();

const handleClickOnCard = item => {
  let { url } = { ...match };
  url =
    url.charAt(url.length - 1) === "/"
      ? url.substring(0, url.length - 1) + "" + url.substring(url.length)
      : url;
  let paramObj;
  switch (item.type) {
    case "product":
      paramObj = {
        pathname: `${url}/product-detail-page/${item.id}`,
        state: {
          queryParams: { brand: [item.brand.trim()] },
          id: item.id,
          title: item.ptitle
        }
      };
      break;
    case "asset":
      let flag;
      flag = url.includes("/asset-detail-page")
        ? 0
        : url.includes("/product-detail-page")
          ? 1
          : null;
      switch (flag) {
        case 0:
          paramObj = {
            pathname: `${url.substring(0, url.lastIndexOf("/"))}/${item.id}`,
            state: {
              queryParams: { brand: [item.brand.trim()] },
              id: item.id,
              title: dynamicAssetTitle(item)
            }
          };
          break;
        case 1:
          paramObj = {
            pathname: `${url
              .substring(0, url.lastIndexOf("/"))
              .replace("product-detail-page", "asset-detail-page")}/${item.id
              }`,
            state: {
              queryParams: { brand: [item.brand.trim()] },
              id: item.id,
              title: dynamicAssetTitle(item)
            }
          };
          break;
        default:
          paramObj = {
            pathname: `${url}/asset-detail-page/${item.id}`,
            state: {
              queryParams: { brand: [item.brand.trim()] },
              id: item.id,
              title: dynamicAssetTitle(item)
            }
          };
          break;
      }
      break;
    default:
      break;
  }
  return paramObj;
};
const fileExtension = asset => {
  const file_type =
    Object.keys(asset).includes("file_type") && asset.file_type !== ""
      ? asset.file_type
      : "N/A";
  return file_type;
};

const dynamicAssetTitle = item => {
  let asset_title = [];
  if (Object.keys(item).includes("upc")) {
    let ptitle = getProductTitle(item.upc, productsList, countryLocale);
    ptitle !== undefined
      ? asset_title.push(ptitle)
      : asset_title.push(item.title);

    if (_.has(item, "asset_type")) {
      item.asset_type[0].dcAssetType? asset_title.push(t(`Asset Type Card.${item.asset_type[0].dcAssetType}`)):asset_title.push( t(`Asset Type Card.Others`)) ;
    }
  } else {
    if (_.has(item, "brand")) {
      let assetBrand = item.brand;
      if (_.has(item, "sub_brand")) {
        asset_title.push(item.sub_brand[0]);
      } else {
        asset_title.push(assetBrand);
      }
    }
    if (_.has(item, "asset_type")) {
      item.asset_type[0].dcAssetType? asset_title.push(t(`Asset Type Card.${item.asset_type[0].dcAssetType}`)):asset_title.push( t(`Asset Type Card.Others`)) ;
    }
    if (_.has(item, "customer_explanation")) {
      asset_title.push(item.customer_explanation);
    }
  }
  asset_title = asset_title.join(" ");
  return asset_title;
};
const placeholder = item => {
  if (item.type === "product") {
    let imageUrl,
      preview_available = false;
    const domain = window.location.origin;
    if (
      Object.keys(item).includes("asset_info_print") &&
      Object.keys(item.asset_info_print).includes("preview_location")
    ) {
      imageUrl = `${domain}/${item.asset_info_print.preview_location}`;
      preview_available = true;
    }else if (Object.keys(item).includes("thumbnail")) {
      imageUrl = item.thumbnail;
      if (imageUrl !== undefined && imageUrl.length > 0) {
        preview_available = true;
      }
    } else if (
      Object.keys(item).includes("asset_info") &&
      Object.keys(item.asset_info).includes("preview_location")
    ) {
      imageUrl = `${domain}/${item.asset_info.preview_location}`;
      preview_available = true;
    } else if (Object.keys(item).includes("asset_location")) {
      let primaryImage = "";
      if (Array.isArray(item.asset_location)) {
        primaryImage = item.asset_location.filter(
          file =>
            file.includes("Primary_Image") && file.indexOf("_MACOSX") === -1
        );
      } else if (
        item.asset_location.includes("Primary_Image") &&
        item.asset_location.indexOf("_MACOSX") === -1
      ) {
        primaryImage = item.asset_location;
      }
      if (primaryImage.length > 0) {
        imageUrl = `${domain}/${primaryImage}`;
        preview_available = true;
      }
    }
    if (preview_available) {
      return <img alt="" src={imageUrl} height="100%" width="100%"></img>;
    } else {
      return (
        <React.Fragment>
          <div className="no-preview-cart">
            <div className="bayer-logo-background"></div>
            {( gigyaRole === "Internal") ?
            <span className="text">{t("Miscellaneous.noPreviewForInternal")}</span>
            :
            <span className="text">{t("Miscellaneous.noPreview")}</span>}
          </div>
        </React.Fragment>
      );
    }
  } else {
    let isPreviewAvailable = false;
    let previewFileSrc;
    let imageUrl;
    const domain = window.location.origin;
    if (_.has(item, "preview_location")) {
      previewFileSrc = item.preview_location;
      imageUrl = `${domain}/${previewFileSrc}`;
      isPreviewAvailable = true;
    } else if (_.has(item, "asset_location")) {
      if (Array.isArray(item.asset_location)) {
        previewFileSrc = item.asset_location.filter(
          file =>
            file.includes("Primary_Image") && file.indexOf("_MACOSX") === -1
        );
        if (previewFileSrc.length > 0) {
          isPreviewAvailable = true;
          imageUrl = `${domain}/${previewFileSrc}`;
        } else {
          const imageTypes = ["png", "jpg", "jpeg"];
          previewFileSrc = item.asset_location.filter(file => {
            const check = imageTypes.some(imgtype => file.includes(imgtype));
            return check;
          });
          if (previewFileSrc.length === 1) {
            isPreviewAvailable = true;
          }
        }
      } else {
        previewFileSrc = item.asset_location;
        const extension = previewFileSrc.slice(
          ((previewFileSrc.lastIndexOf(".") - 1) >>> 0) + 2
        );
        const imageTypes = ["png", "jpg", "jpeg"];
        if (imageTypes.includes(extension)) {
          isPreviewAvailable = true;
          imageUrl = `${domain}/${previewFileSrc}`;
        }
      }
    }
    if (isPreviewAvailable) {
      imageUrl = `${domain}/${previewFileSrc}`;
      return <img alt="" src={imageUrl} height="100%" width="100%"></img>;
    } else {
      return (
        <React.Fragment>
          <div className="no-preview-cart">
            <div className="bayer-logo-background"></div>
            {( gigyaRole === "Internal") ?
            <span className="text">{t("Miscellaneous.noPreviewForInternal")}</span>
            :
            <span className="text">{t("Miscellaneous.noPreview")}</span>}
          </div>
        </React.Fragment>
      );
    }
  }
};
const dispCardAssetBrand = brand => {
  let brandString = "N/A";
  if (brand !== undefined && brand.length > 0) {
    brandString = brand.map(item => `${item}`).join("/");
    return brandString;
  }
  return brandString;
};
const crossMark = async (event, item) => {
  const type = event.currentTarget.getAttribute("data-type");
  if (type === "product") {
    try {
      setDeletedRows((prevDeletedRows) => [...prevDeletedRows, item.id]);
      await axios({
        method: "post",
        url: `${config.favApi}dc/assets/deleteAssetsFromCart/${userId}`,
        data: {
          country: item.country_code,
          assetId: item.id,
          assetName: item.title,
          brand: item.brand
        },
        headers: {
          "X-Country-Code": `${getCountryCode()}`
        }
      });
      setTimeout(() => {
        setDeletedRows((prevDeletedRows) =>
          prevDeletedRows.filter((deletedId) => deletedId !== item.id)
        );
      }, 100);

      // Optionally, refresh the cart list
      await getCartList(userId);
    } catch (error) {
      // Handle error if needed
      console.error("Error in Axios request", error);
    }
  }
  if (type === "asset") {
    try {
      setDeletedRows((prevDeletedRows) => [...prevDeletedRows, item.id]);
      await axios({
        method: "post",
        url: `${config.favApi}dc/assets/deleteAssetsFromCart/${userId}`,
        data: {
          country: item.country_code,
          assetId: item.id,
          assetName: item.title,
          brand: item.brand
        },
        headers: {
          "X-Country-Code": `${getCountryCode()}`
        }
      });
      setTimeout(() => {
        setDeletedRows((prevDeletedRows) =>
          prevDeletedRows.filter((deletedId) => deletedId !== item.id)
        );
      }, 100);

      // Optionally, refresh the cart list
      await getCartList(userId);
    } catch (error) {
      // Handle error if needed
      console.error("Error in Axios request", error);
    }
  }
};

const navigatingFunc=(item)=>{
  let presentationComp;
  if (item.type === "asset" || item.type === "product" ) {
    const match = displayAssetList.some(displayItem => JSON.stringify(displayItem) === JSON.stringify(item));
    presentationComp = (
      <React.Fragment>
          
        <div className= {`shopping-cart-outsideDiv ${
            deletedRows.includes(item.id) ? 'deleted-row' : ''
          }`}>
        <table className="cart-compnew"style={{borderBottom: "1px solid silver"}} >
        <tr className="cart-section" >
        <td className="thumbnailnew"><Link to={ handleClickOnCard(item)}> {placeholder(item)}</Link></td>
          
          <td style={{width:"100%"}}>
          <span className="name" style={{color: "#00617f"}} >
          <Link className="card-block" to={ handleClickOnCard(item)}>
                    <LinesEllipsis
                      text= {match ? dynamicAssetTitle(item): `Produktdaten_${item?.ptitle}_${item?.id}`}
                      maxLine ="3"
                      ellipsis="..."
                      trimRight
                      basedOn="letters"
                    /></Link>
                  </span>
            <span>{t("Miscellaneous.Brand")}{": "}</span>
            <span>
              {match ? dispCardAssetBrand(item.sub_brand):item.brand }
              <div>
              {match ? 
                ( <span>{item.file_size}{" ( "}{fileExtension(item)}{" ) "}</span>)
                :
                ( <span>25KB ( xlsx )</span>)}
              {/* <span>{fileExtension(item)}</span> */}
              </div>
            </span>
          </td>
       <td>   <div data-type={item.type} className="crossMark"  onClick={e => crossMark(e, item)}><i class="bi bi-x-circle-fill "></i></div></td>
        </tr>
       </table>
    
        </div>

      </React.Fragment>
    );
  }
  return presentationComp;
}
const fileType =
"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExt = ".xlsx";
const exportToCSV = (apiData, fileName, pharmacentralnum , brand) => {
  const ws = XLSX.utils.json_to_sheet(apiData[1]);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, `Produktdaten_${fileName}_${pharmacentralnum}` + fileExt);
  window.dataLayer.push({
    event: "gaGenericEvent",
    eventCategory: "Download (local)",
    eventAction: brand,
    eventLabel: "product",
    eventValue: "ProductDataPackage.xlsx",
    environment: ga_env
  });

    axios({
      method: "get",
      url:  `${config.favApi}dc/assets/downloadAllAssetsFromCart/${userId}`,
      //data: favoritesCompleteData,
      headers: {
        "X-Country-Code": `${getCountryCode()}`
      }
    })
    .then(async() => {    
      flag = true;
      await getCartList(userId);
    })
    .catch((error) => {
      // Handle error if needed
      console.error("Error in Axios request", error);
    });

};
const shoppingCartdownloadAll=async()=>{
  //var favAssets = [];
  const favoritesListUrl = `${config.favApi}dc/assets/getAllAssetsFromCart/${userId}`;
  const favoritesData = await axios.get(favoritesListUrl).then(res => res.data);
  const favoritesCompleteData= favoritesData.data
  const favorites = favoritesData.data.map(asset => asset.assetId);
  let downloadAssetList = assets.filter((asset) => favorites.includes(asset.id));

  let downloadProductList = products.filter((asset) => favAssets.includes(asset.id));
 // getExcelData(displayProductList)
  let combinedList = downloadProductList&& downloadAssetList && downloadProductList.concat(downloadAssetList)
  //("asset", combinedList)
  combinedList.map((asset)=> asset.type ==="asset"? downloadOnClick(asset, asset.type,favoritesCompleteData ): exportToCSV(getExcelData(asset), asset?.ptitle, asset?.pharmacentralnum, asset?.brand))
  setCartOopsText("")
  setCartSubText(cartDownloadText)
  setCartImage(DownloadCartImage)
}
const downloadOnClick=async(entity, location, favoritesCompleteData)=> {
  const userId= localStorage.email
  let timeStamp = new Date();
  let track_country = getCountryCode();
  let track_brand = "";
  let track_assetId = "";
  let track_assetName=""
 // event?.preventDefault();
  const env = process.env.REACT_APP_ENVIRONMENT;
  let ga_env = env === "PROD" ? "Production" : "Testing";
  window.dataLayer = window.dataLayer || [];
  var FileSaver = require("file-saver");
  let assetBrand;
  // if (entity.type === "asset") {
  //   assetBrand = event?.currentTarget.getAttribute("data-brand");
  // }
  // event?.currentTarget.classList.add("done");
  // event?.currentTarget.lastElementChild.classList.remove(
  //   "bi-file-earmark-arrow-down"
  // );
  // event?.currentTarget.lastElementChild.classList.add("bi-check-lg");
  let fileSrc;
  if (location === "asset") {
    if (Object.keys(entity).includes("asset_location")) {
      if (Array.isArray(entity.asset_location)) {
        fileSrc = entity.asset_location.filter(file => {
          const extension = file.slice(((file.lastIndexOf(".") - 1) >>> 0) + 2);
          if (extension === "zip") {
            return true;
          } else {
            return false;
          }
        });

        if (fileSrc.length === 0) {
          fileSrc = entity.asset_location;
        }
      } else {
        fileSrc = entity.asset_location;
      }
      switch (entity.type) {
        case "product":
          track_brand = entity.asset_info.brand;
          track_assetId = entity.asset_info.assetId;
          break;
        case "asset":
          track_brand = entity.brand;
          track_assetId = entity.assetId;
          track_assetName=entity.title;
          break;
        default:
          break;
      }
    }
  } else if (location === "print_media") {
    if (Object.keys(entity).includes("print_media_location")) {
      if (Array.isArray(entity.print_media_location)) {
        fileSrc = entity.print_media_location.filter(file => {
          const extension = file.slice(((file.lastIndexOf(".") - 1) >>> 0) + 2);
          if (extension === "zip") {
            return true;
          } else {
            return false;
          }
        });

        if (fileSrc.length === 0) {
          fileSrc = entity.print_media_location;
        }
      } else {
        fileSrc = entity.print_media_location;
      }

      switch (entity.type) {
        case "product":
          track_brand = entity.asset_info_print.brand;
          track_assetId = entity.asset_info_print.assetId;
          break;
        case "asset":
          track_brand = entity.brand;
          track_assetId = entity.assetId;
          track_assetName=entity.title;
          break;
        default:
          break;
      }
    }
  }
  else if (location === "product") {
   getExcelData(entity)
  }
  let zipFiles = [];
  if(location !== "product"){
    if (Array.isArray(fileSrc) && fileSrc.length > 1) {
      const domain = window.location.origin;
      fileSrc.forEach(file => {
        const downloadUrl = `${domain}/${file}`;
        zipFiles.push(downloadUrl);
      });
    }
    else if (fileSrc.indexOf(".zip") === -1 && entity.attachments?.length) { // if it is not zip try to zip
      const domain = window.location.origin;
      zipFiles.push(`${domain}/${fileSrc}`);
      entity.attachments.forEach(attachment => {
        zipFiles.push(`${domain}/${attachment.asset_location}`);
      })
    }
    if (zipFiles.length > 0) {
      const zip = require("jszip")();
      let files = zipFiles;
      files.forEach(function (fileItem) {
        let fileName = fileItem.substring(
          fileItem.lastIndexOf("/") + 1,
          fileItem.length
        );
        if (fileName.indexOf(".zip") === -1) {
          zip.file(fileName, fileItem);
        }
      });
      // for (let file = 0; file < files.length; file++) {
      //   let fileName = files[file].substring(
      //     files[file].lastIndexOf("/") + 1,
      //     files[file].length
      //   );
      //   zip.file(fileName, files[file]);
      // }
      if (entity.type === "product") {
        window.dataLayer.push({
          event: "gaGenericEvent",
          eventCategory: "Download (local)",
          eventAction: entity.brand,
          eventLabel: "product",
          eventValue: "E-CommercePackage.zip",
          environment: ga_env
        });
        zip.generateAsync({ type: "blob" }).then(content => {
          FileSaver.saveAs(content, "E-CommercePackage.zip");
        });
      } else {
        window.dataLayer.push({
          event: "gaGenericEvent",
          eventCategory: "Download (local)",
          eventAction: `${assetBrand}`,
          eventLabel: "asset",
          eventValue: "E_CommerceAssets.zip",
          environment: ga_env
        });
        zip.generateAsync({ type: "blob" }).then(content => {
          FileSaver.saveAs(content, "E_CommerceAssets.zip");
        });
      }
      axios({
        method: "post",
        url: `${config.favApi}dc/userdata/${userId}/downloads`,
        data: {
          downloads: {
            Country: track_country,
            Asset_ID: track_assetId,
            Asset_Name: track_assetName,
            Brand: track_brand,
            User_ID: userId,
            Time_Stamp: timeStamp
          }
        },
        headers: {
          "X-Country-Code": `${getCountryCode()}`
        }
      });
    } else {
      const filePath = Array.isArray(fileSrc) ? fileSrc[0] : fileSrc;
      let fileName = filePath.substring(filePath.lastIndexOf("/") + 1);
      const domain = window.location.origin;
      let downloadUrl = `${domain}/${filePath}`;
      if (entity.type === "product") {
        window.dataLayer.push({
          event: "gaGenericEvent",
          eventCategory: "Download (local)",
          eventAction: entity.brand,
          eventLabel: "product",
          eventValue: "E-CommercePackage.zip",
          environment: ga_env
        });
      } else {
        window.dataLayer.push({
          event: "gaGenericEvent",
          eventCategory: "Download (local)",
          eventAction: `${assetBrand}`,
          eventLabel: "asset",
          eventValue: `${fileName}`,
          environment: ga_env
        });
      }
  
      if (downloadUrl !== null && downloadUrl.length > 0) {
        if (entity.type === "product" || entity.type === "asset" ) {
          FileSaver.saveAs(downloadUrl, fileName);
        } else if(entity.type === "asset"){
          downloadUrl = `${config.baseApi}dc/assets/download/files/${assetBrand}/${entity.assetId}`;
          if (entity.asset_location.indexOf(".zip") === -1) {
            fileName = (entity.title || "Asset_Attachments") + ".zip";
          }
          trackPromise(axios.get(downloadUrl, {
            headers: {
              "X-Country-Code": `${getCountryCode()}`
            }
          }).then(res => res.data).then(res => {
            FileSaver.saveAs(res.data.url, fileName);
          }), "assets-downloading");
  
        }
      }
      axios({
        method: "post",
        url: `${config.favApi}dc/userdata/${userId}/downloads`,
        data: {
          downloads: {
            Country: track_country,
            Asset_ID: track_assetId,
            Asset_Name: track_assetName,
            Brand: track_brand,
            User_ID: userId,
            Time_Stamp: timeStamp
          }
        },
        headers: {
          "X-Country-Code": `${getCountryCode()}`
        }
      });
    }
  }
 
  let currentDate = new Date().toJSON().slice(0, 10);
  const assetPostResponse =  axios({
    method: "POST",
    url: `${config.baseApi}dc/userdata/${userId}/updateAssetNotification`,
    data: {
      "notificationFlag": true,
      "country": `${getCountryCode()}`,
      "assetUpdatedOn": currentDate,
      "downloadedOn": currentDate,
      "assetId": entity.assetId,
      "assetName": entity.title,
      "brand": entity.brand,
      "notificationCheckedOn": currentDate
    },

  })
  if (location === "product") {
    axios({
      method: "get",
      url:  `${config.favApi}dc/assets/downloadAllAssetsFromCart/${userId}`,
      //data: favoritesCompleteData,
      headers: {
        "X-Country-Code": `${getCountryCode()}`
      }
    })
    .then(async() => {    
      flag = true;
      await getCartList(userId);
    })
    .catch((error) => {
      // Handle error if needed
      console.error("Error in Axios request", error);
    });
  }
  if (location === "asset") {
    axios({
      method: "get",
      url:  `${config.favApi}dc/assets/downloadAllAssetsFromCart/${userId}`,
      //data: favoritesCompleteData,
      headers: {
        "X-Country-Code": `${getCountryCode()}`
      }
    })
    .then(async() => {    
      flag = true;
      await getCartList(userId);
    })
    .catch((error) => {
      // Handle error if needed
      console.error("Error in Axios request", error);
    });
  }
  favLength = 0;
  return assetPostResponse
}
const emptyBasket=async()=>{
  //var favAssets = [];
  localStorage.setItem("mass-download", false)
  const favoritesListUrl = `${config.favApi}dc/assets/getAllAssetsFromCart/${userId}`;
  const favoritesData = await axios.get(favoritesListUrl).then(res => res.data);
  const favoritesCompleteData= favoritesData.data
    axios({
      method: "post",
      url:  `${config.favApi}dc/assets/deleteAllAssetsFromCart/${userId}`,
      data: favoritesCompleteData,
      headers: {
        "X-Country-Code": `${getCountryCode()}`
      }
    })
    .then(async() => {
      await getCartList(userId);
    })
    .catch((error) => {
      // Handle error if needed
      console.error("Error in Axios request", error);
    });
}

const handleNotifcatioModal= () => {
  // setNotificationModal(false)
  getNotificationList()
  localStorage.setItem("mass-download", false)
}
const modalcalling =()=>{
  return(
    allList && allList.length > 0 ? 
    <Modal show={JSON.parse(localStorage.getItem("mass-download"))}  aria-labelledby="contained-modal-title-vcenter"
          centered>
          <Modal.Header >
              <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ textAlign: "center" }}>{t("Header.cartModal")}</Modal.Body>
          <Modal.Footer>
              <Button style={{ backgroundColor: "#89d329" , width:"20%"}} onClick={handleNotifcatioModal} >
              {t("Header.cartModalYes")}
              </Button>
              <Button style={{width:"20%"}} variant="secondary" onClick={()=>emptyBasket()} >
              {t("Header.cartModalNo")}
              </Button>
          </Modal.Footer>
      </Modal> 
      :"")
}
  return (
   
    <div class="panel panel-default">
      <div class="panel-body" ref={suggestionWrapper}>
        <div className={ifdownloaded}>
          {/* <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={()=>{iconDown()}}>
        Notification <span class="caret"></span>
      </button> */}
          <button type="button"  class="icon-button"  onClick={() => iconDown()} >
          <span className="material-icons">shopping_cart</span>
            {/* <i className="bi bi-cart"></i> */}
            {favLength > 0 ? (
              <span className="icon-button__badge">{favLength}</span>
            ) : (
              ""
            )}
          </button>
          { changing && 
         <ul className={ifdownloadedNew}  >
                   <div className="basket-empty-main">
          <div className="basket"> BASKET</div>
          {combinedList && combinedList.length > 0 ? 
          <div className="empty-basket" onClick={()=>emptyBasket()}> EMPTY BASKET</div>
          :""}
        </div>
        {combinedList && combinedList.length > 0 ? (
  <div>
    <div className="insideAsset">
      {combinedList.map((asset, index) => {
        return (
          <div className={cardCount()  + " eachRow"}  key={index}>
            {navigatingFunc(asset)}
          </div>
        );
      })}
    </div>
    <div className="ShoppingCart-DownloadButton" onClick={() => shoppingCartdownloadAll()}>
      <button style={{width:"100%",backgroundColor:"#ff3162",color:"white",border:"3px solid #ff3162", height:"2.5rem", borderRadius:"5px"}}>DOWNLOAD ALL</button>
    </div>
  </div>
) : (
  <div style={{textAlign:"center",fontSize:"110px"}}>
      {/* <div className="emptyCartBox">
      <i class="bi bi-cart-x"></i>
      </div> */}
      <div style={{textAlign:"center", paddingBottom: "10px"  }}>
      <img style={{width:"30%"}} src={cartImage} alt="EmptyCartImage" />
        
      </div>
      {/* <h5 >Oops! Your bag is empty.</h5>
      <h6>Add Your Favorite Items To Cart And Get Downloaded it!</h6> */}
      <h5>{cartOopsText}</h5>
      <h6>{cartSubText}</h6>
  </div>
)}

           {/* <div className="ShoppingCart-DownloadButton" onClick={() => shoppingCartdownloadAll()}>
            <button style={{width:"100%",backgroundColor:"#ff3162",color:"white",border:"3px #ff3162", height:"2.5rem", borderRadius:"5px"}}>DOWNLOAD ALL</button>
             </div> */}

         </ul>
}
        </div>
      </div>
      
      {modalcalling() }
       
    </div>
  );
};

const ShoppingCartPage = withTranslation("common")(ShoppingCart);
const mapStateToProps = (state) => {
  return {
    productsList: state.product.productsList,
    assetsList: state.asset.assetsList,
    favAssetsList: state.asset.favAssetsList,
    CartAssetsList: state.asset.CartAssetsList,
    favProductsList: state.product.favProductsList,
    countryLocale: state.locale.countryLocale
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, null)
)(ShoppingCartPage);
