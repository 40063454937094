import { Box, Container, FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import Button from '@mui/material/Button';
// import CloudUpload from "@mui/icons-material/CloudUpload";
import { useTranslation } from "react-i18next";
import "./UploadFile.css"
import axios from "axios";
import config from "../../config";
import { getCountryCode } from "../../util/Helper.js";
import { useContext, useEffect, useState } from "react";
import "./DragDropFile.css"
import { useRef } from "react";
import CloudUpload from "@mui/icons-material/CloudUpload";
import AuthContext from "../../components/Auth/Auth-context";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
// let addedUpc=[];
export default function MyExports() {
  // const gigya = window.gigya;
  const { t } = useTranslation("common");
  const AuthCtx = useContext(AuthContext);
  const userGigyaId = AuthCtx.giveToken();
  const userId= localStorage.email
  // console.log("gigya", userId)
  const [optionList, setOptionList] = useState([]);
  const [allUpcCodes, setAllUpcCodes] = useState([]);
  const [text, setText] = useState('');
  const [suggestions, setSuggestions] = useState([]);
   const [addedUpc, setAddedUpc] = useState([])
  const [fileName, setFileName] = useState("")
  const [fName, setFName] = useState("")
  const [fType, setFType] = useState("")
  const [fSize, setFSize] = useState("")
  const [selectedRetailer, setSelectedRetailer] = useState("");
  const [uploadFile, setUploadFile] = useState(false)
  const [show, setShow] = useState(false);  
  const [showCancel, setShowCancel] = useState(false);
  const [countryFilter, setcountryFilter] = useState("");
  const [countryOptions, setCountryOptions] = useState([]);
    // eslint-disable-next-line
  const [gigyaRole, setGigyaRole] = useState();
  const [inputValue, setInputValue] = useState('');

let userProfile
  const postGigyaID = async () => {
    const response = await axios({
      method: "get",
      url: `${config.baseApi}dc/exports/getrolepermission/${userId}`,
      data: {
        "useAdministration": {
          "Gigya_ID": userId,
        }
      },
      headers: {
        "X-Country-Code": `${getCountryCode()}`
      }
    })
    setGigyaRole(response?.data?.data?.roleName);
  }

  const getData = async (e) => {
    userProfile= (gigyaRole === "Global Admin")? e.toLowerCase() : `${getCountryCode()}`
    //console.log("userProfile",userProfile)
   const response = await axios
      .get(
        `${config.baseApi}dc/exports/getallretailers`,
        {
          headers: {
            "X-Country-Code": userProfile,
          }
        }
      )
    // console.log("country",getCountryCode())
   // console.log("response123", response.data.message)
    setOptionList(response.data.message)
    return response.data;
  };
  const getUpc = async () => {

    const response = await axios
      .get(
        `${config.baseApi}dc/entities/allupc`,
        {
          headers: {
            "X-Country-Code": `${getCountryCode()}`
          }
        }
      )
    setAllUpcCodes(response.data.message)
    return response.data;
  };


  useEffect(() => {
    postGigyaID()
   getData(countryFilter);
    getUpc();
    getCountries();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryFilter])
  const onChangeSuggestion = (text) => {
    setText(text);
    setSuggestions([])
  }

  const onChangeUpc = (text) => {
    let matches = []
    if (text.length > 0) {
      matches = allUpcCodes.filter(upc => {
        const regex = new RegExp(`${text}`, "gi");
        return upc.match(regex)
      })
    }
    setText(text);
    setSuggestions(matches);
  }
  const pushUpc = () => {
    if (!addedUpc.includes(text))
      addedUpc.push(text);
    setText('');
  }
  const handleChangeRetailer = (e) => {
    setSelectedRetailer(e.target.value)
  }
  const fileNameHandler = (e) => {
    e.preventDefault()
    setFileName(e.target.value)
    //console.log("setfile", e.target.value)
  }

  const handleInputChange = (e) => {
    setInputValue(e.target.value)
  };

  // drag state
  const [dragActive, setDragActive] = useState(false);
  // ref
  const inputRef = useRef(null);

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      // handleFiles(e.dataTransfer.files);

      handleFileAndDrop(e.dataTransfer.files[0])
    }
  };

  // triggers when file is selected with click
  const handleFile = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      // handleFiles(e.target.files);
      // eslint-disable-next-line
      const file = e.target.files[0];
      // const fileLink = URL.createObjectURL(file);

//console.log(file)
      handleFileAndDrop(e.target.files[0])
    }
  };
  const handleFileAndDrop = (file) => {
    setFName(file.name)
    setFSize(file.size)
    setFType(file.type)
    setUploadFile(file)
  
    //console.log("file", file)
  }

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };

  const postUploadFile = async () => {
    userProfile= (gigyaRole === "Global Admin")? countryFilter.toLowerCase() : `${getCountryCode()}`
  

    const response = await axios({
      method: "post",
      url: `${config.baseApi}dc/exports/uploadexportfile`,
      data:
      {
        "fileName": fileName,
        "gigyaId": userGigyaId,
        "emailId": userId,
        "filePath": fName,
        "fileType": fType,
        "fileSize": fSize,
        "Retailer": optionList && optionList.length > 0 ? selectedRetailer : inputValue,
        "upc": addedUpc
      },
      headers: {
        // "Content-Type": `application/json`
        "X-Country-Code": userProfile
      }

    });
   
  //   const uploadResponse = await axios.put(
  //     response.data.message,
  //     uploadFile,
  //     {
  //       headers: {
  //         "Content-Type": `application/pdf`
  //       }
  //     }
  //   );
  // }

    const uploadResponse = await fetch(
      response.data.message, 
      {
      method: "PUT",
      // headers:{
      //            "Content-Type": `application/pdf`
      //         },
      body: uploadFile
    });
    console.log(uploadResponse)
    cancelHandler()
    return response;

  }

  const getCountries = async () => {
    const response = await axios
        .get(
            `${config.baseApi}dc/config/getCountries`,
        
        )
     
        const obj = {countryCode: "All", region: "All"};
        setCountryOptions([...response.data.data, obj])
    return response.data;
};

  const cancelHandler = () => {
     setFName("")
    setFileName("")
    setText("")
    setSelectedRetailer("")
    setAddedUpc([])
    setShow(true)
  }
  const cancelHandlerNew = () => {
    setFName("")
   setFileName("")
   setText("")
   setSelectedRetailer("")
   setAddedUpc([])
   setShowCancel(true)
 }
  // console.log("retailer", addedUpc)

  const handleClose = () => {
    
  setShow(false);
  }
  const handleCloseCancel = () => {
    
    setShowCancel(false);
    }
  const handlecountryChange = (evt) => {
    setcountryFilter(evt.target.value);
    getData(evt.target.value)
   // console.log("option1",evt.target.value)
};

  return (
    <div>

      <Container
        className="border"
        style={{ marginTop: "5%", marginBottom: "5%", width: "80%" }}>

        <Container
          style={{
            textAlign: "left",
            paddingLeft: "2%",
            paddingBottom: "1%",
            fontWeight: "600",
            paddingTop: "2%"
          }}>
          <h5 style={{ paddingBottom: "3%" }}>
            My Exports Admin Access- Upload New
          </h5>
          <TextField
            id="filled-basic"
            label="File Name"
            variant="filled"
            style={{ width: "inherit" }}
            value={fileName}
            onChange={fileNameHandler}
           />

          <Box
            className="border"
            style={{ width: "inherit", marginTop: "2%", borderRadius: "5px" }}>
            <h5 style={{
              font: "unset",
              fontWeight: "300", padding: "1%", borderBottom: "1px solid", backgroundColor: "gainsboro"
            }}>Upload File</h5>
            {/* <Box className="dotted-border" style={{borderStyle:"dotted"}}>
                           <CloudUpload fontSize="large"></CloudUpload>
                        
                        </Box> */}
            {/* <DragDropFile></DragDropFile> */}
            <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
              <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleFile} />
              <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : ""}>
                <div>
                  <CloudUpload color="disabled" style={{ fontSize: "80px" }}></CloudUpload>
                  <p style={{ marginBottom: "0px" }}><i>SELECT A FILE TO UPLOAD</i></p>
                  <button className="upload-button" onClick={onButtonClick}><b>OR DRAG AND DROP IT HERE</b></button>
                  <div>   {fName} </div>
                </div>
              </label>
              {dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>}

            </form>
            <p style={{ marginLeft: "2%" }}>OR UPLOAD FROM A URL</p>
            <TextField
              id="filled-basic"
              label="Add the file URL"
              variant="filled"
              style={{ width: "-webkit-fill-available", margin: "2%", marginTop: "0px" }}
              InputProps={{
                endAdornment: <Button variant="outlined" style={{ color: "#FF3366", borderColor: "#FF3366" }}
                  onClick={()=> getData(countryFilter)}>
                  Choose
                </Button>
              }}>

            </TextField>

          </Box>
          <div style={{ display: "inline-block", width: "inherit", marginTop: "2%" }}>
            <TextField
              id="filled-basic"
              label="UPC CODE"
              variant="filled"
              style={{ width: "82%" }}
              type="text"
              value={text}
              onChange={e => onChangeUpc(e.target.value)}
            ></TextField>

            <Button variant="contained" style={{ marginLeft: "2%", marginTop: "1%", backgroundColor: "#FF3366" }} onClick={() => pushUpc()}><i>+ ADD UPC CODE</i></Button>
            {suggestions.map((suggestion, i) =>
              <div key={i} className="suggestion col-md-12 justify-content-md-center" onClick={() => onChangeSuggestion(suggestion)}>{suggestion}</div>
            )}
            {
              addedUpc.map((t) =>
                <div>{t}</div>)
            }

          </div>
          {(gigyaRole === "Global Admin")?
          <div>
          
          <FormControl variant="filled" style={{ marginTop: "2%", width: "100%" }}>
          <InputLabel id="demo-simple-select-filled-label" style={{ marginTop: "2%" }}>Select Country</InputLabel>
          <Select
             labelId="demo-simple-select-label"
             id="demo-simple-select"
             value={countryFilter}
             label="Select Country"
             style={{ marginTop: "2%", width: "inherit" }}
             onChange={handlecountryChange}
             defaultValue={countryFilter}
             displayEmpty
             >
                {countryOptions.sort((a, b) => a.region > b.region ? 1 : -1).map((item, index) => {
               return (

                <MenuItem  className="countryInputRegion"  key={index} value={item?.countryCode}>
                {item?.region}
                </MenuItem>

               );
             })}

          </Select>
        </FormControl> 
          
          <FormControl variant="filled" style={{ marginTop: "2%", width: "100%" }}>
              
          {(countryFilter && optionList && optionList.length > 0  )? 
          <>
            <InputLabel id="demo-simple-select-filled-label" style={{ marginTop: "2%" }}>Select Retailer</InputLabel>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              style={{ marginTop: "2%", width: "inherit" }}
              value={selectedRetailer} onChange={handleChangeRetailer}
            >
              {optionList.map((item, index) => {
                return (
                  <MenuItem key={index} value={item.SK}> {item.retailerName}</MenuItem>
                )
              })}

            </Select></> :
            (countryFilter === "" )? 
            ""
            :
            <TextField
            id="filled-basic"
            label="Retailer"
            variant="filled"
            value={inputValue}
            onChange={handleInputChange}
          ></TextField>
          //   <TextField
          //   fullWidth
          //   disabled = {true}
          //   id="filled-basic"
          //   label="Retailer"
          //   variant="filled"
          //   value="No Retailer Group Available. Go to retailer tab in the Administration to add Retailer"
          // ></TextField>
        }
            <div style={{ display: "inline-block", width: "inherit", marginTop: "2%" ,marginLeft:"72%"}}>
              <Button variant="contained" onClick={postUploadFile} style={{ width: "13%", marginRight: "2%", marginTop: "3%", marginBottom: "3%", backgroundColor: "#FF3366" }}><i>SAVE</i></Button>
              <Button variant="contained" onClick={cancelHandlerNew} style={{ width: "13%", marginTop: "3%", marginBottom: "3%", backgroundColor: "#FF3366" }}><i>CANCEL</i></Button>
            </div>
          </FormControl>
          </div> :
           <FormControl variant="filled" style={{ marginTop: "2%", width: "100%" }}>
               {(optionList && optionList.length > 0  )? 
               <>
           <InputLabel id="demo-simple-select-filled-label" style={{ marginTop: "2%" }}>Select Retailer</InputLabel>
           <Select
             labelId="demo-simple-select-filled-label"
             id="demo-simple-select-filled"
             style={{ marginTop: "2%", width: "inherit" }}
             value={selectedRetailer} 
             onChange={handleChangeRetailer}
           >

             {optionList.map((item, index) => {
               return (
                 <MenuItem key={index} value={item.SK}> {item.retailerName}</MenuItem>
               )
             })}

           </Select></>
           :
            <TextField
            id="filled-basic"
            label="Retailer"
            variant="filled"
            value={inputValue}
          onChange={handleInputChange}
          ></TextField>
           //   <TextField
          //   fullWidth
          //   disabled = {true}
          //   id="filled-basic"
          //   label="Retailer"
          //   variant="filled"
          //   value="No Retailer Group Available. Go to retailer tab in the Administration to add Retailer"
          // ></TextField>
            
         
        }
           <div style={{ display: "inline-block", width: "inherit", marginTop: "2%" ,marginLeft:"72%"}}>
             <Button variant="contained" onClick={postUploadFile} style={{ width: "13%", marginRight: "2%", marginTop: "3%", marginBottom: "3%", backgroundColor: "#FF3366" }}><i>SAVE</i></Button>
             <Button variant="contained" onClick={cancelHandler} style={{ width: "13%", marginTop: "3%", marginBottom: "3%", backgroundColor: "#FF3366" }}><i>CANCEL</i></Button>
           </div>
         </FormControl>}

          
        <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header >
                <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ textAlign: "center" }}>{t(`My Exports.upload_msg_save`)}</Modal.Body>
            <Modal.Footer>
                <Button variant="primaryButton" style={{backgroundColor:"#89d329", color:"#fff"}} onClick={handleClose}>
                <Link to="/home/my-exports" style={{ color: "#ffffff", textDecoration: "auto" }}>
                {t(`close`)}
                </Link>
                </Button>

            </Modal.Footer>
        </Modal>
        <Modal show={showCancel} onHide={handleCloseCancel} aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header >
                <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ textAlign: "center" }}>{t(`My Exports.upload_msg`)}</Modal.Body>
            <Modal.Footer>
                <Button variant="primaryButton" style={{backgroundColor:"#89d329", color:"#fff"}} onClick={handleCloseCancel}>
                <Link to="/home/my-exports" style={{ color: "#ffffff", textDecoration: "auto" }}>
                {t(`close`)}
                </Link>
                </Button>

            </Modal.Footer>
        </Modal>
        </Container>


      </Container>

    </div>
  )
}
