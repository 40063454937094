import React, { Component } from "react";
import Login from "../../components/Login/login";
import { connect } from "react-redux";

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      claimHeadline: "",
      claimText: "",
      descriptiveHeadline: "",
      descriptiveText: "",
      descriptiveTextBold: "",
      title: this.props.title
    };
  }
  componentDidMount() {
    document.title = this.state.title;
    let { countrySettings } = { ...this.props };
    this.setState({
      claimHeadline: countrySettings?.loginPage?.claimHeadline,
      claimText: countrySettings?.loginPage?.claimText,
      descriptiveHeadline: "",
      descriptiveText: countrySettings?.loginPage?.descriptiveText,
      descriptiveTextBold: countrySettings?.loginPage?.descriptiveTextBold,
      title: this.props.title
    });
  }

  componentDidUpdate(prevProps) {
    let { countrySettings } = { ...this.props };
    if (prevProps !== this.props) {
      this.setState({
        claimHeadline: countrySettings?.loginPage?.claimHeadline,
        claimText: countrySettings?.loginPage?.claimText,
        descriptiveHeadline: "",
        descriptiveText: countrySettings?.loginPage?.descriptiveText,
        descriptiveTextBold: countrySettings?.loginPage?.descriptiveTextBold,
        title: this.props.title
      });
    }
  }

  render() {
    return (
      <Login
        claimHeadline={this.state.claimHeadline}
        claimText={this.state.claimText}
        descriptiveHeadline={this.state.descriptiveHeadline}
        descriptiveText={this.state.descriptiveText}
        descriptiveTextBold={this.state.descriptiveTextBold}
      />
    );
  }
}
const mapStateToProps = state => ({
  countrySettings: state.country.countryData
});
export default connect(mapStateToProps)(LoginPage);
