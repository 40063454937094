import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import "./FavoritesPage.scss";
import CardComponent from "../../components/Card/card.jsx";
import { useMediaQuery } from "react-responsive";
import Skeleton from "react-loading-skeleton";
import { ProgressLoader } from "../../components/ProgressLoader/progressLoader";

const Favorites = (props) => {
  const { title } = { ...props };
  useEffect(() => {
    document.title = title;
  }, [title]);
  const { t } = props;
  let displayAssetList;
  let displayProductList;
  let filteredProductList;
  //let filteredAssettList;
  let products = props.productsList;
  let assets = props.assetsList;
  let favProducts = props.favProductsList;
  let favAssets = props.favAssetsList;

  filteredProductList= favProducts.map((prodId)=>prodId.productId)
  displayProductList = products.filter((product) =>
  filteredProductList.includes(product.id)
  );
  displayAssetList = assets.filter((asset) => favAssets.includes(asset.id));
  // filteredAssettList= favAssets.map((AssetId)=>AssetId.assetId)
  // displayAssetList = assets.filter((asset) => filteredAssettList.includes(asset.id));
  const large = useMediaQuery({
    query: "(min-width: 1200px)",
  });
  const mediumlarge_floor = useMediaQuery({
    query: "(min-width: 992px)",
  });
  const mediumlarge_ceil = useMediaQuery({
    query: "(max-width: 1199px)",
  });
  const medium_ceil = useMediaQuery({
    query: "(max-width: 991px)",
  });
  const medium_floor = useMediaQuery({
    query: "(min-width: 481px)",
  });
  const small_ceil = useMediaQuery({ query: "(max-width: 480px)" });
  const small_floor = useMediaQuery({ query: "(min-width: 376px)" });
  const xs_small = useMediaQuery({ query: "(max-width: 375px)" });

  const cardCount = () => {
    let count = 0;
    if (large) {
      count = 3;
    } else if (mediumlarge_floor && mediumlarge_ceil) {
      count = 4;
    } else if (medium_floor && medium_ceil) {
      count = 6;
    } else if (small_floor && small_ceil) {
      count = 8;
    } else if (xs_small) {
      count = 10;
    }
    return `col-${count}`;
  };
  cardCount();
  const dummySkeletonLoader = () => {
    return (
      <>
        <div className="col-12">
          <div className="row">
            <div className="col-3">
              <Skeleton height={440} width={240} />
            </div>
            <div className="col-3">
              <Skeleton height={440} width={240} />
            </div>
            <div className="col-3">
              <Skeleton height={440} width={240} />
            </div>
            <div className="col-3">
              <Skeleton height={440} width={240} />
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <div className="container">
      <div className="row justify-content-left">
        <div className="fav-heading col-8">
          <span>{t("Header.favorites")}</span>
        </div>
        <ProgressLoader
          area="initial-loading"
          skeleton={dummySkeletonLoader()}
          render={
            <div className="col-12">
              <div
                className={small_ceil ? "row justify-content-center" : "row"}
              >
                {displayProductList.map((product, index) => (
                  <div className={cardCount()}>
                    <CardComponent item={product} />
                  </div>
                ))}

                {displayAssetList.map((asset, index) => (
                  <div className={cardCount()}>
                    <CardComponent item={asset} />
                  </div>
                ))}
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};
const FavoritesPage = withTranslation("common")(Favorites);
const mapStateToProps = (state) => {
  return {
    productsList: state.product.productsList,
    assetsList: state.asset.assetsList,
    favAssetsList: state.asset.favAssetsList,
    CartAssetsList: state.asset.CartAssetsList,
    favProductsList: state.product.favProductsList,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, null)
)(FavoritesPage);
